import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer';

const CompanyReducer = (state, action) => {
    switch (action.type) {
        case constants.COMPANY_DIALOG:
            return {
                ...state,
                visible: action.value
            };
        case constants.COMPANY_DIALOG_SELECTED:
            return {
                ...state,
                visibleAgentSelected: action.value
            };
        case constants.COMPANY_LOAD:
            return {
                ...state,
                company: action.value
            };
        case constants.COMPANY_NAME:
            state.company.name = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_ADDRESS:
            state.company.address = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_PHONE:
            state.company.phone = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_MOBILE:
            state.company.mobile = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_RUT:
            state.company.rut = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_IPC:
            state.company.ipc = action.value;    
            return {
                ...state
            };
        case constants.AGENT_SELECTED:
            state.agent = action.value;
            return {
                ...state,
                visibleAgentSelected: true
            };
        case constants.AGENT_FIRST_NAME:
            state.agent.firstName = action.value;    
            return {
                ...state
            };
        case constants.AGENT_LAST_NAME:
            state.agent.lastName = action.value;  
            return {
                ...state
            };
        case constants.AGENT_EMAIL:
            state.agent.email = action.value;    
            return {
                ...state
            };
        case constants.AGENT_BUILDING_SELECTED:
            if (state.agent.buildings === undefined){
                state.agent.buildings = [];
            }
            if (state.agent.buildings.indexOf(action.value) < 0){
                state.agent.buildings.push(action.value);
            }
                
            return {
                ...state
            };
        case constants.AGENT_BUILDING_ELIMINATED_SELECTED:
            state.agent.buildingSelected = action.value;    
            return {
                ...state
            };
        case constants.AGENT_BUILDING_ELIMINATED_SAVE:
        console.log(state.agent.buildingSelected)    
        if (state.agent.buildingSelected){
            let items = state.agent.buildings;
            let index = state.agent.buildings.indexOf(state.agent.buildingSelected);
            state.agent.buildings = items.slice(0, index).concat(items.slice(index + 1, items.length));    
            }
                
            return {
                ...state
            };
        case constants.COMPANY_ICON:
            state.icon = action.value
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default CompanyReducer;