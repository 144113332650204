import * as constants from '../../../Constants';

const ChargeExternalReducer = (state, action) => {
    switch (action.type) {
        case constants.BUILDING_EXTERNAL_LOAD:
            state.buildingData.externalAbitab = action.value.filter(e => e.paymentType === 'ABITAB')[0];
            state.buildingData.externalRedPago = action.value.filter(e => e.paymentType === 'REDPAGOS')[0];

            if (state.buildingData.externalAbitab === undefined){
                state.buildingData.externalAbitab = {}
            }
            if (state.buildingData.externalRedPago === undefined){
                state.buildingData.externalRedPago = {}
            }

            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_ABITAB_COMPANY_NAME:
            state.buildingData.externalAbitab.companyName = action.value;
            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_ABITAB_COMPANY_NR:
            state.buildingData.externalAbitab.companyNr = action.value;
            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_ABITAB_SUBCOMPANY_NR:
            state.buildingData.externalAbitab.subcompanyNr = action.value;
            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_REDPAGO_COMPANY_NAME:
            state.buildingData.externalRedPago.companyName = action.value;
            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_REDPAGO_COMPANY_NR:
            state.buildingData.externalRedPago.companyNr = action.value;
            return {
                ...state
            };
        case constants.BUILDING_EXTERNAL_REDPAGO_SUBCOMPANY_NR:
            state.buildingData.externalRedPago.subcompanyNr = action.value;
            return {
                ...state
            };
        default: return state;
    }
};

export default ChargeExternalReducer;