import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import * as constants from "../../Constants";
import * as action from "./actions/EventAction"

const EventDialog = ({ state, dispatch, fn }) => {

    const onSave = () => {
        action.saveEvent(state, dispatch, fn);
    }

    return (
            <div className="p-fluid">
                <form className="p-fluid" onSubmit={onSave}>
                    <div className="p-md-8 p-sm-12 inline">
                        <Calendar placeholder="Hora" showTime={true} value={state.event.start} style={{marginBottom:'2px'}} 
                            onChange={(e) => dispatch({type: constants.EVENT_START, value: e.value})} required/>
                        <InputText value={state.event.place} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.EVENT_PLACE, value: e.target.value})} placeholder="Lugar"/>
                        <InputText value={state.event.people} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.EVENT_PEOPLE, value: e.target.value})} placeholder="Persona"/>
                        <InputText value={state.event.title} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.EVENT_TITLE, value: e.target.value})} placeholder="Motivo" required/>
                        <Button label="Crear" className="p-button-warning p-md-12 p-sm-12" icon="pi pi-plus" iconPos="right" />
                    </div>                             
                </form>    
            </div>
    );
}

export default EventDialog;
