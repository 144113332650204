import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ProgressBar} from 'primereact/progressbar';
import '../../css/Grid.css';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import CommonAreaReducer from './reducers/CommonAreaReducer';
import CommonAreaDialog from './CommonAreaDialog';
import * as access from "../AccessAction";
import * as action from "./actions/CommonAreaAction";
import * as utils from "../Utils";

const CommonAreaForm = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.search(null, state, dispatch, setLoading);
        }
        }, []
    );
    
    const [loading, setLoading] = useState(true)
    const [visibleCommonArea, setVisibleCommonArea] = useState(false)
    const [visibleCommonAreaEdit, setVisibleCommonAreaEdit] = useState(false)
    const [visibleCommonAreaEliminated, setVisibleCommonAreaEliminated] = useState(false)
    const [state, dispatch] = useReducer(CommonAreaReducer, {
        ...prevState,
        access: true,
        area: {}
    });

    const openCreateCommonArea = () => {
        state.area = {};
        setVisibleCommonArea(true);
    };

    function actionCommonAreaRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selectedCommonArea(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedCommonArea(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedCommonArea(e){
        setVisibleCommonAreaEdit(true)
        dispatch({type: constants.COMMON_AREA_SELECTED, value: e})
    }

    function selectedElimindatedCommonArea(e){
        setVisibleCommonAreaEliminated(true);
        dispatch({type: constants.COMMON_AREA_SELECTED, value: e})
    }

    const confirmationCommonAreaEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedArea(state, dispatch, setVisibleCommonAreaEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleCommonAreaEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.search(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nueva area común" visible={visibleCommonArea} onHide={() => setVisibleCommonArea(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <CommonAreaDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {true}
                    fn = {setVisibleCommonArea}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar area común" visible={visibleCommonAreaEdit} onHide={() => setVisibleCommonAreaEdit(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <CommonAreaDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleCommonAreaEdit}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Eliminar area común" visible={visibleCommonAreaEliminated} modal={true} footer={confirmationCommonAreaEliminated} onHide={() => setVisibleCommonAreaEliminated(false)}>
                Esta seguro que desea eliminar el area común?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Areas comunes</h2>

                    <div class="p-grid box-default-margin">
                        <div className="p-md-12">
                            <Button label="Crear" onClick={() => openCreateCommonArea()}
                                className="p-button-warning" icon="pi pi-plus" iconPos="right"
                            />
                        </div>
                        {
                            (!state.areas || state.areas.length === 0) &&
                            <h3 className="title-content">Sin información de areas comunes</h3>
                        }
                        {
                            state.areas && state.areas.length > 0 &&
                            <DataTable value={state.areas} paginator responsive rows={10}
                            lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} 
                            style={{marginTop:'10px' }} >
                                <Column field="name" header="Nombre" sortable={true}/>
                                <Column field="cost" header="Costo"/>
                                <Column field="movementGroup.name" header="Grupo movimiento"/>
                                <Column field="movementGroup.account.name" header="Cuenta"/>
                                <Column header="Acciones" body={(r) => actionCommonAreaRow(r)}/>
                            </DataTable>
                        }
                    </div>          
                </div>
            }
        </Fragment>
    );
}


export default CommonAreaForm;
