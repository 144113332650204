import React from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import * as utils from "../Utils";

const PayExpenseDialog = ({ state, dispatch }) => {

    function priceRow(value, currencySymbol) {
        return utils.formatCurrency(value, currencySymbol);
    }

    return (
        <div className="p-fluid">   
                { state.paymentExpenses && state.paymentExpenses.length === 0 &&
                    <div className="p-grid p-justify-center" >
                        <h3 className="title-content">No hay pagos asociados.</h3>
                    </div>
                }
                { state.paymentExpenses && state.paymentExpenses.length > 0 &&
                    <div className="p-md-12 inline">
                        <DataTable value={state.paymentExpenses}
                            responsive={true} scrollable={true} style={{marginTop:'10px', padding:'10px' }} >
                            <Column field="value.name" header="Descripción" sortable={true} sortable={true}/>
                            <Column field="value.amount" header="Monto" body={(r) => priceRow(r.value.amount, state.building.currency)}/>
                            <Column field="value.document" header="Documento" sortable={true}/>
                            <Column field="value.group" header="Grupo" sortable={true}/>
                            <Column field="value.payDate" header="F. Mov." style={{width: '15%'}} sortable={true}/>
                            <Column field="value.created" header="F. Creación" style={{width: '15%'}} sortable={true}/>
                            <Column field="value.supplier" header="Proveedor" sortable={true}/>
                        </DataTable>
                    </div>
                }
 
        </div>
    );
}

export default PayExpenseDialog;
