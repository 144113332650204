import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const search = (state, dispatch, fn) => {
    if (!state.filter){
        state.filter = {};
    }
    state.filter.userId = state.user.id;
    axios(
        access.build('post', `/api/prices/user/${state.user.id}/filter`, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.PRICE_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false)
        access.responseData('error', e.message, state, constants.PRICE_LOAD, dispatch, false);
    }) 
}

export const searchByFilters = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/prices/filter`, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.PRICE_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false)
        access.responseData('error', e.message, state, constants.PRICE_LOAD, dispatch, false);
    }) 
}

export const eliminatedPrice = (state, dispatch, fn, setLoading) => {
    setLoading(true)
    axios(
        access.build('delete','/api/prices/' + state.price.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Cotización eliminado correctamente.';

            state.price = {};
            severity = 'info';
            
            search(state, dispatch, setLoading);
            fn(false)
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);setLoading(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const savePrice = (state, dispatch, isNew, fn, setLoading) => {
    state.price.userId = state.user.id;

    axios(
        access.build('post', '/api/prices', state.price)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200){
            if (state.upload){
                upload(state, dispatch, isNew, fn, setLoading, response.data.id);
            } else {
                message = isNew ? 'Cotización creada correctamente.' : 'Cotización modificada correctamente.';
                severity = 'info';
                
                state.price = {};
                search(state, dispatch, setLoading);
                fn(false);

                access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
            }
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);setLoading(false);
            access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
        }
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 

const upload = (state, dispatch, isNew, fn, setLoading, id) => {
    const data = new FormData();
    data.append('file', state.upload);
    axios(
        access.build('post', '/api/prices/upload/' + id, data)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = isNew ? 'Cotización creada correctamente.' : 'Cotización modificada correctamente.';
            severity = 'info';
            
            state.price = {};
            search(state, dispatch, setLoading);
            fn(false);
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);setLoading(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const download = (value, state, dispatch, fn) => {
    fn(true)
    axios(`/api/prices/download/` + value.id, {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        if (response.data == null){
            access.responseData('error', 'Error al descargar el archivo solicitado.', state, constants.DEFAULT, dispatch, false);
        }
        fn(false);
        const file = new Blob(
          [response.data], 
          {type: 'application/json'});

        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(file);
        a.href = blobUrl;
        a.download = response.headers['content-disposition'];
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    })
    .catch(error => {
        fn(false);
    });
}