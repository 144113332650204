import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../../Constants";
import * as action from "./actions/PriceAction";
import {FileUpload} from 'primereact/fileupload';

const PriceDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        if (!isNew){
            state.statuses.map(item => {
                if (item.type === state.price.status){
                    state.price.statusSelected = item;
                }       
            });

            state.user.suppliers.map(item => {
                if (item.id === state.price.supplierId){
                    state.price.supplier = item;
                }       
            });
        }
        }, []
    );

    const onSave = () => {
        setLoading(true)
        action.savePrice(state, dispatch, isNew, fn, setLoading)
    }

    const upload = (event) => {
        dispatch({type: constants.PRICE_UPLOAD, value: event.files[0]})
    };

    return (
        <form className="p-fluid" onSubmit={onSave}>
            <div className="p-grid">
                <div className="p-md-offset-2 p-md-8 p-sm-12">
                    <InputText value={state.price.name} style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.PRICE_NAME, value: e.target.value})} placeholder="Nombre" required />
                    <InputText value={state.price.workId} style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.PRICE_WORK_ID, value: e.target.value})} placeholder="Trabajo relacionado (ingrese nro de referencia)" />
                    <Dropdown optionLabel="name" value={state.price.supplier} options={state.user.suppliers} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.price.supplier ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.PRICE_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor" required/>
                    <Dropdown optionLabel="name" value={state.price.statusSelected} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.price.statusSelected ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.PRICE_STATUS_SELECTED, value: e.value})} placeholder="Estado" required/>
                    <InputTextarea rows={5} cols={20} value={(state.price.description || state.price.description != null) ? state.price.description : ''} placeholder="Detalle" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.PRICE_DESCRIPTION, value: e.target.value})}/>

                    <FileUpload mode="basic" 
                        name="name" 
                        accept=".jpg, .png, .pdf" 
                        url="./upload"
                        mode="advanced"
                        maxFileSize={1000000} //1MB
                        customUpload={true} 
                        uploadHandler={(event) => upload(event)} 
                        chooseLabel="Seleccionar"
                        uploadLabel="Cargar"
                        cancelLabel="Cancelar"/>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-md-4 p-sm-12">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>
            </div> 
        </form>
     
    );
}

export default PriceDialog;
