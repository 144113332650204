import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer'
import * as utils from "../../Utils";

const CommonAreaReducer = (state, action) => {
    switch (action.type) {
        case constants.COMMON_AREA_SEARCH:
            return {
                ...state,
                areas: action.value.content,
                totals: action.value.totalElements
            };
        case constants.COMMON_AREA_SELECTED:
            state.area = action.value;
            state.area.movementGroupId = state.area.movementGroup.id;
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_BUILDING:
            if (!utils.isEmpty(state.area)){
                state.area.movementGroup = action.value.content.filter(type => type.id === state.area.movementGroupId)[0];
            }
            return {
                ...state,
                movements: action.value.content
            };
        case constants.COMMON_AREA_NAME:
            state.area.name = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_DESCRIPTION:
            state.area.description = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_COST:
            state.area.cost = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_MOVEMENT_GROUP:
            state.area.movementGroup = action.value
            state.area.movementGroupId = action.value.id;
            return {
                ...state
            };
        case constants.COMMON_AREA_BOOKING_LIMIT:
            state.area.bookingLimit = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_BOOKING_MAX:
            state.area.bookingMax = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_CHECK_ADMIN:
            state.area.checkAdmin = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_CHECK_DEBTOR_UNIT:
            state.area.debtorUnit = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_RESERVATION_PER_USER:
            state.area.reservationPerUser = action.value;
            return {
                ...state
            }; 
        case constants.COMMON_AREA_ANTICIPATION_DAY:
            state.area.anticipationDay = action.value;
            return {
                ...state
            }; 
        case constants.COMMON_AREA_RESERVATION_MAX_DAY:
            state.area.reservationMaxDay = action.value;
            return {
                ...state
            };
        case constants.COMMON_AREA_RESERVATION_DAY:
            state.area.reservationDay = action.value;
            return {
                ...state
            };       
        case constants.CASH_UNITS:
            return {
                ...state,
                units: action.value
            };    
        case constants.DEFAULT:
            return {
                ...state
            };  
        default: return BuildingReducer(state, action);
    }
};

export default CommonAreaReducer;