import * as constants from '../../../Constants';
import CompanyReducer from '../../company/reducers/CompanyReducer'

const ComunicationReducer = (state, action) => {
    switch (action.type) {
        case constants.COMUNICATION_COMPANY_LOAD:
            return {
                ...state,
                company: action.value
            };
        case constants.COMUNICATION_CATEGORY_LOAD:
            return {
                ...state,
                categories: action.value
            };
        case constants.COMUNICATION_LOAD:
            return {
                ...state,
                comunications: action.value
            };
        case constants.COMUNICATION_SUMMARY:
            state.summary = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_DATE:
            state.filter.date = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_UNIT_SELECTED:
            state.filter.unit = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_UNIT_SELECTED:
            state.filter.unit = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_SUPPLIER_SELECTED:
            state.filter.supplier = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_CATEGORY_SELECTED:
            state.filter.category = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_AGENT_SELECTED:
            state.filter.agent = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_TYPE_SELECTED:
            state.filter.type = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_FILTER_STATUS_SELECTED:
            state.filter.status = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_CATEGORY_SELECTED:
            state.comunication.category = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_SELECTED:
            state.comunication = action.value.value
            return {
                ...state
            };
        case constants.COMUNICATION_TITLE:
            state.comunication.title = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_DESCRIPTION:
            state.comunication.description = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_TYPE_SELECTED:
            state.comunication.typeSelected = action.value
            state.comunication.type = action.value.type;
            return {
                ...state
            };
        case constants.COMUNICATION_STATUS_SELECTED:
            state.comunication.statusSelected = action.value
            state.comunication.status = action.value.type;
            return {
                ...state
            };
        case constants.COMUNICATION_UNIT_SELECTED:
            state.comunication.unit = action.value
            return {
                ...state
            };
        case constants.COMUNICATION_SUPPLIERS:
            state.suppliers = action.value
            return {
                ...state
            };
        case constants.CASH_UNITS:
            return {
                ...state,
                units: action.value
            };
        default: return CompanyReducer(state, action);
    }
};

export default ComunicationReducer;