import React, {useEffect, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {Calendar} from 'primereact/calendar';

import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import * as action from "./actions/IncomeExpenseAction"
import * as actionMovementGroup from "../movement/group/actions/MovementGroupAction";

const IncomeDialog = ({ state, dispatch, fn, setLoading}) => {

    useEffect(() => {
        state.income.expires = new Date()
        actionMovementGroup.movementGroupsByType(state, dispatch, 'INCOME', setLoading);
      }, [])

    const onSave = () => {
        action.saveIncome(state, true, dispatch, fn, setLoading);
    }

    const clear = () => {
        dispatch({type: constants.CASH_INCOME_UNIT_SELECTED, value: undefined});
    };

    const changeMovementGroup = (e) => {        
        if (e.value.name === 'Deuda inicial'){
            state.income.description = 'Deuda inicial'
        }

        dispatch({type: constants.CASH_INCOME_MOVEMENT_GROUP_SELECTED, value: e.value})
    };

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-12">
                    <div className="p-md-6">
                        <Calendar locale={configurations.es} placeholder="Fecha" showIcon={true} value={state.income.expires} dateFormat="dd/mm/yy"
                                tooltip="Fecha que expira el ingreso mensual"
                                onChange={(e) => dispatch({type: constants.CASH_INCOME_EXPIRES_SELECTED, value: e.value})} required/>
                        
                    </div>
                    <div className="p-md-12">
                        <InputText value={state.income.description} className="p-md-12" placeholder="Descripción" 
                            onChange={(e) => dispatch({type: constants.CASH_INCOME_DESCRIPTION, value: e.target.value})}/>
                    </div>
                    
                    <div className="p-md-6">
                        <Dropdown optionLabel="name" value={state.income.movementGroup} options={state.movementsByIncome} 
                            style={{borderLeft: !state.income.movementGroup ? '3px solid salmon' : ''}} 
                            onChange={(e) => changeMovementGroup(e)} placeholder="Grupo" required/>
                    </div>

                    {/*             
                    <div className="p-md-6">
                        <Dropdown  optionLabel="name" value={state.income.oType} options={peoples}
                            style={{borderLeft: !state.income.oType ? '3px solid salmon' : ''}} 
                            onChange={(e) => dispatch({type: constants.CASH_INCOME_PEOPLE_TYPE, value: e.value})} placeholder="Tipo" required/>
                    </div>
                    */}
                    { state.units &&
                        <div className="p-md-6">
                            <div className="p-md-8 inline" style={{marginLeft:'-5px'}}>
                                 <Dropdown optionLabel="name" value={state.income.unit} options={state.units} 
                                      onChange={(e) => dispatch({type: constants.CASH_INCOME_UNIT_SELECTED, value: e.value})} placeholder="Unidad"
                                      required = {state.income.movementGroup && state.income.movementGroup.name === 'Deuda inicial'}
                                      style={{borderLeft: (state.income.movementGroup && state.income.movementGroup.name === 'Deuda inicial') ? '3px solid salmon' : ''}} />
                             </div>
                            {
                                state.income.unit &&
                                <div className="p-md-2 inline">
                                    <i className="pi pi-trash" style={{ marginLeft: '10px',fontSize: '2em', color:'#f88d12', verticalAlign:'middle' }} onClick={() => clear()} title="Limpiar filtros"></i>  
                                </div>
                            }
                        </div>
                    }

                    <div className="p-md-4">
                        <InputText value={state.income.amount} placeholder="Monto" type="number" tooltip={'Monto en ' + `${state.building.currency}`}                            onChange={(e) => dispatch({type: constants.CASH_INCOME_AMOUNT, value: e.target.value})} step='0.01' required/>
                    </div>
                    <div className="p-md-4">
                        <InputText value={state.income.number} placeholder="Número de comprobante"
                            onChange={(e) => dispatch({type: constants.CASH_INCOME_NUMBER, value: e.target.value})}/>
                    </div>

                    <div className="p-md-12">
                        <InputTextarea rows={3} cols={20} value={state.income.observations} placeholder="Observaciones" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.CASH_INCOME_OBSERVATIONS, value: e.target.value})}/>
                    </div>
                </div>

                <div className="p-md-3">
                    <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
     
    );
}

export default IncomeDialog;
