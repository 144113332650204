import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import * as constants from "../../Constants";
import * as action from "./actions/PeopleAction";


const PeopleDialog = ({ state, isNew, dispatch }) => {

    const values = [{name: 'Propietario', code: 'P'}, {name: 'Inquilino', code: 'I'}]

    useEffect(() => {
        if (isNew){
            state.people = {};
        } else {
            console.log(state.people)
            //load Dropdown peopleType
            values.forEach(value => {
                if (value.code === state.people.peopleType){
                    state.people.oType = value;
                }
            })

            action.unitsByPeople(state.people, state, dispatch);
        }
            
      }, []) 

    const onSave = () => {
        action.savePeople(state, isNew, dispatch)
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-6">
                    <InputText value={state.people.firstName} style={{marginBottom:'2%'}} placeholder="Nombre"
                        onChange={(e) => dispatch({type: constants.PEOPLE_FIRST_NAME, value: e.target.value})} required/>
                    <InputText id="in" value={state.people.lastName} style={{marginBottom:'2%'}} placeholder="Apellido"
                        onChange={(e) => dispatch({type: constants.PEOPLE_LAST_NAME, value: e.target.value})} required/>
                    <InputText id="in" value={state.people.documentNr} style={{marginBottom:'2%'}} placeholder="Nro. documento"
                        onChange={(e) => dispatch({type: constants.PEOPLE_DOCUMENT, value: e.target.value})} required/>
                    <InputText id="in" value={state.people.email} style={{marginBottom:'2%'}} placeholder="Email"
                        onChange={(e) => dispatch({type: constants.PEOPLE_EMAIL, value: e.target.value})} required/>
                    <InputText id="in" value={state.people.phone} style={{marginBottom:'2%'}} placeholder="Telefono"
                        onChange={(e) => dispatch({type: constants.PEOPLE_PHONE, value: e.target.value})}/>
                    <InputText id="in" value={state.people.mobile} style={{marginBottom:'2%'}} placeholder="Celular"
                        onChange={(e) => dispatch({type: constants.PEOPLE_MOBILE, value: e.target.value})}/>
                    <Dropdown  optionLabel="name" value={state.people.oType} options={values} style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.PEOPLE_TYPE, value: e.value})} placeholder="Tipo"/>
                    {
                        !isNew &&
                        <div style={{marginTop:'15px'}}>
                            <Checkbox onChange={(e) => dispatch({type: constants.PEOPLE_CHECK_ELIMINATED, value: e.checked})} checked={state.people.eliminated}></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label">Eliminar</label>
                        </div>
                    }
                </div>

                {
                    !isNew && state.people.units && state.people.units.length > 0 &&
                    <div className="box-default-margin">
                        <div className="p-md-12">
                            <h2 className="title-content-menu">Unidades</h2>
                            <DataTable value={state.people.units} paginator={true} rows={10} selectionMode="single" style={{marginTop:'10px'}} >
                                <Column field="name" header="Nombre" />
                                <Column field="buildingId" header="Id. Edificio" />
                            </DataTable>
                        </div>
                    </div>
                }    

                <div className="p-md-12 p-sm-12">
                    { isNew && 
                        <div className="p-md-3">
                            <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right"/>
                        </div>
                    }
                    
                    { !isNew && 
                        <div className="p-md-3" style={{marginTop: '10px'}}>
                            <Button label="Guardar" className="p-button-warning" icon="pi pi-pencil" iconPos="right"/>
                        </div>
                    }
                </div>    
            </form>
        </div>
    );
}

export default PeopleDialog;
