import * as constants from '../../../Constants';

const LoginReducer = (state, action) => {
    switch (action.type) {
        case constants.LOGIN:
            state.isLogged = action.value != null;
            state.error = null;
            return {
                ...state,
                user: action.value
            };
        case constants.LOGIN_EMAIL:
            if (!state.user)
                state.user = {}
            state.user.email = action.value;    
            return {
                ...state
            };
        case constants.LOGIN_PASSWORD:
            if (!state.user)
                state.user = {}
            state.user.password = action.value;    
            return {
                ...state
            };
        case constants.LOGIN_ERROR:    
            return {
                ...state,
                error: action.value
            };
        case constants.LOGIN_ERROR_USER:    
            return {
                ...state,
                user: action.value,
                messageError: action.messageError,
                message: action.message
            };
        case constants.LOGIN_USER_CONTACT:
            state.user.contact = action.value;    
            return {
                ...state
            };
        case constants.LOGIN_FORWARD_REGISTER:
            return {
                ...state,
                forwardRegister: true
            };
        case constants.REGISTER:
            state.error = null
            return {
                ...state,
                verify: action.value
            };
        case constants.REGISTER_FORWARD_LOGIN:
            return {
                ...state,
                forwardLogin: true
            };
        case constants.REGISTER_NAME:
            state.register.name = action.value;    
            return {
                ...state
            };    
        case constants.REGISTER_EMAIL:
            state.register.email = action.value;    
            return {
                ...state
            };
        case constants.REGISTER_PASSWORD:
            state.register.password = action.value;    
            return {
                ...state
            };
        case constants.REGISTER_REPLY_PASSWORD:
            state.register.reply = action.value;    
            return {
                ...state
            };
        case constants.REGISTER_ROLE:    
            state.role = action.value;
            state.register.role = action.value.code
            return {
                ...state
            };
        case constants.REGISTER_VERIFY_CODE:
            state.verifyCode = action.value;    
            return {
                ...state
            };
        case constants.REGISTER_VERIFY_ERROR:
            state.verifyError = action.value;    
            return {
                ...state
            };
        case constants.REGISTER_ERROR:    
            return {
                ...state,
                error: action.value
            };
        default: return state;
    }
};

export default LoginReducer;