import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {Calendar} from 'primereact/calendar';
import {DataTable} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {Column} from 'primereact/column';
import {TabView,TabPanel} from 'primereact/tabview';
import '../../css/Grid.css';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import classNames from 'classnames';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import PaymentReducer from './reducers/PaymentReducer';
import PaymentDialog from './PaymentDialog';
import PaymentDetailDialog from './PaymentDetailDialog'; 
import PaymentTotalDialog from './PaymentTotalDialog';
import * as access from "../AccessAction";
import * as action from "./actions/CashAction";
import * as actionPayment from "./actions/PaymentAction";
import * as actionSupplier from "../supplier/actions/SupplierAction";
import * as actionMovementGroup from "../movement/group/actions/MovementGroupAction";
import * as utils from "../Utils";

const Cash = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building !== undefined){
            prevState.activeIndex = 0;
            action.summary(utils.period(0), prevState.building, prevState, dispatch, setLoading);
        }
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visiblePayment, setVisiblePayment] = useState(false)
    const [visiblePaymentDetail, setVisiblePaymentDetail] = useState(false)
    const [visiblePaymentTotal, setVisiblePaymentTotal] = useState(false)
    const [openFilterDrawerPayment, setOpenFilterDrawerPayment] = useState(false)
    const [openFilterDrawerPaymentExpense, setOpenFilterDrawerPaymentExpense] = useState(false)
    const [visibleEliminatedPayment, setVisibleEliminatedPayment] = useState(false)
    const [openFilterDrawerPaymentEliminated, setOpenFilterDrawerPaymentEliminated] = useState(false)
    const [state, dispatch] = useReducer(PaymentReducer, {
        ...prevState,
        access: true,
        paymentExpense: {},
        activeIndex: 0,
        summary: {},
        paymentSummary: {},
        payment: {
            date: new Date(),
            minimum: 0,
            pay: 0,
            paymentType: 'UNKNOWN',
            depositUndefined: false
        }
    });

    const closeFilterDrawerPayment  = () => {
        setOpenFilterDrawerPayment((prevState) => !prevState)
    }

    const closeFilterDrawerPaymentExpense  = () => {
        setOpenFilterDrawerPaymentExpense((prevState) => !prevState)
    }

    const closeFilterDrawerPaymentEliminated = () => {
        setOpenFilterDrawerPaymentEliminated((prevState) => !prevState)
    }

    function loadFilters(fn, typeGroup){
        state.filter = {}

        actionSupplier.suppliers(state, dispatch, constants.CASH_EXPENSE_SUPPLIERS);
        if (typeGroup === "EXPENSE"){
            actionMovementGroup.movementGroupsByTypeOnlineEvent(state, dispatch, typeGroup);
        }
        fn(true)
    }

    function loadEliminatedFilters(fn){
        state.filter = {
            eliminated: true
        }
        fn(true)
    }

    const changeTab = (index) => {
        setLoading(true)
        state.activeIndex = index;
        action.search(state, dispatch, setLoading);
        return;
    }

    function search() {
        setLoading(true);
        action.search(state, dispatch, setLoading);
    }

    function priceRow(value, currencySymbol) {
        return utils.formatCurrency(value, currencySymbol);
    }

    function typePaymentRow(value){
        return actionPayment.namePaymentByType(value);
    }

    function unitRow(value) {
        return value === undefined ? 'Sin identificar' : value;
    }

    const clear = () => {
        state.unit = undefined;
        dispatch({type: constants.DEFAULT})
    };

    function actionsRow(value) {
        return <div>
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedPayment(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedElimindatedPayment(e){
        dispatch({type: constants.PAYMENT_SELECTED, value: e})
        setVisibleEliminatedPayment(true);
    }

    const confirmationEliminatedPayment = (
        <div>
            <Button icon="pi pi-check" onClick={() => actionPayment.eliminatedPayment(state, dispatch, setVisibleEliminatedPayment)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminatedPayment(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoadPayment(event, fn) {
        state.first = event.first;
        if (state.findByFilter){
            action.searchByFilter(event, state, dispatch, fn, setLoading)
        } else {
            setLoading(true);
            action.pagination(event, state, dispatch, setLoading);
        }
    }

    function openPaymentDialog(){
        setLoading(true)
        actionPayment.loadPayment(state, dispatch, state.building, setVisiblePayment, setLoading);
    }

    function openPaymentDetailDialog(){
        setLoading(true)
        actionPayment.loadPayment(state, dispatch, state.building, setVisiblePaymentDetail, setLoading);
    }

    function openPaymentTotalDialog(){
        setVisiblePaymentTotal(true)
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Nuevo pago" maximizable={true} visible={visiblePayment} onHide={() => setVisiblePayment(false)} style={{maxWidth: mobile() ? '90%' : '90%'}} closeOnEscape = {false} >
                <PaymentDialog
                    state = { state }
                    dispatch = { dispatch } 
                    fn = {setVisiblePayment}
                    fnLoading = {setLoading}
                    mobile = {mobile} />                
            </Dialog>
            
            <Dialog header="Nuevo pago detallado" visible={visiblePaymentDetail} onHide={() => setVisiblePaymentDetail(false)} style={{maxWidth: mobile() ? '90%' : '30%'}} >
                <PaymentDetailDialog
                    state = { state }
                    dispatch = { dispatch }
                    fn = {setVisiblePaymentDetail} 
                    fnLoading = {setLoading}
                    mobile = {mobile} />
            </Dialog>
            <Dialog header="Nuevo pago totalizado" maximizable={true} visible={visiblePaymentTotal} onHide={() => setVisiblePaymentTotal(false)} style={{maxWidth: mobile() ? '90%' : '90%'}} closeOnEscape = {false} >
                <PaymentTotalDialog
                    state = { state }
                    dispatch = { dispatch } 
                    fn = {setVisiblePaymentTotal}
                    fnLoading = {setLoading}
                    mobile = {mobile} />                
            </Dialog>
            <Dialog header="Eliminar Pago" visible={visibleEliminatedPayment} modal={true} footer={confirmationEliminatedPayment} onHide={() => setVisibleEliminatedPayment(false)}>
                Esta seguro que desea eliminar el pago?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Recaudación</h1>
                    </div>    
                    <div className="p-md-2 inline">
                        {
                            state.rangeEnd && 
                            <Fragment>
                                    <h2 className="title-content"  style={{marginBlockStart: '1%', marginBlockEnd:'1%'}} >{String(state.periodMonth).toUpperCase()} {state.periodYear}</h2>
                                    <h3 className="title-content"  style={{marginBlockStart: '1%'}} >Fecha de cierre: {state.rangeEnd}</h3>
                            </Fragment>   
                        }
                    </div>

                    <div class="p-grid p-justify-center box-default-margin"> 
                        <div className="p-md-8 p-sm-12">   
                            <Calendar locale={configurations.es} placeholder="Periodo" showIcon={true} value={state.period} view="month"
                                    dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2050"
                                    onChange={(e) => dispatch({type: constants.CASH_PERIOD_SELECTED, value: e.value})}/>
                            <div style={{marginLeft:'1%'}}></div>
                            <div style={{marginTop:'1%'}}>
                                <Dropdown optionLabel="name" value={state.unit} options={state.units} className="p-md-3 p-sm-12"
                                        onChange={(e) => dispatch({type: constants.CASH_UNIT_SELECTED, value: e.value})} placeholder="Unidades"/>
                                <span>
                                    <Button label="Buscar" onClick={() =>  search()} style={{marginLeft: mobile() ? '0%' : '1%', marginTop: mobile() ? '1%' : '0%'}}
                                        className="p-button-warning p-lg-2 p-md-3 p-sm-12" icon="pi pi-search" iconPos="right" />
                                    {
                                        state.unit &&
                                        <i className="pi pi-trash" style={{ marginLeft: '10px',fontSize: '2em', color:'#f88d12' }} onClick={() => clear()} title="Limpiar filtros"></i>  
                                    }    
                                                    
                                </span>
                            </div>
                        </div>
                        { state.admin && 
                            <div className="p-md-2 p-sm-12 inline">
                                <Button label="Nuevo pago" 
                                        className="p-button-warning p-md-10 p-sm-12" icon="pi pi-fw pi-plus" iconPos="right"
                                        onClick = {() => openPaymentDialog()}
                                        style={{margin:'1%'}}
                                        tooltip="Para ingresar un pago manual debe seleccionar previamente una unidad"
                                        disabled = {state.building === undefined} />
                                <Button label="Nuevo pago detallado" 
                                        className="p-button-warning p-md-10 p-sm-12" icon="pi pi-fw pi-plus" iconPos="right"
                                        style={{margin:'1%'}}
                                        onClick = {() => openPaymentDetailDialog()}
                                        tooltip="Para ingresar un pago manual debe seleccionar previamente una unidad"
                                        disabled = {state.building === undefined} /> 
                                <Button label="Nuevo pago totalizado" 
                                        className="p-button-warning p-md-10 p-sm-12" icon="pi pi-fw pi-plus" iconPos="right"
                                        style={{margin:'1%'}}
                                        onClick = {() => openPaymentTotalDialog()}
                                        tooltip="Para ingresar un pago manual debe seleccionar previamente una unidad"
                                        disabled = {state.building === undefined} /> 
                            </div>
                        }
                    </div>
                    <div class="p-grid p-justify-center box-default" style={{marginTop:'2%'}}>
                        <div class="p-md-2 p-sm-11">
                            <div class="box-default" style={{marginTop:'2%'}}>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content" style={{marginBlockEnd: 0}}>Total a recaudar</h2>
                                    </div> 
                                </div>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.income)}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-2 p-sm-11">
                            <div class="box-default" style={{marginTop:'2%'}}>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content" style={{marginBlockEnd: 0}}>Recaudado</h2>
                                    </div> 
                                </div>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.pay)}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-2 p-sm-11">
                            <div class="box-default" style={{marginTop:'2%'}}>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content" style={{marginBlockEnd: 0}}>Pendientes</h2>
                                    </div> 
                                </div>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.pending)}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-md-2 p-sm-11">
                            <div class="box-default" style={{marginTop:'2%'}}>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content" style={{marginBlockEnd: 0}}>Gastos</h2>
                                    </div> 
                                </div>
                                <div class="p-md-12">
                                    <div class="p-grid p-justify-center">
                                        <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.expense_pay)}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TabView className="p-col-12" style={{marginTop:'2%'}} activeIndex={state.activeIndex} onTabChange={(e) => changeTab(e.index)}>
                            <TabPanel header="Pagos">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Filtros" onClick={() => loadFilters(setOpenFilterDrawerPayment, "INCOME")}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                <Drawer open={openFilterDrawerPayment} onClose={closeFilterDrawerPayment} direction='right' size={mobile() ? 250 : 500}>
                                    <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                        <h3 className="title-content">Filtros</h3>
                                        { openFilterDrawerPayment &&
                                            <Fragment>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <Calendar locale={configurations.es} placeholder="Fecha de pago" showIcon={true} value={state.filter.datePay}
                                                        dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_DATE_PAY, value: e.value})}/>
                                                </div>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <Calendar locale={configurations.es} placeholder="Fecha ingresado" showIcon={true} value={state.filter.created}
                                                        dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_CREATED, value: e.value})}/>
                                            </div>
                                                
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <InputText value={state.filter.code} placeholder="Codigo" className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_CODE, value: e.target.value})}/>
                                                    <Dropdown optionLabel="name" className="p-md-12 p-sm-12" style={{marginTop:'2%'}} value={state.filter.unit} options={state.units} 
                                                        onChange={(e) => dispatch({type: constants.FILTER_UNIT_SELECTED, value: e.value})} placeholder="Unidades"/>
                                                    <Dropdown optionLabel="name" value={state.filter.paymentType} options={configurations.typePayments} style={{marginTop:'2%'}} className="p-md-12 p-sm-12" 
                                                        onChange={(e) => dispatch({type: constants.FILTER_PAYMENT_TYPE_SELECTED, value: e.value})} placeholder="Tipo" required/>
                                                
                                                    <Button label="Buscar" onClick={() =>  action.searchByFilter(null, state, dispatch, setOpenFilterDrawerPayment, setLoading)}
                                                        className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </Drawer>

                                {
                                    (!state.payments || state.payments.length === 0) &&
                                    <h3 className="title-content">Sin información de pagos</h3>
                                }
                                {
                                    state.payments && state.payments.length > 0 &&
                                    <DataTable value={state.payments} paginator={true} responsive={true} rows={10} 
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoadPayment(event, setOpenFilterDrawerPayment)} totalRecords={state.totals} 
                                    style={{marginTop:'10px', padding:'10px' }} >
                                        <Column header="Codigo" field="value.code" sortable={true} />
                                        <Column header="Nombre" field="value.name" sortable={true} />
                                        <Column header="Unidad" body={(r) => unitRow(r.value.unit)} sortable={true} />
                                        <Column header="Persona" field="value.peopleType" sortable={true} />
                                        <Column header="A pagar" body={(r) => priceRow(r.value.total, state.building.currency)}/>
                                        <Column header="Pagado"  body={(r) => priceRow(r.value.pay, state.building.currency)} />
                                        <Column header="Fecha de pago" field="value.date" sortable={true}/>
                                        <Column header="Tipo"  body={(r) => typePaymentRow(r.value.type)} sortable={true}/>
                                        {
                                            state.admin && <Column header="Acciones" body={(r) => actionsRow(r.value)} style={{textAlign:'center', width: '15%'}}/>
                                        }
                                    </DataTable>
                                }
                            </TabPanel>
                            <TabPanel header="Gastos">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Filtros" onClick={() => loadFilters(setOpenFilterDrawerPaymentExpense, "EXPENSE")}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                <Drawer open={openFilterDrawerPaymentExpense} onClose={closeFilterDrawerPaymentExpense} direction='right' size={mobile() ? 250 : 500}>
                                    <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                        <h3 className="title-content">Filtros</h3>
                                        { openFilterDrawerPaymentExpense &&
                                            <Fragment>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <Calendar locale={configurations.es} placeholder="Fecha de pago" showIcon={true} value={state.filter.datePay}
                                                        dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_DATE_PAY, value: e.value})}/>
                                                </div>
                                                
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <InputText value={state.filter.name} placeholder="Nombre" className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_NAME, value: e.target.value})}/>
                                                    <InputText value={state.filter.document} placeholder="Documento" style={{marginTop: '2%'}} className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_DOCUMENT, value: e.target.value})}/>
                                                    <Dropdown optionLabel="name" value={state.filter.movementGroup} options={state.movementsByExpense}
                                                        className="p-md-12 p-sm-12" style={{marginTop: '2%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_MOVEMENT_GROUP_SELECTED, value: e.value})} placeholder="Grupo"/>
                                                    <Dropdown optionLabel="name" value={state.filter.oSupplier} options={state.suppliers}
                                                        className="p-md-12 p-sm-12" style={{marginTop: '2%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor"/>
                                                    <Button label="Buscar" onClick={() =>  action.searchByFilter(null, state, dispatch, setOpenFilterDrawerPaymentExpense, setLoading)}
                                                        className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </Drawer>
                                {
                                    (!state.expenses || state.expenses.length === 0) &&
                                    <h3 className="title-content">Sin información de gastos</h3>
                                }
                                {
                                    state.expenses && state.expenses.length > 0 &&
                                    <DataTable value={state.expenses} paginator={true} responsive={true} rows={10}
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoadPayment(event, setOpenFilterDrawerPaymentExpense)} totalRecords={state.totals} 
                                    style={{marginTop:'10px', padding:'10px' }} >
                                        <Column field="value.name" header="Nombre" sortable={true}/>
                                        <Column field="value.amount" header="Monto" body={(r) => priceRow(r.value.amount, state.building.currency)}/>
                                        <Column field="value.document" header="Documento" sortable={true}/>
                                        <Column field="value.group" header="Grupo" sortable={true}/>
                                        <Column field="value.payDate" header="F. Mov." sortable={true}/>
                                        <Column field="value.created" header="F. Creación" sortable={true}/>
                                        <Column field="value.supplier" header="Proveedor" sortable={true}/>
                                    </DataTable>
                                }
                            </TabPanel>
                            <TabPanel header="Pagos eliminados" disabled={!state.admin}>
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Filtros" onClick={() => loadEliminatedFilters(setOpenFilterDrawerPaymentEliminated)}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                <Drawer open={openFilterDrawerPaymentEliminated} onClose={closeFilterDrawerPaymentEliminated} direction='right' size={mobile() ? 250 : 500}>
                                    <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                        <h3 className="title-content">Filtros</h3>
                                        { openFilterDrawerPaymentEliminated &&
                                            <Fragment>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <Calendar locale={configurations.es} placeholder="Fecha de pago" showIcon={true} value={state.filter.datePay}
                                                        dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_DATE_PAY, value: e.value})}/>
                                                </div>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <Calendar locale={configurations.es} placeholder="Fecha ingresado" showIcon={true} value={state.filter.created}
                                                        dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_CREATED, value: e.value})}/>
                                            </div>
                                                
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <InputText value={state.filter.code} placeholder="Codigo" className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_CODE, value: e.target.value})}/>
                                                    <Dropdown optionLabel="name" className="p-md-12 p-sm-12" style={{marginTop:'2%'}} value={state.filter.unit} options={state.units} 
                                                        onChange={(e) => dispatch({type: constants.FILTER_UNIT_SELECTED, value: e.value})} placeholder="Unidades"/>
                                                
                                                    <Button label="Buscar" onClick={() =>  action.searchByFilter(null, state, dispatch, setOpenFilterDrawerPaymentEliminated, setLoading)}
                                                        className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </Drawer>

                                {
                                    (!state.eliminateds || state.payments.length === 0) &&
                                    <h3 className="title-content">Sin información de pagos eliminados</h3>
                                }
                                {
                                    state.eliminateds && state.eliminateds.length > 0 &&
                                    <DataTable value={state.eliminateds} paginator={true} responsive={true} rows={10} 
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoadPayment(event, setOpenFilterDrawerPaymentEliminated)} totalRecords={state.totals}
                                    style={{marginTop:'10px', padding:'10px' }} >
                                        <Column header="Codigo" field="value.code" sortable={true} />
                                        <Column header="Nombre" field="value.name" sortable={true} />
                                        <Column header="Unidad" field="value.unit" sortable={true} />
                                        <Column header="Persona" field="value.peopleType" sortable={true} />
                                        <Column header="A pagar" body={(r) => priceRow(r.value.total, state.building.currency)}/>
                                        <Column header="Pagado"  body={(r) => priceRow(r.value.pay, state.building.currency)} />
                                        <Column header="Fecha de pago" field="value.date" sortable={true}/>
                                        <Column header="Tipo" field="value.type" sortable={true}/>
                                    </DataTable>
                                }
                            </TabPanel>
                        </TabView>
                    </div>     
                </div>
            }
        </Fragment>
    );
}


export default Cash;
