import React, {useEffect, useReducer} from 'react';
import classNames from 'classnames';
import * as action from './components/information/actions/InformationAction';
import * as actionCompany from './components/company/actions/CompanyAction';
import InformationReducer from './components/information/reducers/InformaitonReducer';

const AppMenuInformation = ({ prevState, configDialogActive, onConfigClick, onConfigButtonClick, isDesktop }) => {

     useEffect(() => {
        action.load(state, dispatch);
      }, []) 
      
    const [state, dispatch] = useReducer(InformationReducer, {
        ...prevState,
        quotes: []
    });

    function Money() {
        const items = state.quotes ? state.quotes.map((quote) => {
            let purchase = parseFloat(quote.purchase.replace(",", ".")).toFixed(2)
            let sale = parseFloat(quote.sale.replace(",", ".")).toFixed(2)
           return <li>
                    <div className="team-box p-col-5 inline">
                        <img src={quote.url} alt={quote.money}></img>
                        <span className="team-member" style={{marginLeft:'5%', fontSize:'10px'}}>{quote.money}</span>
                    </div>
                    <div className="p-col-5 inline">
                        <div className="team-box">
                            <span className="team-member">{purchase}</span>
                        </div>
                        <div className="team-box">
                            <span className="team-member" style={{marginLeft:'15px'}}>{sale}</span>
                        </div>
                    </div>
                </li>
        }) : [];
        return (
          <ul>{items}</ul>
        );
      }

    return (
        <div className={classNames("layout-config p-d-none p-d-sm-block", {'layout-config-active': configDialogActive})} onClick={onConfigClick} style={{display: isDesktop? '' : 'none'}}>
            <div className="layout-config-content">
                <button className="layout-config-button" id="layout-config-button" onClick={onConfigButtonClick}>
                    <i className="pi pi-angle-left"></i>
                </button>
                <div className="p-col-12">
                    <div className="p-grid p-justify-center">
                        <h2 className="title-content-menu">IPC {state.company ? state.company.ipc : 'No configurado'}</h2>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-center">
                        <h2 className="title-content-menu">Cotización</h2>
                    </div>
                    <div className="p-col-12 team"> 
                        <Money></Money>  
                        <label className="title-content-menu" style={{fontSize:'10px'}}>Valores sujetos a confimración</label>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-center">
                        <h2 className="title-content-menu">Soporte</h2>
                    </div>
                    <div className="p-col-12" style={{color: "#a29e9e"}}>Tel.: 098 745 211</div>
                    <div className="p-col-12" style={{color: "#a29e9e"}}>E-Mail: soporte@woloolo.com</div>
                </div>
            </div>
        </div>
    )
}

export default AppMenuInformation
