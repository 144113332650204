import * as constants from '../../../Constants';
import ComunitacionReducer from './ComunicationReducer'

const NoteReducer = (state, action) => {
    switch (action.type) {
        case constants.COMUNICATION_NOTE_AGENT:
            state.note.agent = action.value;
            return {
                ...state
            };
        case constants.COMUNICATION_NOTE_SUPPLIER:
            state.note.supplier = action.value;
            return {
                ...state
            };
        case constants.COMUNICATION_NOTE_MESSAGE:
            state.note.message = action.value;
            return {
                ...state
            };
        case constants.COMUNICATION_NOTES:
            state.notes = action.value
            return {
                ...state
            };
        case constants.DEFAULT:
            return {
                ...state
            };
        default: return ComunitacionReducer(state, action);
    }
};

export default NoteReducer;