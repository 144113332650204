import React, {useEffect, useReducer, useState, Fragment} from 'react';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Messages} from 'primereact/messages';
import { Redirect } from 'react-router-dom'
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import LiquidationReducer from "./reducers/LiquidationReducer";
import * as action from "./actions/LiquidationAction"
import * as actionCash from "../cash/actions/CashAction"
import * as utils from "../Utils";
import * as access from "../AccessAction";


const LiquidationForm = ({ prevState, menuRightActive, mobile }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.search(null, state, dispatch, setLoading);
        }
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleGenerate, setVisibleGenerate] = useState(false)
    const [state, dispatch] = useReducer(LiquidationReducer, {
        ...prevState,
        access: true,
        liquidationDate: new Date()
    });

    const liquidation = () => {
        setLoading(true);
        action.search(null, state, dispatch, setLoading);
    }

    function priceRow(value, currencySymbol) {
        return utils.formatCurrency(value, currencySymbol);
    }

    function liquidationRow(value) {
        return <Button type="button" icon="pi pi-arrow-down" onClick={() =>  actionCash.download(value, state, dispatch, setLoading)} className="p-button-warning"/>
    }

    const onGenerate = () => {
        setVisibleGenerate(false);
        setLoading(true)
        action.generateLiquidation(state, dispatch, setLoading)
    }

    const onPreview = () => {
        setLoading(true)
        action.previewLiquidation(state, dispatch, setLoading)
    }

    function actionRow(value) {
        return <div>
             <button className="p-link" onClick={() =>  action.downloadExternal('ABITAB', value, state, setLoading)}>
                <img src="assets/layout/images/abitab.jpeg" alt="Abitab" title="Exportar abitab" className="circular--square"/>
            </button>
            <button className="p-link" onClick={() =>  action.downloadExcel(value.executionId, state, setLoading)}>
                <img src="assets/layout/images/xls.jpeg" alt="Excel" title="Exportar excel" className="circular--square"/>
            </button>
            {/*<button className="p-link">
                <img src="assets/layout/images/redpagos.png" alt="Red Pagos" className="circular--square"/>
            </button>*/}
        </div>   
    }

    function talonRow(executionId) {
        return <div>
            <button className="p-link" onClick={() =>  action.downloadTalon(executionId, state, setLoading)}>
                <img src="assets/layout/images/barcode.png" alt="Descargar talon de pago" className="circular--square"/>
            </button>
        </div>   
    }

    const confirmationGenerate = (
        <div>
            <Button icon="pi pi-check" onClick={() => onGenerate()} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleGenerate(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.search(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>

            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Generar liquidación" visible={visibleGenerate} modal={true} footer={confirmationGenerate} onHide={() => setVisibleGenerate(false)}>
                Esta seguro que desea generar liquidación?
            </Dialog>
            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h1 className="title-content">Liquidaciones</h1>
                    <div className="box-default-margin">                     
                        <div className="p-md-7 p-sm-12 inline">
                            <span className="p-md-12 p-sm-12 inline">
                                <Calendar locale={configurations.es} placeholder="Liq. del periodo" showIcon={true} value={state.period} view="month"
                                        dateFormat="yymm" yearNavigator={true} yearRange="2010:2050"  
                                        onChange={(e) => dispatch({type: constants.LIQUIDATION_PERIOD_SELECTED, value: e.value})}/>
                            </span>

                            <span className="p-md-5 p-sm-11 inline">
                                <Button label="Buscar" onClick={() => liquidation()}
                                    className="p-button-warning p-md-5" icon="pi pi-search" iconPos="right" />
                            </span>
                        </div>
                        {
                            state.admin && 
                                <div className="p-md-4 p-sm-11 inline" style={{border:' 1px solid #ff8b06', margin: '10px', borderRadius: '15px'}}>
                                    <div className="p-md-12">
                                            <p>El informe de liquidación sera generado a partir de la fecha seleccionada hasta el dia actual. 
                                                Puede ingresar también un informe anterior de referencia.
                                            </p>
                                        </div>
                                        <div className="p-grid p-md-12">
                                            <div className="p-md-7">
                                                <Calendar locale={configurations.es} placeholder="Fecha" showIcon={true} value={state.liquidationDate} dateFormat="dd/mm/yy"
                                                    onChange={(e) => dispatch({type: constants.LIQUIDATION_DATE, value: e.value})}
                                                    required/>
                                                <InputText value={state.executionRefId} className="p-md-12" style={{marginTop:'2%'}}
                                                    onChange={(e) => dispatch({type: constants.LIQUIDATION_EXECUTION_REF_ID, value: e.target.value})} placeholder="Id. ref."/>
                                            </div>
                                            <div className="p-md-4 p-sm-11">
                                                <Button label="Generar" className="p-button-warning p-sm-12" icon="pi pi-plus" iconPos="right" onClick ={() => setVisibleGenerate(true)} />
                                                <Button label="Previsualizar" className="p-button-warning p-sm-12" icon="pi pi-plus" iconPos="right" style={{marginTop:'2%'}} onClick ={() => onPreview()}/>
                                            </div> 
                                        </div>
                                </div>
                        }              

                        { state.liquidations && 
                            <div className="p-md-12">
                                <h3 className="title-content-menu">Liquidaciones de {state.building.name}</h3>
                                <DataTable value={state.liquidations} paginator responsive scrollable 
                                    resizableColumns reorderableColumns columnResizeMode="expand" rows={10} 
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} 
                                    style={{marginTop:'10px', padding:'10px' }} >
                                    <Column header="Periodo" field="value.period"  sortable={true}/>
                                    <Column header="Pago anterior" body={(r) => priceRow(r.value.lastPayment, state.building.currency)} style={{width: '12%'}}/>
                                    <Column header="Pago" body={(r) => priceRow(r.value.pay, state.building.currency)} style={{width: '10%'}}/>
                                    <Column header="T. del periodo" body={(r) => priceRow(r.value.totalPeriod, state.building.currency)} style={{width: '10%'}}/>
                                    <Column header="T. por pagar" body={(r) => priceRow(r.value.totalPay, state.building.currency)} style={{width: '10%'}}/>
                                    <Column header="Tipo ejecución" field="value.executionType" style={{width: '8%'}} sortable={true}/>
                                    <Column header="Id. ejecución" field="value.executionId" style={{width: '8%'}}/>
                                    <Column header="Ref. ejecución" field="value.executionRefId" style={{width: '8%'}}/>
                                    <Column header="Desde" body={(r) => utils.dateRow(r.value.executionDate)} sortable={true}/>
                                    <Column header="Hasta" body={(r) => utils.dateRow(r.value.created)} sortable={true}/>
                                    <Column header="Informe" body={(r) => liquidationRow(r.value.executionId)} style={{width: '5%'}}/>
                                    <Column header="Talon" body={(r) => talonRow(r.value.executionId)} style={{width: '5%'}}/>
                                    {
                                        state.admin &&
                                        <Column header="Exportar" body={(r) => actionRow(r.value)} style={{width: '10%'}}/>
                                    }
                                
                                </DataTable>
                            </div>
                        }
                    </div>
                    
                </div>
            }
        </Fragment>
        
    );
}

export default LiquidationForm;
