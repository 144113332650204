import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer';
import * as utils from "../../Utils";

const CashReducer = (state, action) => {
    switch (action.type) {
        case constants.DEFAULT:
            return {
                ...state
            };
        case constants.CASH_UNITS:
            return {
                ...state,
                units: action.value
            };
        case constants.CASH_PERIOD_SELECTED:
            return {
                ...state,
                period: action.value
            };
        case constants.CASH_UNIT_SELECTED:
            return {
                ...state,
                unit: action.value
            };
        case constants.CASH_INCOME_EXPIRES_SELECTED:
            state.income.expires = action.value
            return {
                ...state
            };
        case constants.CASH_INCOME_DESCRIPTION:
            state.income.description = action.value
            return {
                ...state
            };
        case constants.CASH_INCOME_AMOUNT:
            state.income.amount = action.value
            return {
                ...state
            };
        case constants.CASH_INCOME_NUMBER:
            state.income.number = action.value
            return {
                ...state
            };
        case constants.CASH_INCOME_OBSERVATIONS:
            state.income.observations = action.value
            return {
                ...state
            };
        case constants.CASH_INCOME_UNIT_SELECTED:
            if (action.value === undefined){
                state.income.unit = undefined;
                state.income.unitId = undefined;
            } else {
                state.income.unit = action.value;
                state.income.unitId = action.value.id;
            }
            
            return {
                ...state
            };
        case constants.CASH_INCOME_PEOPLE_TYPE:
            state.income.oType = action.value;
            state.income.peopleType = action.value.code;
            return {
                ...state
            };
        case constants.CASH_INCOME_MOVEMENT_GROUP_SELECTED:
            state.income.movementGroup = action.value;
            state.income.groupId = action.value.id;
            return {
                ...state
            };
        case constants.CASH_EXPENSE_MOVEMENT_GROUP_SELECTED:
            state.expense.movementGroup = action.value;
            state.expense.groupId = action.value.id;
            return {
                ...state
            };
        case constants.CASH_EXPENSE_PEOPLE_TYPE:
            state.expense.oType = action.value;
            state.expense.peopleType = action.value.code;
            return {
                ...state
            };
        case constants.CASH_EXPENSE_DESCRIPTION:
            state.expense.description = action.value
            return {
                ...state
            };
        case constants.CASH_EXPENSE_EXPIRES_SELECTED:
            state.expense.expires = action.value
            return {
                ...state
            }; 
        case constants.CASH_EXPENSE_AMOUNT:
            state.expense.amount = action.value
            return {
                ...state
            };
        case constants.CASH_EXPENSE_NAME:
            state.expense.name = action.value
            return {
                ...state
            };
        case constants.CASH_EXPENSE_UPLOAD:
            state.upload = action.value
            return {
                ...state
            };
        case constants.CASH_LOAD:
            if (action.units){
                state.units = action.units;
            }

            return {
                ...state,
                building: action.buildingState,
                summary: action.value,
                rangeEnd: action.value.rangeEnd,
                periodMonth: action.value.periodMonth,
                periodYear: action.value.periodYear
            };
        case constants.CASH_SEARCH_RANGE_PERIOD:
            return {
                ...state,
                rangeEnd: action.range.value.rangeEnd,
                periodMonth: action.range.value.periodMonth,
                periodYear: action.range.value.periodYear
            }
        case constants.CASH_SEARCH_INCOME:
            return {
                ...state,
                findByFilter: false,
                incomes: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_EXPENSE:
            return {
                ...state,
                findByFilter: false,
                expenses: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_FILTER_INCOME:
            return {
                ...state,
                findByFilter: true,
                incomes: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_FILTER_EXPENSE:
            return {
                ...state,
                findByFilter: true,
                expenses: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_PAYMENT:
            return {
                ...state,
                findByFilter: false,
                payments: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_FILTER_PAYMENT:
            return {
                ...state,
                findByFilter: true,
                payments: action.value.content,
                totals: action.value.totalElements
            }; 
        case constants.CASH_SEARCH_PAYMENT_ELIMINATED:
            return {
                ...state,
                findByFilter: false,
                eliminateds: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_FILTER_PAYMENT_ELIMINATED:
            return {
                ...state,
                findByFilter: true,
                eliminateds: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_PAYMENT_EXPENSE:
            return {
                ...state,
                findByFilter: false,
                expenses: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_SEARCH_FILTER_PAYMENT_EXPENSE:
            return {
                ...state,
                findByFilter: true,
                expenses: action.value.content,
                totals: action.value.totalElements
            };
        case constants.CASH_EXPENSE_SUPPLIERS:
            return {
                ...state,
                suppliers : action.value
            }
        case constants.CASH_EXPENSE_SUPPLIER_SELECTED:
            state.expense.oSupplier = action.value
            return {
                ...state
            }
        case constants.FILTER_CODE:
            state.filter.code = action.value;
            return {
                ...state
            };
        case constants.FILTER_NAME:
            state.filter.name = action.value;
            return {
                ...state
            };
        case constants.FILTER_DATE_PAY:
            state.filter.datePay = action.value;
            return {
                ...state
            }; 
        case constants.FILTER_CREATED:
            state.filter.created = action.value;
            return {
                ...state
            }; 
        case constants.FILTER_PAYMENT_TYPE_SELECTED:
            state.filter.paymentType = action.value;
            return {
                ...state
            };
        case constants.FILTER_UNIT_SELECTED:
            state.filter.unit = action.value;
            return {
                ...state
            };
        case constants.FILTER_SUPPLIER_SELECTED:
            state.filter.oSupplier = action.value;
            return {
                ...state
            };
        case constants.FILTER_MOVEMENT_GROUP_SELECTED:
            state.filter.movementGroup = action.value;
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_BUILDING:
            if (action.typeGroup === 'EXPENSE'){
                state.movementsByExpense = action.value;
            } else {
                state.movementsByIncome = action.value
            }
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default CashReducer;