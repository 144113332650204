import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

export default class Logout extends Component {

	constructor() {
		super();
	}

	render() {
		localStorage.removeItem('building');
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		localStorage.removeItem('companyId');
		return <Redirect to={{ pathname: '/', }} />
	}
}