import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer'

const CoefficientReducer = (state, action) => {
    switch (action.type) {
        case constants.COEFFICIENT_SEARCH:
            return {
                ...state,
                coefficients: action.value.content,
                totals: action.value.totalElements
            };
        case constants.COEFFICIENT_SUMMARY:
            state.summary = action.value
            return {
                ...state,
                coefficients: action.data.content,
                totals: action.data.totalElements,
            };
        case constants.COEFFICIENT_SELECTED:
            return {
                ...state,
                coefficient: action.value
            };
        case constants.COEFFICIENT_PERCENTAGE:
            state.coefficient.percentage = action.value;
            return {
                ...state
            };
        case constants.COEFFICIENT_LOAD_UNITS:
            return {
                ...state,
                units: action.value
            };
        case constants.COEFFICIENT_UNIT_SELECTED:
            state.coefficient.unit = action.value;
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default CoefficientReducer;