import React, {useEffect, useReducer, useState, Fragment} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Chart} from 'primereact/chart';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {DataView} from 'primereact/dataview';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {ProgressBar} from 'primereact/progressbar';
import '../../css/Grid.css';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import classNames from 'classnames';
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import * as action from "./actions/BuildingAction";
import * as actionComunication from "../comunication/actions/ComunicationAction";
import BuildingReducer from "./reducers/BuildingReducer";
import * as access from "../AccessAction";
import * as utils from "../Utils";

const BuildingDashboard = ({ prevState, menuRightActive }) => {
      
    useEffect(() => {
        access.access(constants.BUILDING_DASHBOARD_ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building !== undefined){
            action.search(utils.period(0), prevState, dispatch, setLoading);
        }
        }, []
    );


    const [loading, setLoading] = useState(true)
    const [visibleComunication, setVisibleComunication] = useState(false)
    const [state, dispatch] = useReducer(BuildingReducer, {
        ...prevState,
        access: true,
        summary: {}      
    });

    const search = () => {
        let period = utils.period(0);
        if (state.period){
            period = String(state.period.getFullYear()).concat(configurations.appendZeroes(state.period.getMonth() + 1));
        }

        setLoading(true);
        action.search(period, state, dispatch, setLoading);
    }

    function amountRow(account, currencySymbol) {
        let value = account.incomePay - account.expensePay
        let color = getColor(value)
        return <div className={color + " p-grid p-justify-center"} 
            style={{padding:'2%', margin:'0 5% 0 5%', borderRadius: '20px', fontSize: '18px' }}>
            <label className="status-box">{utils.formatCurrency(value, currencySymbol)}</label> 
        </div>
    }

    function getColor(value){
        let color = 'summary-status-closed';
        if (value < 0){
            color = 'summary-status-alert'
        }

        return color;
    }


    function getComunication(comunicationId){
        setLoading(true);
        actionComunication.searchComunicationById(comunicationId, constants.BUILDING_COMUNICATION_SEARCH, state, dispatch, setLoading, setVisibleComunication);
    }

    const comunicationView = (comunication) => {
        return <Fragment>
            {
                comunication != null &&
                <div className="p-md-12">
                    <div className="p-md-7 inline">
                        <div className='p-md-12 p-grid p-justify-center'>
                            <div className="team-box">
                                <div className="team-member title-content">{comunication.title}</div>
                            </div>
                        </div>
                        <div className='p-md-12 p-grid p-justify-center'>
                            <div className="team-box">
                                <span className="team-member title-content" >{comunication.created}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-md-2 inline">
                            <Button type="button"
                                label="Detalle"
                                onClick={() => getComunication(comunication.id)} className="p-button-secondary"/>
                    </div>
                    
                    
                </div>
            }
        </Fragment>
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.admin && state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={state.admin && !state.access} onHide={(e) => dispatch({type: constants.BUILDING_DASHBOARD_REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Contacto Biwol" visible={state.userLoginViewPhone} className="p-md-6 p-sm-12" onHide={(e) => dispatch({type: constants.BUILDING_DASHBOARD_REDIRECT, value: e})}>
                <p>Estimados residentes del Complejo de Viviendas del Ejército:
                Por este medio, les queremos comunicar la nueva y única línea de respuesta telefónica de la administración. Si bien todo debe ser canalizado a través del sistema Woloolo, dejamos este medio disponible como excepción.
                Línea celular (Whatsapp y llamadas): <h3>098 96 9999</h3>.
                Agradecemos  su colaboración;
                Saludos, BIWOL ADMINISTRACION.</p>
            </Dialog>

            <Dialog header="Información" visible={visibleComunication} className="p-md-6 p-sm-12" onHide={() => setVisibleComunication(false)}>
                {   state.comunication &&
                    <div className="p-md-12">
                        <div className='p-md-12 p-grid p-justify-center'>
                            <div className="team-box">
                                <div className="team-member title-content">{state.comunication.title}</div>
                            </div>
                        </div>
                        <div className='p-md-12 p-grid p-justify-center'>
                            <div className="team-box">
                                <span className="team-member title-content" >{state.comunication.description}</span>
                            </div>
                        </div>
                    </div>
                }
            </Dialog>
            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Estado del Edificio</h1>
                    </div>
                    <div className="p-md-2 inline">
                        {
                            state.rangeEnd && 
                            <Fragment>
                                    <h2 className="title-content"  style={{marginBlockStart: '1%', marginBlockEnd:'1%'}} >{String(state.periodMonth).toUpperCase()} {state.periodYear}</h2>
                                    <h3 className="title-content"  style={{marginBlockStart: '1%'}} >Fecha de cierre: {state.rangeEnd}</h3>
                            </Fragment>   
                        }
                    </div>
                    
                    {
                        !state.admin && state.message &&
                        <h3 className="title-content">{state.message}</h3>
                    }

                    <div className="p-grid p-justify-center box-default">
                        <div className="p-md-5 p-sm-12">
                            <span className="p-md-12 p-sm-12 inline">
                                <Calendar locale={configurations.es} placeholder="Periodo" showIcon={true} value={state.period} view="month"
                                        dateFormat="yymm" yearNavigator={true} yearRange="2010:2050"
                                        onChange={(e) => dispatch({type: constants.BUILDING_DASHBOARD_PERIOD_SELECTED, value: e.value})}/>
                            </span>

                            {state.access &&
                                <span className="p-md-5 inline">
                                    <Button label="Buscar" onClick={() => search()}
                                        className="p-button-warning p-md-6" icon="pi pi-search" iconPos="right" />
                                </span>
                            }
                        </div>
                        <div className="p-md-4 p-sm-12">
                            <h1 className="title-content">Cajas</h1>
                            <DataTable value={state.accounts} responsive={true} style={{fontSize: 14}}>
                                <Column field="name" header="Nombre"/>
                                <Column header="Monto" body={(r) => amountRow(r, state.building.currency)} />
                            </DataTable>
                        </div>
                        
                        <div className="p-col-12 p-grid p-justify-center" style={{marginTop: '1%'}}>
                            <div className="p-md-3 p-sm-11 inline">
                                <div className="box-default">
                                    <div className="p-col-12">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Recaudación del período</h2>
                                        </div> 
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.pay)}</h2>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="p-md-3 p-sm-11 inline">
                                <div className="box-default">
                                    <div className="p-col-12">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Egresos del período</h2>
                                        </div> 
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.expense)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div class="p-md-3 p-sm-11 inline">
                                <div class="box-default">
                                    <div class="p-col-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Deudores</h2>
                                        </div> 
                                    </div>
                                    <div class="p-col-12" >
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{state.building.currency} {utils.formatNumber(state.summary.debt)}</h2>
                                        </div>
                                    </div>
                                </div>
                        </div>*/}
                        </div>

                        <div class="p-col-12 p-grid p-justify-center" style={{marginTop: '2%'}}>
                            {
                                state.admin && 
                                <Fragment>
                                    <div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default" style={{padding:'4%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Medio de pago</h2>
                                                </div> 
                                            </div>
                                            {state.summary.graphPayment &&
                                                    <Chart type="pie" data={state.summary.graphPayment} />
                                                }
                                        </div>
                                    </div>
                                    <div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default" style={{padding:'4%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Composición de egresos</h2>
                                                </div> 
                                            </div>
                                            <div class="p-col-12">
                                                {state.summary.graphExpense &&
                                                        <Chart type="horizontalBar" data={state.summary.graphExpense} />
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default p-grid" style={{padding:'5%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Unidades con deuda</h2>
                                                </div> 
                                            </div>
                                            <DataTable value={state.summary.debts} paginator={true} rows={3} responsive={true} style={{marginTop:'10px', padding:'10px' }} >
                                                <Column field="unitCode" header="Unidad" />
                                                <Column body={(r) => utils.formatCurrency(r.pendingPay, state.building.currency)} header="Deuda"/>
                                            </DataTable>
                                        </div>
                                    </div>*/}        
                                </Fragment>
                            }
                            {
                                !state.admin &&
                                <Fragment>
                                    <div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default p-grid" style={{padding:'5%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Comunicados</h2>
                                                </div> 
                                            </div>
                                            {
                                                (state.summary.notices && state.summary.notices.length > 0) && 
                                                <DataView value={state.summary.notices} itemTemplate={comunicationView} layout={'grid'}
                                                    paginator={true} rows={3}/>
                                            }
             
                                        </div>
                                    </div>  
                                    <div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default" style={{padding:'4%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Composición de egresos</h2>
                                                </div> 
                                            </div>
                                            <div class="p-col-12">
                                                {state.summary.graphExpense &&
                                                        <Chart type="horizontalBar" data={state.summary.graphExpense} />
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-md-3 p-sm-11 inline">
                                        <div class="box-default p-grid" style={{padding:'5%', maxHeight: '60vh', minHeight: '30vh'}}>
                                            <div class="p-col-12">
                                                <div class="p-grid p-justify-center">
                                                    <h2 className="title-content" style={{marginBlockEnd: 0}}>Unidades con deuda</h2>
                                                </div> 
                                            </div>
                                            <DataTable value={state.summary.debts} paginator={true} rows={3} responsive={true} style={{marginTop:'10px', padding:'10px' }} >
                                                <Column field="unitCode" header="Unidad" />
                                                <Column body={(r) => utils.formatCurrency(r.pendingPay, state.building.currency)} header="Deuda"/>
                                            </DataTable>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        
                        <div class="p-col-12 p-grid p-justify-center" style={{marginTop: '2%'}}>
                            <div class="p-md-4 p-sm-11">
                                <div class="box-default p-grid" style={{padding:'2%', maxHeight: '30vh'}}>
                                    <div class="p-col-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content">Documentos</h2>
                                        </div> 
                                    </div>
                                    {
                                        (!state.building.documentations || Object.keys(state.building.documentations).length === 0) &&
                                        <div class="p-col-12">
                                            <div class="p-grid p-justify-center">
                                                <h3 className="title-content">Sin documentos cargados</h3>
                                            </div>
                                        </div>    
                                    }
                                    {
                                        state.building.documentations && 
                                        Object.keys(state.building.documentations).map((key, i) => {
                                            let document = state.building.documentations[key];
                                            let ext = document.name.substring(document.name.indexOf(".")+1).toUpperCase();
                                            let icon = 'pi pi-file'
                                            if (ext === 'PDF'){
                                                icon = 'pi pi-file-pdf'
                                            } else if (ext === 'XLS' || ext === 'XLSX'){
                                                icon = 'pi pi-file-excel'
                                            }
         
                                            return (
                                                <div className="p-md-12">
                                                    <Button type="button" label={document.name}  
                                                        icon={icon} iconPos="left" className="p-button-secondary p-md-9 inline"/>
                                                    <div className="p-md-1 inline"></div>
                                                    <Button type="button"
                                                        icon="pi pi-arrow-down"
                                                        onClick={() => action.downloadDocument(document.name, state, dispatch, setLoading)} className="p-button-warning"/>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </div>
                                 
                            { state.admin &&     
                                <Fragment>
                                    <div className="p-md-1 p-sm-1"></div>  
                                    <div className="p-md-4 p-sm-11 user-detail">
                                        <h3 className="title-content">Espacio disponible en la plataforma asociado al edificio</h3>
                                        <div className="p-grid ">
                                            {
                                                state.summary.sizes && state.summary.sizes.map((quota) => {
                                                    return (<div className="p-col-6">
                                                        {
                                                            <div className="user-detail-box">
                                                                <div className="user-detail-box-name">{quota.name} </div>
                                                                <div className="user-detail-box-detail">{quota.size} KB</div>
                                                                <i className="pi pi-list"></i>
                                                                <ProgressBar value={quota.percentage}/>
                                                            </div>
                                                            
                                                        }   
                                                    </div> )                             
                                                })
                                            }            
                                        </div>
                                    </div>    
                                </Fragment>
                                 
                            }

                        </div>
                    </div>    
                </div>
            }
        </Fragment>
    );
}


export default BuildingDashboard;
