import * as constants from '../../../Constants';
import CashReducer from '../../cash/reducers/CashReducer';

const PaymentReducer = (state, action) => {
    switch (action.type) {
        case constants.UNIT_BUILDING_SELECTED:
            return {
                ...state,
                units: action.units,
                paymentsAccounts: action.valuePayments,
                accounts: action.valueAccounts,
                header: action.header
            };
        case constants.CHANGE_PAYMENT_CODE:
            action.item.codePayment = action.value
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_DETAIL_CODE:
            state.payment.code = action.value
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_DATE:
            action.item.date = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_DETAIL_DATE:
            state.payment.date = action.value
            return {
                ...state
            }  
        case constants.PAYMENT_TYPE_SELECTED:
            action.item.type = action.value;
            action.item.paymentType = action.value.type;
            return {
                ...state
            }
        case constants.PAYMENT_DETAIL_TYPE_SELECTED:
            state.payment.type = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_PAY:
            action.item.pay = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_DETAIL_PAY:
            state.payment.pay = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_EXECUTION_ID:
            state.payment.executionId = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_AMOUNT_ACCOUNT:
            action.item.amount = action.value;
            action.item.editabled = true
            return {
                ...state
            }

        case constants.CHANGE_PAYMENT_DESCRIPTION:
            state.payment.description = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_UNIT_SELECTED:
            state.payment.unit = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PEOPLE_TYPE:
            state.payment.oType = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_NAME:
            state.payment.firstName = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_LASTNAME:
            state.payment.lastName = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_DOCUMENT:
            state.payment.document = action.value;
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_TOTAL:
            state.payment.total = action.value;
            return {
                ...state
            }
        case constants.PAYMENT_SEARCH: 
            state.payments = action.value;
            return {
                ...state
            }
        case constants.PAYMENT_SELECTED:
            state.payment = action.value
            return {
                ...state
            }
        case constants.CHANGE_PAYMENT_UNIT_UNDEFINED:
            state.payment.depositUndefined = action.value;
            return {
                ...state
            }
        case constants.CHANGE_AMOUNT_ACCOUNT:
            state.accounts = action.value
            return {
                ...state
            }
        case constants.CASH_PAYMENT_SUMMARY_TOTAL:
            state.paymentSummary = action.value.value
            state.payment.executionId = state.paymentSummary.executionId
            return {
                ...state
            }    
        default: return CashReducer(state, action);
    }
};

export default PaymentReducer;