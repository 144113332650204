import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

const availables = ["Dólar", "Dólar eBROU", "Real", "Euro", "Peso Argentino", "Guarani"];
const countries = new Map([["Dólar" ,"assets/images/usa.svg"], ["Dólar eBROU" ,"assets/images/usa.svg"], ["Real", "assets/images/br.svg"], 
        ["Peso Argentino", "assets/images/arg.svg"], ["Guarani", "assets/images/pr.svg"], ["Euro", 'assets/images/eu.svg']]); 

export const load = (state, dispatch) => {
    
    axios(
        access.build('get', `/api/info/bank-quote`, null)
    )
    .then(response => {
        let results = [];
        response.data.forEach(obj => {
            if (availables.includes(obj.money)){
                let data = new Object();
                data.money = obj.money;
                obj.prices.map((price, idx) => {
                    if (idx === 0){
                        data.purchase = price.value
                    }
                    if (idx === 1){
                        data.sale = price.value
                    }
                })
                data.url = countries.get(obj.money);
                results.push(data);
            }
           
        });

        
        axios(
            access.build('get', '/api/companies/company/' + state.companyId, null)
        )
        .then(responseCompany => {
            dispatch({ type: constants.INFORMATION_LOAD, value: results, valueCompany: responseCompany.data }); 
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.COMPANY_LOAD, dispatch, false);
        })  
           
    })
    .catch((e) => {
        responseData('error', e.message, state, constants.INFORMATION_LOAD, dispatch, false);
    })    
}

export const responseData = (severity, message, state, type, dispatch, value) => {
    dispatch({ type: type, value: value });
}