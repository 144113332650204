import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";

export const search = (event, state, dispatch, fn) => {
    if (state.building){
        if (state.admin){
            searchByAdmin(event, state, dispatch, fn);
        } else {
            searchByUser(event, state, dispatch, fn);
        }
    } else {
        access.responseData('error', 'Debe seleccionar un edificio', state, constants.LIQUIDATION_SEARCH, dispatch, false);
    }
}

export const searchByAdmin = (pagination, state, dispatch, fn) => {
    let url = `/api/movement/liquidation/` + state.building.id;
    if (state.period){
        url += '/' + utils.getConvertPeriod(state.period);
    }
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false);
        dispatch({ type: constants.LIQUIDATION_SEARCH, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.LIQUIDATION_SEARCH, dispatch, false);
    })  
}

export const searchByUser = (pagination, state, dispatch, fn) => {
    let url = ''
    if (state.period){
        url += '/' + utils.getConvertPeriod(state.period);
    }

    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', `/api/movement/liquidation/user/` + state.building.id + `/` + state.user.email + url, null)
    )
    .then(response => {
        fn(false)
        dispatch({ type: constants.LIQUIDATION_SEARCH, value: response.data });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.LIQUIDATION_SEARCH, dispatch, false);
    });
}

export const generateLiquidation = (state, dispatch, fn) => {
    if (state.building.executionType === 'AUTOMATIC'){
        fn(false)
        access.responseData('error', 'La ejecución de liquidación está en modo automatico. Si desea ejecutar manualmente, debe editar el edificio.', state, constants.DEFAULT, dispatch, false);
        return;
    }
    
    let date = utils.getDate(state.liquidationDate)
    let url = state.executionRefId ? '/api/movement/liquidation/building/' + state.building.id + '/' + state.executionRefId + '/' + date : 
                '/api/movement/liquidation/building/' + state.building.id + '/'  + date
    axios(
        access.build('post', url, {})
    )
    .then((response) => {
        let message, severity;
        if (response.data.code !== null && response.data.code.startsWith("200")) {
            severity = 'info';
            message = response.data.message === null ? 'No hay ingresos para el periodo seleccionado' : 'Liquidación creada correctamente, identificador: ' + response.data.message;
            
            searchByAdmin(null, state, dispatch, fn);
        }
        else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }

        state.executionRefId = undefined;
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);

    })
    .catch((e) => {
        fn(false);
        let message = e.response.data ? e.response.data.message : e.message;
        access.responseData('error', message, state, constants.DEFAULT, dispatch, false);
    });
}

export const previewLiquidation = (state, dispatch, fn) => {    
    let date = utils.getDate(state.liquidationDate)
    let url = state.executionRefId ? '/api/movement/liquidation/preview/' + state.companyId + '/' + state.building.id + '/' + state.executionRefId + '/' + date : 
                '/api/movement/liquidation/preview/' + state.companyId + '/' + state.building.id + '/'  + date
    axios(url, {
            method: 'GET',
            responseType: 'blob'
        }
    )
    .then((response) => {
        fn(false);
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});

        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(file);
        a.href = blobUrl;
        a.download = response.headers['content-disposition'];
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);

    })
    .catch((e) => {
        fn(false);
        access.responseData('error', 'Error generar preview', state, constants.DEFAULT, dispatch, false);
    });
}

export const downloadExternal = (type, value, state, fn) => {
    fn(true)
    axios(`/api/external/download/` + state.building.id + `/` + value.executionId + `/` + type, {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        fn(false);
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});

        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(file);
        a.href = blobUrl;
        a.download = response.headers['content-disposition'];
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    })
    .catch(error => {
        console.log(error);
        fn(false);
    });
}

export const downloadTalon = (executionId, state, fn) => {
    fn(true)
    let url = state.admin ? `/api/report/building/` + state.building.id + `/` + executionId + `/ABITAB` : 
                            `/api/report/building/` + state.building.id + `/` + state.user.email + `/` + executionId + `/ABITAB`
    axios(url, {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        fn(false);
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});

        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(file);
        a.href = blobUrl;
        a.download = response.headers['content-disposition'];
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    })
    .catch(error => {
        console.log(error);
        fn(false);
    });
}

export const downloadExcel = (executionId, state, fn) => {
    fn(true)
    console.log(state.building.companyId, executionId)
    axios(`/api/report/` + state.building.companyId + `/building/detail/xls/` + state.building.id + `/` + executionId, {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        fn(false);
        const file = new Blob(
          [response.data], 
          {type: 'application/xls'});

        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(file);
        a.href = blobUrl;
        a.download = response.headers['content-disposition'];
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    })
    .catch(error => {
        console.log(error);
        fn(false);
    });
}