import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import  AppTopbar  from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import AppMenuInformation from './AppMenuInformation';
import { Route, Redirect } from 'react-router-dom';
import CompanyDashboard from './components/company/CompanyDashboard';
import CompanyForm from './components/company/CompanyForm';
import BuildingDashboard from './components/building/BuildingDashboard';
import BuildingForm from './components/building/BuildingForm';
import PeopleForm from './components/people/PeopleForm';
import UnitForm from './components/units/UnitForm';
import CoefficientForm from './components/coefficient/CoefficientForm';
import MovementGroupForm from './components/movement/group/MovementGroupForm';
import MovementAccountForm from './components/movement/account/MovementAccountForm';
import CashForm from './components/cash/CashForm';
import SupplierForm from './components/supplier/SupplierForm';
import IncomeExpenseForm from './components/income_expense/IncomeExpenseForm';
import PaymentLoadForm from './components/payment_load/PaymentLoadForm';
import PaymentExpenseForm from './components/payment_expense/PaymentExpenseForm';
import HistoryPaymentForm from './components/history/payment/HistoryPaymentForm';
import WorkForm from './components/maintenance/work/WorkForm';
import PriceForm from './components/maintenance/price/PriceForm';
import UpkeepForm from './components/maintenance/upkeep/UpkeepForm';
import UserForm from './components/user/UserForm';
import ComunicationForm from './components/comunication/ComunicationForm';
import LiquidationForm from './components/liquidation/LiquidationForm';
import PaymentDiscountTaxForm from './components/payment_discount_tax/PaymentDiscountTaxForm';
import CommonAreaForm from './components/common_areas/CommonAreaForm';
import ReservationAreaAdminForm from './components/common_areas/reservations/ReservationAreaAdminForm';
import * as utils from "./components/Utils"

import './css/Styles.css';
import 'primereact/resources/primereact.min.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props,
            layoutMode: 'slim',
            lightMenu: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            isRTL: false,
            topbarColor: 'layout-topbar-light',
            topbarMenuActive: false,
            activeTopbarItem: null,
            closed: false,
            inlineUserMenuActive: false,
            menuActive: false,
            themeColor: 'orange',
            configDialogActive: false
        };

        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRightMenuButtonClick = this.onRightMenuButtonClick.bind(this);
        this.onProfileMenuClick = this.onProfileMenuClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.createMenu();
        utils.changeStyleSheetUrl('layout-css', 'orange', 'layout');
        utils.changeStyleSheetUrl('theme-css', 'orange', 'theme');
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false,
            closed: false
        }));

        if(this.isOverlay()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }

        if(this.isDesktop())
            this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
        else {
            this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
            if (this.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onConfigButtonClick(event){
        this.configClick = true;
        this.setState({configDialogActive: !this.state.configDialogActive})
    }

    onConfigClick(){
        this.configClick = true;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    blockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
    onRightMenuButtonClick(event){
        this.setState({closed: !this.state.closed});

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onProfileMenuClick(event) {
        this.profileClick = true;
        this.setState({inlineUserMenuActive: !this.state.inlineUserMenuActive})
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }
    onMenuItemClick(event) {
        this.hideOverlayMenu();
        this.setState({
            menuActive: event.item.items
        })
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 896;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 896;
    }

    changeMenuMode(event) {
        this.setState({
            layoutMode : event.menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
    }

    changeMenuColor(event) {
        this.setState({lightMenu : event.lightMenu})
    }

    dataStore(){
        utils.getDataStore(this.state);
    }

    createMenu() { 
        this.dataStore();

        this.menu = [];
        if (this.state.admin) {
            this.menu = [
                { label: 'Home', icon: 'pi pi-fw pi-home', to: '/app'},
                { label: 'Dashboard', icon: 'pi pi-fw pi-chart-bar', to: '/dashboard'},
                { 
                    label: 'Caja', icon: 'pi pi-fw pi-money-bill', 
                    items: [
                        { label: 'Cobros y Pagos', icon: 'pi pi-fw pi-money-bill', to: '/income_expense'  },
                        { label: 'Recaudación', icon: 'pi pi-fw pi-dollar', to: '/cash' },
                        { label: 'Recaudación masiva', icon: 'pi pi-fw pi-id-card', to: '/payment_load' },
                        { label: 'Gastos', icon: 'pi pi-fw pi-ticket', to: '/payment_expense' },
                        { label: 'Liquidación', icon: 'pi pi-fw pi-file-o', to: '/liquidation'}, 
                        { label: 'Historial', icon: 'pi pi-fw pi-list',
                            items: [
                                { label: 'Cobros', icon: 'pi pi-fw pi-money-bill', to: '/history_payment' }
                            ]
                        } 
                    ]
                },
                { label: 'Comunicación', icon: 'pi pi-fw pi-envelope', to: '/comunication'},
                {
                    label: 'Areas comunes', icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: 'Areas', icon: 'pi pi-fw pi-table', to: '/common_area' },
                        { label: 'Reservas', icon: 'pi pi-fw pi-tags', to: '/reservation_admin_area' },
                    ]
                },
                {
                    label: 'Configuración', icon: 'pi pi-fw pi-cog',
                    items: [
                        { label: 'Cajas', icon: 'pi pi-fw pi-id-card', to: '/movement_account' },
                        { label: 'Grupo Movimientos', icon: 'pi pi-fw pi-tags', to: '/movement_group' },
                        { label: 'Personas', icon: 'pi pi-fw pi-users', to: '/people' },
                        { label: 'Unidades', icon: 'pi pi-fw pi-list', to: '/unit' },
                        { label: 'Descuentos y Recargos', icon: 'pi pi-fw pi-ticket', to: '/payment_discount_tax' },
                        { label: 'Coeficientes', icon: 'pi pi-fw pi-calendar', to: '/coefficient' },
                        { label: 'Proveedores', icon: 'pi pi-fw pi-clone', to: '/supplier' },
                    ]
                },
                {
                    label: 'Administración', icon: 'pi pi-fw pi-desktop',
                    items: [
                        { label: 'Administradora', icon: 'pi pi-fw pi-th-large', to: '/company'  },
                        { label: 'Negocios', icon: 'pi pi-fw pi-map-marker', to: '/building' },
                    ]
                },
                { label: 'Seguridad', icon: 'pi pi-fw pi-lock', to: '/user'}           
            ];
        } else if (this.state.isSupplier){
            this.menu = [
                { label: 'Reclamos', icon: 'pi pi-fw pi-chart-bar', to: '/app'},
                { label: 'Cotizaciones', icon: 'pi pi-fw pi-dollar', to: '/price'}, 
                { label: 'Mantenimientos', icon: 'pi pi-fw pi-sitemap', to: '/upkeep'},
                { label: 'Seguridad', icon: 'pi pi-fw pi-lock', to: '/user'} 
            ];
        } else {
            this.menu = [
                { label: 'Dashboard', icon: 'pi pi-fw pi-chart-bar', to: '/app'},
                { 
                    label: 'Caja', icon: 'pi pi-fw pi-money-bill', 
                    items: [
                        { label: 'Cobros y Pagos', icon: 'pi pi-fw pi-money-bill', to: '/income_expense'  },
                        { label: 'Recaudación', icon: 'pi pi-fw pi-dollar', to: '/cash' },
                        { label: 'Gastos', icon: 'pi pi-fw pi-ticket', to: '/payment_expense' },
                        { label: 'Liquidación', icon: 'pi pi-fw pi-file-o', to: '/liquidation'}
                    ]
                },
                { label: 'Comunicación', icon: 'pi pi-fw pi-envelope', to: '/comunication'}, 
                { label: 'Seguridad', icon: 'pi pi-fw pi-lock', to: '/user'}     
            ];
        }
        
       
    }


    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-slim': true,
            'layout-menu-light': true,
            'layout-overlay-active': this.state.overlayMenuActive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-rtl': this.state.isRTL
        }, this.state.topbarColor);

        this.dataStore();
        return (
            <div className={layoutClassName}>
                {
                    this.state.forbidden  === 'true' &&
                    <Redirect to='/access'></Redirect>
                }
                {
                    this.state.closed &&
                    <Redirect to='/close'></Redirect>
                }
                <AppTopbar prevState={this.state} activeTopbarItem={this.state.activeTopbarItem} 
                           onRightMenuButtonClick={this.onRightMenuButtonClick} onMenuButtonClick={this.onMenuButtonClick} />

                <div className='layout-menu-container' onClick={this.onMenuClick}>
                    <div className="menu-scroll-content">
                        <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} 
                                layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                    </div>
                </div>

                <div className="layout-main">
                    <div className="layout-content">
                        { (this.state.admin || 
                            (!this.state.isSupplier && !this.state.completed.onlyBuilding)) &&
                            <Fragment>
                                <Route exact path='/app' render={() => <CompanyDashboard prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile} />} />
                                <Route path="/dashboard" render={() => <BuildingDashboard prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                            </Fragment>
                        }

                        {
                            !this.state.admin && 
                            !this.state.isSupplier && 
                            this.state.completed.onlyBuilding && 
                            <Route path="/app" render={() => <BuildingDashboard prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        }

                        {/*work*/}
                        { this.state.isSupplier &&
                            <Fragment>
                                <Route exact path='/app' render={() => <WorkForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                                <Route exact path='/price' render={() => <PriceForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                                <Route exact path='/upkeep' render={() => <UpkeepForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                            </Fragment>
                        }
                        
                        {/*cash*/}
                        <Route path="/income_expense" render={() => <IncomeExpenseForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                        <Route path="/cash" render={() => <CashForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                        <Route path="/payment_load" render={() => <PaymentLoadForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/payment_expense" render={() => <PaymentExpenseForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                        <Route path="/payment_discount_tax" render={() => <PaymentDiscountTaxForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />

                        {/*history*/}
                        <Route path="/history_payment" render={() => <HistoryPaymentForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        
                        {/*liquidations by user*/}
                        <Route path="/liquidation" render={() => <LiquidationForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                    
                        {/*comunications*/}
                        <Route path="/comunication" render={() => <ComunicationForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />

                        {/*configurations*/}
                        <Route path="/company" render={() => <CompanyForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/people" render={() => <PeopleForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/building" render={() => <BuildingForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/unit" render={() => <UnitForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/coefficient" render={() => <CoefficientForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile} />} />
                        <Route path="/supplier" render={() => <SupplierForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile} />} />
                        <Route path="/movement_account" render={() => <MovementAccountForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        <Route path="/movement_group" render={() => <MovementGroupForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />
                        
                        {/* user */}
                        <Route path="/user" render={() => <UserForm prevState={this.state} menuRightActive={this.state.configDialogActive} />} />

                        {/* common_area */}
                        <Route path="/common_area" render={() => <CommonAreaForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                        { this.state.admin &&
                            <Route path="/reservation_admin_area" render={() => <ReservationAreaAdminForm prevState={this.state} menuRightActive={this.state.configDialogActive} mobile={this.isMobile}/>} />
                        }
                    </div>
                    
                    {
                        (!this.isMobile() || !this.isTablet()) &&
                        <AppMenuInformation prevState={this.state} configDialogActive={this.state.configDialogActive} 
                    onConfigButtonClick={this.onConfigButtonClick} onConfigClick={this.onConfigClick} isDesktop={this.isDesktop()} />
                    }
                    
                </div>

                <AppFooter />

                <div className="layout-content-mask"></div>
            </div>
        );
    }
}

export default App;
