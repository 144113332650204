import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer';

const SupplierReducer = (state, action) => {
    switch (action.type) {
        case constants.SUPPLIER_NAME:
            state.supplier.name = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_TYPE:
            state.supplier.oType = action.value;
            state.supplier.category = state.supplier.oType.type;
            return {
                ...state
            };
        case constants.SUPPLIER_EMAIL:
            state.supplier.email = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_PHONE:
            state.supplier.phone = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_MOBILE:
            state.supplier.mobile = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_RUT:
            state.supplier.rut = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_ADDRESS:
            state.supplier.address = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_BANK_NAME:
            state.supplier.bankName = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_BANK_TYPE_ACCOUNT:
            state.supplier.bankTypeAccount = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_BANK_STORE:
            state.supplier.bankStore = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_BANK_NUMBER_ACCOUNT:
            state.supplier.bankNumberAccount = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_PAYMENT_TYPE:
            state.supplier.paymentType = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_ELIMINATED:
            state.supplier.eliminated = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_BUILDING:
            return {
                ...state,
                suppliers: action.value.content,
                totals: action.value.totalElements
            }
        case constants.SUPPLIER_SELECTED:
            state.supplier = action.value;
            state.supplier.oType = state.types.filter(t => t.type === state.supplier.category)[0];
            return {
                ...state
            };
        case constants.SUPPLIER_PAYMENTS:
            state.payments = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_FILTER_DATE:
            state.filter.date = action.value
            return {
                ...state
            };
        case constants.SUPPLIER_FILTER_DOCUMENT:
            state.filter.document = action.value
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default SupplierReducer;