import React, {Fragment, useEffect} from 'react';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import '../../../css/Styles.css'

import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import * as action from "./actions/ReservationAreaAction";

const ReservationAreaDialog = ({ state, dispatch, isNew, fnLoading, fn }) => {
    
    useEffect(() => {
        state.statuses.map(item => {
            if (item.type === state.reservation.status){
                state.reservation.statusSelected = item;
            }
        });

        if (state.reservation.unitId){
            state.units.map(item => {
                if (item.id === state.reservation.unitId){
                    state.reservation.unit = item;
                }
            });
        }

        state.reservation.oType = configurations.peopleTypes.filter(s => s.code === state.reservation.peopleType)[0];
        
    }, []
    );

    const onSave = () => {
        fnLoading(true)
        action.saveReservation(state, isNew, dispatch, fnLoading, fn)
    }

    return (
        <form className="p-fluid" onSubmit={onSave}>
           <div className="p-grid">
            <div className="p-md-offset-2 p-md-8 p-sm-12">
                {
                    isNew && 
                    <Fragment>
                         <div className="p-md-6">
                            <Calendar locale={configurations.es} placeholder="Comienzo" showTime={true} showIcon={true} value={state.reservation.start} dateFormat="dd/mm/yy" 
                                onChange={(e) => dispatch({type: constants.RESERVATION_START, value: e.value})} required/>
                        </div>
                        <div className="p-md-6">
                            <Calendar locale={configurations.es} placeholder="Finalización" showTime={true} showIcon={true} value={state.reservation.end} dateFormat="dd/mm/yy" 
                                onChange={(e) => dispatch({type: constants.RESERVATION_END, value: e.value})} required/>
                        </div>
                    </Fragment>
                }
               
                <Dropdown optionLabel="name" value={state.reservation.commonArea} options={state.commonAreas} className="p-md-3 p-sm-12" style={{marginBottom:'10px', borderLeft: !state.reservation.commonArea ? '3px solid salmon' : ''}}
                    onChange={(e) => dispatch({type: constants.RESERVATION_COMMON_AREA_SELECTED, value: e.value})} placeholder="Area común" required/>
                <Dropdown optionLabel="name" value={state.reservation.unit} options={state.units} className="p-md-3 p-sm-12" style={{marginBottom:'10px', borderLeft: !state.reservation.unit ? '3px solid salmon' : ''}}
                    onChange={(e) => dispatch({type: constants.RESERVATION_UNIT_SELECTED, value: e.value})} placeholder="Unidad" required/>
                {
                    state.admin &&
                    <Fragment>
                        <Dropdown  optionLabel="name" value={state.reservation.oType} options={configurations.peopleTypes} style={{marginBottom:'10px', borderLeft: !state.reservation.oType ? '3px solid salmon' : ''}}
                            onChange={(e) => dispatch({type: constants.RESERVATION_PEOPLE_TYPE, value: e.value})} placeholder="Tipo de propietario" required/>
                        <Dropdown optionLabel="name" value={state.reservation.statusSelected} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.reservation.statusSelected ? '3px solid salmon' : ''}}
                            onChange={(e) => dispatch({type: constants.RESERVATION_STATUS_SELECTED, value: e.value})} placeholder="Estado" required/>
                    </Fragment>
                    
                }
            </div>
        </div>

        <div className="p-grid">
            <div className="p-md-4 p-sm-12">
                <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
            </div>
        </div>    
    </form>
    );
}

export default ReservationAreaDialog;
