import React, {Component} from 'react';
import {Button} from "primereact/button";

export default class Error extends Component {

	render() {
		return <div className="exception-body error">
			<div className="exception-panel">
				<div className="exception-content">
					<img src="assets/layout/images/pages/icon-error.svg" alt="roma"/>
						<h1>Ocurrio un error</h1>
						<p>Contacte a su administrador</p>

						<Button label="Volver a loguearse" className="p-button-warning"  onClick={() => {window.location = "/?#/login"}} />
				</div>
			</div>
		</div>
	}
}