import React, {useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {PickList} from 'primereact/picklist';
import {RadioButton} from 'primereact/radiobutton';

import * as constants from "../../Constants";
import * as action from "./actions/UnitAction"
import * as actionPeople from "../people/actions/PeopleAction"

const UnitDialog = ({ state, isNew, dispatch, fn, setLoading }) => {

    useEffect(() => {
        action.searchPeoplesByCompany(state, dispatch);
        if (isNew){
            let building = state.building ? state.building : {}
            state.unit = {
                notRented: true
            };
            state.unit.building = building;
            state.targets = [];
        } else {
           action.searchPeoplesByUnit(state, dispatch);
        }
            
      }, []) 

    const onPeoplesBySearch = () => {
        if ((state.filter.first_name === undefined || state.filter.first_name.length === 0) &&
        (state.filter.last_name === undefined || state.filter.last_name.length === 0) &&
        (state.filter.email === undefined || state.filter.email.length === 0)){
            action.searchPeoplesByCompany(state, dispatch);
        } else {
            actionPeople.search(state.filter, state, dispatch);
        }
    }

    const onSave = (e) => {
        e.preventDefault();
        var submit = document.activeElement.getAttribute("name");
        if (submit !== "search" && validate()){
            setLoading(true);
            action.saveUnit(state, isNew, dispatch, fn, setLoading);
        }   
    }

    function validate(){
        var nameValue = document.forms["unitForm"]["unitName"].value;
        if (nameValue === "")
            return false;
        
        return true
    }

    return (
        <div className="p-fluid">
            <form id="unitForm" className="p-fluid" onSubmit={onSave}>
                <div className="p-md-8">
                    <InputText id="in" name="unitName" value={state.unit.name} style={{marginBottom:'2px'}} placeholder="Nombre"
                        onChange={(e) => dispatch({type: constants.UNIT_NAME, value: e.target.value})} required/>
                    <Fieldset legend='Buscador de personas'>
                        <div className="p-grid">
                            <div className="p-md-12 filter-container">
                                <InputText className="p-md-12" value={state.filter.firstName} style={{marginBottom:'2px'}} placeholder="Nombre"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_FIRST_NAME_SEARCH, value: e.target.value})}/>
                                <InputText className="p-md-12" value={state.filter.lastName} style={{marginBottom:'2px'}} placeholder="Apellido"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_LAST_NAME_SEARCH, value: e.target.value})}/>
                                <InputText className="p-md-12" value={state.filter.email} style={{marginBottom:'2px'}} placeholder="Correo"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_EMAIL_SEARCH, value: e.target.value})}/>
                                <div className="p-md-6">
                                    <Button label="Buscar" onClick={() => onPeoplesBySearch()} name="search"
                                        className="p-button-warning" icon="pi pi-search" iconPos="right" />
                                </div>
                            </div>   
                        </div>
                    </Fieldset>
                </div>
                <div className="p-md-12">
                    <div className="p-md-12" style={{marginBottom:'2px'}}>Personas responsables de la unidad</div>
                    <PickList source={state.peoples} target={state.targets} 
                                sourceHeader="Personas" targetHeader="Personas de la unidad"
                                responsive={true} itemTemplate={(people) => <span>{people.email}</span>}
                                onChange={event => dispatch({type: constants.UNIT_LIST_PEOPLE, peoples: event.source, targets: event.target})} />
                </div>
            
                <div className="p-md-12" style={{marginBottom: '15px'}}>
                    <div className="p-col-12 p-md-4">
                        <RadioButton value={true} inputId="rb1" onChange={event => dispatch({type: constants.UNIT_NOT_RENTED, value: event.value})} checked={state.unit.notRented}/>
                        <label htmlFor="rb1" className="p-radiobutton-label">Unidad con propietario</label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <RadioButton value={false} inputId="rb2" onChange={event => dispatch({type: constants.UNIT_NOT_RENTED, value: event.value})} checked={!state.unit.notRented}/>
                        <label htmlFor="rb2" className="p-radiobutton-label">Unidad con inquilino</label>
                    </div>

                    {/*<Checkbox onChange={(e) => dispatch({type: constants.UNIT_NOT_RENTED, value: e.checked})} checked={state.unit.notRented}></Checkbox>
                    <label htmlFor="cb1" className="p-checkbox-label">Unidad sin inquilinos</label>*/}
                </div>
                <div className="p-grid">
                    { isNew && <div className="p-md-4">
                        <Button label="Crear" name="create" className="p-button-warning" icon="pi pi-plus" iconPos="right"/>
                    </div>}
                    { !isNew && <div className="p-md-4">
                        <Button label="Guardar" name="update" className="p-button-warning" icon="pi pi-pencil" iconPos="right"/>
                    </div>}
                </div> 
            </form>    
        </div>
    );
}

export default UnitDialog;
