import * as constants from '../../../../Constants';
import CashReducer from '../../../cash/reducers/CashReducer'

const HistoryPaymentReducer = (state, action) => {
    switch (action.type) {
        case constants.HISTORY_PAYMENT_FILTER_TYPE_SELECTED:
            state.filter.type = action.value
            return {
                ...state
            };
        case constants.HISTORY_PAYMENT_FILTER_STATUS_SELECTED:
            state.filter.status = action.value
            return {
                ...state
            };
        case constants.HISTORY_PAYMENT_SEARCH:
            return {
                ...state,
                paymentLoads: action.value
            };
        case constants.PAYMENT_LOAD_SELECTED:
            state.paymentLoad = action.value
            return {
                ...state
            };
        default: return CashReducer(state, action);
    }
};

export default HistoryPaymentReducer;