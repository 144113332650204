import * as constants from '../../../Constants';
import CashReducer from '../../cash/reducers/CashReducer';
import * as utils from "../../Utils";

const PaymentExpenseReducer = (state, action) => {
    switch (action.type) {
        case constants.PAYMENT_EXPENSE_LOAD:
            return {
                ...state,
                summary: action.value,
                rangeEnd: action.value.rangeEnd,
                periodMonth: action.value.periodMonth,
                periodYear: action.value.periodYear
            };
        case constants.PAYMENT_EXPENSE_MOVEMENT_GROUP_SELECTED:
            state.paymentExpense.movementGroup = action.value;
            state.paymentExpense.groupId = action.value.id;
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_PEOPLE_TYPE:
            state.paymentExpense.oType = action.value;
            state.paymentExpense.peopleType = action.value.code;
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_DESCRIPTION:
            state.paymentExpense.description = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_EXPIRES_SELECTED:
            state.paymentExpense.payDate = action.value
            return {
                ...state
            }; 
        case constants.PAYMENT_EXPENSE_AMOUNT:
            state.paymentExpense.amount = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_NAME:
            state.paymentExpense.name = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_DOCUMENT:
            state.paymentExpense.document = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_ID:
            state.paymentExpense.expenseId = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_UPLOAD:
            state.upload = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_SUPPLIER_SELECTED:
            state.paymentExpense.supplier = action.value
            return {
                ...state
            };
        case constants.PAYMENT_EXPENSE_SELECTED:
            state.paymentExpense = action.value
            return {
                ...state
            };
        default: return CashReducer(state, action);
    }
};

export default PaymentExpenseReducer;