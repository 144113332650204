import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const summary = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/works/summary/` + state.user.id, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false);
        dispatch({ type: constants.WORK_SUMMARY, value: response.data.value });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.WORK_SUMMARY, dispatch, false);
    }) 
}

export const searchWorkClosed = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/works/closed/filter/` + state.user.id, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.WORK_CLOSED, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.WORK_CLOSED, dispatch, false);
    }) 
}

export const eliminatedWork = (state, dispatch, fn, setLoading) => {
    axios(
        access.build('delete','/api/works/' + state.work.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Reclamo eliminado correctamente.';

            state.payment = {};
            severity = 'info';
            
            summary(state, dispatch, setLoading);
            fn(false);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);setLoading(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const saveWork = (state, dispatch, fn, setLoading) => {
    if (state.work.statusSelected){
        state.work.status = state.work.statusSelected.type;
    }

    axios(
        access.build('post', `/api/works/`, state.work)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Trabajo modificada correctamente.';
            severity = 'info';
            
            summary(state, dispatch, setLoading);
            fn(false);
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);setLoading(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);

    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 