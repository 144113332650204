import * as constants from '../../../Constants';
import CashReducer from '../../cash/reducers/CashReducer'

const PaymentDiscountTaxReducer = (state, action) => {
    switch (action.type) {
        case constants.PAYMENT_DISCOUNT_LOAD:
            return {
                ...state,
                discounts: action.value
            };
        case constants.PAYMENT_DISCOUNT_SELECTED:
            return {
                ...state,
                discount: action.value
            };
        case constants.PAYMENT_DISCOUNT_DESCRIPTION:
            state.discount.description = action.value;
            return {
                ...state
            };
        case constants.PAYMENT_DISCOUNT_AMOUNT:
            state.discount.amount = action.value;
            return {
                ...state
            };
        case constants.PAYMENT_DISCOUNT_PEOPLE_TYPE:
            state.discount.oType = action.value;
            state.discount.peopleType = action.value.code;
            return {
                ...state
            };
        case constants.PAYMENT_TAX_LOAD:
            return {
                ...state,
                taxes: action.value
            };
        case constants.PAYMENT_TAX_SELECTED:
            return {
                ...state,
                tax: action.value
            };
        case constants.PAYMENT_TAX_DESCRIPTION:
            state.tax.description = action.value;
            return {
                ...state
            };
        case constants.PAYMENT_TAX_AMOUNT:
            state.tax.amount = action.value;
            return {
                ...state
            };
        default: return CashReducer(state, action);
    }
};

export default PaymentDiscountTaxReducer;