import * as constants from '../../../../Constants';
import CommonAreaReducer from '../../reducers/CommonAreaReducer'
import * as utils from "../../../Utils";

const ReservationAreaReducer = (state, action) => {
    switch (action.type) {
        case constants.RESERVATION_SEARCH:
            return {
                ...state,
                reservations: action.value
            };
        case constants.RESERVATION_SUMMARY:
            state.summary = action.value
            return {
                ...state
            };
        case constants.RESERVATION_START:
            state.reservation.start = action.value
            return {
                ...state
            };
        case constants.RESERVATION_END:
            state.reservation.end = action.value
            return {
                ...state
            };
        case constants.RESERVATION_COMMON_AREA_SELECTED:
            state.reservation.commonArea = action.value
            return {
                ...state
            };
        case constants.RESERVATION_UNIT_SELECTED:
            state.reservation.unit = action.value
            return {
                ...state
            };
        case constants.RESERVATION_STATUS_SELECTED:
            state.reservation.statusSelected = action.value
            state.reservation.status = action.value.type;
            return {
                ...state
            };
        case constants.RESERVATION_PEOPLE_TYPE:
            state.reservation.oType = action.value;
            state.reservation.peopleType = action.value.code;
            return {
                ...state
            }
        case constants.COMMON_AREA_RESERVATION_SEARCH:
            if (!utils.isEmpty(state.reservation)){
                state.reservation.commonArea = action.value.content.filter(type => type.id === state.reservation.commonAreaId)[0];
            }
            return {
                ...state,
                commonAreas: action.value.content
            };
        case constants.RESERVATION_SELECTED:
            state.reservation = action.value.value
            if (!utils.isEmpty(state.reservation)){
                state.reservation.commonArea = state.commonAreas.filter(type => type.id === state.reservation.commonAreaId)[0];
            }
            return {
                ...state
            };
        case constants.RESERVATION_FILTER_DATE:
            state.filter.date = action.value
            return {
                ...state
            };
        case constants.RESERVATION_FILTER_UNIT_SELECTED:
            state.filter.unit = action.value
            return {
                ...state
            };
        case constants.RESERVATION_FILTER_COMMON_AREA_SELECTED:
            state.filter.commonArea = action.value
            return {
                ...state
            };
        case constants.RESERVATION_FILTER_STATUS_SELECTED:
            state.filter.status = action.value
            return {
                ...state
            };
        default: return CommonAreaReducer(state, action);
    }
};

export default ReservationAreaReducer;