import React, {useEffect, useReducer, useState, Fragment} from 'react';
import { Redirect } from 'react-router-dom'
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Draggable from 'react-draggable';
import {Messages} from 'primereact/messages';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import classNames from 'classnames';
import * as constants from "../../../Constants";
import HistoryPaymentReducer from "./reducers/HistoryPaymentReducer"
import * as action from "./actions/HystoryPaymentAction"
import * as access from "../../AccessAction";


const HistoryPaymentForm = ({ prevState, menuRightActive }) => {
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.search(prevState, dispatch, setLoading);
        }
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleError, setVisibleError] = useState(false)
    const [state, dispatch] = useReducer(HistoryPaymentReducer, {
        ...prevState,
        access: true,
        paymentLoads: [],
        filter: {},
        types: [{'name': 'BROU', 'type': 'BROU'}, {'name': 'SANTANDER', 'type': 'SANTANDER'}, 
        {'name': 'SCOTIABANK', 'type': 'SCOTIABANK'}, {'name': 'REDPAGOS', 'type': 'REDPAGOS'}],
        statuses: [{'name': 'En Proceso', 'type': 'PROCESS'}, {'name': 'Finalizado', 'type': 'COMPLETED'},
        {'name': 'Finalizado con Error', 'type': 'COMPLETED_ERROR'}, {'name': 'Error', 'type': 'ERROR'}]
    });

    const clear = () => {
        state.filter = {};
        dispatch({type: constants.DEFAULT})
    };

    function actionRow(value) {
        return <div>
            <i className="pi pi-search inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Ver errores"></i>  
        </div>   
    }

    function selected(e){
        setVisibleError(true)
        dispatch({type: constants.PAYMENT_LOAD_SELECTED, value: e.value})
    }
    
    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>

            <div className={classNames({'menu-right-active': menuRightActive})}>
                {
                state.redirect &&
                    <Redirect to="/app" />
                }

                <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                    <p>{state.message}</p>
                </Dialog>

                <Dialog header="Errores" visible={visibleError} style={{maxWidth:'80%'}} onHide={(e) => setVisibleError(false)}>
                    {state.paymentLoad && !state.paymentLoad.errors &&
                        <h3 className="title-content">No hay errores</h3>
                    }

                    {state.paymentLoad && state.paymentLoad.errors &&
                        <Draggable>
                            <p>{state.paymentLoad.errors}</p>
                        </Draggable>
                    }
                </Dialog>

                {
                    loading && 
                    <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                    </div>
                }
                {
                    !loading && 
                    <Fragment>
                        <div className="p-md-2 inline">
                            <h1 className="title-content" title={state.building ? state.building.name : ''} >Historial de recaudación masiva</h1>
                        </div>
                        <div className="box-default-margin">
                            <div className="p-md-9 inline">
                                <Dropdown optionLabel="name" value={state.filter.type} options={state.types} className="p-md-3 p-sm-12" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.HISTORY_PAYMENT_FILTER_TYPE_SELECTED, value: e.value})} placeholder="Tipo"/>
                                <Dropdown optionLabel="name" value={state.filter.status} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.HISTORY_PAYMENT_FILTER_STATUS_SELECTED, value: e.value})} placeholder="Estado"/>
                                <Button label="Buscar" onClick={() => action.search(state, dispatch, setLoading)}
                                    className="p-button-warning p-md-3 p-sm-12" icon="pi pi-search" iconPos="right" />
                            </div>
                            <div className="p-md-2 inline">
                                <Button label="Limpiar filtros" onClick={() => clear()}
                                    className="p-button-warning" icon="pi pi-refresh" iconPos="right" />
                            </div>

                            { state.paymentLoads.length > 0 && 
                                <div className="p-md-12">
                                    <h3 className="title-content-menu">Historico</h3>
                                    <DataTable value={state.paymentLoads} paginator={true} rows={10} responsive={true} style={{marginTop:'10px'}} >
                                        <Column field="value.user.name" header="Usuario" sortable={true}/>
                                        <Column field="value.path" header="File"/>
                                        <Column field="value.building.name" header="Edificio" sortable={true}/>
                                        <Column field="value.paymentType" header="Tipo" sortable={true}/>
                                        <Column field="value.paymentStatus" header="Estado" sortable={true}/>
                                        <Column field="value.created" header="Fecha" sortable={true}/>
                                        <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                    </DataTable>
                                </div>
                            }
                        </div>   
                    </Fragment>
                }
            </div>
        </Fragment>
        
    );
}

export default HistoryPaymentForm;
