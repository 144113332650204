import * as constants from '../../../../Constants';
import BuildingReducer from '../../../building/reducers/BuildingReducer';

const WorkReducer = (state, action) => {
    switch (action.type) {
        case constants.WORK_SUMMARY:
            return {
                ...state,
                summary: action.value.data,
                works: action.value.works
            };
        case constants.WORK_CLOSED:
            return {
                ...state,
                worksClosed: action.value
            };
        case constants.WORK_FILTER_DATE:
            state.filter.date = action.value;
            return {
                ...state
            };
        case constants.WORK_SELECTED:
            state.work = action.value;
            return {
                ...state
            };
        case constants.WORK_STATUS_SELECTED:
            state.work.statusSelected = action.value
            state.work.status = action.value.type;
            return {
                ...state
            };
        case constants.WORK_DESCRIPTION:
            state.work.description = action.value
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default WorkReducer;