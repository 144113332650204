import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";

export const saveIncome = (state, isNew, dispatch, fn, setLoading) => {
    state.income.buildingId = state.building.id;

    axios(
        access.build('post', '/api/income/', state.income)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined || response.data.code.startsWith("200")) {
            if (isNew){
                message = 'Ingreso creado correctamente.';
            } else {
                message = 'Ingreso modificado correctamente.';
            }

            state.income = {
                expires: new Date()
            };

            severity = 'info';
            
            //search income
            state.activeIndex = 0;
            search(state, dispatch, setLoading);

            fn(false);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);

    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const saveExpense = (state, isNew, dispatch, fn, setLoading) => { 
    state.expense.buildingId = state.building.id;
    if (state.expense.oSupplier)
        state.expense.supplierId = state.expense.oSupplier.id;
    
    const data = new FormData();
    data.append('file', state.upload);
    data.append('expense', JSON.stringify(state.expense));
 
    axios(
        access.build('post', '/api/expense/' , data)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = isNew ? 'Egreso creado correctamente.' : 'Egreso modificado correctamente.';

            state.expense = {
                expires: new Date()
            };
 
            state.upload = undefined;
            severity = 'info';
            
            //search expense
            state.activeIndex = 1;
            search(state, dispatch, setLoading);
            
            fn(false);
        }
        else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);

    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const search = (state, dispatch, fn) => {
    pagination(null, state, dispatch, fn);
    searchRangePeriod(state, dispatch, fn);
}

export const pagination = (pagination, state, dispatch, fn) => {
    let period = getPeriod(state);
    let building = JSON.parse(localStorage.getItem('building'));
    if (state.activeIndex === 0)
        searchIncome(pagination, period, building, state.unit, state, dispatch, fn);
    if (state.activeIndex === 1)
        searchExpense(pagination, period, building, state, dispatch, fn);
}

export const searchIncome = (pagination, period, building, unit, state, dispatch, fn) => {
    if (building != null){
        let param = unit != null ? `?unitId=` + unit.id : `?unitId=`;
        if (pagination === null){
            state.first = 0;
        } else {
            param += '&page=' + pagination.page + '&size=' + pagination.rows;
        }
        axios(
            access.build('get', `/api/income/movement/` + building.id + `/` + period + param, null)
        )
        .then(response => {
            fn(false);
            dispatch({ type: constants.CASH_SEARCH_INCOME, value: response.data});
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.CASH_SEARCH_INCOME, dispatch, false);
        })
    }    
}

export const searchRangePeriod = (state, dispatch, fn) => {
    let period = getPeriod(state);
    let building = JSON.parse(localStorage.getItem('building'));
    axios(
        access.build('get', `/api/movement/range-period/` + building.id + `/` + period, null)
    )
    .then(response => {
        fn(false);
        dispatch({ type: constants.CASH_SEARCH_RANGE_PERIOD, range: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.CASH_SEARCH_RANGE_PERIOD, dispatch, false);
    })
}

export const searchExpense = (pagination, period, building, state, dispatch, fn) => {
    if (building != null){
        let url = `/api/expense/movement/` + building.id + `/` + period;
        if (pagination === null){
            state.first = 0;
        } else {
            url += '?page=' + pagination.page + '&size=' + pagination.rows;
        }

        axios(
            access.build('get', url, null)
        )
        .then(response => {
            fn(false)
            dispatch({ type: constants.CASH_SEARCH_EXPENSE, value: response.data });
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.CASH_SEARCH_EXPENSE, dispatch, false);
        })
    }    
}

export const downloadFileExpense = (id, ext, state, dispatch) => {
    let period = getPeriod(state);
    
    let data = (ext === 'pdf') ? `pdf/` : `img/`;
    axios(`/api/expense/download/` + data + id + `/` + period, {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        const file = new Blob(
          [response.data], 
          {type: (ext === 'pdf') ? 'application/pdf' : ('image/'+ext) });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
    });
   
}

export const eliminatedExpense = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/expense/' + state.expense.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Egreso eliminado correctamente.';
            }

            state.expense = {};
            severity = 'info';
            
            state.activeIndex = 1;
            search(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedIncome = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/income/' + state.income.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Ingreso eliminado correctamente.';
            }

            state.income = {};
            severity = 'info';
            
            state.activeIndex = 0;
            search(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const searchByFilter = (pagination, state, dispatch, fn, setLoading) => {
    setLoading(true)
    if (state.activeIndex === 0) 
        searchIncomeByFilter(pagination, state, dispatch, fn, setLoading);
    if (state.activeIndex === 1) 
        searchExpenseByFilter(pagination, state, dispatch, fn, setLoading);
}

export const searchIncomeByFilter = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.movementGroup){
        state.filter.groupId = state.filter.movementGroup.id;
    }
    if (state.filter.unit){
        state.filter.unitId = state.filter.unit.id;
    }

    let url = `/api/income/movement/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    } 

    axios(
        access.build('post', url , state.filter)
    )
    .then(response => {
        fn(false);setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_INCOME, value: response.data });
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.CASH_SEARCH_FILTER_INCOME, dispatch, false);
    }) 
}

export const searchExpenseByFilter = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.oSupplier){
        state.filter.supplierId = state.filter.oSupplier.id;
    }
    if (state.filter.movementGroup){
        state.filter.groupId = state.filter.movementGroup.id;
    }

    let url = `/api/expense/movement/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    } 

    axios(
        access.build('post', url, state.filter)
    )
    .then(response => {
        fn(false);setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_EXPENSE, value: response.data });
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.CASH_SEARCH_FILTER_EXPENSE, dispatch, false);
    }) 
}

export const savePayExpense = (state, dispatch, fn) => { 
    axios(
        access.build('post', '/api/payment_expense/payment/' + state.expense.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = 'Pago realizado correctamente.';
            severity = 'info';
            
            state.activeIndex = 1;
            search(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false)
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

const getPeriod = (state) => {
    let period = state.period ? 
        String(state.period.getFullYear()).concat(configurations.appendZeroes(state.period.getMonth() + 1)) : 
        utils.period(0);
    return period; 
}