import React, { Fragment, useEffect } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import Draggable from 'react-draggable';
import * as action from "./actions/PaymentAction"
import * as configurations from "../../Configurations";
import * as constants from "../../Constants";

const PaymentTotalDialog = ({state, dispatch, fn, fnLoading, mobile}) => {

    useEffect(() => {
        action.getSummaryPaymentFromLiquidation(state, dispatch, fnLoading)
        }, []
    );

    const onSave = () => {
            action.paymentTotal(state, dispatch, fn);            
        
    }
    
    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-sm-12 p-md-12 inline">
                    {state.paymentSummary && 
                        <Fragment>
                            <h3 className="title-content" style={{ textAlign: 'center'}}>Periodo {state.paymentSummary.startPeriod} - {state.paymentSummary.endPeriod}</h3>
                            <h3 className="title-content" style={{ textAlign: 'center'}}>{state.paymentSummary.total ? 'Total a pagar $' + state.paymentSummary.total : 'No hay monto para el periodo'}</h3>
                        </Fragment>
                    }
                    
                </div>

                {
                    state.paymentSummary && state.paymentSummary.executionId &&
                    <div className="p-md-5 p-sm-12">
                        <Calendar locale={configurations.es} value={state.payment.date} placeholder="Fecha" showIcon={true} dateFormat="dd/mm/yy" style={{marginBottom:'10px'}} 
                                onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DETAIL_DATE, value: e.target.value})} required/>
                        <InputText id="code" style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DETAIL_CODE, value: e.target.value})} placeholder="Código"/>
                        <InputText id="execution_id" value={state.payment.executionId} style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_EXECUTION_ID, value: e.target.value})} placeholder="Id. ejecución" required/>
                        <Dropdown optionLabel="name" value={state.payment.type} options={configurations.typePayments} className="p-md-2" style={{marginBottom:'10px'}} 
                                    onChange={(e) => dispatch({type: constants.PAYMENT_DETAIL_TYPE_SELECTED, value: e.value})} placeholder="Origen de pago" required/>
                                            
                        <div className="p-md-8 p-sm-12">
                            <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right" tooltip="Genera los pagos tomando la ultima liquidacion del periodo."/>
                        </div>
                    </div>
                }
                 
            </form>
        </div>
        
    );
}

export default PaymentTotalDialog;
