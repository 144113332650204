import React, {useReducer, useEffect, Fragment, useState} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import {Calendar} from 'primereact/calendar';
import classNames from 'classnames';
import {Fieldset} from 'primereact/fieldset';
import {Dropdown} from 'primereact/dropdown';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as configurations from "../../../Configurations";
import * as constants from "../../../Constants";
import PriceReducer from "./reducers/PriceReducer";
import PriceDialog from './PriceDialog';
import * as action from "./actions/PriceAction";
import * as utils from "../../Utils";

const PriceForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        action.search(prevState, dispatch, setLoading);
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visiblePrice, setVisiblePrice] = useState(false)
    const [visiblePriceEdit, setVisiblePriceEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [state, dispatch] = useReducer(PriceReducer, {
        ...prevState,
        access: true,
        price: {},
        filter: {},
        statuses: [{'name': 'Pendiente', 'type': 'PENDING'},{'name': 'En proceso', 'type': 'PROCESS'},{'name': 'Finalizado', 'type': 'FINALIZED'}, 
        {'name': 'Cancelado', 'type': 'CANCELLED'}, {'name': 'Programado', 'type': 'FUTURE'}]
    });

    function createPrice(){
        state.price = {}
        setVisiblePrice(true);
    }
    

    function actionRow(e) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(e)} title="Editar"></i> 
            <i className="pi pi-arrow-down" style={{fontSize: '2em', color:'#f88d12' }} onClick={() =>  action.download(e.value, state, dispatch, setLoading)} title="Descargar cotización"/>
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(e)} title="Eliminar"></i>  
        </div>   
    }

    function selected(e){
        setVisiblePriceEdit(true)
        dispatch({type: constants.PRICE_SELECTED, value: e})
    }


    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.PRICE_SELECTED, value: e})
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedPrice(state, dispatch, setVisibleEliminated, setLoading)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function statusRow(value){
        let status = utils.statusRow(value)
        let color = 'summary-status-closed';
        if (status === 'Pendiente'){
            color = 'summary-status-alert'
        } else if (status === 'Asignado'){
            color = 'summary-status-warning'
        } else if (status === 'Finalizado'){
            color = 'summary-status-success'
        } 
        
        return <div className={color + " p-grid p-justify-center"} 
            style={{padding:'2%', margin:'0 5% 0 5%', borderRadius: '20px', fontSize: '12px' }}>
            <label className="status-box">{status}</label> 
        </div>
    }

    const clear = () => {
        dispatch({type: constants.PRICE_FILTER_SUPPLIER, value: undefined});
    };

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>

            <Dialog header="Nueva cotización" visible={visiblePrice} style={{maxWidth:'50%'}} onHide={() => setVisiblePrice(false)}>
                <PriceDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew ={true}
                    fn = {setVisiblePrice}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar cotización" visible={visiblePriceEdit} style={{maxWidth:'50%'}} onHide={() => setVisiblePriceEdit(false)}>
                <PriceDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew ={false}
                    fn = {setVisiblePriceEdit}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Eliminar cotización" visible={visibleEliminated} modal={true} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar dicha cotización?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                     <div className="p-md-2">
                        <h1 className="title-content" style={{marginBlockEnd: 0}}>Cotizaciones</h1>
                    </div>
                    <div className="p-md-12">
                        <div class="p-grid box-default-margin">
                            <div className="p-md-12">
                                <Button label="Crear cotización" style={{marginTop:'20px'}}
                                    className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={() => createPrice()}/>
                            </div>

                            <Fieldset legend='Buscador' className="p-md-12">
                                <div className="p-grid">
                                    <div className="p-md-12">
                                        <Calendar locale={configurations.es} placeholder="Desde" showIcon={true} value={state.filter.ingress} dateFormat="dd/mm/yy"
                                            tooltip="Fecha de ingreso de las cotizaciones"
                                            onChange={(e) => dispatch({type: constants.PRICE_FILTER_DATE, value: e.value})}/>
                                    </div>
                                    <div className="p-md-12">   
                                        <Dropdown optionLabel="name" value={state.filter.supplier} options={state.user.suppliers} className="p-md-2" style={{borderLeft: !state.price.supplier ? '3px solid salmon' : ''}}
                                            onChange={(e) => dispatch({type: constants.PRICE_FILTER_SUPPLIER, value: e.value})} placeholder="Proveedor"/> 
                                        {
                                            state.filter.supplier &&
                                            <div className="p-md-2 inline">
                                                <i className="pi pi-trash" style={{ marginLeft: '10px',fontSize: '2em', color:'#f88d12', verticalAlign:'middle' }} onClick={() => clear()} title="Limpiar filtro"></i>  
                                            </div>
                                        }
                                    </div>       
                                    <div className="p-md-2">
                                        <Button label="Buscar" onClick={() =>  action.searchByFilters(state, dispatch, setLoading)}
                                            className="p-button-warning p-md-8" icon="pi pi-search" iconPos="right" />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>   

                    { state.prices && state.prices.length > 0 &&
                       <div className="box-default-margin">
                            <div className="p-md-12">
                                <h2 className="title-content">Listado de cotizaciones</h2>
                                <DataTable value={state.prices} paginator={true} responsive={true} rows={10}>
                                    <Column field="value.id" header="Ref." sortable={true}/>
                                    <Column field="value.name" header="Nombre" />
                                    <Column body={(r) => statusRow(r.value.status)} header="Estado" style={{width: '10%'}} />
                                    <Column field="value.ingress" header="Creación" />
                                    <Column field="value.supplierName" header="Proveedor" />
                                    <Column field="value.workId" header="Trabajo Ref." sortable={true}/>
                                    <Column field="value.file" header="Cotización" />
                                    <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                </DataTable>
                            </div>
                        </div>        
                    }
                </div>
                    
            }
        </Fragment>
    );
}

export default PriceForm;
