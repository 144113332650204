import React, {useReducer, useEffect, useState, Fragment} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import * as constants from "../../../Constants";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import MovementAccountReducer from "./reducers/MovementAccountReducer";
import MovementAccountDialog from "./MovementAccountDialog";
import * as access from "../../AccessAction";
import * as action from "./actions/MovementAccountAction";
import * as utils from "../../Utils";

const MovementAccountForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.search(prevState, dispatch, setLoading);
        }
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleMovementAccount, setVisibleMovementAccount] = useState(false)
    const [visibleMovementAccountEdit, setVisibleMovementAccountEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [state, dispatch] = useReducer(MovementAccountReducer, {
        ...prevState,
        access: true,
        movementAccount: {},
        groups: [{name: 'Fondo reserva', code: 'FR'}, {name: 'Gastos comunes', code: 'GC'}]
    });

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function selected(e){
        setVisibleMovementAccountEdit(true)
        dispatch({type: constants.MOVEMENT_ACCOUNT_SELECTED, value: e})
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.MOVEMENT_ACCOUNT_SELECTED, value: e})
    }

    function amountRow(account, currencySymbol) {
        return utils.formatCurrency(account.incomePay - account.expensePay, currencySymbol);
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedMovementAccount(state, dispatch, setVisibleEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} style={{maxWidth:'90%'}} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nuevo caja" visible={visibleMovementAccount} style={{maxWidth:'90%'}} onHide={() => setVisibleMovementAccount(false)}>
                <MovementAccountDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                    fn = {setVisibleMovementAccount}
                />
            </Dialog>
            <Dialog header="Editar caja" visible={visibleMovementAccountEdit} style={{maxWidth:'90%'}} onHide={() => setVisibleMovementAccountEdit(false)}>
                <MovementAccountDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                    fn = {setVisibleMovementAccountEdit}
                />
            </Dialog>
            <Dialog header="Eliminar caja" visible={visibleEliminated} modal={true} style={{maxWidth:'90%'}} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar la caja?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Cajas</h2>
                    <div className="box-default" style={{padding:'10px'}}>
                        { state.building &&
                            <div className="p-md-12">
                                <Button label="Crear caja" style={{marginTop:'20px'}}
                                    className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={() => setVisibleMovementAccount(true)}/>
                            </div>
                        }

                        { state.building && state.accounts && state.accounts.length > 0 &&
                            <div>
                                <h2 className="title-content">Listado de cajas</h2>
                                <DataTable value={state.accounts} paginator={true} responsive={true} rows={10} selectionMode="single" 
                                    onSelectionChange={e =>  dispatch({type: constants.MOVEMENT_ACCOUNT_SELECTED, value: e.value})} >
                                    <Column field="name" header="Nombre" sortable={true}/>
                                    <Column header="Cobranza" body={(r) => utils.formatCurrency(r.incomePay, state.building.currency)}/>
                                    <Column header="Gastos" body={(r) => utils.formatCurrency(r.expensePay, state.building.currency)}/>
                                    <Column header="Monto total" body={(r) => amountRow(r, state.building.currency)} sortable={true}/>
                                    <Column field="accountType" header="Tipo de caja" sortable={true}/>
                                    <Column field="priority" header="Prioridad" sortable={true}/>
                                    <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                </DataTable>
                            </div>
                            
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default MovementAccountForm;
