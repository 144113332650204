import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {Checkbox} from 'primereact/checkbox';

import * as constants from "../../../Constants";
import * as action from "./actions/MovementAccountAction";

const MovementAccountDialog = ({ state, isNew, dispatch, fn }) => {

    useEffect(() => {
        if (isNew)
            state.movementAccount = {};
      }, []) 

    const onSave = () => {
        action.saveMovementAccount(state, isNew, dispatch, fn)
    }

    return (
        <div className="p-fluid" style={{margin: '0 auto'}}>
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-8">
                    <InputText value={state.movementAccount.name} style={{marginBottom:'2%'}}
                        onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_NAME, value: e.target.value})} placeholder="Nombre" required/>
                    <InputText value={state.movementAccount.priority} style={{marginBottom:'2%'}} type="number"
                        onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_PRIORITY, value: e.target.value})} placeholder="Prioridad" required/>
                    <Dropdown  optionLabel="name" value={state.movementAccount.oAccountType} options={state.groups}
                        style={{borderLeft: !state.movementAccount.oAccountType ? '3px solid salmon' : '', marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_GROUPTYPE, value: e.value})} placeholder="Grupo Caja" required/>
                    <div className="p-md-12">
                        <Checkbox onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_ALLOW_DELETE, value: e.checked})} checked={state.movementAccount.allowDelete}></Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label">Permite eliminar caja</label>
                    </div>
                </div>
                {
                        isNew && 
                        <Fragment>
                            <div className="p-md-12">
                                <h3 className="title-content-menu">Inicializar montos</h3>
                            </div>
                            <InputText value={state.movementAccount.incomePay} style={{marginBottom:'2%'}} type="number" step='0.01'
                                onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_INCOME, value: e.target.value})} placeholder="Cobranza"/>
                            <InputText value={state.movementAccount.expensePay} style={{marginBottom:'2%'}} type="number" step='0.01'
                                onChange={(e) => dispatch({type: constants.MOVEMENT_ACCOUNT_EXPENSE, value: e.target.value})} placeholder="Gastos"/>
                        </Fragment>
                }

                <div className="p-md-4 p-sm-12">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>
            </form>    
        </div>
    );
}

export default MovementAccountDialog;
