import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer';

const UnitReducer = (state, action) => {
    switch (action.type) {
        case constants.UNIT_SAVE_BUILDING_SELECTED:
            if (action.unit_building !== null){
                state.unit.building = action.unit_building;
            }
            state.peoples = action.value
            return {
                ...state
            };
        case constants.UNIT_LOAD_PEOPLES_SELECTED:
            state.peoples = action.peoples;
            state.targets = action.targets;
            return {
                ...state
            };
        case constants.UNIT_BUILDING_SEARCH:
            return {
                ...state,
                units: action.value.content,
                totals: action.value.totalElements
            };
        case constants.UNIT_NAME:
            state.unit.name = action.value;
            return {
                ...state
            };
        case constants.UNIT_DIALOG:
            return {
                ...state,
                visibleUnit: action.value
            };   
        case constants.UNIT_SELECTED:
            state.unit = action.value
            state.unit.building = state.building;
            return {
                ...state,
                targets: state.unit.peoples
            }; 
        case constants.UNIT_LIST_PEOPLE:
            return {
                ...state,
                targets: action.targets
            }; 
        case constants.UNIT_PEOPLES:
            return {
                ...state,
                targets: action.value
            };
        case constants.UNIT_NOT_RENTED: 
            state.unit.notRented = action.value;
            return {
                ...state
            }; 
        default: return BuildingReducer(state, action);
    }
};

export default UnitReducer;