import * as constants from '../../../../Constants';
import BuildingReducer from '../../../building/reducers/BuildingReducer';

const MovementAccountReducer = (state, action) => {
    switch (action.type) {
        case constants.MOVEMENT_ACCOUNT_SEARCH:
            state.accounts = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_NAME:
            state.movementAccount.name = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_PRIORITY:
            state.movementAccount.priority = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_INCOME:
            state.movementAccount.incomePay = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_EXPENSE:
            state.movementAccount.expensePay = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_GROUPTYPE:
            state.movementAccount.oAccountType = action.value
            state.movementAccount.accountType = state.movementAccount.oAccountType.code
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_ALLOW_DELETE:
            state.movementAccount.allowDelete = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_ACCOUNT_SELECTED:
            state.movementAccount = action.value
            state.movementAccount.oAccountType = state.groups.filter(group => group.code === state.movementAccount.accountType)[0]
            return {
                ...state
            };     
        default: return BuildingReducer(state, action);
    }
};

export default MovementAccountReducer;