import React, {useReducer, useState, Fragment, useEffect} from 'react';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import classNames from 'classnames';
import * as constants from "../../Constants";
import PeopleReducer from "../people/reducers/PeopleReducer"
import UnitDialog from "./UnitDialog"
import * as action from "./actions/UnitAction"
import * as access from "../AccessAction";

const UnitForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building !== undefined){
            action.searchByBuilding(null, prevState, dispatch, setLoading);
        }
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleUnit, setVisibleUnit] = useState(false)
    const [visibleUnitEdit, setVisibleUnitEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [state, dispatch] = useReducer(PeopleReducer, {
        ...prevState,
        access: true,
        unit: {
            notRented: true
        },
        filter: {},
        targets: []
    });

    const clearUnit = () => {
        state.unit = {
            notRented: true
        };
        state.targets = [];
        setVisibleUnit(true);
    }

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function selected(e){
        setVisibleUnitEdit(true)
        dispatch({type: constants.UNIT_SELECTED, value: e})
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.UNIT_SELECTED, value: e})
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedUnit(state, dispatch, setVisibleEliminated, setLoading)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function unitRented(value){
        return value ? 'NO': 'SI'
    }

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.searchByBuilding(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} style={{maxWidth:'90%'}} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            
            <Dialog header="Unidad" visible={visibleUnit} style={{maxWidth:'90%'}} onHide={() => setVisibleUnit(false)}>
                <UnitDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                    fn = {setVisibleUnit} 
                    setLoading = {setLoading} />
            </Dialog>
            <Dialog header="Unidad" visible={visibleUnitEdit} style={{maxWidth:'90%'}} onHide={() => setVisibleUnitEdit(false)}>
                <UnitDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                    fn = {setVisibleUnitEdit}
                    setLoading = {setLoading} />
            </Dialog>
            <Dialog header="Eliminar Unidad" visible={visibleEliminated} style={{maxWidth:'90%'}} modal={true} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar unidad?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Gestión de Unidades</h2>
                    <div className="box-default" style={{padding:'10px'}}>
                        <div className="p-grid p-md-6">
                            <Button label="Crear Unidad" style={{marginBottom:'10px'}} onClick = {() => clearUnit()}
                                className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                        </div>
                    </div>
                    
                    {
                        (!state.units || state.units.length === 0) &&
                        <div className="box-default-margin" style={{maringTop: '1%'}}>
                            <h3 className="title-content">Sin unidades asociadas</h3>
                        </div>
                    }
                    {
                        state.units && state.units.length > 0 &&
                        <div className="box-default-margin" style={{maringTop: '1%'}}>
                            <div className="p-md-12">
                                <h2 className="title-content-menu">Unidades {state.building.name}</h2>
                                <DataTable value={state.units} paginator responsive rows={10} 
                                lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} 
                                style={{marginTop:'10px'}} >
                                    <Column field="name" header="Nombre" />
                                    <Column header="Con inquilino" body={(r) => unitRented(r.notRented)}></Column>
                                    <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                </DataTable>
                            </div>
                        </div>
                    }          
                </div>
            }
        </Fragment>
        
    );
}

export default UnitForm;
