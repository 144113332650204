import React, { Fragment, useEffect } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import Draggable from 'react-draggable';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import * as action from "./actions/PaymentAction"
import * as configurations from "../../Configurations";
import * as constants from "../../Constants";

const PaymentDetailDialog = ({state, dispatch, fn, fnLoading, mobile}) => {

    useEffect(() => {
        state.messageErrorAmount = null;
        }, []
    );

    const peopleTypes = [{name: 'Propietario', code: 'P'}, {name: 'Inquilino', code: 'I'}]

    function setNumberDecimal(event) {
        let amount = parseFloat(event).toFixed(2);
        isValidateAmountAccounts(state.accounts, amount);
        dispatch({type: constants.CHANGE_PAYMENT_DETAIL_PAY, value: amount});
    }

    const onSave = () => {
        if (isValidateAmountAccounts(state.accounts, state.payment.pay)){
            action.createPayment(state, dispatch, fn);            
        } 
    }

    const onCheckedDeposit = (e) => {
        if (e){
            state.payment.unit = undefined
        }
        dispatch({type: constants.CHANGE_PAYMENT_UNIT_UNDEFINED, value: e})
    }

    const onEditorValueChange = (props, value) => {
        let updatedAccounts = [...props.value];
        updatedAccounts[props.rowIndex][props.field] = value;

        if (isValidateAmountAccounts(updatedAccounts, state.payment.pay)){
            dispatch({type: constants.CHANGE_AMOUNT_ACCOUNT, value: updatedAccounts})  
        } 
    }
    
    function isValidateAmountAccounts(updatedAccounts, pay){
        state.messageErrorAmount = null;
        let accounts = []
        let amountTotal = 0;
        updatedAccounts.forEach(a => {
            let account = {}
            account.id = a.id
            account.amount = a.amount
            accounts.push(account)
    
            amountTotal += parseFloat(a.amount);
          }); 

        if (amountTotal != pay){
            state.messageErrorAmount = 'El total asociado a las cajas debe coincidir con el monto del pago.'
            dispatch({type: constants.DEFAULT})  
            return false;
        } 

        return true;
    }

    const inputTextEditor = (props, field) => {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;
    }
    
    const amountEditor = (props) => {
        return inputTextEditor(props, 'amount');
    }

    return (
        <Draggable disabled={mobile()}>
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-8 p-sm-12">
                    <InputText id="code" style={{marginBottom:'10px'}}
                        onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DETAIL_CODE, value: e.target.value})} placeholder="Código"/>
                    
                    <div className="p-md-12">
                        <div className="p-md-9 inline" style={{marginLeft:'-5px'}}>
                            <Dropdown optionLabel="name" value={state.payment.unit} options={state.units} 
                                style={{borderLeft: (!state.payment.depositUndefined && !state.payment.unit) ? '3px solid salmon' : ''}} 
                                required = {!state.payment.depositUndefined && !state.payment.unit}
                                disabled = {state.payment.depositUndefined}
                                onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_UNIT_SELECTED, value: e.value})} placeholder="Unidad" />
                        </div>
                        <div className="p-md-3 inline">
                            <Checkbox onChange={(e) => onCheckedDeposit(e.checked)} checked={state.payment.depositUndefined}></Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label">Sin identificar</label>
                        </div>
                    </div>

                    <Dropdown  optionLabel="name" value={state.payment.oType} options={peopleTypes} style={{marginBottom:'10px', borderLeft: !state.payment.oType ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.CHANGE_PEOPLE_TYPE, value: e.value})} placeholder="Persona" required/>
                    <Fragment>
                        <InputText id="description" style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DESCRIPTION, value: e.target.value})} placeholder="Descripción"/>
                        <InputText id="name" style={{marginBottom:'10px'}}
                            onChange={e => dispatch({type: constants.CHANGE_PAYMENT_NAME, value: e.target.value})} placeholder="Nombre"/>
                        <InputText id="lastname" style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_LASTNAME, value: e.target.value})} placeholder="Apellido"/>
                        <InputText id="document" style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DOCUMENT, value: e.target.value})} placeholder="Documento"/>
                    </Fragment> 
                    
                    <Dropdown optionLabel="name" value={state.payment.type} options={configurations.typePayments} className="p-md-2" style={{marginRight:'10px', marginBottom:'10px', borderLeft: !state.payment.type ? '3px solid salmon' : ''}} 
                                onChange={(e) => dispatch({type: constants.PAYMENT_DETAIL_TYPE_SELECTED, value: e.value})} placeholder="Origen de pago" required/>
                    <InputText id="total" style={{marginBottom:'10px'}} tooltip={'Monto en ' + `${state.building.currency}`}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_TOTAL, value: e.target.value})} placeholder="A pagar"/>
                    
                    <InputText id="payment" style={{marginBottom:'10px'}} tooltip={'Monto en ' + `${state.building.currency}`}
                        onChange={(e) => setNumberDecimal(e.target.value)} placeholder="Pago" type="number"
                        min="1.00" step='0.01' required/>
                        
                    <Calendar locale={configurations.es} value={state.payment.date} placeholder="Fecha" showIcon={true} dateFormat="dd/mm/yy" style={{marginBottom:'10px'}}
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DETAIL_DATE, value: e.target.value})} required/>

                    <h3 className="title-content">Asignación de montos a cajas</h3>
                    <DataTable value={state.accounts} responsive={true} editMode="row" >
                        <Column field="name" header="Caja" sortable={true}/>
                        <Column field="amount" header="Monto" editor={amountEditor} />
                        <Column rowEditor />
                    </DataTable>
                    {
                        state.messageErrorAmount &&
                        <h3 className="title-content" style={{color: '#e06f6f'}}>{state.messageErrorAmount}</h3>
                    }
                    
                    <div className="p-md-8 p-sm-12">
                        <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right"/>
                    </div>
                </div> 
            </form>
        </div>
        </Draggable>
        
    );
}

export default PaymentDetailDialog;
