import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return <div className="layout-footer">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid p-justify-center"> 
                        <img src="assets/layout/images/woloolo.png" style={{with:'200px', height:'40px'}} alt="Plataforma de administradoras"/>
                    </div>
                </div>
            </div>
        </div>
    }
}