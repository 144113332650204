import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import * as constants from "../../Constants";
import * as action from "./actions/PaymentDiscountTaxAction"

const TaxDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    const onSave = () => {
        action.saveTax(state, dispatch, isNew, fn, setLoading);
    }

    function setNumberDecimal(event) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: constants.PAYMENT_TAX_AMOUNT, value: amount})
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-12">
                    <div className="p-md-8">
                        <InputText value={state.tax.description} placeholder="Descripción" 
                            onChange={(e) => dispatch({type: constants.PAYMENT_TAX_DESCRIPTION, value: e.target.value})} required/>
                    </div>
                    <div className="p-md-8">
                        <InputText placeholder="Monto %" value={state.tax.amount}
                            onChange={(e) => setNumberDecimal(e.target.value)} type="number"
                            min="0" step='0.01' required/>
                    </div>
                </div>

                <div className="p-md-3">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default TaxDialog;
