import * as constants from '../../../Constants';
import CompanyReducer from './CompanyReducer';

const EventReducer = (state, action) => {
    switch (action.type) {
        case constants.COMPANY_EVENT:
            state.eventCalendar = action.value ? state.events.filter(e => e.id === parseInt(action.value.id))[0] : undefined;
            return {
                ...state
            };
        case constants.EVENT_LOAD:
            state.events = action.value.events;
            state.eventsDay = action.value.eventsDay;
            return {
                ...state
            };
        case constants.EVENT_START:
            state.event.start = action.value
            return {
                ...state
            };
        case constants.EVENT_PEOPLE:
            state.event.people = action.value
            return {
                ...state
            };
        case constants.EVENT_PLACE:
            state.event.place = action.value
            return {
                ...state
            };
        case constants.EVENT_TITLE:
            state.event.title = action.value
            return {
                ...state
            };
        default: return CompanyReducer(state, action);
    }
};

export default EventReducer;