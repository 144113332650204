import React,{useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../Constants";
import * as action from "./actions/PaymentDiscountTaxAction"

const DiscountDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        state.discount.oType = peoples.filter(s => s.code === state.discount.peopleType)[0];
        }, []
    );
    
    const peoples = [{name: 'Propietario', code: 'P'}, {name: 'Inquilino', code: 'I'}]

    const onSave = () => {
        action.saveDiscount(state, dispatch, isNew, fn, setLoading);
    }

    function setNumberDecimal(event) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: constants.PAYMENT_DISCOUNT_AMOUNT, value: amount})
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-8">
                    <InputText value={state.discount.description} placeholder="Descripción" 
                        onChange={(e) => dispatch({type: constants.PAYMENT_DISCOUNT_DESCRIPTION, value: e.target.value})} required/>
                </div>
                <div className="p-md-8">
                    <InputText placeholder="Monto %" value={state.discount.amount}
                        onChange={(e) => setNumberDecimal(e.target.value)} type="number"
                        min="0" step='0.01' required/>
                </div>
                <div className="p-md-6">
                    <Dropdown  optionLabel="name" value={state.discount.oType} options={peoples}
                        style={{borderLeft: !state.discount.oType ? '3px solid salmon' : ''}} 
                        onChange={(e) => dispatch({type: constants.PAYMENT_DISCOUNT_PEOPLE_TYPE, value: e.value})} placeholder="Tipo"/>
                </div>
 

                <div className="p-md-3">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default DiscountDialog;
