import React, {useEffect, Fragment, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Spinner} from 'primereact/spinner';
import {FileUpload} from 'primereact/fileupload';
import {Dropdown} from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import * as configurations from "../../Configurations";
import * as constants from "../../Constants";
import * as action from "./actions/BuildingAction";

const BuildingDialog = ({ state, isNew, fn, dispatch }) => {

    const [activeAlert, setActiveAlert] = useState(false)
    const types = [{name: 'Edificio', type: 'BUILDING'},{name: 'Condominio', type: 'CONDOMINIUM'}];
    const executions = [{name: 'Automático', type: 'AUTOMATIC'},{name: 'Manual', type: 'MANUAL'}];
    
    useEffect(() => {
        if (!isNew){
            action.alertsByBuilding(state, dispatch);

            types.forEach(o => {
                if(o.type === state.buildingData.type){
                    state.buildingData.oType = o;
                }
            });
            executions.forEach(o => {
                if(o.type === state.buildingData.executionType){
                    state.buildingData.oExecution = o;
                }
            });

            let documents = []
            Object.keys(state.buildingData.documentations).map(key => {
                let document = state.buildingData.documentations[key];
                documents.push(document);
            })

            state.buildingData.documents = documents;

            configurations.currencies.forEach(o => {
                if(o.currency === state.buildingData.currency){
                    state.buildingData.oCurrency = o;
                }
            });
        }
      }, []) 
    
    const saveAlerts = () => {
        action.saveAlert(state.alertPendingPay, state, dispatch);
        action.saveAlert(state.alertDebtor, state, dispatch);
    }

    const onSave = () => {
        action.saveBuilding(state, isNew, dispatch, fn);
    }

    function addService(e){
        if (state.service && state.service.description){
            dispatch({type: constants.BUILDING_SERVICE_ADD});
        }
        e.preventDefault();
    }

    function serviceRow(e) {
        return <div>
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedService(e)} title="Eliminar"></i>  
        </div>   
    }

    function selectedElimindatedService(e){
        dispatch({type: constants.BUILDING_SERVICE_ELIMINATED_SAVE, value: e})
    }

    const upload = (event) => {
        action.uploadDocument(event, state, dispatch, fn);
    };

    function deleteRow(value) {
        return <Button type="button" icon="pi pi-trash" onClick={() =>  action.deleteDocument(value, state, dispatch, fn)} className="p-button-warning"/>
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-grid" style={{padding:'2%'}}>
                    <h3 className="title-content p-md-12 p-sm-12" >Datos del edificio</h3>
                    <div className="p-md-8">
                        <InputText value={state.buildingData.name} style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.BUILDING_NAME, value: e.target.value})} placeholder="Nombre" required/>
                        <InputText value={state.buildingData.address}  style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.BUILDING_ADDRESS, value: e.target.value})} placeholder="Dirección" required/>
                        <Dropdown optionLabel="currency" value={state.buildingData.oCurrency} options={configurations.currencies} required
                                onChange={(e) => dispatch({type: constants.BUILDING_CURRENCY, value: e.value})} placeholder="Moneda"/>

                        { !isNew && 
                            <Fragment>
                                <InputText value={state.buildingData.phone ? state.buildingData.phone : ''} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_PHONE, value: e.target.value})} placeholder="Telefonos"/>
                                <InputText value={state.buildingData.mobile ? state.buildingData.mobile : ''} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_MOBILE, value: e.target.value})} placeholder="Celulares"/>
                                <InputText value={state.buildingData.code ? state.buildingData.code : ''} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_CODE, value: e.target.value})} placeholder="Codigo"/>
                                <Dropdown  optionLabel="name" value={state.buildingData.oType} options={types} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_TYPE, value: e.value})} placeholder="Tipo"/>
                                <Spinner value={state.buildingData.closeDay} style={{marginBottom:'2px'}} 
                                    onChange={(e) => dispatch({type: constants.BUILDING_CLOSE_DAY, value: e.value})} 
                                    min={1} max={30} placeholder="Cierre del periodo" tooltip="Dia de cierra periodo contable."/>
                                <Spinner value={state.buildingData.payDay} style={{marginBottom:'2px'}} 
                                    onChange={(e) => dispatch({type: constants.BUILDING_PAY_DAY, value: e.value})} 
                                    min={1} max={30} placeholder="Ultimo día para pago bonificado" tooltip="Ultimo día (inclusive) para realizar el pago con bonificación. Luego de esta fecha debe pagar la totalidad sin bonificar."/>
                                <InputTextarea rows={6} cols={40} value={state.buildingData.note ? state.buildingData.note : ''} 
                                    onChange={(e) => dispatch({type: constants.BUILDING_NOTE, value: e.target.value})} placeholder="Texto libre"/>
                                <Dropdown  optionLabel="name" value={state.buildingData.oExecution} options={executions} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_EXECUTION_TYPE, value: e.value})} placeholder="Ejecución de liquidación" tooltip="Ejecución de liquidación."/>
                                
                                <h4 className="title-content" style={{marginTop:'5%'}}>Información disponible solo para el administrador</h4>
                                <InputTextarea rows={3} cols={40} value={state.buildingData.internalNote ? state.buildingData.internalNote : ''} 
                                    onChange={(e) => dispatch({type: constants.BUILDING_INTERNAL_NOTE, value: e.target.value})} placeholder="Información interna"/>
                            </Fragment>
                        }
                        </div>
                </div>
            
            
                {
                    !isNew && 
                    <div className="p-md-12" style={{marginTop: '2%'}}>
                        <div class="p-grid box-default-margin">
                            <h3 className="title-content p-md-12 p-sm-12" >Servicios</h3>
                            <div className="p-md-4 p-sm-12">
                                <InputText value={(state.service && state.service.description) ? state.service.description : ''} style={{marginBottom:'2px'}}
                                    onChange={(e) => dispatch({type: constants.BUILDING_SERVICE_NAME, value: e.target.value})} placeholder="Servicio"/>
                                <Button label="Agregar" className="p-button-warning" icon="pi pi-plus" iconPos="right"
                                    onClick={(e) => addService(e)} />
                            </div>
                            
                            { state.buildingData.services && state.buildingData.services.length > 0 &&
                                <Fragment>
                                    <DataTable value={state.buildingData.services} style={{marginTop:'15px'}}>
                                        <Column field="description" header="Servicio" />
                                        <Column header="Acciones" body={(r) => serviceRow(r)} style={{width: '15%'}}/>
                                    </DataTable>                                    
                                </Fragment>
                            }
                        </div>
                        

                        <div class="p-grid box-default-margin" style={{marginTop: '4%'}}>
                            <h3 className="title-content p-md-12 p-sm-12" >Alertas</h3>
                        
                            {
                                ((!state.buildingData.alerts || state.buildingData.alerts.length === 0)  && !activeAlert) &&
                                <Fragment>
                                    <label htmlFor="cb1" className="p-checkbox-label">Sin alertas configuradas, activar: </label>
                                    <div style={{marginTop:'15px'}} className="inline">
                                        <Checkbox onChange={(e) => setActiveAlert(true)} checked={activeAlert}></Checkbox>
                                    </div>
                                </Fragment>
                            }
                            {
                                ((state.buildingData.alerts && state.buildingData.alerts.length > 0) || activeAlert) &&
                                <div className="p-col-12">
                                    <div className="p-col-12">
                                        <label htmlFor="cb1" className="p-checkbox-label">Notificar proximo vencimiento</label>
                                    </div>
                                    <div className="p-col-2">
                                        <Spinner value={state.alertPendingPay.delay} style={{marginBottom:'2px'}} 
                                            onChange={(e) => dispatch({type: constants.BUILDING_ALERT_PENDING_DAY, value: e.value})} 
                                            min={1} max={31}
                                            tooltip="Se notifica el proximo vencimiento dias antes del cierre."/>
                                    </div>
                                    
                                    <div className="p-col-12" style={{marginTop: '2%'}}>
                                        <label htmlFor="cb1" className="p-checkbox-label">Notificar a deudores</label>
                                    </div>
                                    <div className="p-col-2">
                                        <Spinner value={state.alertDebtor.frecuency} style={{marginBottom:'2px'}} 
                                            onChange={(e) => dispatch({type: constants.BUILDING_ALERT_DEBTOR, value: e.value})} 
                                            min={1} max={31}
                                            tooltip="Se notifica a deudores con dicha frecuencia en dias."/>
                                    </div>

                                    <div className="p-md-4">
                                        <Button label="Guardar alertas" className="p-button-warning" icon="pi pi-plus" iconPos="right" 
                                            onClick={() => saveAlerts()}/>
                                    </div>
                                </div>
                            }
                        </div>

                        {
                            (state.buildingData.externalAbitab || state.buildingData.externalRedPago) &&
                            <div class="p-grid p-justify-center box-default-margin" style={{marginTop: '4%'}}>
                                <h3 className="title-content p-md-12 p-sm-12" >Redes de cobranza</h3>
                                { state.buildingData.externalAbitab &&
                                    <div className="p-md-5 p-sm 12 inline">
                                        <div class="p-grid p-justify-center box-default-margin" style={{margin:'2%'}}> 
                                            <button className="p-link">
                                                <img src="assets/layout/images/abitab.jpeg" alt="Abitab" className="rectangule--square"/>
                                            </button>
                                            
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalAbitab.companyName} placeholder="Nombre de empresa" style={{marginBottom:'2%'}}
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_ABITAB_COMPANY_NAME, value: e.target.value})}/>
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalAbitab.companyNr} placeholder="Num. de empresa" style={{marginBottom:'2%'}}
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_ABITAB_COMPANY_NR, value: e.target.value})}/>
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalAbitab.subcompanyNr} placeholder="Numero de sub-empresa"
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_ABITAB_SUBCOMPANY_NR, value: e.target.value})}/>
                                            <span className="p-md-12 p-sm-12">Nro. Lote: {state.buildingData.externalAbitab.loteId}</span>
                                        </div>
                                    </div>
                                }
                                { state.buildingData.externalRedPago && 
                                    <div className="p-md-5 p-sm 12 inline"> 
                                        <div class="p-grid p-justify-center box-default-margin" style={{margin:'2%'}}> 
                                            <button className="p-link">
                                                <img src="assets/layout/images/redpagos.png" alt="Red Pagos" className="rectangule--square"/>
                                            </button>
                                            
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalRedPago.companyName} placeholder="Nombre de empresa" style={{marginBottom:'2%'}}
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_REDPAGO_COMPANY_NAME, value: e.target.value})}/>
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalRedPago.companyNr} placeholder="Num. de empresa" style={{marginBottom:'2%'}}
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_REDPAGO_COMPANY_NR, value: e.target.value})}/>
                                            <InputText className="p-md-8 p-sm-12" value={state.buildingData.externalRedPago.subcompanyNr} placeholder="Numero de sub-empresa"
                                                onChange={(e) => dispatch({type: constants.BUILDING_EXTERNAL_REDPAGO_SUBCOMPANY_NR, value: e.target.value})}/>
                                            <span className="p-md-12 p-sm-12">Nro. Lote: {state.buildingData.externalRedPago.loteId}</span>
                                        </div>
                                    </div>
                                }   
                            </div>
                        }

                        <div class="p-grid p-justify-center box-default-margin" style={{marginTop: '4%'}}>
                            <h3 className="title-content p-md-12 p-sm-12" >Documentos del edificio</h3>
                            <FileUpload name="name" url="./upload" 
                                maxFileSize={1000000}
                                mode="advanced"
                                accept="*" 
                                multiple={false}
                                customUpload={true} 
                                uploadHandler={(event) => upload(event)}
                                chooseLabel="Seleccionar"
                                uploadLabel="Cargar"
                                cancelLabel="Cancelar"/>

                            {
                                state.buildingData.documents && state.buildingData.documents.length > 0 &&
                                <DataTable value={state.buildingData.documents} paginator={true} rows={3} responsive={true} className="p-md-8" >
                                    <Column field="name" header="Nombre" className="p-md-10"/>
                                    <Column header="Acción" body={(r) => deleteRow(r.name)}/>
                                </DataTable>
                            }
                        </div>
                        
                    </div>
                }
        
                <div className="p-grid">
                    { isNew && <div className="p-md-4">
                        <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right"/>
                        </div>
                    }
                    { !isNew && <div className="p-md-4" style={{marginTop:'15px'}}>
                        <Button label="Guardar Edificio" className="p-button-warning" icon="pi pi-pencil" iconPos="right"/>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
}

export default BuildingDialog;
