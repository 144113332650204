import React, {useReducer, Fragment, useEffect, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Fieldset} from 'primereact/fieldset';
import {Messages} from 'primereact/messages';
import classNames from 'classnames';
import {FileUpload} from 'primereact/fileupload';
import * as constants from "../../Constants";
import PeopleReducer from "./reducers/PeopleReducer"
import PeopleDialog from "./PeopleDialog"
import * as action from "./actions/PeopleAction"
import * as access from "../AccessAction";

const PeopleForm = ({ prevState, menuRightActive }) => {

    useEffect((state) => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.companyId != undefined){
            action.searchPeoples(prevState, dispatch);
        }
        }, []
    );

    const [loadPeople, setLoadPeople] = useState(false)
    const [state, dispatch] = useReducer(PeopleReducer, {
        ...prevState,
        access: true,
        visiblePeople: false,
        visiblePeopleSelected: false,
        people: {},
        filter: {},
        values:[{name: 'Edificio 1', code: '1'},{name: 'Edificio 2', code: '2'}, {name: 'Edificio 3', code: '3'}],
    });

    const search = () => {
        action.search(state.filter, state, dispatch);
    }

    const dialog = (type, visible) => {
        dispatch({type: type, value: visible});
        return;
    }

    const upload = (event) => {
        action.upload(event, state, dispatch, setLoadPeople);
    };

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Propietario o Inquilino" visible={state.visiblePeople} style={{maxWidth:'50%'}} onHide={() => dialog(constants.PEOPLE_DIALOG, false)}>
                <PeopleDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                />
            </Dialog>
            <Dialog header="Propietario o Inquilino" visible={state.visiblePeopleSelected} style={{maxWidth:'50%'}} onHide={() => dialog(constants.PEOPLE_DIALOG_SELECTED, false)}>
                <PeopleDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                />
            </Dialog>
            <Dialog header="Cargar desde archivo" visible={loadPeople} style={{maxWidth:'60%'}} onHide={() => setLoadPeople(false)}>
                <FileUpload name="name" url="./upload" 
                    maxFileSize={1000000}
                    mode="advanced"
                    accept="*" 
                    multiple={false}
                    customUpload={true} 
                    uploadHandler={(event) => upload(event)}
                    chooseLabel="Seleccionar"
                    uploadLabel="Cargar"
                    cancelLabel="Cancelar"/>
                
                {
                    state.errors && state.errors.length > 0 && 
                    <Fragment>
                        <h3 className="title-content">Errores del proceso de carga</h3>
                        <DataTable value={state.errors} paginator={true} responsive={true} rows={10} 
                            style={{marginTop:'10px'}} >
                            <Column field="line" header="Linea" />
                            <Column field="message" header="Error"/>
                        </DataTable>
                    </Fragment>
                }
            </Dialog>

            <div className={classNames({'menu-right-active': menuRightActive})}>
                <h2 className="title-content">Gestión de Propietarios e inquilinos</h2>
                <div className="box-default" style={{padding:'10px'}}>
                    <div className="p-md-9 inline">
                        <Button label="Crear Persona" style={{marginBottom:'10px'}} onClick = {() => dialog(constants.PEOPLE_DIALOG, true)}
                            className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                    </div>
                    <div className="p-md-2 inline">
                        <Button label="Importar Personas" style={{marginBottom:'10px'}} onClick = {() => setLoadPeople(true)}
                            className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                    </div>
                        
                    <Fieldset legend='Buscador'>
                        <div className="p-grid">
                            <div className="p-md-4 filter-container">
                                    <InputText className="p-md-12" value={state.filter.firstName} style={{marginBottom:'2px'}} placeholder="Nombre"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_FIRST_NAME_SEARCH, value: e.target.value})}/>
                                <InputText className="p-md-12" value={state.filter.lastName} style={{marginBottom:'2px'}} placeholder="Apellido"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_LAST_NAME_SEARCH, value: e.target.value})}/>
                                <InputText className="p-md-12" value={state.filter.email} style={{marginBottom:'2px'}} placeholder="Correo"
                                        onChange={(e) => dispatch({type: constants.PEOPLE_EMAIL_SEARCH, value: e.target.value})}/>
                                <Button label="Buscar" onClick={() => search()}
                                        className="p-button-warning p-md-6" icon="pi pi-search" iconPos="right" />
                            </div>   
                        </div>
                    </Fieldset>
                </div>
            </div>

            {
                state.peoples && state.peoples.length > 0 &&
                <div className="box-default-margin">
                    <div className="p-md-12">
                        <h3 className="title-content-menu">Personas</h3>
                        <DataTable value={state.peoples} paginator={true} responsive={true} rows={10} selectionMode="single" selection={state.selectedPeople} 
                            onSelectionChange={e =>  dispatch({type: constants.PEOPLE_SELECTED, value: e.value})}
                            style={{marginTop:'10px'}} >
                            <Column field="firstName" header="Nombre" />
                            <Column field="lastName" header="Apellido"/>
                            <Column field="documentNr" header="Doc. Identidad"/>
                            <Column field="email" header="Email"/>
                            <Column field="peopleType" header="Tipo"/>
                            <Column field="phone" header="Telefono"/>
                        </DataTable>
                    </div>
                </div>
            }
                
        </Fragment>
        
    );
}

export default PeopleForm;
