import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import axios from "axios";
import * as access from "../../AccessAction";
import * as actionCash from "./CashAction";
import * as utils from "../../Utils";

export const loadPayment = (state, dispatch, building, fn, fnLoading) => {
    state.paymentsAccounts = []
     axios(
        access.build('get', `/api/accounts/` + building.id, null)
    )
    .then(response => {
        let accounts = response.data;
        if (response.status === 200) {
            accounts.forEach(e => { 
                e.amount = 0;
            });
        }

        let period = utils.getPeriod(state);
        let param = `?period=` + period + `&page=0&size=10000`;
        axios(
            access.build('get', `/api/income/` + building.id + param, null)
        )
        .then(responseIncome => {
            let incomes = responseIncome.data.value.incomes
            getUnitsByBuildingId(state, dispatch, state.building, incomes,  accounts, fn, fnLoading)
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    }) 
} 

export const getUnitsByBuildingId = (state, dispatch, building, payments, accounts, fn, fnLoading) => {
    axios(
        access.build('get', `/api/units/building/` + building.id, null)
    )
    .then(response => {
        let header =  headerByAccounts(accounts)
        completedDataPayments(payments)
        fnLoading(false)
        fn(true)
        
        dispatch({type: constants.UNIT_BUILDING_SELECTED, value: building, units: response.data, valuePayments: payments, valueAccounts: accounts, header: header});
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.UNIT_BUILDING_SELECTED, dispatch, false);
    }) 
} 

function headerByAccounts(accounts){
    let header = {"header1": 'Unidad', "header2": 'Fecha', "header3": 'Codigo', "header4": 'Origen', "header5": 'Pago'};
    if (accounts.length > 0 ){
       accounts.forEach((a, idx) => {
            let numHeader = idx+6;
            header["header"+numHeader] = a.name
        })
    }
    
    return [header];
}

function completedDataPayments(payments){
    payments.forEach((payment) =>{
        payment.date = new Date();
    })
}

export const searchPay = (pagination, building, state, dispatch, fn) => {
    if (building != null){
        let period = utils.getPeriod(state);
        let param = state.unit != null ? `?unitId=` + state.unit.id : `?unitId=`;
        if (pagination === null){
            state.first = 0;
        } else {
            param += '&page=' + pagination.page + '&size=' + pagination.rows;
        }

        axios(
            access.build('get', `/api/payments/` + building.id + `/` + period + param, null)
        )
        .then(response => {
            fn(false);
            dispatch({ type: constants.CASH_SEARCH_PAYMENT, value: response.data });
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    }    
}

export const searchPayEliminated = (pagination, building, state, dispatch, fn) => {
    if (building != null){
        let period = utils.getPeriod(state);
        let param = state.unit != null ? `?unitId=` + state.unit.id : `?unitId=`;
        if (pagination === null){
            state.first = 0;
        } else {
            param += '&page=' + pagination.page + '&size=' + pagination.rows;
        }

        axios(
            access.build('get', `/api/payments/eliminated/` + building.id + `/` + period + param, null)
        )
        .then(response => {
            fn(false);
            dispatch({ type: constants.CASH_SEARCH_PAYMENT_ELIMINATED, value: response.data });
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    }    
}

export const searchPaymentExpense = (pagination, period, building, state, dispatch, fn) => {
    if (building != null){
        let url = `/api/payment_expense/` + building.id + `/` + period;
        if (pagination === null){
            state.first = 0;
        } else {
            url += '?page=' + pagination.page + '&size=' + pagination.rows;
        }

        axios(
            access.build('get', url, null)
        )
        .then(response => {
            fn(false);
            dispatch({ type: constants.CASH_SEARCH_PAYMENT_EXPENSE, value: response.data });
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    }    
}

export const createPayment = (state, dispatch, fn) => {
    state.payment.buildingId = state.building.id;
    state.payment.companyId = state.companyId;
    state.payment.paymentType = state.payment.type.type;
    if (state.payment.unit){
        state.payment.unitId = state.payment.unit.id;
    }
    
    state.payment.peopleType = state.payment.oType.code;
    
    let accounts = []
    state.accounts.forEach(a => {
        let account = {}
        account.id = a.id
        account.amount = a.amount
        accounts.push(account)

        }); 
    state.payment.accounts = accounts;
    
    axios( 
        access.build('post', '/api/payments', state.payment)
    )
    .then((response) => {
        if (response.status === 200) {
            loadPaymentSuccess(state, dispatch, fn)
        }
    })
    .catch((e) => {
        fn(false);
       dispatch({ type: constants.DEFAULT, value: 'Error al generar el pago.' });
    });
}

function loadPaymentSuccess(state, dispatch, fn){
    state.activeIndex = 0;
    state.payment = {
        date: new Date(),
        minimum: 0,
        pay: 0,
        paymentType: 'UNKNOWN',
        depositUndefined: false
    }
    state.paymentSummary = {}
    actionCash.summary(utils.period(0), state.building, state, dispatch, fn);
    access.responseData('info', 'Pago creado correctamente', state, constants.DEFAULT, dispatch, false);
}

export const createPaymentDistribution = (state, dispatch, fn) => {
    axios( 
        access.build('post', '/api/payments/save/distribution', state.paymentsAccounts)
    )
    .then((response) => {
        if (response.status === 200) {
            state.paymentsAccounts = []
            actionCash.summary(utils.period(0), state.building, state, dispatch, fn);
            access.responseData('info', 'Pagos realizados correctamente', state, constants.DEFAULT, dispatch, false);
        }
    })
    .catch((e) => {
        fn(false);
       dispatch({ type: constants.DEFAULT, value: 'Error al generar los pagos.' });
    });
    
}

export const eliminatedPayment = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/payments/' + state.payment.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Pago eliminado correctamente.';

            state.payment = {};
            severity = 'info';
            
            actionCash.summary(utils.period(0), state.building, state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const namePaymentByType = (value) => {
    let paymentName;
    configurations.typePayments.forEach(o => {
        if(o.type === value){
            paymentName = o.name;
        }
    });

    return paymentName;
}


export const getSummaryPaymentFromLiquidation = (state, dispatch, fn) => {
    if (state.building != null){
        let period = utils.getPeriod(state);
        axios(
            access.build('get', `/api/payments/summary/` + state.building.id + `/` + period, null)
        )
        .then(response => {
            fn(false);
            dispatch({ type: constants.CASH_PAYMENT_SUMMARY_TOTAL, value: response.data });
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    }    
}

export const paymentTotal = (state, dispatch, fn) => {
    state.payment.buildingId = state.building.id;
    state.payment.companyId = state.companyId;
    state.payment.paymentType = state.payment.type.type;

    axios( 
        access.build('post', '/api/payments/save/total', state.payment)
    )
    .then((response) => {
        if (response.status === 200) {
            loadPaymentSuccess(state, dispatch, fn)
        }
    })
    .catch((e) => {
        fn(false);
       dispatch({ type: constants.DEFAULT, value: 'Error al generar los pagos.' });
    });
    
}