import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {Calendar} from 'primereact/calendar';
import {DataTable} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {Column} from 'primereact/column';
import {TabView,TabPanel} from 'primereact/tabview';
import '../../css/Grid.css';
import classNames from 'classnames';
import '../../css/Grid.css';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import IncomeExpenseReducer from './reducers/IncomeExpenseReducer';
import IncomeDialog from './IncomeDialog';
import ExpenseDialog from './ExpenseDialog';
import * as access from "../AccessAction";
import * as action from "./actions/IncomeExpenseAction";
import * as actionBuilding from "../building/actions/BuildingAction";
import * as actionSupplier from "../supplier/actions/SupplierAction";
import * as actionPaymentExpense from "../payment_expense/actions/PaymentExpenseAction";
import * as utils from "../Utils";
import * as actionMovementGroup from "../movement/group/actions/MovementGroupAction";
import PayExpenseDialog from './PayExpenseDialog';

const IncomeExpense = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            prevState.activeIndex = 0;
            action.search(prevState, dispatch, setLoading);
        }
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleIncome, setVisibleIncome] = useState(false)
    const [visibleExpense, setVisibleExpense] = useState(false)
    const [visibleEliminatedExpense, setVisibleEliminatedExpense] = useState(false)
    const [visibleEliminatedIncome, setVisibleEliminatedIncome] = useState(false)
    const [openFilterDrawerIncome, setOpenFilterDrawerIncome] = useState(false)
    const [openFilterDrawerExpense, setOpenFilterDrawerExpense] = useState(false)
    const [visiblePayExpense, setVisiblePayExpense] = useState(false)
    const [visiblePaymentExpenses, setVisiblePaymentExpenses] = useState(false)
    const [state, dispatch] = useReducer(IncomeExpenseReducer, {
        ...prevState,
        access: true,
        income: {},
        expense: {},
        activeIndex: 0
    });

    const closeFilterDrawerIncome  = () => {
        setOpenFilterDrawerIncome((prevState) => !prevState)
    }

    const closeFilterDrawerExpense  = () => {
        setOpenFilterDrawerExpense((prevState) => !prevState)
    }

    const changeTab = (index) => {
        state.activeIndex = index;
        setLoading(true)
        action.search(state, dispatch, setLoading);
        return;
    }

    function search() {
        setLoading(true);
        action.search(state, dispatch, setLoading);
    }

    const getBuilding = (type) => {
        actionBuilding.getBuilding(state, dispatch, state.building, type);
    }

    function downloadFileExpenseRow(id, file, ext){
        if (file != null){
            let fileName = file;
            if (file.length > 5){
                fileName = file.substring(1, 6) + "..."   
            }

            return <Button type="button" tooltip={'Descargar ' + file} label={fileName} onClick={() =>  action.downloadFileExpense(id, ext, state, dispatch)} className="p-button-warning"/>
        } else {
            return "";
        }
    }

    function actionExpenseRow(value) {
        return <div>
            <i className="pi pi-bars inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedPaymentExpenses(value)} title="Pagos asociados"></i> 
            <i className="pi pi-check-circle inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedPayExpense(value)} title="Pagar"></i> 
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedExpense(value)} title="Eliminar"></i>  
        </div>   
    }

    function actionIncomeRow(value) {
        return <div>
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedIncome(value)} title="Eliminar"></i>  
        </div>   
    }

    function unitRow(value){
        return (value !== undefined && value != null) ? value : 'Todos'
    }

    function selectedElimindatedExpense(e){
        dispatch({type: constants.EXPENSE_SELECTED, value: e})
        setVisibleEliminatedExpense(true);
    }

    function selectedElimindatedIncome(e){
        dispatch({type: constants.INCOME_SELECTED, value: e})
        setVisibleEliminatedIncome(true);
    }

    function selectedPayExpense(e){
        dispatch({type: constants.EXPENSE_SELECTED, value: e})
        setVisiblePayExpense(true);
    }

    function selectedPaymentExpenses(e){
        setLoading(true)
        actionPaymentExpense.searchPaymentsByExpenseId(state, dispatch, e, setVisiblePaymentExpenses, setLoading);
    }

    const confirmationEliminatedExpense = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedExpense(state, dispatch, setVisibleEliminatedExpense)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminatedExpense(false)} label="No" className="p-button-secondary" />
        </div>
    );

    const confirmationEliminatedIncome = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedIncome(state, dispatch, setVisibleEliminatedIncome)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminatedIncome(false)} label="No" className="p-button-secondary" />
        </div>
    );

    const confirmationPayExpense = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.savePayExpense(state, dispatch, setVisiblePayExpense)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisiblePayExpense(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function loadFilters(fn, typeGroup){
        state.filter = {}

        actionSupplier.suppliers(state, dispatch, constants.CASH_EXPENSE_SUPPLIERS);
        actionMovementGroup.movementGroupsByTypeOnlineEvent(state, dispatch, typeGroup);
        fn(true);
    }

    function onLazyLoadIncome(event) {
        state.first = event.first;
        if (state.findByFilter){
            action.searchByFilter(event, state, dispatch, setOpenFilterDrawerIncome, setLoading)
        } else {
            setLoading(true);
            action.pagination(event, state, dispatch, setLoading);
        }
    }

    function onLazyLoadExpense(event) {
        state.first = event.first;
        if (state.findByFilter){
            action.searchByFilter(event, state, dispatch, setOpenFilterDrawerExpense, setLoading)
        } else {
            setLoading(true);
            action.pagination(event, state, dispatch, setLoading);
        }
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nuevo ingreso a cobrar" visible={visibleIncome} onHide={() => setVisibleIncome(false)}>
                <IncomeDialog
                    state = {state}
                    dispatch = {dispatch}
                    getBuilding = {getBuilding}
                    fn = {setVisibleIncome}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Nuevo egreso a pagar" visible={visibleExpense} onHide={() => setVisibleExpense(false)}>
                <ExpenseDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew ={true}
                    fn = {setVisibleExpense}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Listado de pagos" visible={visiblePaymentExpenses} onHide={() => setVisiblePaymentExpenses(false)} style={{maxWidth: mobile() ? '90%' : '80%'}}>
                <PayExpenseDialog
                    state = {state}
                    dispatch = {dispatch}
                />
            </Dialog>

            <Dialog header="Eliminar Egreso" visible={visibleEliminatedExpense} modal={true} footer={confirmationEliminatedExpense} onHide={() => setVisibleEliminatedExpense(false)}>
                Esta seguro que desea eliminar el egreso?
            </Dialog>
            <Dialog header="Eliminar Ingreso" visible={visibleEliminatedIncome} modal={true} footer={confirmationEliminatedIncome} onHide={() => setVisibleEliminatedIncome(false)}>
                Esta seguro que desea eliminar el ingreso?
            </Dialog>
            <Dialog header="Realizar pago" visible={visiblePayExpense} modal={true} footer={confirmationPayExpense} onHide={() => setVisiblePayExpense(false)}>
                Esta seguro que desea confirmar el pago?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Pagos y Cobros</h1>
                    </div>
                    <div className="p-md-2 inline">
                        {
                            state.rangeEnd && 
                            <Fragment>
                                    <h2 className="title-content"  style={{marginBlockStart: '1%', marginBlockEnd:'1%'}} >{String(state.periodMonth).toUpperCase()} {state.periodYear}</h2>
                                    <h3 className="title-content"  style={{marginBlockStart: '1%'}} >Fecha de cierre: {state.rangeEnd}</h3>
                            </Fragment>   
                        }
                    </div>

                    <div class="p-grid box-default-margin">
                        <div className="p-md-8 p-sm-12"> 
                            <Calendar locale={configurations.es} placeholder="Periodo" showIcon={true} value={state.period} view="month"
                                    dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2050" style={{margin:'1%'}}
                                    onChange={(e) => dispatch({type: constants.CASH_PERIOD_SELECTED, value: e.value})}/>
                            <div style={{marginLeft:'1%'}}></div>
                            
                            <Button label="Buscar" onClick={() =>  search()}
                                    className="p-button-warning p-lg-2 p-md-3 p-sm-11" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                        </div>
                        { state.admin && 
                            <div className="p-md-3 p-sm-12 inline">
                                <Button label="A cobrar" onClick={() => setVisibleIncome(true)}
                                    className="p-button-warning p-sm-5" style={{margin:'1%'}} icon="pi pi-plus" iconPos="right" 
                                    disabled = {state.building === undefined}/> 
                                <Button label="A pagar" onClick={() => setVisibleExpense(true)}
                                    className="p-button-warning p-sm-5" style={{margin:'1%'}} icon="pi pi-plus" iconPos="right"
                                    disabled = {state.building === undefined} />
                            </div>
                        }
                        
                        <TabView className="p-md-12" style={{marginTop: '1%'}} activeIndex={state.activeIndex} onTabChange={(e) => changeTab(e.index)}>
                            <TabPanel header="Ingresos a cobrar">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Filtros" onClick={() => loadFilters(setOpenFilterDrawerIncome, "INCOME")}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                <Drawer open={openFilterDrawerIncome} onClose={closeFilterDrawerIncome} direction='right' size={mobile() ? 250 : 500}>
                                    <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                        <h3 className="title-content">Filtros</h3>
                                        {openFilterDrawerIncome &&
                                            <Fragment>
                                                <Calendar locale={configurations.es} placeholder="Desde" showIcon={true} value={state.filter.date}
                                                    dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                    onChange={(e) => dispatch({type: constants.FILTER_DATE, value: e.value})}/>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <InputText value={state.filter.document} placeholder="Documento" className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_DOCUMENT, value: e.target.value})}/>
                                                    <Dropdown optionLabel="name" className="p-md-12 p-sm-12" style={{marginTop:'2%'}} value={state.filter.unit} options={state.units} 
                                                        onChange={(e) => dispatch({type: constants.FILTER_UNIT_SELECTED, value: e.value})} placeholder="Unidades"/>
                                                    <Dropdown optionLabel="name" value={state.filter.movementGroup} options={state.movementsByIncome}
                                                        className="p-md-12 p-sm-12" style={{marginTop: '2%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_MOVEMENT_GROUP_SELECTED, value: e.value})} placeholder="Grupo"/>
                                            
                                                    <Button label="Buscar" onClick={() =>  action.searchByFilter(null, state, dispatch, setOpenFilterDrawerIncome, setLoading)}
                                                        className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </Drawer>
                                
                                {
                                    (!state.incomes || state.incomes.length === 0) &&
                                    <h3 className="title-content">Sin información de ingresos</h3>
                                }
                                
                                {
                                    state.incomes && state.incomes.length > 0 && 
                                    <DataTable value={state.incomes} paginator={true} responsive={true} rows={10} first={state.first}
                                    lazy={true} onPage={(event) => onLazyLoadIncome(event)} totalRecords={state.totals} 
                                    style={{marginTop:'10px', padding:'10px' }} >
                                        <Column field="value.description" header="Descripción" style={{width: '12%'}} sortable={true}/>
                                        <Column body={(r) => utils.formatCurrency(r.value.amount, state.building.currency)} header="Monto"/>
                                        <Column field="value.document" header="Documento" style={{width: '15%'}} sortable={true}/>
                                        <Column header="Unidad" body={(r) => unitRow(r.value.unit)} sortable={true}/>
                                        <Column field="value.group" header="Grupo" sortable={true}/>
                                        <Column field="value.created" header="F. Creación" style={{width: '12%'}} sortable={true}/>
                                        <Column field="value.expires" header="F. Mov." style={{width: '10%'}}  sortable={true}/>
                                        <Column field="value.supplierId" header="Proveedor" style={{width: '12%'}} sortable={true}/>
                                        { 
                                            state.admin && <Column header="Acciones" body={(r) => actionIncomeRow(r.value)} style={{textAlign:'center', width: '8em'}}/>
                                        }
                                    </DataTable>
                                }
                            </TabPanel>
                            <TabPanel header="Egresos a pagar">
                            <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Filtros" onClick={() => loadFilters(setOpenFilterDrawerExpense, "EXPENSE")}
                                        className="p-button-warning p-lg-9 p-md-9 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                <Drawer open={openFilterDrawerExpense} onClose={closeFilterDrawerExpense} direction='right' size={mobile() ? 250 : 500}>
                                    <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                        <h3 className="title-content">Filtros</h3>
                                        {openFilterDrawerExpense &&
                                            <Fragment>
                                                <Calendar locale={configurations.es} placeholder="Desde" showIcon={true} value={state.filter.date}
                                                    dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                    onChange={(e) => dispatch({type: constants.FILTER_DATE, value: e.value})}/>
                                                <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                    <InputText value={state.filter.document} placeholder="Documento" className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_DOCUMENT, value: e.target.value})}/>
                                                    <InputText value={state.filter.expenseId} placeholder="Ref. a pagar" style={{marginTop: '2%'}} className="p-md-12 p-sm-12"
                                                        onChange={(e) => dispatch({type: constants.FILTER_EXPENSE_ID, value: e.target.value})}/>
                                                    <Dropdown optionLabel="name" value={state.filter.movementGroup} options={state.movementsByExpense}
                                                        className="p-md-12 p-sm-12" style={{marginTop: '2%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_MOVEMENT_GROUP_SELECTED, value: e.value})} placeholder="Grupo"/>
                                            
                                                    <Dropdown optionLabel="name" value={state.filter.oSupplier} options={state.suppliers}
                                                        className="p-md-12 p-sm-12" style={{marginTop: '2%'}}
                                                        onChange={(e) => dispatch({type: constants.FILTER_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor"/>
                                        
                                                    <Button label="Buscar" onClick={() =>  action.searchByFilter(null, state, dispatch, setOpenFilterDrawerExpense, setLoading)}
                                                        className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                </Drawer>

                                {
                                    (!state.expenses || state.expenses.length === 0) &&
                                    <h3 className="title-content">Sin información de egresos</h3>
                                }
                                {
                                    state.expenses && state.expenses.length > 0 &&
                                    <DataTable value={state.expenses} paginator={true} responsive={true} rows={10} 
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoadExpense(event)} totalRecords={state.totals} 
                                    style={{marginTop:'10px', padding:'10px' }} >
                                        <Column field="value.id" header="Ref." style={{width: '5%'}}/>
                                        <Column field="value.name" header="Nombre" sortable={true}/>
                                        <Column body={(r) => utils.formatCurrency(r.value.amount, state.building.currency)} header="Monto"/>
                                        <Column field="value.document" header="Documento" sortable={true}/>
                                        <Column field="value.group" header="Grupo" sortable={true}/>
                                        <Column field="value.created" header="F. Creación" sortable={true}/>
                                        <Column field="value.expires" header="F. Mov." sortable={true}/>
                                        <Column field="value.supplier" header="Proveedor" sortable={true}/>
                                        <Column field="value.payments" header="Pagos realizados"/>
                                        <Column field="value.lastPaymentDate" header="F. ultimo pago"/>
                                        <Column header="Archivo" body={(r) => downloadFileExpenseRow(r.value.id, r.value.file, r.value.ext)}/>
                                        {
                                            state.admin && <Column header="Acciones" body={(r) => actionExpenseRow(r.value)} style={{width: '10%'}}/>
                                        }
                                    </DataTable>
                                }
                        </TabPanel>
                    </TabView>
                    </div>          
                </div>
            }
        </Fragment>
    );
}


export default IncomeExpense;
