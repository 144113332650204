import React, {useReducer, useEffect, Fragment, useState} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import SupplierReducer from "./reducers/SupplierReducer";
import * as action from "./actions/SupplierAction"
import SupplierDialog from "./SupplierDialog";
import SupplierAccountDialog from "./SupplierAccountDialog";
import * as access from "../AccessAction";

const SupplierForm = ({ prevState, menuRightActive, mobile }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building !== undefined){
            action.load(null, prevState, dispatch, setLoading);
        }
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleSupplier, setVisibleSupplier] = useState(false)
    const [visibleSupplierEdit, setVisibleSupplierEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [visiblePayments, setVisiblePayments] = useState(false)
    const [state, dispatch] = useReducer(SupplierReducer, {
        ...prevState,
        access: true,
        supplier: {},
        types: [{'name': 'Jardineria', 'type': 'GARDENING'}, {'name': 'Mantenimiento', 'type': 'MAINTENANCE'}, 
        {'name': 'Sanitaria', 'type': 'SANITARY'}, {'name': 'Otro', 'type': 'OTHER'}]
    });

    function createSupplier(){
        state.supplier = {}
        setVisibleSupplier(true);
    }
    
    function categoryRow(value){
        return state.types.filter(t => t.type === value)[0].name;
    }

    function certificateRow(value) {
        return value ? <i className="pi pi-star" style={{ marginLeft: '10px',fontSize: '2em', color:'#EFEB51' }} title="Certificado"></i> : "";
    }

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-list inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selectedAccountPayments(value)} title="Estado de Cuenta"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function selected(e){
        setVisibleSupplierEdit(true)
        dispatch({type: constants.SUPPLIER_SELECTED, value: e})
    }

    function selectedAccountPayments(e){
        setVisiblePayments(true)
        dispatch({type: constants.SUPPLIER_SELECTED, value: e})
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.SUPPLIER_SELECTED, value: e})
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedSupplier(state, dispatch, setVisibleEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.load(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} style={{maxWidth: mobile() ? '90%' : '50%'}}  onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Nuevo proveedor" visible={visibleSupplier} style={{maxWidth: mobile() ? '90%' : '50%'}}  onHide={() => setVisibleSupplier(false)}>
                <SupplierDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                    fn = {setVisibleSupplier}
                />
            </Dialog>
            <Dialog header="Editar proveedor" visible={visibleSupplierEdit} style={{maxWidth: mobile() ? '90%' : '50%'}}  onHide={() => setVisibleSupplierEdit(false)}>
                <SupplierDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                    fn = {setVisibleSupplierEdit}
                />
            </Dialog>
            <Dialog header="Eliminar Proveedor" visible={visibleEliminated} style={{maxWidth: mobile() ? '90%' : '50%'}}  modal={true} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar proveedor?
            </Dialog>
            <Dialog header="Estado de cuenta" visible={visiblePayments} style={{maxWidth: mobile() ? '90%' : '50%'}}  onHide={() => setVisiblePayments(false)}>
                <SupplierAccountDialog
                    state = {state}
                    dispatch = {dispatch}
                />
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-12">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Proveedores</h1>
                    </div>

                    <div class="p-grid box-default-margin">
                        <div className="p-md-12">
                            <Button label="Crear proveedor" style={{marginTop:'20px'}}
                                className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={() => createSupplier()}/>
                        </div>

                        { state.building && state.suppliers && state.suppliers.length > 0 &&
                            <div>
                                <h2 className="title-content">Listado de proveedores</h2>
                                <DataTable value={state.suppliers} paginator responsive rows={10} 
                                lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} >
                                    <Column field="name" header="Nombre" />
                                    <Column body={(r) => categoryRow(r.category)} header="Rubro" />
                                    <Column field="email" header="Email" />
                                    <Column field="phone" header="Teléfono" />
                                    <Column field="mobile" header="Celular" />
                                    <Column field="address" header="Dirección" />
                                    <Column field="rut" header="Rut" />
                                    <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                    <Column body={(r) => certificateRow(r.certificate)} header="Certificado" />
                            </DataTable>
                            </div>
                            
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default SupplierForm;
