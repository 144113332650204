import React, {useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';

import * as constants from "../../Constants";
import * as action from "./actions/SupplierAction";

const SupplierDialog = ({ state, isNew, dispatch, fn }) => {

    useEffect(() => {
        if (isNew){
            state.supplier = {}
        }
            
      }, [])

    const onSave = () => {
        action.saveSupplier(state, isNew, dispatch, fn)
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-5 p-sm-12 inline">
                    <InputText value={state.supplier.name} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_NAME, value: e.target.value})} placeholder="Nombre" required/>
                    <InputText value={state.supplier.address} style={{marginBottom:'2%'}}
                        onChange={(e) => dispatch({type: constants.SUPPLIER_ADDRESS, value: e.target.value})} placeholder="Dirección" required/>
                    <InputText value={state.supplier.email} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_EMAIL, value: e.target.value})} placeholder="Email"/>
                    <InputText value={state.supplier.phone} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_PHONE, value: e.target.value})} placeholder="Telefono"/>
                    <InputText value={state.supplier.mobile} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_MOBILE, value: e.target.value})} placeholder="Celular"/>
                    <InputText value={state.supplier.rut} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_RUT, value: e.target.value})} placeholder="Rut"/>
                    <Dropdown  optionLabel="name" value={state.supplier.oType} options={state.types}
                        style={{borderLeft: !state.supplier.oType ? '3px solid salmon' : ''}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_TYPE, value: e.value})} placeholder="Tipo" required/>                               
                </div>

                <div className="p-md-1 p-sm-12 inline"/>

                <div className="p-md-5 p-sm-12 inline">
                    <h3 className="title-content">Banco</h3>
                    <InputText value={state.supplier.bankName} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_BANK_NAME, value: e.target.value})} placeholder="Banco" required/>
                    <InputText value={state.supplier.bankTypeAccount} style={{marginBottom:'2%'}}
                        onChange={(e) => dispatch({type: constants.SUPPLIER_BANK_TYPE_ACCOUNT, value: e.target.value})} placeholder="Tipo de cuenta" required/>
                    <InputText value={state.supplier.bankStore} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_BANK_STORE, value: e.target.value})} placeholder="Sucursal"/>
                    <InputText value={state.supplier.bankNumberAccount} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_BANK_NUMBER_ACCOUNT, value: e.target.value})} placeholder="Numero de cuenta"/>
                    <InputText value={state.supplier.paymentType} style={{marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_PAYMENT_TYPE, value: e.target.value})} placeholder="Forma de pago"/>                         
                </div>

                <div className="p-md-4 p-sm-12">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div> 
            </form>    
        </div>
    );
}

export default SupplierDialog;
