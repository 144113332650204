import React, {useEffect, useReducer, Fragment, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom'
import {DataTable} from 'primereact/datatable';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import ReservationAreaReducer from "./reducers/ReservationAreaReducer"
import * as action from "./actions/ReservationAreaAction"
import * as access from "../../AccessAction";
import ReservationAreaDialog from './ReservationAreaDialog';

const ReservationAreaAdminForm = ({ prevState, menuRightActive, mobile }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.loadAdmin(state, dispatch, setLoading);
        }
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleReservation, setVisibleReservation] = useState(false)
    const [visibleReservationEdit, setVisibleReservationEdit] = useState(false)
    const [state, dispatch] = useReducer(ReservationAreaReducer, {
        ...prevState,
        access: true,
        reservation: {
            building: prevState.building
        },
        reservations: [],
        filter: {},
        summary: [],
        statuses: [{'name': 'Pendiente', 'type': 'PENDING'},{'name': 'Confirmado', 'type': 'CONFIRMED'}, {'name': 'Rechazado', 'type': 'DECLINED'},{'name': 'Cancelado', 'type': 'CANCELLED'},{'name': 'Finalizado', 'type': 'FINALIZED'}]
    });

    function actionsRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => openReservation(value)} title="Reservación"></i>             
        </div>   
    }

    function statusRow(value){
        let color = getColor(value.statusStr);
        
        return <div className={color + " p-grid p-justify-center"} 
            style={{padding:'2%', margin:'0 5% 0 5%', borderRadius: '20px', fontSize: '12px' }}>
            <label className="status-box">{value.statusStr}</label> 
        </div>
    }

    function getColor(value){
        let color = 'summary-status-closed';
        if (value === 'Pendiente'){
            color = 'summary-status-alert'
        } else if (value === 'Confirmado'){
            color = 'summary-status-warning'
        } else if (value === 'Finalizado'){
            color = 'summary-status-success'
        } 

        return color;
    }

    function openCreateReservation(){
        state.reservation = {
            building: state.building
        };
        setVisibleReservation(true);
    }

    function openReservation(e){
        dispatch({type: constants.RESERVATION_SELECTED, value: e})
        setVisibleReservationEdit(true);
    }

    function clear(){
        state.filter = {}
        dispatch({type: constants.DEFAULT})
    }

    function search() {
        setLoading(true);
        action.search(state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Crear reserva" visible={visibleReservation} className="p-md-4 p-sm-12" onHide={() => setVisibleReservation(false)} style={{maxWidth:'80%'}} contentStyle={{ height: "50vh" }} maximizable >
                <ReservationAreaDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {true}
                    fnLoading = {setLoading}
                    fn = {setVisibleReservation}
                />
            </Dialog>
            <Dialog header="Editar reserva" visible={visibleReservationEdit} className="p-md-4 p-sm-12" onHide={() => setVisibleReservationEdit(false)}  style={{maxWidth:'80%'}} contentStyle={{ height: "50vh" }} maximizable>
                <ReservationAreaDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fnLoading = {setLoading}
                    fn = {setVisibleReservationEdit}
                />
            </Dialog>
            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }

            { 
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Gestión de reservaciones</h2>
                    <div className="box-default" style={{padding:'10px'}}>
                        <div className="p-md-6 p-sm-12">
                            <Button label="Crear" style={{marginBottom:'10px'}} onClick = {() => openCreateReservation()}
                                className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                        </div>

                        <div class="p-grid p-justify-center" style={{marginTop: '2%'}}>
                        {
                            state.summary && 
                            state.summary.map(function(o){
                                let status = o[0].status;
                                let color = getColor(status);

                                return (<div className="p-md-2 p-sm-11 inline">
                                    <div className={color + " box-default summary-status"}>
                                        <div className="p-col-12">
                                            <label className="status-box" style={{marginBlockEnd: 5}}>{status}</label>
                                            <div style={{borderBlockEnd: '1px solid white', marginLeft:'1em', marginRight:'2em'}}></div>
                                            
                                            <div style={{marginTop:'5%'}}>
                                                <div className="p-md-4 inline">
                                                    <label className="status-box" style={{marginBlockEnd: 0, fontWeight: 600}}>{o[0].type}</label>
                                                    <h2 className="title-content status-box" style={{marginBlockEnd: 0, marginBlockStart: 0}}>{o[0].value}</h2>
                                                </div>
                                                {o.length > 1 &&
                                                    <Fragment>
                                                        <div className="p-md-4 inline">
                                                            <label className="status-box" style={{marginBlockEnd: 0, fontWeight: 600}}>{o[1].type}</label>
                                                            <h2 className="title-content status-box" style={{marginBlockEnd: 0, marginBlockStart: 0}}>{o[1].value}</h2>
                                                        </div>
                                                        <div className="p-md-1 inline" style={{textAlign: 'end'}}>
                                                            <i class="pi pi-user status-box" style={{fontSize: "2em"}}></i>
                                                        </div>
                                                    </Fragment> 
                                                }
                                                
                                                {o.length === 1 &&
                                                    <div className="p-md-6 inline" style={{textAlign: 'end'}}>
                                                        <i class="pi pi-user status-box" style={{fontSize: "2em"}}></i>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                </div>)
                            })
                        }
                        </div> 
                    </div>

                    <div className="box-default-margin">
                        <h3 className="title-content-menu p-col-12">Busqueda</h3>
                            <div className="p-md-12 p-sm-12">
                                    <div className="p-md-2 p-sm-11">
                                        <Calendar locale={configurations.es} placeholder="Fecha desde" showIcon={true} value={state.filter.date}
                                            dateFormat="yy-mm-dd"
                                            onChange={(e) => dispatch({type: constants.RESERVATION_FILTER_DATE, value: e.value})}/>
                                    </div>
                                    
                                    <Dropdown optionLabel="name" value={state.filter.commonArea} options={state.commonAreas} className="p-md-3 p-sm-12" style={{borderLeft: !state.reservation.commonArea ? '3px solid salmon' : ''}}
                                        onChange={(e) => dispatch({type: constants.RESERVATION_FILTER_COMMON_AREA_SELECTED, value: e.value})} placeholder="Area común" required/>
                                    <Dropdown optionLabel="name" value={state.filter.unit} options={state.units} className="p-md-3 p-sm-12" style={{marginLeft:'1%', borderLeft: !state.reservation.unit ? '3px solid salmon' : ''}}
                                        onChange={(e) => dispatch({type: constants.RESERVATION_FILTER_UNIT_SELECTED, value: e.value})} placeholder="Unidad" required/>
                                    <Dropdown optionLabel="name" value={state.filter.status} options={state.statuses} className="p-md-2 p-sm-11" style={{marginLeft:'1%', marginRight:'5px'}}
                                        onChange={(e) => dispatch({type: constants.RESERVATION_FILTER_STATUS_SELECTED, value: e.value})} placeholder="Estado"/>
                                    
                                    <Button label="Buscar" onClick={() => search()} style={{marginBottom:'2px', marginRight:'5px'}}
                                        className="p-button-warning p-md-1 p-sm-11" icon="pi pi-search" iconPos="right" />
                                    <Button label="Limpiar" onClick={() => clear()} tilte="Limpiar filtros" style={{marginBottom:'2px', marginRight:'5px'}}
                                        className="p-button-warning p-md-1 p-sm-11" icon="pi pi-trash" iconPos="right" />
                            </div>

                        { state.reservations.length > 0 &&
                            <div className="box-default-margin">
                                <h3 className="title-content-menu">Reservaciones</h3>
                                <DataTable value={state.reservations} paginator={true} responsive={true} rows={10} style={{marginTop:'10px'}} >
                                    <Column field="value.userName" header="Usuario" sortable={true}/>
                                    <Column field="value.unitName" header="Unidad" sortable={true} style={{width: '6%'}}/>
                                    <Column field="value.peopleType" header="Tipo persona" sortable={true} style={{width: '8%'}}/>
                                    <Column field="value.commonAreaName" header="Area" sortable={true}/>
                                    <Column header="Estado" body={(r) => statusRow(r.value)} style={{width: '10%'}}/>
                                    <Column field="value.start" header="Inicio" sortable={true}/>
                                    <Column field="value.end" header="Fin" sortable={true}/>
                                    <Column header="Acciones" body={(r) => actionsRow(r)} style={{textAlign:'center', width: '8em'}} />
                                </DataTable>
                            </div>
                        }
                    </div>
                </div>
            }
        </Fragment>        
    );
}

export default ReservationAreaAdminForm;
