import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";

export const search = (state, dispatch, fn) => {
    let period = utils.getPeriod(state);
    let building = JSON.parse(localStorage.getItem('building')); 

    summary(period, building, state, dispatch, fn);
}

export const summary = (period, building, state, dispatch, fn) => {
    if (building){
        axios(
            access.build('get', `/api/payment_expense/summary/` + building.id + `/` + period, null)
        )
        .then(response => {
            state.message = undefined;
            dispatch({ type: constants.PAYMENT_EXPENSE_LOAD, value: response.data.value });
            searchPaymentExpense(null, period, building, state, dispatch, fn);
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.PAYMENT_EXPENSE_LOAD, dispatch, false);
        })  
    }
}

export const pagination = (pagination, state, dispatch, fn) => {
    let building = JSON.parse(localStorage.getItem('building')); 
    let period = utils.getPeriod(state);
    searchPaymentExpense(pagination, period, building, state, dispatch, fn);
}

export const searchPaymentExpense = (pagination, period, building, state, dispatch, fn) => {
    if (building != null){
        let url = `/api/payment_expense/` + building.id + `/` + period;
        if (pagination === null){
            state.first = 0;
        } else {
            url += '?page=' + pagination.page + '&size=' + pagination.rows;
        }

        axios(
            access.build('get', url, null)
        )
        .then(response => {
            fn(false)
            dispatch({ type: constants.CASH_SEARCH_PAYMENT_EXPENSE, value: response.data });
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        })
    }    
}

export const searchPaymentExpenseByFilter = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.movementGroup){
        state.filter.groupId = state.filter.movementGroup.id;
    }
    if (state.filter.oSupplier){
        state.filter.supplierId = state.filter.oSupplier.id;
    }

    let url = `/api/payment_expense/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('post', url, state.filter)
    )
    .then(response => {
        fn(false);setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_PAYMENT_EXPENSE, value: response.data });
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    })  
}

export const savePaymentExpense = (state, dispatch, fn, setLoading) => { 
    state.paymentExpense.buildingId = state.building.id;

    if (state.paymentExpense.supplier)
        state.paymentExpense.supplierId = state.paymentExpense.supplier.id;

    const data = new FormData();
    data.append('file', state.upload);
    data.append('paymentExpense', JSON.stringify(state.paymentExpense));
 
    axios(
        access.build('post', '/api/payment_expense/' , data)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = 'Gasto creado correctamente.';

            state.paymentExpense = {
                payDate: new Date()
            };
            
            state.upload = undefined;
            severity = 'info';

            search(state, dispatch, setLoading);
            
            fn(false);
        }
        else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);

    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const downloadFileExpense = (id, ext, fileName) => {    
    let data = (ext === 'pdf') ? `pdf` : `img`;
    axios(`/api/payment_expense/download/` + data + `/` + id , {
        method: 'GET',
        responseType: 'blob'
    })
    .then(response => {
        const file = new Blob(
          [response.data], 
          {type: (ext === 'pdf') ? 'application/pdf' : ('image/'+ext) });
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement("a");
        anchor.href = fileURL;
        anchor.download = fileName;
        
        document.body.appendChild(anchor);
        anchor.click();
    })
    .catch(error => {
        console.log(error);
    });
   
}

export const searchPaymentExpenseBySupplierIdAndFilter = (supplierId, filter, state, dispatch, t) => {
    axios(
        access.build('post', `/api/payment_expense/filter/` + state.building.id + `/` + supplierId, filter)
    )
    .then(response => {
        dispatch({ type: t, value: response.data.value });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    })
}

export const eliminatedPaymentExpense = (state, dispatch, fn, setLoading) => { 
    setLoading(true)
    axios(
        access.build('delete', '/api/payment_expense/' + state.paymentExpense.id, null)
    )
    .then((response) => {
        let message, severity;
        fn(false)
        if (response.status === 200) {
            message = 'Gasto eliminado correctamente.';

            state.paymentExpense = {};
            severity = 'info';
            
            search(state, dispatch, setLoading);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const searchPaymentsByExpenseId = (state, dispatch, expense, fn, fnLoading) => {
    axios(
        access.build('get', `/api/payment_expense/payment/` + expense.id, null)
    )
    .then(response => {
        fn(true)
        fnLoading(false)
        dispatch({ type: constants.SEARCH_PAYMENT_EXPENSES, value: response.data });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    }) 
}