import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Messages} from 'primereact/messages';
import classNames from 'classnames';
import {FileUpload} from 'primereact/fileupload';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import PaymentLoadReducer from "./reducers/PaymentLoadReducer"
import * as action from "./actions/PaymentLoadAction"
import * as access from "../AccessAction";


const PaymentLoadForm = ({ prevState, menuRightActive }) => {
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        
        }, []
    );

    const [loading, setLoading] = useState(false)
    const [state, dispatch] = useReducer(PaymentLoadReducer, {
        ...prevState,
        access: true,
        filter: {},
        load:{},
        exts: [{'name': 'XLSX', 'type': 'XLSX'},{'name': 'TXT', 'type': 'TXT'}],
        types: [{'name': 'REDPAGOS', 'type': 'REDPAGOS'},
        , {'name': 'ABITAB', 'type': 'ABITAB'}
		, {'name': 'BROU', 'type': 'BROU'}
		, {'name': 'ITAU', 'type': 'ITAU'}
		, {'name': 'SANTANDER', 'type': 'SANTANDER'}],
        statuses: [{'name': 'En Proceso', 'type': 'PROCESS'}, {'name': 'Finalizado', 'type': 'COMPLETED'},
        {'name': 'Finalizado con Error', 'type': 'COMPLETED_ERROR'}, {'name': 'Error', 'type': 'ERROR'}]
    });

    const upload = (event) => {
        setLoading(true)
        action.upload(event, state, dispatch, setLoading);
    };

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Recaudación masiva</h1>
                    </div>

                    <div class="p-grid box-default-margin" style={{marginTop:'1%'}}>
                        <div className="p-md-12">
                            <h3 className="title-content">Recaudación masiva por archivo</h3>
                        </div>
                        <div className="p-md-12">
                            <Dropdown optionLabel="name" value={state.load.selectedExt} options={state.exts} className="p-md-2" style={{marginRight:'10px',  borderLeft: !state.load.selectedExt ? '3px solid salmon' : ''}}
                                    onChange={(e) => dispatch({type: constants.PAYMENT_LOAD_EXT_SELECTED, value: e.value})} placeholder="Extensión" required/>
                            <Dropdown optionLabel="name" value={state.load.selectedType} options={state.types} className="p-md-2" style={{marginRight:'10px',  borderLeft: !state.load.selectedType ? '3px solid salmon' : ''}}
                                    onChange={(e) => dispatch({type: constants.PAYMENT_LOAD_TYPE_SELECTED, value: e.value})} placeholder="Tipo" required/>
                        </div>
                        <div className="p-md-12">    
                            <FileUpload name="name" url="./upload" 
                                maxFileSize={1000000}
                                mode="advanced"
                                accept="*" 
                                multiple={false}
                                customUpload={true} 
                                uploadHandler={(event) => upload(event)}
                                disabled={ !state.load.selectedExt || !state.load.selectedType }
                                chooseLabel="Seleccionar"
                                uploadLabel="Cargar"
                                cancelLabel="Cancelar"/>
                        </div>
                    </div>     
                </div>
            }
        </Fragment>
        
    );
}

export default PaymentLoadForm;
