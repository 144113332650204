import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as actionEvent from "./EventAction";
import * as actionBuilding from "../../building/actions/BuildingAction"

export const getCompany = (state, companyId, dispatch) => {
    axios(
        access.build('get', '/api/companies/company/' + companyId, null)
    )
    .then(response => {
        dispatch({ type: constants.COMPANY_LOAD, value: response.data }); 
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_LOAD, dispatch, false);
    })    
}

export const load = (state, companyId, dispatch) => {
    axios(
        access.build('get', '/api/companies/company/' + companyId, null)
    )
    .then(response => {
        state.company = response.data;
        state.loading = false;
        actionBuilding.buildingsByRole(companyId, state, dispatch);
        loadComunication(state, companyId, dispatch); 
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_LOAD, dispatch, false);
    })    
}

const loadComunication = (state, companyId, dispatch) => {
    axios(
        access.build('get', '/api/comunications/company/resume/' + companyId, null)
    )
    .then(response => {
        state.comunicationSummary = response.data.value;
        dispatch({ type: constants.COMPANY_LOAD, value: response.data }); 
        actionEvent.load(state, dispatch);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_LOAD, dispatch, false);
    })
}

export const saveCompany = (state, dispatch) => {   
    axios(
        access.build('post', '/api/companies/company/' + state.user.name + '/' + state.user.email, state.company)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Administradora creada correctamente.';
            severity = 'info';
            afterSaveCompany(state, response);
        } 
            
        access.responseData(severity, message, state, constants.COMPANY_DIALOG, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_DIALOG, dispatch, false);
    });
}

const afterSaveCompany = (state, response) => {
    state.company = response.data;
    state.companyId = state.company.id;
    state.loadApp = true;
    localStorage.setItem('companyId', state.companyId)
} 

export const updateCompany = (state, dispatch, fn) => {   
    axios(
        access.build('put', '/api/companies/company/' + state.company.id, state.company)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Administradora modificada correctamente.';
            severity = 'info';

            state.agent = {};
            fn(false)
        } 
        
        access.responseData(severity, message, state, constants.COMPANY_DIALOG, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMPANY_DIALOG, dispatch, false);
    });
}

export const saveAgent = (state, dispatch, isNew, fn) => {
    axios(
        access.build('post', '/api/companies/agent/save/' + state.companyId, state.agent)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (isNew){
                message = 'Operador creado correctamente.';
            } else {
                message = 'Operador modificado correctamente.';
            }

            state.agent = {};
            fn(false);
            getCompany(state, state.companyId, dispatch);
            severity = 'info';
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);
        }

        access.responseData(severity, message, state, constants.COMPANY_DIALOG, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMPANY_DIALOG, dispatch, false);
    });
}

export const eliminatedAgent = (state, dispatch, fn) => {    
    axios(
        access.build('delete', '/api/companies/agent/' + state.agent.id + '/' + state.companyId, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Operador eliminado correctamente.';
            }

            state.supplier = {};
            severity = 'info';
            fn(false);
            getCompany(state, state.companyId, dispatch);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }

        access.responseData(severity, message, state, constants.COMPANY_DIALOG, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMPANY_DIALOG, dispatch, false);
    });
}

export const uploadIcon = (event, state, dispatch, fn) => {
    const data = new FormData();
    data.append('file', event.files[0]);
    data.append('name', 'document');

    axios(
        access.build('post', `/api/companies/upload/${state.companyId}/icon`  , data)
    )
    .then((response) => {
        let message, severity;
        message = 'Logo cargado correctamente.';
        severity = 'info';
        fn(false);    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}


export const getIcon = (state, dispatch) => {
    axios(
        access.build('get', '/api/companies/icon/' + state.companyId, null)
    )
    .then(response => {
        dispatch({ type: constants.COMPANY_ICON, value: response.data }); 
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_ICON, dispatch, false);
    })
}
