import React, {useReducer, useEffect, useState, Fragment} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import { Redirect } from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import * as constants from "../../../Constants";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import MovementGroupReducer from "./reducers/MovementGroupReducer";
import * as actionBuilding from "../../building/actions/BuildingAction"
import MovementGroupDialog from "./MovementGroupDialog";
import * as access from "../../AccessAction";
import * as action from "./actions/MovementGroupAction";

const MovementGroupForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.search(null, prevState, dispatch, setLoading);
        }
        
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleMovementGroup, setVisibleMovementGroup] = useState(false)
    const [visibleMovementGroupEdit, setVisibleMovementGroupEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [state, dispatch] = useReducer(MovementGroupReducer, {
        ...prevState,
        access: true,
        movementGroup: {},
        types: [{name: 'Ingreso', code: 'INCOME'}, {name: 'Egreso', code: 'EXPENSE'}],
        groups: [{name: 'Fondo reserva', code: 'FR'}, {name: 'Gastos comunes', code: 'GS'}]
    });

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function booleanRow(value){
        return value ? 'SI': 'NO'
    }

    function selected(e){
        setVisibleMovementGroupEdit(true)
        dispatch({type: constants.MOVEMENT_GROUP_SELECTED, value: e})
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.MOVEMENT_GROUP_SELECTED, value: e})
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedMovementGroup(state, dispatch, setVisibleEliminated, setLoading)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.search(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} style={{maxWidth:'90%'}} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nuevo grupo de movimiento" visible={visibleMovementGroup} style={{maxWidth:'90%'}} onHide={() => setVisibleMovementGroup(false)}>
                <MovementGroupDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                    fn = {setVisibleMovementGroup}
                    fnLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar grupo de movimiento" visible={visibleMovementGroupEdit} style={{maxWidth:'90%'}} onHide={() => setVisibleMovementGroupEdit(false)}>
                <MovementGroupDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                    fn = {setVisibleMovementGroupEdit}
                    fnLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Eliminar grupo de movimiento" visible={visibleEliminated} modal={true} style={{maxWidth:'90%'}} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar grupo de movimiento?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Grupo de Movimientos</h2>
                    <div className="box-default" style={{padding:'10px'}}>
                        { state.building &&
                            <div className="p-md-12">
                                <Button label="Crear grupo" style={{marginTop:'20px'}}
                                    className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={() => setVisibleMovementGroup(true)}/>
                            </div>
                        }

                        { state.movements && state.movements.length > 0 &&
                            <div>
                                <h2 className="title-content">Listado de grupo de movimientos</h2>
                                <DataTable value={state.movements} paginator responsive rows={10} 
                                    lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} 
                                     >
                                    <Column field="name" header="Nombre" sortable={true}/>
                                    <Column field="type" header="Tipo" sortable={true}/>
                                    <Column body={(r) => booleanRow(r.toPrint)} header="En informe liq." sortable={true}/>
                                    <Column body={(r) => booleanRow(r.applyCoefficient)} header="Aplica coef." sortable={true}/>
                                    <Column field="account.name" header="Caja" sortable={true}/>
                                    <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                            </DataTable>
                            </div>
                            
                        }
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default MovementGroupForm;
