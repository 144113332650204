import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from "./App";
import Landing from "./Landing";
import Login from "./components/login/Login";
import Register from "./components/login/Register";
import Logout from "./pages/Logout";
import Error from "./pages/Error";
import Access from "./pages/Access";
import NotFound from "./pages/NotFound";

class AppWrapper extends Component {
	constructor(props) {
		super(props)
		this.state = { ...props}
	  }
	
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}
	
	render() {
		switch(this.props.location.pathname) {
			case "/app":
				return <Route path="/app"	render={() => <App prevState={this.props.location.state} />}/>
			case "/login":
				return <Route path="/login"	render={() => <Login prevState={this.props.location.state} />}/>
			case "/register":
				return <Route path="/register"	render={() => <Register />}/>
			case "/close":
				return <Route path="/close"	render={() => <Logout />}/>
			case "/access":
				return <Route path="/access" component={Access}/>
			case "/error":
				return <Route path="/error" component={Error}/>
			case "/404":
				return <Route path="/404" component={NotFound}/>
			case "/company":
				return <Route path="/company" render={() => <App prevState={this.props.location.state} />}	/>
			case "/building":
				return <Route path="/building" render={() => <App prevState={this.props.location.state} />}	/>
			case "/people":
				return <Route path="/people" render={() => <App prevState={this.props.location.state} />}	/>
			case "/unit":
				return <Route path="/unit" render={() => <App prevState={this.props.location.state} />}	/>
			case "/movement_group":
				return <Route path="/movement_group" render={() => <App prevState={this.props.location.state} />}	/>
			case "/movement_account":
				return <Route path="/movement_account" render={() => <App prevState={this.props.location.state} />}	/>
			case "/dashboard":
				return <Route path="/dashboard" render={() => <App prevState={this.props.location.state} />}	/>
			case "/cash":
				return <Route path="/cash" render={() => <App prevState={this.props.location.state} />}	/>
			case "/income_expense":
				return <Route path="/income_expense" render={() => <App prevState={this.props.location.state} />}	/>
			case "/payment_load":
				return <Route path="/payment_load" render={() => <App prevState={this.props.location.state} />}	/>
			case "/history_payment":
				return <Route path="/history_payment" render={() => <App prevState={this.props.location.state} />}	/>
			case "/payment_expense":
				return <Route path="/payment_expense" render={() => <App prevState={this.props.location.state} />}	/>
			case "/payment_discount_tax":
				return <Route path="/payment_discount_tax" render={() => <App prevState={this.props.location.state} />}	/>
			case "/comunication":
				return <Route path="/comunication" render={() => <App prevState={this.props.location.state} />}	/>
			case "/liquidation":
				return <Route path="/liquidation" render={() => <App prevState={this.props.location.state} />}	/>
			case "/supplier":
				return <Route path="/supplier" render={() => <App prevState={this.props.location.state} />}	/>
			case "/price":
				return <Route path="/price" render={() => <App prevState={this.props.location.state} />}	/>
			case "/upkeep":
				return <Route path="/upkeep" render={() => <App prevState={this.props.location.state} />}	/>
			case "/maintenance":
				return <Route path="/maintenance" render={() => <App prevState={this.props.location.state} />}	/>
			case "/user":
				return <Route path="/user" render={() => <App prevState={this.props.location.state} />}	/>
			case "/coefficient":
				return <Route path="/coefficient" render={() => <App prevState={this.props.location.state} />}	/>
			case "/common_area":
				return <Route path="/common_area" render={() => <App prevState={this.props.location.state} />}	/>
			case "/reservation_admin_area":
				return <Route path="/reservation_admin_area" render={() => <App prevState={this.props.location.state} />}	/>
			default:
		return <Route render={() => <Landing />} />
		}
		return <App></App>;
	}
}

export default withRouter(AppWrapper);