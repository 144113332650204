import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ProgressBar} from 'primereact/progressbar';
import '../../css/Grid.css';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import CoefficientReducer from './reducers/CoefficientReducer';
import CoefficientDialog from './CoefficientDialog';
import * as access from "../AccessAction";
import * as action from "./actions/CoefficientAction";
import * as utils from "../Utils";

const CoefficientForm = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.load(prevState, dispatch, setLoading);
        }
        }, []
    );
    
    const [loading, setLoading] = useState(true)
    const [visibleCoefficient, setVisibleCoefficient] = useState(false)
    const [visibleCoefficientEdit, setVisibleCoefficientEdit] = useState(false)
    const [visibleCoefficientEliminated, setVisibleCoefficientEliminated] = useState(false)
    const [state, dispatch] = useReducer(CoefficientReducer, {
        ...prevState,
        access: true,
        coefficient: {},
        summary: {}
    });

    const openCreateCoefficient = () => {
        state.coefficient = {};
        setVisibleCoefficient(true);
    };

    function actionCoefficientRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selectedCoefficient(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedCoefficient(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedCoefficient(e){
        setVisibleCoefficientEdit(true)
        dispatch({type: constants.COEFFICIENT_SELECTED, value: e.value})
    }

    function selectedElimindatedCoefficient(e){
        setVisibleCoefficientEliminated(true);
        dispatch({type: constants.COEFFICIENT_SELECTED, value: e.value})
    }

    const confirmationCoefficientEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedCoefficient(state, dispatch, setVisibleCoefficientEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleCoefficientEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function onLazyLoad(event) {
        state.first = event.first;
        setLoading(true);
        action.search(event, state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nuevo coeficiente" visible={visibleCoefficient} onHide={() => setVisibleCoefficient(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <CoefficientDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {true}
                    fn = {setVisibleCoefficient}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar coeficiente" visible={visibleCoefficientEdit} onHide={() => setVisibleCoefficientEdit(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <CoefficientDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleCoefficientEdit}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Eliminar coeficiente" visible={visibleCoefficientEliminated} modal={true} footer={confirmationCoefficientEliminated} onHide={() => setVisibleCoefficientEliminated(false)}>
                Esta seguro que desea eliminar el coeficiente?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Coeficientes</h1>
                    </div>

                    <div class="p-grid box-default-margin">
                        <div className="p-md-12">
                            <Button label="Crear" onClick={() => openCreateCoefficient()}
                                className="p-button-warning" icon="pi pi-plus" iconPos="right"
                            />
                        </div>
                        <div className="p-md-12 p-sm-11 user-detail">
                        <div className="p-grid p-justify-center">
                            <div className="p-md-4 user-detail-box">
                                <div className="user-detail-box-name">{state.summary.units} unidades en el edificio</div>
                                <div className="user-detail-box-name">{state.summary.pending ? state.summary.pending : 0} unidades sin configurar coeficientes</div>
                                <div className="user-detail-box-detail">{state.summary.percentage} %</div>
                                <i className="pi pi-list"></i>
                                <ProgressBar value={state.summary.percentage}/>
                            </div>
                            </div>
                        </div>
                        {
                            (!state.coefficients || state.coefficients.length === 0) &&
                            <h3 className="title-content">Sin información de coeficientes</h3>
                        }
                        {
                            state.coefficients && state.coefficients.length > 0 &&
                            <DataTable value={state.coefficients} paginator responsive rows={10}
                            lazy={true} first={state.first} onPage={(event) => onLazyLoad(event)} totalRecords={state.totals} 
                            style={{marginTop:'10px' }} >
                                <Column field="value.name" header="Unidad" sortable={true} sortable={true}/>
                                <Column header="Coeficiente" body={(r) => utils.percentageRow(r.value.percentage)}/>
                                <Column field="value.created" header="F. Creación" sortable={true}/>
                                <Column header="Acciones" body={(r) => actionCoefficientRow(r)}/>
                            </DataTable>
                        }
                    </div>          
                </div>
            }
        </Fragment>
    );
}


export default CoefficientForm;
