import React, {useEffect, useReducer, Fragment, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom'
import {DataTable} from 'primereact/datatable';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import NoteReducer from "./reducers/NoteReducer"
import ComunicationDialog from "./ComunicationDialog"
import NoteDialog from "./NoteDialog";
import * as action from "./actions/ComunicationAction"
import * as access from "../AccessAction";
import * as utils from "../Utils"

const ComunicationForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            action.load(state, dispatch, setLoading);
        }
        }, []
    );

    const [loading, setLoading] = useState(true)
    const [visibleComunication, setVisibleComunication] = useState(false)
    const [visibleComunicationEdit, setVisibleComunicationEdit] = useState(false)
    const [visibleNote, setVisibleNote] = useState(false)
    const [state, dispatch] = useReducer(NoteReducer, {
        ...prevState,
        access: true,
        comunication: {
            building: prevState.building
        },
        comunications: [],
        filter: {},
        note: {},
        summary: [],
        types: [{'name': 'Consulta', 'type': 'HELP'},{'name': 'Reclamo', 'type': 'CLAIM'}, {'name': 'Comunicado', 'type': 'NOTICE'}],
        statuses: [{'name': 'Pendiente', 'type': 'PENDING'},{'name': 'Asignado', 'type': 'ASSIGNED'}, {'name': 'En proceso', 'type': 'PROCESS'},{'name': 'Cancelado', 'type': 'CANCELLED'},{'name': 'Finalizado', 'type': 'FINALIZED'}]
    });

    function actionsRow(value) {
        return <div>
            { utils.isPermission(state, "COMUNICATION_WRITE") &&
                <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => openComunication(value)} title="Comunicación"></i>  
            }
            
            <i className="pi pi-list inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => openNote(value)} title="Nota"></i>              
        </div>   
    }

    function statusRow(value){
        let color = getColor(value.statusStr);
        
        return <div className={color + " p-grid p-justify-center"} 
            style={{padding:'2%', margin:'0 5% 0 5%', borderRadius: '20px', fontSize: '12px' }}>
            <label className="status-box">{value.statusStr}</label> 
        </div>
    }

    function getColor(value){
        let color = 'summary-status-closed';
        if (value === 'Pendiente'){
            color = 'summary-status-alert'
        } else if (value === 'Asignado'){
            color = 'summary-status-warning'
        } else if (value === 'Finalizado'){
            color = 'summary-status-success'
        } 

        return color;
    }

    function openCreateComunication(){
        state.comunication = {
            building: state.building
        };
        setVisibleComunication(true);
    }

    function openComunication(e){
        dispatch({type: constants.COMUNICATION_SELECTED, value: e})
        setVisibleComunicationEdit(true);
    }

    function openNote(e){
        dispatch({type: constants.COMUNICATION_SELECTED, value: e})
        setVisibleNote(true);
    }

    function clear(){
        state.filter = {}
        state.comunications = []
        dispatch({type: constants.DEFAULT})
    }

    function search() {
        setLoading(true);
        action.search(state, dispatch, setLoading);
    }
    
    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }
            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Consulta o reclamo" visible={visibleComunication} className="p-md-4 p-sm-12" onHide={() => setVisibleComunication(false)}>
                <ComunicationDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {true}
                    fn = {setVisibleComunication}
                />
            </Dialog>
            <Dialog header="Editar consulta o reclamo" visible={visibleComunicationEdit} className="p-md-4 p-sm-12" onHide={() => setVisibleComunicationEdit(false)}>
                <ComunicationDialog
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleComunicationEdit}
                />
            </Dialog>
            <Dialog header="Nota" visible={visibleNote} className="p-md-6 p-sm-12" onHide={() => setVisibleNote(false)}>
                <NoteDialog
                    state = {state}
                    dispatch = {dispatch}
                    fn = {setVisibleNote}
                />
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <h2 className="title-content">Gestión de Consultas y reclamos</h2>
                    <div className="box-default" style={{padding:'10px'}}>
                        {
                            utils.isPermission(state, "COMUNICATION_WRITE") &&
                            <div className="p-md-6 p-sm-12">
                                <Button label="Crear" style={{marginBottom:'10px'}} onClick = {() => openCreateComunication()}
                                    className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                            </div>
                        }
                        
                        {
                            state.admin &&
                            <div class="p-grid p-justify-center" style={{marginTop: '2%'}}>
                                    {
                                        state.summary && 
                                        state.summary.map(function(o){
                                            let status = o[0].status;
                                            let color = getColor(status);

                                            return (<div className="p-md-2 p-sm-11 inline">
                                                <div className={color + " box-default summary-status"}>
                                                    <div className="p-col-12">
                                                        <label className="status-box" style={{marginBlockEnd: 5}}>{status}</label>
                                                        <div style={{borderBlockEnd: '1px solid white', marginLeft:'1em', marginRight:'2em'}}></div>
                                                        
                                                        <div style={{marginTop:'5%'}}>
                                                            <div className="p-md-4 inline">
                                                                <label className="status-box" style={{marginBlockEnd: 0, fontWeight: 600}}>{o[0].type}</label>
                                                                <h2 className="title-content status-box" style={{marginBlockEnd: 0, marginBlockStart: 0}}>{o[0].value}</h2>
                                                            </div>
                                                            {o.length > 1 &&
                                                                <Fragment>
                                                                    <div className="p-md-4 inline">
                                                                        <label className="status-box" style={{marginBlockEnd: 0, fontWeight: 600}}>{o[1].type}</label>
                                                                        <h2 className="title-content status-box" style={{marginBlockEnd: 0, marginBlockStart: 0}}>{o[1].value}</h2>
                                                                    </div>
                                                                    <div className="p-md-1 inline" style={{textAlign: 'end'}}>
                                                                        <i class="pi pi-user status-box" style={{fontSize: "2em"}}></i>
                                                                    </div>
                                                                </Fragment> 
                                                            }
                                                            
                                                            {o.length === 1 &&
                                                                <div className="p-md-6 inline" style={{textAlign: 'end'}}>
                                                                    <i class="pi pi-user status-box" style={{fontSize: "2em"}}></i>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>)
                                        })
                                    }
                            </div>    
                        }
                    </div>

                    
                    <div className="box-default-margin">
                        <h3 className="title-content-menu p-col-12">Busqueda</h3>
                        <div className="p-md-12 p-sm-12">
                                <div className="p-md-2 p-sm-11">
                                    <Calendar locale={configurations.es} placeholder="Fecha desde" showIcon={true} value={state.filter.date}
                                        dateFormat="yy-mm-dd"
                                        onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_DATE, value: e.value})}/>
                                </div>
                                {
                                    state.admin && state.company &&
                                    <Dropdown optionLabel="firstName" value={state.filter.agent} options={state.company.agents} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_AGENT_SELECTED, value: e.value})} placeholder="Agente" />    
                                }
                                
                                <Dropdown optionLabel="name" value={state.filter.category} options={state.categories} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_CATEGORY_SELECTED, value: e.value})} placeholder="Categoria"/>
                                <Dropdown optionLabel="name" value={state.filter.type} options={state.types} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_TYPE_SELECTED, value: e.value})} placeholder="Tipo"/>
                                <Dropdown optionLabel="name" value={state.filter.status} options={state.statuses} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_STATUS_SELECTED, value: e.value})} placeholder="Estado"/>
                                <Dropdown optionLabel="name" value={state.filter.unit} options={state.units} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_UNIT_SELECTED, value: e.value})} placeholder="Unidad"/>
                                {
                                    state.admin &&
                                    <Dropdown optionLabel="name" value={state.note.supplier} options={state.suppliers} className="p-md-2 p-sm-11" style={{marginBottom:'2px', marginRight:'5px'}}
                                        onChange={(e) => dispatch({type: constants.COMUNICATION_FILTER_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor"/>
                                }
                                <Button label="Buscar" onClick={() => search()} style={{marginBottom:'2px', marginRight:'5px'}}
                                    className="p-button-warning p-md-1 p-sm-11" icon="pi pi-search" iconPos="right" />
                                <Button label="Limpiar" onClick={() => clear()} tilte="Limpiar filtros" style={{marginBottom:'2px', marginRight:'5px'}}
                                    className="p-button-warning p-md-1 p-sm-11" icon="pi pi-trash" iconPos="right" />
                        </div>  
                        
                        { state.comunications.length > 0 && !state.admin &&
                            <div className="p-md-12">
                                <h3 className="title-content-menu">Comunicaciones</h3>
                                <DataTable value={state.comunications} paginator={true} responsive={true} rows={10} style={{marginTop:'10px'}} >
                                    <Column field="value.title" header="Titulo" sortable={true}/>
                                    <Column field="value.unit.name" header="Unidad" sortable={true}/>
                                    <Column field="value.building.name" header="Edificio" sortable={true}/>
                                    <Column field="value.typeStr" header="Tipo" sortable={true}/>
                                    <Column header="Estado"  body={(r) => statusRow(r.value)} />
                                    <Column field="value.created" header="Creado" sortable={true}/>
                                    <Column header="Acciones" body={(r) => actionsRow(r)} style={{width: '15%'}}/>
                                </DataTable>
                            </div>
                        }

                        { state.comunications.length > 0 && state.admin && 
                            <div className="p-md-12">
                                <h3 className="title-content-menu">Comunicaciones</h3>
                                <DataTable value={state.comunications} paginator={true} responsive={true} rows={10} style={{marginTop:'10px'}} >
                                    <Column field="value.title" header="Titulo" sortable={true}/>
                                    <Column field="value.user.name" header="Usuario" sortable={true}/>
                                    <Column field="value.agent.name" header="Asignado" sortable={true}/>
                                    <Column field="value.unit.name" header="Unidad" sortable={true} style={{width: '6%'}}/>
                                    <Column field="value.building.name" header="Edificio" sortable={true}/>
                                    <Column field="value.typeStr" header="Tipo" sortable={true}/>
                                    <Column header="Estado" body={(r) => statusRow(r.value)} />
                                    <Column field="value.supplier" header="Proveedor" sortable={true}/>
                                    <Column field="value.working" header="Trabajando" sortable={true}/>
                                    <Column field="value.created" header="Creado" sortable={true}/>
                                    <Column header="Acciones" body={(r) => actionsRow(r)} style={{textAlign:'center', width: '8em'}} style={{width: '15%'}}/>
                                </DataTable>
                            </div>
                        }
                    </div>
                    
                </div>
            }  
        </Fragment>
        
    );
}

export default ComunicationForm;
