import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const upload = (event, state, dispatch, fn) => {
    let message = validate(state);
    if (message){
        access.responseData('error', message, state, constants.DEFAULT, dispatch, false);
    } else {
        const data = new FormData();
        data.append('file', event.files[0]);
        data.append('name', 'payment');
    
        state.load.companyId = state.companyId;
        state.load.type = state.load.selectedType.type;
        state.load.ext = state.load.selectedExt.type;
        state.load.buildingId = state.building.id;
        state.load.userId = state.user.id;

        axios(
            access.build('post', `/api/payments/upload/${state.load.companyId}/${state.load.buildingId}/${state.load.userId}/${state.load.type}`  , data)
        )
        .then((response) => {
            let message, severity;
            if (response.status === 200) {
                if (response.data.length > 0){
                    severity = 'warn';
                    message = 'Archivo cargado correctamente, pero con errores. Verifique el historial.';
                } else {
                    severity = 'info';
                    message = 'Archivo cargado correctamente.';
                }
                
                state.load = {};
                
            } else {
                message = 'Error al guardar.';
                severity = 'error';
            }

            fn(false)
            access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
        });
    }
}

const validate = (state, dispatch) => {
    let message = undefined;
    if (!state.load.selectedType){
        message = "Debe seleccionar el origen del archivo."
    }

    if (!state.load.selectedExt){
        message = "Debe seleccionar el tipo de archivo."
    }

    if (!state.building){
        message = "Debe seleccionar un edificio."
    }
    return message;
}