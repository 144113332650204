import React, { Fragment, useEffect } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import Draggable from 'react-draggable';
import {DataView} from 'primereact/dataview';
import * as action from "./actions/PaymentAction"
import * as configurations from "../../Configurations";
import * as constants from "../../Constants";

const PaymentDialog = ({state, dispatch, fn, mobile}) => {

    useEffect(() => {
        state.messageErrorAmount = null;
        }, []
    );


    function setNumberDecimal(event, data) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: constants.CHANGE_PAYMENT_PAY, value: amount, item: data});
    }

    function setNumberAccount(event, account) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: constants.CHANGE_PAYMENT_AMOUNT_ACCOUNT, value: amount, item: account});
    }

    const renderHeaderView = (data) => {
        return <div className="p-md-12">
            {
                 data != null && Object.values(data).map(value => {
                    let cls = (value === 'Fecha')? 'p-md-2 inline' : 'p-md-1 inline' 
                    //let cls =  'p-md-1 inline' 
                    return  <div className={cls}>
                                <p style={{fontFamily: 'Lato, sans-serif', fontWeight: '800', fontSize: '14px', color: '#79797c', textAlign: 'center'}}>{value}</p>
                            </div>
                 })
            }
        </div>
    }

    const renderItemView = (data) => {
        return <Fragment>
            {
                 data != null &&
                <div >
                    <div className="p-sm-6 p-md-1 inline"><span className="team-member">{data.unitCode}</span></div>
                    <div className="p-sm-6 p-md-2 inline">
                        <Calendar locale={configurations.es} value={data.date} placeholder="Fecha" showIcon={true} dateFormat="dd/mm/yy" 
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_DATE, value: e.target.value, item: data})} required/>
                    </div>
                    <div className="p-sm-6 p-md-1 inline">
                        <InputText value={data.codePayment} placeholder="Código"
                            onChange={(e) => dispatch({type: constants.CHANGE_PAYMENT_CODE, value: e.target.value, item: data})}/>
                    </div>
                    <div className="p-sm-6 p-md-1 inline">
                        <Dropdown optionLabel="name" value={data.type} options={configurations.typePayments} 
                                onChange={(e) => dispatch({type: constants.PAYMENT_TYPE_SELECTED, value: e.value, item: data})} placeholder="Origen de pago"/>
                    </div>
                    
                    <div className="p-sm-12 p-md-1 inline">
                        <InputText id="payment" style={{marginBottom:'10px'}}
                            onChange={(e) => setNumberDecimal(e.target.value, data)} placeholder="Pago" type="number"
                            min="1.00" step='0.01' required/>
                    </div>
                    
                    {
                        state.accounts.map(account => {
                            let accountPayment = data.accounts.filter(a => a.id === account.id);
                            if (accountPayment.length > 0){
                                let amount = state.building.currency + " " + accountPayment[0].amount;
                                let message = "Monto aculumado para dicha cuenta es " + accountPayment[0].amount;
                                return <div className="p-sm-12 p-md-1 inline">
                                    <InputText
                                        onChange={(e) => setNumberAccount(e.target.value, accountPayment[0])} type="number"
                                        min="1.00" step='0.01' placeholder={amount} tooltip={message}/>
                                    </div>
                            } else {
                                return <div className="p-sm-12 p-md-1 inline">
                                        <InputText placeholder={`${state.building.currency}` + ' 0'} disabled/>
                                        </div>
                            }
                            
                        })
                    }
                     <div className="p-sm-12 p-md-2 inline">
                        <p style={{fontFamily: 'Lato, sans-serif', fontWeight: '800', fontSize: '9px', color: '#79797c', textAlign: 'center'}}>Periodo {data.payment.start_period} - {data.payment.end_period}</p>
                        <p style={{fontFamily: 'Lato, sans-serif', fontWeight: '800', fontSize: '9px', color: '#79797c', textAlign: 'center'}}>Pagos totales $ {data.payment.amount}</p>
                     </div>
                    
                </div>
               
            }
        </Fragment>
    }

    const onSave = () => {
        action.createPaymentDistribution(state, dispatch, fn);    
    }


    return (
        <Draggable disabled={mobile()}>
        <div className="p-fluid">
                <div className="p-md-12">
                    {
                        (!state.units || state.units.length === 0) && 
                        <div className="p-grid p-justify-center" >
                            <h3 className="title-content">No hay unidades definidas</h3>
                        </div>
                    }
                    {
                        (state.paymentsAccounts && state.paymentsAccounts.length > 0) && 
                        <Fragment>
                            <DataView value={state.header} itemTemplate={renderHeaderView} layout={'list'} style={{marginBottom: '1%'}}/>
                            <DataView value={state.paymentsAccounts} filterBy="code" itemTemplate={renderItemView} layout={'list'} />
                             <div className="p-md-2 p-sm-12">
                                <Button label="Guardar" className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={onSave}/>
                            </div>
                        </Fragment>
                    }
                </div>
        </div>
        </Draggable>
        
    );
}

export default PaymentDialog;
