import * as constants from '../../../../Constants';
import BuildingReducer from '../../../building/reducers/BuildingReducer';

const PriceReducer = (state, action) => {
    switch (action.type) {
        case constants.PRICE_LOAD:
            state.prices = action.value
            return {
                ...state
            };
        case constants.PRICE_NAME:
            state.price.name = action.value
            return {
                ...state
            };
        case constants.PRICE_DESCRIPTION:
            state.price.description = action.value;
            return {
                ...state
            };
        case constants.PRICE_INGRESS:
            state.price.ingress = action.value
            return {
                ...state
            };
        case constants.PRICE_WORK_ID:
            state.price.workId = action.value
            return {
                ...state
            };
        case constants.PRICE_SUPPLIER_SELECTED:
            state.price.supplier = action.value
            state.price.supplierId = action.value.id;
            return {
                ...state
            };
        case constants.PRICE_STATUS_SELECTED:
            state.price.statusSelected = action.value
            state.price.status = action.value.type;
            return {
                ...state
            };
        case constants.PRICE_ELIMINATED:
            state.price.eliminated = action.value
            return {
                ...state
            };
        case constants.PRICE_SELECTED:
            state.price = action.value.value;
            return {
                ...state
            };
        case constants.PRICE_FILTER_DATE:
            state.filter.ingress = action.value
            return {
                ...state
            };
        case constants.PRICE_FILTER_WORK:
            state.filter.workId = action.value
            return {
                ...state
            };
        case constants.PRICE_FILTER_SUPPLIER:
            if (action.value){
                state.filter.supplier = action.value;
                state.filter.supplierId = action.value.id;
            } else {
                state.filter.supplier = undefined;
                state.filter.supplierId = undefined;
            }
            return {
                ...state
            };
        case constants.PRICE_UPLOAD:
            state.upload = action.value
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default PriceReducer;