import React, {useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import * as utils from "../Utils";

import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import * as actionPaymentExpense from "../payment_expense/actions/PaymentExpenseAction";

const SupplierAccountDialog = ({ state, dispatch}) => {

    useEffect(() => {
        state.filter = {}
        actionPaymentExpense.searchPaymentExpenseBySupplierIdAndFilter(state.supplier.id, state.filter, state, dispatch, constants.SUPPLIER_PAYMENTS)
      }, [])

    const onFilter = () => {
        actionPaymentExpense.searchPaymentExpenseBySupplierIdAndFilter(state.supplier.id, state.filter, state, dispatch, constants.SUPPLIER_PAYMENTS)
    }

    return (
        <div className="p-fluid">
            <h3 className="title-content" >{state.supplier ? state.supplier.name : ''}</h3>
            {/*<div className="p-md-12">
                <div className="p-md-6">
                    <Calendar locale={configurations.es} placeholder="A partir" showIcon={true} value={state.filter.date} dateFormat="dd/mm/yy"
                        onChange={(e) => dispatch({type: constants.SUPPLIER_FILTER_DATE, value: e.value})} required/>
                </div>
                <div className="p-md-6">
                    <InputText value={state.filter.document} placeholder="Ref." 
                        onChange={(e) => dispatch({type: constants.SUPPLIER_FILTER_DOCUMENT, value: e.target.value})}/>
                </div>
                <div className="p-md-6">
                    <Button label="Filtrar" style={{marginTop:'20px'}}
                        className="p-button-warning" icon="pi pi-search" iconPos="right" onClick={() => onFilter()}/>
                </div>
    </div>*/}
            {
                (!state.payments || state.payments.length === 0) &&
                <h3 className="title-content">Sin información del estado de cuenta</h3>
            }
            {
                state.payments && state.payments.length > 0 &&
                <DataTable value={state.payments} paginator={true} rows={10} responsive={true} style={{marginTop:'10px', padding:'10px' }} >
                    <Column field="name" header="Descripción" />
                    <Column field="payDate" header="F. Mov." style={{width: '15%'}}/>
                    <Column field="created" header="F. Creación" style={{width: '15%'}}/>
                    <Column field="amount" header="Monto" body={(r) => utils.formatCurrency(r.amount, state.building.currency)}/>
                    <Column field="document" header="Ref."/>
                </DataTable>
            } 
        </div>
    );
}

export default SupplierAccountDialog;
