import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const search = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/payments/company/${state.companyId}/filter`, getFilter(state))
     )
    .then(response => {
        fn(false)
        dispatch({ type: constants.HISTORY_PAYMENT_SEARCH, value: response.data});    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.HISTORY_PAYMENT_SEARCH, dispatch, false);
    })
}

export const getFilter = (state) => {
    let filter = {};
    if (state.filter && state.filter.type){
        filter.type = state.filter.type.type;
    }    
    if (state.filter && state.filter.status){
        filter.status = state.filter.status.type;
    }

    filter.buildingId = state.building.id;

    return filter;
}
