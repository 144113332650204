import React, {useEffect, useReducer, useState, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Dialog} from 'primereact/dialog';
import {Redirect} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../Constants";
import * as utils from "../Utils"
import LoginReducer from './reducers/LoginReducer'
import * as action from './actions/LoginAction';

const Register = ({prevState}) => {

	useEffect(() => {
        utils.changeStyleSheetUrl('layout-css', 'orange', 'layout');
		utils.changeStyleSheetUrl('theme-css', 'orange', 'theme');
		
		}, []
    );

	const [loading, setLoading] = useState(false)
	const [verifyCode, setVerifyCode] = useState(false)
    const [state, dispatch] = useReducer(LoginReducer, {  
		...prevState,
		roles: [{name: 'Residente', code: 'USER'}, {name: 'Administrador', code: 'ADMIN'}],
		role: {name: 'Residente', code: 'USER'},
		register: {
			role: "USER"
		}
    });

	const register = () => {
		setLoading(true)
		action.register(state, dispatch, setLoading);
	}

	const verify = () => {
		setVerifyCode(true)
		action.verify(state, dispatch, setVerifyCode);
	}

	const openLogin = (e) => {
		e.preventDefault();
		dispatch({ type: constants.REGISTER_FORWARD_LOGIN });
	}

	if (state.forwardLogin === true) {
		return <Redirect to={{ pathname: '/login', state: state }} />
	  }
	
	return (
		<Fragment>
			<LoadingOverlay
				active={loading}
				spinner
				text='Espere por favor...'>
				<div className="landing-body">
					<Dialog header="Ingresar codigo de validación" visible={state.verify} style={{maxWidth:'50%'}} onHide={() => dispatch({type: constants.REGISTER, value: false})}>
							{
								state.verifyError && 
								<div className="p-col-8 p-offset-2">
									<label style={{fontSize: '16px', fontFamily: 'Lato, sans-serif', color:"red"}}>{state.verifyError}</label>
								</div>
							}
							{
								verifyCode &&
								<div className="p-col-8 p-offset-2">
									<label style={{fontSize: '16px', fontFamily: 'Lato, sans-serif'}}>Validando codigo...</label>
								</div>							
							}
							<div className="p-md-8 p-md-offset-2 p-sm-12">
								<InputText className="input-login p-col-12" type="password" placeholder="Codigo" style={{paddingInlineEnd: '10%',borderColor: '#E2841A', borderRadius: '25px', 
									fontSize: '16px', fontFamily: 'Lato, sans-serif'}} disabled = {verifyCode}
									onChange={(e) => dispatch({type: constants.REGISTER_VERIFY_CODE, value: e.target.value})}/>
							</div>
							<div className="p-md-8 p-md-offset-2 p-sm-12" >
								<Button label="Enviar codigo" className="p-button p-col-12"  icon="pi pi-user" style={{fontSize:'16px'}} 
									onClick={() => verify()} disabled = {verifyCode} />
							</div>
					</Dialog>
					<div className="landing-wrapper">
						<div className="landing-header">
							<div className="landing-header-content">
								<div style={{height: '10vh'}}></div>
								<div className="p-justify-center">
									<div className="p-col-12">
										<img src="../../../web/assets/img/logo_mago.png" style={{with:'200px', height:'60px'}} alt="woloolo"/>
									</div>
									<div className="p-col-12">
										<span className="title-content-white" style={{'fontSize': '24px'}}>¡Crear nueva cuenta!</span>
									</div>
											
									<div className="p-col-12" style={{marginTop:"2%"}}>
										{
											state.error && 
											<div className="p-col-12">
												<label style={{fontSize: '16px', fontFamily: 'Lato, sans-serif', color:"red"}}>{state.error}</label>
											</div>
										}
										<div className="p-col-12">
											<InputText type="text" className="p-md-3 p-sm-10" placeholder="Nombre" style={{paddingInlineEnd: '10%',borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '16px', fontFamily: 'Lato, sans-serif'}}
												onChange={(e) => dispatch({type: constants.REGISTER_NAME, value: e.target.value})}/>
										</div>
										<div className="p-col-12">
											<InputText type="email" className="p-md-3 p-sm-10" placeholder="E-Mail" style={{paddingInlineEnd: '10%',borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '16px', fontFamily: 'Lato, sans-serif'}}
												onChange={(e) => dispatch({type: constants.REGISTER_EMAIL, value: e.target.value})}/>
										</div>
										<div className="p-col-12" >
											<InputText className="input-login p-md-3 p-sm-10" type="password" placeholder="Contraseña" style={{paddingInlineEnd: '10%',borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '16px', fontFamily: 'Lato, sans-serif'}}
												onChange={(e) => dispatch({type: constants.REGISTER_PASSWORD, value: e.target.value})}/>
										</div>
										<div className="p-col-12" >
											<InputText className="input-login p-md-3 p-sm-10" type="password" placeholder="Repetir contraseña" style={{paddingInlineEnd: '10%',borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '16px', fontFamily: 'Lato, sans-serif'}}
												onChange={(e) => dispatch({type: constants.REGISTER_REPLY_PASSWORD, value: e.target.value})}/>
										</div>
										<div className="p-col-12" >
											<Dropdown className="p-md-2 p-sm-6"  optionLabel="name" value={state.role} options={state.roles} 
												onChange={(e) => dispatch({type: constants.REGISTER_ROLE, value: e.value})} placeholder="Rol"/>
										</div>
										<div className="p-col-12">
											<Button label="REGISTRARME" className="p-button-secondary p-md-3 p-sm-10"  icon="pi pi-user" style={{padding:'0', paddingLeft: '2%', paddingRight: '2%', fontSize:'20px'}} 
											onClick={() => register()} />
										</div>
										
										<div className="p-col-12">
											<label style={{fontSize: '18px', fontFamily: 'Lato, sans-serif', color:"white"}}>¿Nuevo en Woloolo?</label>
											<a style={{marginLeft: '1%',fontSize: '18px', fontFamily: 'Lato, sans-serif', cursor:'pointer'}}
											onClick={event => openLogin(event)}>Iniciar Sesión</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LoadingOverlay>
			
		</Fragment>
    );
}


export default Register;