import React, {useEffect, useReducer, useState, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Redirect} from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Dialog} from 'primereact/dialog';
import * as constants from "../../Constants";
import * as utils from "../Utils"
import LoginReducer from './reducers/LoginReducer'
import AssingPendingDialog from './AssignPendingDialog';
import * as action from './actions/LoginAction';

const Login = ({prevState}) => {
      
    useEffect(() => {
        utils.changeStyleSheetUrl('layout-css', 'orange', 'layout');
        utils.changeStyleSheetUrl('theme-css', 'orange', 'theme');
        }, []
    );
	const [loading, setLoading] = useState(false)
	const [userAssignPendingAdmin, setUserAssignPendingAdmin] = useState(false)
    const [state, dispatch] = useReducer(LoginReducer, {  
		...prevState
    });

	function isMobile() {
        return window.innerWidth <= 896;
    }

	const login = () => {
		setLoading(true)
		action.login(state, dispatch, setLoading, setUserAssignPendingAdmin);
	}

	const openRegister = (e) => {
		dispatch({ type: constants.LOGIN_FORWARD_REGISTER });   
	}

	if (state.isLogged === true) {
		return <Redirect to={{ pathname: '/app', state: state }} />
	}
	if (state.forwardRegister === true) {
		return <Redirect to={{ pathname: '/register', state: state }} />
	}

	return (
		<Fragment>
			<Dialog header="" visible={userAssignPendingAdmin} style={{maxWidth: isMobile() ? '90%' : '50%'}}  onHide={() => setUserAssignPendingAdmin(false)}>
                <AssingPendingDialog
                    state = {state}
                    dispatch = {dispatch}
                    fn = {setUserAssignPendingAdmin}
                />
            </Dialog>
			{
				loading && 
				<div style={{width:'100%', height:'100', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'15%'}}>
						<Loader type="Rings" color="#00BFFF" height={50} width={100} />
				</div>
			}
			{
				!loading &&
				<div className="landing-body">
					<div className="landing-wrapper" style={{overflow: 'hidden'}}>
						<div className="landing-header p-grid p-justify-center" style={{display:'flex'}}>
							<div className="landing-header-content p-md-4" style={{paddingTop: '0px !important'}}>
								<div style={{height: '10vh'}}></div>
								<div className="p-justify-center">
									<div className="p-col-12">
										<img src="../../../web/assets/img/logo_mago.png" style={{with:'200px', height:'60px'}} alt="woloolo"/>
									</div>
									<div className="p-col-12">
										<span className="title-content-white" style={{'fontSize': '24px'}}>Inicie sesión para continuar</span>
									</div>
											
									<div className="p-md-offset-1 p-md-10 p-sm-12" style={{marginTop:"2%"}}>
										{
											state.error && 
											<div className="p-md-12 p-sm-12">
												<label style={{fontSize: '16px', fontFamily: 'Lato, sans-serif', color:"red"}}>{state.error}</label>
											</div>
										}
										<div>
											<InputText id="input" type="text" placeholder="Email" style={{borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '14px', fontFamily: 'Lato, sans-serif'}} className='p-sm-12'
												onChange={(e) => dispatch({type: constants.LOGIN_EMAIL, value: e.target.value})}/>
											<InputText className="input-login" type="password" placeholder="Contraseña" style={{marginTop: '2%',borderColor: '#E2841A', borderRadius: '25px', 
												fontSize: '14px', fontFamily: 'Lato, sans-serif'}} className='p-sm-12'
												onChange={(e) => dispatch({type: constants.LOGIN_PASSWORD, value: e.target.value})}/>
											
											<div className="p-col-12" style={{marginTop: '4%'}}>
												<a style={{fontSize: '16px', fontFamily: 'Lato, sans-serif', color:"white", cursor:'pointer'}}>¿Olvido su contraseña?</a>
											</div>

											<div className="p-col-12"  style={{marginTop: '1%'}}>
												<Button label="INICIAR SESIÓN" className="p-button-secondary"  icon="pi pi-user" style={{padding:'0', paddingLeft: '2%', paddingRight: '2%', fontSize:'20px'}} 
												onClick={() => login()} />
											</div>
											<div className="p-col-12">
												<label style={{fontSize: '16px', fontFamily: 'Lato, sans-serif', color:"white"}}>¿Nuevo en Woloolo?</label>
												<a style={{marginLeft: '1%',fontSize: '18px', fontFamily: 'Lato, sans-serif', cursor:'pointer'}} onClick={event => openRegister(event)} >Registrarme</a>
											</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Fragment>
    );
}


export default Login;