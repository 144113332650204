import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {Calendar} from 'primereact/calendar';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {TabView,TabPanel} from 'primereact/tabview';
import '../../../css/Grid.css';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import classNames from 'classnames';
import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import WorkReducer from './reducers/WorkReducer';
import * as action from "./actions/WorkAction";
import WorkDialog from "./WorkDialog"
import * as utils from "../../Utils";

const WorkForm = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        if (prevState.suppliers && prevState.suppliers.length > 0){
            prevState.filter = {};
            action.summary(prevState, dispatch, setLoading);
        } else {
            dispatch({ type: constants.REDIRECT});
        }
        
        }, []
    );
    
    const [loading, setLoading] = useState(true)
    const [visibleWorkEdit, setVisibleWorkEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [openFilterDrawerWorkClosed, setOpenFilterDrawerWorkClosed] = useState(false)
    const [state, dispatch] = useReducer(WorkReducer, {
        ...prevState,
        access: true,
        filter: {},
        summary: [],
        work: {},
        statuses: [{'name': 'Pendiente', 'type': 'PENDING'},{'name': 'En proceso', 'type': 'PROCESS'},{'name': 'Finalizado', 'type': 'FINALIZED'}, 
        {'name': 'Cancelado', 'type': 'CANCELLED'}, {'name': 'Rechazado', 'type': 'DECLINED'}, {'name': 'Programado', 'type': 'FUTURE'}]
    });

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function actionClosedRow(value) {
        return <div>
            <i className="pi pi-search inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Ver"></i>  
        </div>   
    }

    function selected(e){
        setVisibleWorkEdit(true)
        dispatch({type: constants.WORK_SELECTED, value: e})
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.WORK_SELECTED, value: e})
    }

    const confirmationEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedWork(state, dispatch, setVisibleEliminated, setLoading)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function getSummary(status){
        let value = null;

        if (state.summary){
            state.summary.forEach(s => {
                if (s.type == status){
                  value = s.pay;
                }
              });
        }

        return value !== null ? value : 0;
    }

    const changeTab = (index) => {
        state.activeIndex = index;
        if (index === 0){
            action.summary(state, dispatch, setLoading);
        } else {
            action.searchWorkClosed(state, dispatch, setOpenFilterDrawerWorkClosed)
        }
        
        return;
    }

    const closeFilterDrawerWorkClosed  = () => {
        setOpenFilterDrawerWorkClosed((prevState) => !prevState)
    }

    function loadFilters(fn){
        state.filter = {}
        fn(true)
    }

    function statusRow(value){
        let status = utils.statusRow(value)
        let color = 'summary-status-closed';
        if (status === 'Pendiente'){
            color = 'summary-status-alert'
        } else if (status === 'Asignado'){
            color = 'summary-status-warning'
        } else if (status === 'Finalizado'){
            color = 'summary-status-success'
        } 
        
        return <div className={color + " p-grid p-justify-center"} 
            style={{padding:'2%', margin:'0 5% 0 5%', borderRadius: '20px', fontSize: '12px' }}>
            <label className="status-box">{status}</label> 
        </div>
    }

    function summary(){
        setLoading(true);
        action.summary(state, dispatch, setLoading);
    }
    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/error" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>
            <Dialog header="Eliminar Reclamo" visible={visibleEliminated} modal={true} footer={confirmationEliminated} onHide={() => setVisibleEliminated(false)}>
                Esta seguro que desea eliminar el trabajo de su panel?
            </Dialog>
            <Dialog header="Trabajo" visible={visibleWorkEdit} className="p-md-4 p-sm-12" onHide={() => setVisibleWorkEdit(false)}>
                <WorkDialog
                    state = {state}
                    dispatch = {dispatch}
                    fn = {setVisibleWorkEdit}
                    setLoading = {setLoading}
                />
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-12">
                        <div className="p-md-2 inline">
                            <h1 className="title-content" style={{marginBlockEnd: 0}}>Dashboard</h1>
                        </div>

                        <div className="p-grid p-justify-center box-default-margin">
                            <div className="p-md-12 inline"> 
                                <span className="p-md-4">
                                    <Calendar locale={configurations.es} placeholder="Desde" showIcon={true} value={state.filter.date} dateFormat="dd/mm/yy"
                                        tooltip="Fecha a partir que se ingresaron reclamos"
                                        onChange={(e) => dispatch({type: constants.WORK_FILTER_DATE, value: e.value})}/>
                                </span> 
                                <span className="p-md-3" style={{marginLeft:'25px'}}>
                                    <Button label="Buscar" onClick={() =>  summary()}
                                        className="p-button-warning" icon="pi pi-search" iconPos="right" />
                                </span>
                            </div>
                        </div>
                        <div class="p-grid p-justify-center box-default" style={{marginTop:'2%'}}>
                            <div class="p-md-3">
                                <div class="box-default">
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Trabajos sin responder</h2>
                                        </div> 
                                    </div>
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{ getSummary('PENDING') }</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3">
                                <div class="box-default">
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Trabajos Activos</h2>
                                        </div> 
                                    </div>
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{ getSummary('PROCESS') }</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-md-3">
                                <div class="box-default">
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content" style={{marginBlockEnd: 0}}>Trabajos finalizados</h2>
                                        </div> 
                                    </div>
                                    <div class="p-md-12">
                                        <div class="p-grid p-justify-center">
                                            <h2 className="title-content bold color-orange">{ getSummary('FINALIZED') }</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TabView className="p-col-12" style={{marginTop:'3%'}} activeIndex={state.activeIndex} onTabChange={(e) => changeTab(e.index)}>
                                <TabPanel header="Solicitudes de reclamos">
                                    {
                                        (!state.works || state.works.length === 0) &&
                                        <h3 className="title-content">Sin información de trabajos</h3>
                                    }
                                    {
                                        state.works && state.works.length > 0 &&
                                        <DataTable value={state.works} paginator={true} rows={10} responsive={true} scrollable={true} style={{marginTop:'10px', padding:'10px' }} >
                                            <Column field="comunication.building.name" header="Edificio" sortable={true} />
                                            <Column field="comunication.description" header="Reclamo" sortable={true}/>
                                            <Column field="created" header="Ingreso" sortable={true}/>
                                            <Column field="id" header="Ref." sortable={true} style={{width: '10%'}}/>
                                            <Column header="Estado" body={(r) => statusRow(r.status)} style={{width: '10%'}}/>
                                            <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                        </DataTable>
                                    }
                                </TabPanel>
                                <TabPanel header="Finalizados">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                        <Button label="Filtros" onClick={() => loadFilters(setOpenFilterDrawerWorkClosed)}
                                            className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                        />
                                    </div>
                                    <Drawer open={openFilterDrawerWorkClosed} onClose={closeFilterDrawerWorkClosed} direction='right' size={mobile() ? 250 : 500}>
                                        <div style={{marginTop: mobile() ? '50%' : '15%'}} className="p-col-12">
                                            <h3 className="title-content">Filtros</h3>
                                            { openFilterDrawerWorkClosed &&
                                                <Fragment>
                                                    <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                        <Calendar locale={configurations.es} placeholder="Fecha ingresado" showIcon={true} value={state.filter.created}
                                                            dateFormat="yy-mm-dd" style={{margin:'1%'}}
                                                            onChange={(e) => dispatch({type: constants.WORK_FILTER_DATE, value: e.value})}/>
                                                </div>
                                                    
                                                    <div style={{marginLeft:'1%'}} className="p-md-6 p-sm-10">
                                                        <Button label="Buscar" onClick={() =>  action.searchWorkClosed(state, dispatch, setOpenFilterDrawerWorkClosed)}
                                                            className="p-button-warning p-lg-12 p-md-12 p-sm-12" style={{margin:'1%'}} icon="pi pi-search" iconPos="right" />
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                    </Drawer>

                                    {
                                        (!state.worksClosed || state.worksClosed.length === 0) &&
                                        <h3 className="title-content">Sin información de finalizados</h3>
                                    }
                                    {
                                        state.worksClosed && state.worksClosed.length > 0 &&
                                        <DataTable value={state.worksClosed} paginator={true} rows={10} responsive={true} scrollable={true} style={{marginTop:'10px', padding:'10px' }} >
                                            <Column field="value.comunication.building.name" header="Edificio" sortable={true}/>
                                            <Column field="value.comunication.description" header="Reclamo" sortable={true}/>
                                            <Column field="value.created" header="Ingreso" sortable={true}/>
                                            <Column field="value.id" header="Ref." sortable={true} style={{width: '5%'}}/>
                                            <Column header="Estado" body={(r) => statusRow(r.value.status)} />
                                            <Column header="Acciones" body={(r) => actionClosedRow(r.value)} style={{width: '15%'}}/>
                                        </DataTable>
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>          
                </div>
            }
        </Fragment>
    );
}


export default WorkForm;
