import * as constants from '../../../Constants';
import CashReducer from '../../cash/reducers/CashReducer'

const PaymentReducer = (state, action) => {
    switch (action.type) {
        case constants.PAYMENT_LOAD_EXT_SELECTED:
            state.load.selectedExt = action.value;
            return {
                ...state
            };
        case constants.PAYMENT_LOAD_TYPE_SELECTED:
            state.load.selectedType = action.value;
            return {
                ...state
            };
        default: return CashReducer(state, action);
    }
};

export default PaymentReducer;