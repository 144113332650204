import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import '../../css/Styles.css'

import * as constants from "../../Constants";
import * as action from "./actions/ComunicationAction"
import * as utils from "../Utils"

const NoteDialog = ({ state, dispatch, fn }) => {
  
    useEffect(() => {
        action.notes(state, dispatch);
        }, []
    );

    const onSave = () => {
        action.saveNote(state, dispatch, fn)
    }

    return (
        <Fragment>
            <form className="p-fluid" onSubmit={onSave}>
                { 
                    utils.isPermission(state, "NOTE_WRITE") &&
                    <div className="p-md-offset-3 p-md-6 p-sm-12">
                        {
                            state.admin && state.comunication.type != 'NOTICE' &&
                            <div  className="p-md-12 p-sm-12">
                                <Dropdown optionLabel="firstName" value={state.note.agent} options={state.company.agents} style={{marginBottom:'2%'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_NOTE_AGENT, value: e.value})} placeholder="Agente" />    
                            </div>
                        }
                        {
                            state.admin && !state.comunication.working && state.comunication.type != 'NOTICE' &&
                            <div  className="p-md-12 p-sm-12">
                                <Dropdown optionLabel="name" value={state.note.supplier} options={state.suppliers} style={{marginBottom:'2%'}}
                                    onChange={(e) => dispatch({type: constants.COMUNICATION_NOTE_SUPPLIER, value: e.value})} placeholder="Proveedor"/>
                            </div>
                        }
                        
                        <InputTextarea rows={5} cols={20} value={state.note.message} placeholder="Nota" 
                                autoResize={true} onChange={(e) => dispatch({type: constants.COMUNICATION_NOTE_MESSAGE, value: e.target.value})}/>
                    </div>
                }

                {
                    state.notes && state.notes.length == 0 &&
                    <div className="p-grid p-justify-center" >
                        <h3 className="title-content">No hay notas asociadas</h3>
                    </div>
                }
                
                <div className="p-md-offset-1 p-md-11 p-sm-12" style={{marginTop: '%5'}}>
                    {
                        state.notes &&  state.notes.map(function(o, index){ 
                            let color = index%2 === 0 ? '#ced3cf4f' : 'white';
                            return (
                                <div className="p-md-11 p-sm-12 box-default" style={{backgroundColor: color, marginTop:'2%'}}>
                                    <div className="p-md-12 title-content">
                                        <h3>Informador: {o.userName}</h3>
                                        {
                                            o.agentName &&
                                            <h3>Asignado: {o.agentName}</h3>
                                        }
                                        { 
                                            o.supplierName &&
                                            <h3>Proveedor: {o.supplierName}</h3>
                                        }
                                        
                                    </div>
                                    <div className="p-md-12" style={{color: '#4d58e0'}}>
                                        <p>
                                            {o.message}
                                        </p>
                                    </div>
                                    <div className="p-md-offset-8 p-md-4 title-content">
                                        <h3>Fecha de creación: {o.created}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="p-md-4 p-sm-12">
                    <Button label='Guardar' className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>  
            </form>
        </Fragment>
        
    );
}

export default NoteDialog;
