import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer';

const UserReducer = (state, action) => {
    switch (action.type) {
        case constants.USER_SECURITY_LOAD:
            state.security.email = state.user.email;
            return {
                ...state
            };
        case constants.USER_SECURITY_OLD:
            state.security.old = action.value;
            return {
                ...state
            };
        case constants.USER_SECURITY_NEW:
            state.security.new = action.value;
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default UserReducer;