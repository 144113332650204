import React,{useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Spinner} from 'primereact/spinner';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import * as constants from "../../Constants";
import * as action from "./actions/CommonAreaAction"
import * as actionMovementGroup from "../movement/group/actions/MovementGroupAction"

const CommonAreaDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        setLoading(true)
        actionMovementGroup.movementGroupsAll(state, dispatch, setLoading)
        }, []
    );

    const onCheckedAdmin = (e) => {
        dispatch({type: constants.COMMON_AREA_CHECK_ADMIN, value: e})
    }

    const onDebtorUnit = (e) => {
        dispatch({type: constants.COMMON_AREA_CHECK_DEBTOR_UNIT, value: e})
    }

    const onSave = () => {
        action.saveArea(state, dispatch, isNew, fn, setLoading);
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-grid" style={{padding:'2%'}}>
                    <div className="p-md-10">
                        <InputText value={state.area.name} style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.COMMON_AREA_NAME, value: e.target.value})} placeholder="Nombre" required/>
                        <InputText value={state.area.description}  style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.COMMON_AREA_DESCRIPTION, value: e.target.value})} placeholder="Descripción"/>
                        <InputText value={state.area.cost} style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.COMMON_AREA_COST, value: e.target.value})} placeholder="Costo" required/>
                        <Dropdown  optionLabel="name" value={state.area.movementGroup} options={state.movements}
                            style={{borderLeft: !state.area.movementGroup ? '3px solid salmon' : '', marginBottom:'2%'}} 
                            onChange={(e) => dispatch({type: constants.COMMON_AREA_MOVEMENT_GROUP, value: e.value})} placeholder="Grupo" required/>
                        <div className="p-md-12">
                            <label htmlFor="cb1" className="p-checkbox-label">Confirmación del Administrador </label>
                            <div style={{marginTop:'15px'}} className="inline">
                                <Checkbox onChange={(e) => onCheckedAdmin(e.checked)} checked={state.area.checkAdmin}></Checkbox>
                            </div>
                        </div>
                        <div className="p-md-12">
                            <label htmlFor="cb1" className="p-checkbox-label">¿Permitir reservaciones a unidades con morosidad?</label>
                            <div style={{marginTop:'15px'}} className="inline">
                                <Checkbox onChange={(e) => onDebtorUnit(e.checked)} checked={state.area.debtorUnit}></Checkbox>
                            </div>
                        </div>
                        
                        <div className="p-md-10">
                            <Spinner value={state.area.bookingLimit} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_BOOKING_LIMIT, value: e.value})} 
                                        min={1} max={60} placeholder="Limite semanal de reservas por usuario" tooltip="Limite semanal de reservas por usuario"/>
                            <Spinner value={state.area.bookingMax} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_BOOKING_MAX, value: e.value})} 
                                        min={1} max={2} placeholder="Maximo de reservas simultanas" tooltip="Maximo de reservas simultanas"/>
                            <Spinner value={state.area.reservationPerUser} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_RESERVATION_PER_USER, value: e.value})} 
                                        min={1} max={10} placeholder="Número máximo de reservaciones pendientes por usuario" tooltip="Número máximo de reservaciones pendientes por usuario"/>
                            <Spinner value={state.area.anticipationDay} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_ANTICIPATION_DAY, value: e.value})} 
                                        min={1} max={60} placeholder="No permitir que se reserve con más de XX días de anticipación" tooltip="No permitir que se reserve con más de XX días de anticipación"/>
                            <Spinner value={state.area.reservationMaxDay} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_RESERVATION_MAX_DAY, value: e.value})} 
                                        min={1} max={60} placeholder="Tiempo de anticipacion para la reserva (Dias)" tooltip="Tiempo de anticipacion para la reserva (Dias)"/>
                            <Spinner value={state.area.reservationDay} style={{marginBottom:'2px'}} required
                                        onChange={(e) => dispatch({type: constants.COMMON_AREA_RESERVATION_DAY, value: e.value})} 
                                        min={1} max={60} placeholder="Tiempo establecido para cancelar la reserva (Dias)" tooltip="Tiempo establecido para cancelar la reserva (Dias)"/>

                        </div>
                    </div>
                </div>
                <div className="p-md-3">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default CommonAreaDialog;
