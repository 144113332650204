import React, { Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log('Error', error, info);
    this.setState({ hasError: true });

    this.props.history.push('/error');
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
        return <Fragment></Fragment>
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);