import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../../Constants";
import * as action from "./actions/WorkAction";

const WorkDialog = ({ state, dispatch, fn, setLoading }) => {

    useEffect(() => {
        state.statuses.map(item => {
            
            if (item.type === state.work.status){
                state.work.statusSelected = item;
            }
        });
        }, []
    );

    const onSave = () => {
        setLoading(true)
        action.saveWork(state, dispatch, fn, setLoading)
    }

    return (
        <form className="p-fluid" onSubmit={onSave}>
            <div className="p-grid">
                <div className="p-md-offset-2 p-md-8 p-sm-12">
                    <Dropdown optionLabel="name" value={state.work.statusSelected} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.work.statusSelected ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.WORK_STATUS_SELECTED, value: e.value})} placeholder="Estado" required/>
                    <InputTextarea rows={5} cols={20} value={(state.work.description || state.work.description != null) ? state.work.description : ''} placeholder="Detalle" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.WORK_DESCRIPTION, value: e.target.value})}/>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-md-4 p-sm-12">
                    <Button label='Guardar' className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>
            </div> 
        </form>
     
    );
}

export default WorkDialog;
