import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";
import * as actionArea from "../../actions/CommonAreaAction"

export const loadAdmin = (state, dispatch, fn) => {
    search(state, dispatch, fn);
    summary(state, dispatch); 
    actionArea.commonAreasLoadByReservation(state, dispatch, fn);
}

export const search = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/commonareas/reservations/${state.building.id}/filter`, getFilter(state))
     )
    .then(response => {
        fn(false);
        dispatch({ type: constants.RESERVATION_SEARCH, value: response.data});    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.RESERVATION_SEARCH, dispatch, false);
    })
}

export const summary = (state, dispatch) => {
    axios(
        access.build('get', `/api/commonareas/reservations/${state.building.id}/summary`, null)
     )
    .then(response => {
        dispatch({ type: constants.RESERVATION_SUMMARY, value: response.data.value});   
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.RESERVATION_SUMMARY, dispatch, false);
    })
}

export const getFilter = (state) => {
    let filter = {};  
    if (state.filter.status){
        filter.status = state.filter.status.type;
    }
    if (state.filter.commonArea){
        filter.commonAreaId = state.filter.commonArea.id;
    }
    if (state.filter.unit){
        filter.unitId = state.filter.unit.id;
        filter.unit = undefined;
    }
    filter.date = state.filter.date;
    return filter;
}

export const saveReservation = (state, isNew, dispatch, fnLoading, fn) => {
    let url = `/api/reservations`;
    if (isNew){
        url = `/api/commonareas/reservations`
        state.reservation.companyId = state.companyId;
        if (!state.reservation.userId){
            state.reservation.userId = state.user.id;
        }
        
        if (!state.reservation.buildingId){
            state.reservation.buildingId = state.reservation.building.id;
        }
    }
    
    state.reservation.commonAreaId = state.reservation.commonArea ? state.reservation.commonArea.id : undefined;
    state.reservation.unitId = state.reservation.unit ? state.reservation.unit.id : undefined;
    state.reservation.unit = undefined;
    state.reservation.commonArea = undefined;
    state.reservation.created = undefined;
    state.reservation.building = undefined;
    if (state.reservation.statusSelected){
        state.reservation.status = state.reservation.statusSelected.type;
    }
    if (!state.admin && isNew){
        state.reservation.status = 'PENDING'
    }

    axios(
        access.build('post', url, state.reservation)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined){
                message = isNew ? 'Reservación creada correctamente.' : 'Reservación modificada correctamente.';
            } else {
                message = response.data.message;    
            }

            state.reservation = {
                building: state.building
            };
            severity = 'info';
            
            if (state.admin){
                summary(state, dispatch);
            }

            search(state, dispatch, fn);
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            if (response.data.message !== undefined){
                message = response.data.message;
                severity = 'warning';
            }
            
            fn(false)
        }

        fnLoading(false)
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);

    })
    .catch((e) => {
        fn(false)
        fnLoading(false)
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 