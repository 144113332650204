import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const change = (state, dispatch, fn) => {
    axios(
        access.build('post', '/api/users/change', state.security)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Cambio realizado correctamente.';
            }

            state.security = {};
            severity = 'info';
        } else {
            message = response.data.message;
            severity = 'error';
        }

        fn(false);
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', 'Ocurrio un error', state, constants.DEFAULT, dispatch, false);
    });
}
