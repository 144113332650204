import React, {useState, useEffect, useReducer, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {DataView} from 'primereact/dataview';
import {Dialog} from 'primereact/dialog';
import '../../css/Grid.css';
import '../../css/Styles.css'
import * as constants from "../../Constants";
import {FullCalendar} from 'primereact/fullcalendar';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import classNames from 'classnames';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventReducer from "./reducers/EventReducer"
import * as action from "./actions/CompanyAction"
import * as actionBuilding from "../building/actions/BuildingAction";
import * as actionEvent from "./actions/EventAction"
import BuildingDialog from '../building/BuildingDialog';
import CompanyDialog from './CompanyDialog';
import EventDialog from './EventDialog';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const CompanyDashboard = ({ prevState, menuRightActive , mobile}) => {
    
    useEffect(() => {
        if (state.companyId != undefined){
            action.load(state, state.companyId, dispatch);
        }
        
      }, [])
    
    const [newEvent, setNewEvent] = useState(false)
    const [eliminateEvent, setEliminateEvent] = useState(false)
    const [newBuilding, setNewBuilding] = useState(false)
    const [editdBuilding, setEditBuilding] = useState(false)
    const [viewBuilding, setViewBuilding] = useState(false)
    const [visibleEliminatedBuilding, setVisibleEliminatedBuilding] = useState(false)
    const [selectedBuilding, setSelectedBuilding] = useState({})
    const [state, dispatch] = useReducer(EventReducer, {
        ...prevState,
        company: {},
        loading: true,
        buildingData: {},
        comunicationSummary: {},
        event: {},
        events: null,
        sortOptions: [
            {label: 'Nombre', value: 'name'},
            {label: 'Cant. Unidades', value: 'quantityUnits'},
            {label: 'Fecha de cierre', value: 'closeDay'},
        ],
        fullcalendarOptions: {
            plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            defaultDate: new Date(),
            header: {
                right: 'dayGridMonth,timeGridWeek',
                center: 'title',
                left: 'prev,next',
              },
            locale: 'es',
            selectable: true,
            editable: true,
            eventClick: function(info) {
                dispatch({type: constants.COMPANY_EVENT, value: info.event});
              }
        }
    });

    const createBuilding = () => {
        state.buildingData = {};
        setNewBuilding(true);
    }

    const cleanEvent = () => {
        dispatch({type: constants.COMPANY_EVENT, value: undefined});
    }

    const modal = (visible, type) => {
        dispatch({type: type, value: visible});
        return;
    }

    const onChangeBuilding = (building) => {
        actionBuilding.getBuildingOnSelected(state, dispatch, building, setSelectedBuilding, setViewBuilding);
    }

    const changeBuilding = (building) => {
        localStorage.setItem('building', JSON.stringify(building));
        state.loadDashboard = true;

        //refresh page
        setViewBuilding(false)
        modal(false, constants.BUILDING_DIALOG);
    }

    const onEditBuilding = (building) => {
        actionBuilding.getBuildingOnEdit(state, dispatch, building, setEditBuilding);
    }

    const onCloneBuilding = (building) => {
        actionBuilding.cloneBuilding(state, dispatch, building);
    }

    const confirmationChangeBuilding = (
        <div>
            <Button icon="pi pi-check" onClick={() => changeBuilding(selectedBuilding)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setViewBuilding(false)} label="No" className="p-button-secondary" />
        </div>
    );

    const dataView = (building, layout) => {
        if (!building) {
            return [];
        }

        return (
            <div className="p-md-3 p-sm-12">
                <div className="p-col-4 inline" style={{verticalAlign: 'bottom'}}>
                    <div className="p-grid p-justify-center" style={{backgroundColor: '#d6ffc8', borderRadius: '10px', padding: '10px', cursor: 'pointer'}} onClick={() => onChangeBuilding(building)}>
                        <i className="pi pi-home" style={{'fontSize': '5em', color: '#ffffff'}}></i>
                    </div>
                </div>
                <div className="p-col-8 inline">
                    <div className="bold">{building.name} 
                        {
                            state.admin &&
                            <div className="inline" style={{'marginLeft': '2px'}}>
                                <i className="pi pi-pencil" style={{'color': '#ff9317','fontSize': '1.5em'}} title="Editar edificio" onClick={() => onEditBuilding(building)} ></i>
                                <i className="pi pi-trash" style={{'color': '#ff9317','fontSize': '1.5em'}} title="Eliminar edificio" onClick={() => selectedElimindatedBuilding(building)} ></i>
                                <i className="pi pi-copy" style={{'color': '#ff9317','fontSize': '1.5em'}} title="Copiar edificio" onClick={() => onCloneBuilding(building)} ></i>
                            </div>
                        }
                    </div>
                    <div>{building.address}</div>
                    <div>Cant.  unidades: <b>{building.quantityUnits}</b></div>
                    <div>Fecha de cierre: <b>{building.closeDay}</b></div>
                    <div title="Fecha de bonificación">Fecha de bonif.: <b>{building.payDay ? building.payDay : building.closeDay}</b></div>
                    <div>Moneda: <b>{building.currency}</b></div>
                </div>
            </div>

        );
    }

    function selectedElimindatedBuilding(e){
        state.buildingData = e;
        setVisibleEliminatedBuilding(true);
    }

    const confirmationEliminatedBuilding = (
        <div>
            <Button icon="pi pi-check" onClick={() => actionBuilding.eliminatedBuilding(state, dispatch, setVisibleEliminatedBuilding)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleEliminatedBuilding(false)} label="No" className="p-button-secondary" />
        </div>
    );
    
    const comunicationView = (comunication) => {
        return <Fragment>
            {
                comunication != null &&
                <div className="p-md-12">
                    <div className="team-box p-md-12 p-sm-6 inline">
                        <span className="team-member" style={{fontSize:'14px'}}>{comunication.user.name} - {comunication.user.email}</span>
                    </div>
                    <div className="p-md-12 p-sm-6 inline">
                        <div className="team-box p-md-8 p-sm-12">
                            <div className="team-member">{comunication.title}</div>
                            <div className="team-member">{comunication.building ? comunication.building.name : ''}</div>
                        </div>
                        <div className="team-box p-md-offset-4 p-md-8">
                            <span className="team-member">{comunication.created}</span>
                        </div>
                    </div>
                    <div style={{borderBlockEnd: '1px solid #ccc', marginLeft:'3em', marginRight:'3em'}}></div>
                </div>
            }
        </Fragment>
    }

    const confirmationEliminatedEvent = (
        <div>
            <Button icon="pi pi-check" onClick={() => actionEvent.eliminatedEvent(state, dispatch, setEliminateEvent)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setEliminateEvent(false)} label="No" className="p-button-secondary" />
        </div>
    );

    return (        
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.loadDashboard && 
                <Redirect to={{ pathname: '/dashboard', state: state }} />
            }

            {
              state.loadApp &&
                <Redirect to={{ pathname: '/app', state: state }} />
            }

            <Dialog header="Nueva Administración" className="p-md-6 p-sm-11" visible={state.companyId == undefined} onHide={() => modal(false, constants.COMPANY_DIALOG)}>
                <CompanyDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                     />
            </Dialog>

            <Dialog header="Nuevo Edificio" className="p-md-6 p-sm-11"  visible={newBuilding} onHide={() => setNewBuilding(false)}>
                <BuildingDialog
                    state = {state}
                    isNew = {true}
                    fn = {setNewBuilding}
                    dispatch = {dispatch} />
            </Dialog>

            <Dialog header="Editar edificio" className="p-md-8 p-sm-11"  visible={editdBuilding} onHide={() => setEditBuilding(false)}>
                <BuildingDialog
                    state = {state}
                    isNew = {false}
                    fn = {setEditBuilding}
                    dispatch = {dispatch} />
            </Dialog>

            <Dialog header="Crear evento" contentStyle={{ height: "80vh" }} maximizable={true} className="p-md-6 p-sm-11" visible={newEvent} onHide={() => setNewEvent(false)}>
                <EventDialog
                    state = {state}
                    dispatch = {dispatch}
                    fn = {setNewEvent} />
            </Dialog>

            <Dialog header="Cambio de Edificio" className="p-sm-11" style={{maxWidth: mobile() ? '90%' : '50%'}} visible={viewBuilding} modal={true} footer={confirmationChangeBuilding} onHide={() => setViewBuilding(false)}>
                Esta seguro que desea cambiar de edificio?
            </Dialog>
            <Dialog header="Eliminar Edificio" className="p-sm-11" style={{maxWidth: mobile() ? '90%' : '50%'}} visible={visibleEliminatedBuilding} modal={true} footer={confirmationEliminatedBuilding} onHide={() => setVisibleEliminatedBuilding(false)}>
                Esta seguro que desea eliminar el edificio?
            </Dialog>
            <Dialog header="Eliminar Evento" className="p-sm-11" style={{maxWidth: mobile() ? '90%' : '50%'}} visible={eliminateEvent} modal={true} footer={confirmationEliminatedEvent} onHide={() => setEliminateEvent(false)}>
                Esta seguro que desea eliminar el evento?
            </Dialog>

            {
                state.loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !state.loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                <div className="p-col-12">
                    {
                        state.admin &&
                        <Fragment>
                            <div className="p-md-12 p-sm-12">
                                <h1 className="title-content">Administración</h1>
                                <h2 className="title-content">{state.company.name}</h2>
                            </div>
                            <div className="p-grid p-justify-center">
                                <div className="p-md-3 p-sm-11 resize both">
                                    <div className="box-dashboard">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content-white">Eventos del día</h2>
                                        </div>
                                    </div>

                                    <div className="box-default" style={mobile() ? {marginTop:'10%', paddingRight:'2%'}: {marginTop:'10%', paddingRight:'2%', minHeight: '50vh',
                                        display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                        <div className="p-justify-center layout-config-content">
                                            {
                                                (!state.eventsDay || state.eventsDay.length === 0) && 
                                                <Fragment>
                                                    <div className="p-md-12 p-sm-12 p-grid p-justify-center">
                                                        <h3 className="title-content">No hay eventos para el día</h3>
                                                    </div>
                                                    <div className="p-md-12 p-sm-12 p-grid p-justify-center">
                                                        <Button label="Agregar evento"
                                                            className="p-button-secondary" icon="pi pi-plus" iconPos="left" onClick={() => setNewEvent(true)}/>
                                                    </div>
                                                </Fragment>
                                            }
                                            {
                                                state.eventsDay && state.eventsDay.length > 0 &&
                                                <div className="p-grid p-justify-center" style={{padding:'5%'}}>
                                                    <h3 className="title-content">Que tengo que hacer hoy?</h3>
                                                    <DataTable value={state.eventsDay} paginator={true} responsive={true} rows={5} >
                                                        <Column header="Lugar" field="place" />
                                                        <Column header="Dia" field="start"/>
                                                        <Column header="Persona" field="people" />
                                                        <Column header="Motivo" field="title" />
                                                    </DataTable>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-md-3 p-sm-11 resize both">
                                    <div className="box-dashboard">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content-white">Consultas / Reclamos</h2>
                                        </div>
                                    </div>

                                    <div className="box-default" style={mobile() ? {marginTop:'12%', paddingRight:'2%'}: {marginTop:'10%', paddingRight:'2%', minHeight: '50vh', 
                                        display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                        {
                                            (state.comunicationSummary.comunications && state.comunicationSummary.comunications.length > 0) && 
                                            <div className="p-md-4 p-md-offset-8">
                                                <span className="summary-comunication-number-pending summary-status-alert" 
                                                        title="Comunicaciones en estado pendiente">{state.comunicationSummary.quantityPending}</span>
                                            </div>
                                        }
                                        <div className="p-justify-center layout-config-content">
                                            {
                                                (!state.comunicationSummary.comunications || state.comunicationSummary.comunications.length === 0) && 
                                                <div className="p-grid p-justify-center" >
                                                    <h3 className="title-content">No hay nuevas comunicaciones</h3>
                                                </div>
                                            }
                                            {
                                                (state.comunicationSummary.comunications && state.comunicationSummary.comunications.length > 0) && 
                                                <DataView value={state.comunicationSummary.comunications} filterBy="name" itemTemplate={comunicationView} layout={'grid'}
                                                    paginator={true} rows={3}/>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="p-md-3 p-sm-11 resize both">
                                    <div className="box-dashboard">
                                        <div className="p-grid p-justify-center">
                                            <h2 className="title-content-white">Calendario</h2>
                                        </div>
                                    </div>

                                    <div className="box-default" style={mobile() ? {marginTop:'10%', padding:'5vh'} : {marginTop:'10%', padding:'5vh', minHeight: '40vh'}}>
                                        <div className="p-grid p-justify-center">
                                            <FullCalendar events={state.events} options={state.fullcalendarOptions} />
                                            {state.eventCalendar && 
                                                <div className="p-sm-12 p-md-12" style={{marginTop:'2%'}}>
                                                    <div className="p-md-offset-10 p-md-2" >
                                                        <i className="pi pi-trash" style={{'color': '#ff9317','fontSize': '1.5em'}} title="Eliminar" onClick={() => setEliminateEvent(true)}></i>
                                                        <i className="pi pi-minus-circle" style={{'color': '#ff9317','fontSize': '1.5em'}} title="Cerrar" onClick={() => cleanEvent()}></i>
                                                    </div>
                                                    <h3 className="title-content">{state.eventCalendar.title}</h3>
                                                    <h3 className="title-content">{state.eventCalendar.place}</h3>
                                                    <h3 className="title-content">{state.eventCalendar.people}</h3>
                                                    <h3 className="title-content">{state.eventCalendar.start ? state.eventCalendar.start.toLocaleString() : ''}</h3>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </Fragment>
                    }
                </div>    

                <div className="p-grid p-justify-center">
                    <div className="p-col">
                        <div className="box-default" style={{marginTop:'2%'}}>
                            <div className="p-col-12">
                                <div className="p-grid p-justify-center">
                                    <h1 className="title-content">{state.admin ? 'Mis Negocios' : 'Mis edificios'}</h1>
                                </div>
                                {
                                    state.admin &&
                                    <div className="p-md-offset-9 p-md-2 p-sm-offset-5 p-sm-6">
                                        <div className="p-grid p-justify-end">
                                            <Button label="Nuevo Edificio"
                                                className="p-button-secondary" icon="pi pi-plus" iconPos="left" onClick={() => createBuilding()}/>
                                        </div>
                                    </div>
                                }

                                {
                                    (!state.buildings || state.buildings.length === 0) && 
                                    <h3 className="title-content">Sin negocios asociados</h3>
                                }
                                {
                                    state.buildings && state.buildings.length > 0 && 
                                    <div className="p-md-offset-1 p-md-10 p-sm-11">
                                        <DataView value={state.buildings} filterBy="name" itemTemplate={dataView} layout={'grid'} paginator={true} rows={20} />
                                    </div>  
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            
        </Fragment>
    );
}


export default CompanyDashboard
