import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";

export const search = (state, dispatch, fn) => {
    if (state.activeIndex === 0)
        discounts(state, dispatch, fn);
    else if (state.activeIndex === 1){
        taxes(state, dispatch, fn);
    }
}

export const discounts = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/payments/discount/` + state.building.id, null)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.PAYMENT_DISCOUNT_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.PAYMENT_DISCOUNT_LOAD, dispatch, false);
    })  
}

export const taxes = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/payments/tax/` + state.building.id, null)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.PAYMENT_TAX_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.PAYMENT_TAX_LOAD, dispatch, false);
    }) 
}

export const saveDiscount = (state, dispatch, isNew, fn, setLoading) => { 
    state.discount.buildingId = state.building.id;

    axios(
        access.build('post', '/api/payments/discount/' , state.discount)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = isNew ? 'Descuento creado correctamente.' : 'Descuento modificado correctamente.';

            state.discount = {};
            
            severity = 'info';
            state.activeIndex = 0;
            search(state, dispatch, fn);
        }
        else {
            message = response.data.message;
            severity = 'error';
        }

        setLoading(false)
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedDiscount = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/payments/discount/' + state.discount.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Descuento eliminado correctamente.';

            state.payment = {};
            severity = 'info';
            
            state.activeIndex = 0;
            search(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const saveTax = (state, dispatch, isNew, fn, setLoading) => { 
    state.tax.buildingId = state.building.id;

    axios(
        access.build('post', '/api/payments/tax/' , state.tax)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = isNew ? 'Recargo creado correctamente.' : 'Recargo modificado correctamente.';

            state.discount = {};
            
            severity = 'info';
            state.activeIndex = 1;
            search(state, dispatch, fn);
        }
        else {
            message = response.data.message;
            severity = 'error';
        }

        setLoading(false);
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedTax = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/payments/tax/' + state.tax.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Recargo eliminado correctamente.';

            state.payment = {};
            severity = 'info';
            
            state.activeIndex = 1;
            search(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}