import * as constants from "../Constants";
import axios from "axios";

export const access = (type, isUnit, state, dispatch) => {
    if (state.admin || state.isSupplier){
        accessAdmin(type, isUnit, state, dispatch);
    } else {
        accessUser(type, isUnit, state, dispatch);
    }
}

const accessAdmin = (type, isUnit, state, dispatch) => {
    if (state.building === "undefined" || state.building === null) {
        dispatch({ type: type, access: false, message: 'Debe seleccionar un edificio.'});
    } else if (isUnit) {
        getUnitsAfterAccess(state, dispatch);    
    }
}

const accessUser = (type, isUnit, state, dispatch) => {
    let message = '';
    if (state.buildings === null || state.buildings.length === 0) {
        message = 'No se encuentra asociado a ningún edificio. Por favor solicitar la vinculación a su administración.' 
        dispatch({ type: type, access: false, message: message});
    } else if (state.building === "undefined" || state.building === null) {
        message = 'Debe seleccionar un edificio.';
        dispatch({ type: type, access: true, message: message});
    } else if (isUnit) {
        getUnitsAfterAccess(state, dispatch);    
    }
}

export const getUnitsAfterAccess = (state, dispatch) => {
    let url = `/api/units/building/` + state.building.id;
    if (!(state.admin || state.isSupplier)){
        url = url + `/email/` + state.user.email;
    } 

    axios(
        build('get', url, null)
    )
    .then(response => {
        dispatch({ type: constants.CASH_UNITS, value: response.data });    
    })
    .catch((e) => {
        responseData('error', e.message, state, constants.CASH_UNITS, dispatch, false);
    })    
}

export const build = (method, url, data) => {
   let info = {
        method: method,
        url: url,
        headers: getHeaders()
    };

    if (data != null){
        info.data = data;
    }

    return info; 
}

export const buildType = (method, url, data, type) => {
    let info = {
         method: method,
         url: url,
         headers: getHeaders(),
         responseType: type
     };
 
     if (data != null){
         info.data = data;
     }
 
     return info; 
 }

const getHeaders = () => {
    let token = localStorage.getItem('token');
    let headers = {
        'Authorization': token
    }
    return headers;
}

export const responseData = (severity, message, state, type, dispatch, value) => {
    if (message === "Request failed with status code 403"){
        localStorage.setItem("forbidden", true);
    } else {
        if (!state.messages){
            console.log('error')
        } else {
            if (message === "Request failed with status code 500"){
                message = "Ocurrio un error inesperado."
            }
            state.messages.show({life: 5000, severity: severity, summary: message});
            dispatch({ type: type, value: value });
        }
    }
}
