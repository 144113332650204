import * as constants from "../../../Constants";
import * as configurations from "../../../Configurations";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";

export const load = (state, dispatch) => {
    axios(
        access.build('get', `/api/events/company/` + state.companyId, null)
    )
    .then(response => {
        dispatch({ type: constants.EVENT_LOAD, value: response.data.value });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.EVENT_LOAD, dispatch, false);
    }) 
}

export const saveEvent = (state, dispatch, fn) => {
    state.event.companyId = state.companyId;
    state.event.userId = state.user.id

    axios(
        access.build('post', '/api/events', state.event)
    )
    .then((response) => {
        if (response.status === 200) {
            state.event = {}
            fn(false)
        } 
            
        load(state, dispatch);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.EVENT_LOAD, dispatch, false);
    });
}

export const eliminatedEvent = (state, dispatch, fn) => {
    axios(
        access.build('delete', '/api/events/' + state.eventCalendar.id, null)
    )
    .then((response) => {
        if (response.status === 200) {
            state.eventCalendar = undefined
            fn(false)
        } 
            
        load(state, dispatch);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.EVENT_LOAD, dispatch, false);
    });
}