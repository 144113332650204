import React, {useEffect, useReducer, Fragment, useState} from 'react';
import { Redirect } from 'react-router-dom'
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {TabView,TabPanel} from 'primereact/tabview';
import '../../css/Grid.css';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import PaymentDiscountTaxReducer from './reducers/PaymentDiscountTaxReducer';
import DiscountDialog from './DiscountDialog';
import TaxDialog from './TaxDialog';
import * as access from "../AccessAction";
import * as action from "./actions/PaymentDiscountTaxAction";
import * as utils from "../Utils";

const PaymentExpenseForm = ({ prevState, menuRightActive, mobile }) => {
    
    useEffect(() => {
        access.access(constants.ACCESS_DENIED, true, prevState, dispatch);
        if (prevState.building != undefined){
            prevState.activeIndex = 0;
            action.search(prevState, dispatch, setLoading);
        }
        }, []
    );
    
    const [loading, setLoading] = useState(true)
    const [visibleDiscount, setVisibleDiscount] = useState(false)
    const [visibleDiscountEdit, setVisibleDiscountEdit] = useState(false)
    const [visibleDiscountEliminated, setVisibleDiscountEliminated] = useState(false)
    const [visibleTax, setVisibleTax] = useState(false)
    const [visibleTaxEdit, setVisibleTaxEdit] = useState(false)
    const [visibleTaxEliminated, setVisibleTaxEliminated] = useState(false)
    const [state, dispatch] = useReducer(PaymentDiscountTaxReducer, {
        ...prevState,
        access: true,
        discount: {},
        tax: {},
        activeIndex: 0
    });


    const changeTab = (index) => {
        setLoading(true);
        state.activeIndex = index;
        action.search(state, dispatch, setLoading);
        return;
    }

    const openCreateDiscount = () => {
        state.discount = {};
        setVisibleDiscount(true);
    };

    function actionDiscountRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selectedDiscount(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedDiscount(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedDiscount(e){
        setVisibleDiscountEdit(true)
        dispatch({type: constants.PAYMENT_DISCOUNT_SELECTED, value: e})
    }

    function selectedElimindatedDiscount(e){
        setVisibleDiscountEliminated(true);
        dispatch({type: constants.PAYMENT_DISCOUNT_SELECTED, value: e})
    }

    const confirmationDiscountEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedDiscount(state, dispatch, setVisibleDiscountEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleDiscountEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );

    function actionTaxRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selectedTax(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindatedTax(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedTax(e){
        setVisibleTaxEdit(true)
        dispatch({type: constants.PAYMENT_TAX_SELECTED, value: e})
    }

    function selectedElimindatedTax(e){
        setVisibleTaxEliminated(true);
        dispatch({type: constants.PAYMENT_TAX_SELECTED, value: e})
    }

    const confirmationTaxEliminated = (
        <div>
            <Button icon="pi pi-check" onClick={() => action.eliminatedTax(state, dispatch, setVisibleTaxEliminated)} label="Si" />
            <Button icon="pi pi-times" onClick={() => setVisibleTaxEliminated(false)} label="No" className="p-button-secondary" />
        </div>
    );


    const openCreateTax = () => {
        state.tax = {};
        setVisibleTax(true);
    };

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
              state.redirect &&
                <Redirect to="/app" />
            }

            <Dialog header="Acceso denegado" visible={!state.access} onHide={(e) => dispatch({type: constants.REDIRECT, value: e})}>
                <p>{state.message}</p>
            </Dialog>

            <Dialog header="Nuevo descuento" visible={visibleDiscount} onHide={() => setVisibleDiscount(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <DiscountDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {true}
                    fn = {setVisibleDiscount}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar descuento" visible={visibleDiscountEdit} onHide={() => setVisibleDiscountEdit(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <DiscountDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleDiscountEdit}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Nuevo recargo" visible={visibleTax} onHide={() => setVisibleTax(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <TaxDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleTax}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Editar recargo" visible={visibleTaxEdit} onHide={() => setVisibleTaxEdit(false)} style={{maxWidth: mobile() ? '90%' : '50%'}} >
                <TaxDialog 
                    state = {state}
                    dispatch = {dispatch}
                    isNew = {false}
                    fn = {setVisibleTaxEdit}
                    setLoading = {setLoading}
                />
            </Dialog>
            <Dialog header="Eliminar Descuento" visible={visibleDiscountEliminated} modal={true} footer={confirmationDiscountEliminated} onHide={() => setVisibleDiscountEliminated(false)}>
                Esta seguro que desea eliminar el descuento?
            </Dialog>
            <Dialog header="Eliminar Recargo" visible={visibleTaxEliminated} modal={true} footer={confirmationTaxEliminated} onHide={() => setVisibleTaxEliminated(false)}>
                Esta seguro que desea eliminar el recargo?
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="p-md-9 inline">
                        <h1 className="title-content" style={{marginBlockEnd: 0}} title={state.building ? state.building.name : ''} >Descuentos y Recargos</h1>
                    </div>

                    <div class="p-grid p-justify-center box-default-margin" style={{marginTop:'1%'}}>
                        <TabView className="p-col-12" style={{marginTop:'3%'}} activeIndex={state.activeIndex} onTabChange={(e) => changeTab(e.index)}>
                            <TabPanel header="Descuentos">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Crear" onClick={() => openCreateDiscount()}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                
                                {
                                    (!state.discounts || state.discounts.length === 0) &&
                                    <h3 className="title-content">Sin información de descuentos</h3>
                                }
                                {
                                    state.discounts && state.discounts.length > 0 &&
                                    <DataTable value={state.discounts} paginator={true} rows={10} responsive={true} style={{marginTop:'10px' }} >
                                        <Column field="description" header="Descripción" sortable={true} />
                                        <Column header="Monto" body={(r) => utils.percentageRow(r.amount)}/>
                                        <Column field="peopleType" header="Tipo persona" sortable={true}/>
                                        <Column field="created" header="F. Creación" sortable={true}/>
                                        <Column header="Archivo" body={(r) => actionDiscountRow(r)}/>
                                    </DataTable>
                                }
                            </TabPanel>
                            <TabPanel header="Recargos">
                                <div style={{marginRight:'1%'}} className="p-md-offset-10 p-sm-offset-5">
                                    <Button label="Crear" onClick={() => openCreateTax()}
                                        className="p-button-warning p-lg-10 p-md-10 p-sm-12" icon="pi pi-plus" iconPos="right"
                                    />
                                </div>
                                
                                {
                                    (!state.taxes || state.taxes.length === 0) &&
                                    <h3 className="title-content">Sin información de recargos</h3>
                                }
                                {
                                    state.taxes && state.taxes.length > 0 &&
                                    <DataTable value={state.taxes} paginator={true} rows={10} responsive={true} style={{marginTop:'10px'}} >
                                        <Column field="description" header="Descripción" sortable={true}/>
                                        <Column header="Monto" body={(r) => utils.percentageRow(r.amount)}/>
                                        <Column field="created" header="F. Creación" sortable={true}/>
                                        <Column header="Acciones" body={(r) => actionTaxRow(r)}/>
                                    </DataTable>
                                }
                            </TabPanel>
                        </TabView>
                    </div>          
                </div>
            }
        </Fragment>
    );
}


export default PaymentExpenseForm;
