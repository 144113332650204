import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import ErrorBoundary from './ErrorBoundary';
import {HashRouter} from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';

ReactDOM.render(
	<HashRouter>
		<ErrorBoundary>
			<AppWrapper></AppWrapper>
		</ErrorBoundary>
	</HashRouter>,
	document.getElementById('root')
);

