import React,{useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../Constants";
import * as action from "./actions/CoefficientAction"

const CoefficienttDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        action.unitsByCoefficient(state, dispatch, setLoading);
        }, []
    );

    const onSave = () => {
        action.saveCoefficient(state, dispatch, isNew, fn, setLoading);
    }

    function setNumberDecimal(event) {
        let amount = parseFloat(event).toFixed(6);
        dispatch({type: constants.COEFFICIENT_PERCENTAGE, value: amount})
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-6">
                    <InputText placeholder="Coeficiente %" value={state.coefficient.percentage} type="number"
                        onChange={(e) => setNumberDecimal(e.target.value)} step="0.000001" required/>
                </div>
                <div className="p-md-6">
                    <div style={{marginLeft:'-5px'}}>
                            <Dropdown optionLabel="name" value={state.coefficient.unit} options={state.units} 
                                onChange={(e) => dispatch({type: constants.COEFFICIENT_UNIT_SELECTED, value: e.value})} placeholder="Unidad" required
                                style={{borderLeft: !state.coefficient.unit ? '3px solid salmon' : ''}} />
                        </div>
                </div>
                <div className="p-md-3">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default CoefficienttDialog;
