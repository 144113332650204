import React, {useEffect, useReducer, Fragment, useState} from 'react';
import classNames from 'classnames';
import {Dropdown} from 'primereact/dropdown';
import {Messages} from 'primereact/messages';
import CompanyReducer from "./components/company/reducers/CompanyReducer"
import * as actionCompany from "./components/company/actions/CompanyAction"

const AppTopbar = ({ prevState, activeTopbarItem, onRightMenuButtonClick, onMenuButtonClick }) => {

    useEffect(() => {
        if (state.companyId != undefined && state.companyId != null){
            actionCompany.load(state, state.companyId, dispatch);
        }
      }, [])
      
    const [state, dispatch] = useReducer(CompanyReducer, {
        ...prevState,
        company:{}
    });
    
    let building = localStorage.getItem('building');
    if (building !== undefined) {
        state.building = JSON.parse(building);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            <div className="layout-topbar">
                <div style={{padding:'12px 20px'}}>
                    <button className="p-link layout-right-panel-button layout-topbar-icon" onClick={onRightMenuButtonClick}>
                        <i className="pi pi-sign-out"></i>
                    </button>
                    <button className="p-link layout-menu-button layout-topbar-icon" onClick={onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    {
                        !state.isSupplier &&
                        <div className="p-dropdown">
                            {
                                state.building &&
                                <h3 className="title-content">Edificio actual: {state.building.name}</h3>
                            }
                            {
                                !state.building && 
                                <h3 className="title-content">Sin edificio seleccionado</h3>
                            }
                        </div>

                    }
                    
                     <div style={{verticalAlign: 'middle', textAlign: 'right'}} className="p-col-5 topbar-welcome-user">
                        <label style={{ fontFamily:'Lato, sans-serif', fontSize:'18px', color:'#89c2f0'}}>¡Bienvenido/a {state.user.name}!</label>     
                        <label style={{ fontFamily:'Lato, sans-serif', fontSize:'14px', color:'#79797c', 
                            marginTop: '5px'}}>{state.company.name !== undefined ? ('Administración ' + state.company.name) : ''}</label>
                    </div>
                                    
                    <ul className="topbar-menu">
                        <li className={classNames('user-profile', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'})}>
                            <button className="p-link">
                                    <img src="assets/layout/images/mago_blue.png" alt="roma-layout"/>
                                    <div className="layout-profile-userinfo">
                                        <span className="layout-profile-name">{state.user.name}</span>
                                        <span className="layout-profile-role">{state.user.email}</span>
                                    </div>
                                </button>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default AppTopbar