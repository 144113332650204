import * as constants from '../../../Constants';
import UnitReducer from '../../units/reducers/UnitReducer';

const PeopleReducer = (state, action) => {
    switch (action.type) {
        case constants.PEOPLE_BUILDING_SEARCH:
            state.people.buildings = action.value; 
            return {
                ...state
            };
        case constants.PEOPLE_EMAIL_SEARCH:
            state.filter.email = action.value;
            return {
                ...state
            };
        case constants.PEOPLE_FIRST_NAME_SEARCH:
            state.filter.first_name = action.value;
            return {
                ...state
            };
        case constants.PEOPLE_LAST_NAME_SEARCH:
            state.filter.last_name = action.value;
            return {
               ...state
            };
        case constants.PEOPLE_SEARCH:
            return {
                ...state,
                peoples: action.value
            };
        case constants.PEOPLE_DIALOG:
            return {
                ...state,
                visiblePeople: action.value
            };
        case constants.PEOPLE_DIALOG_SELECTED:
            return {
                ...state,
                visiblePeopleSelected: action.value
            };
        case constants.PEOPLE_SELECTED:
            state.people = action.value;
            return {
                ...state,
                visiblePeopleSelected: true
            }; 
        case constants.PEOPLE_FIRST_NAME:
            state.people.firstName = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_LAST_NAME:
            state.people.lastName = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_DOCUMENT:
            state.people.documentNr = action.value;
            return {
                ...state
            };
        case constants.PEOPLE_EMAIL:
            state.people.email = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_PHONE:
            state.people.phone = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_MOBILE:
            state.people.mobile = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_TYPE:
            state.people.oType = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_UNIT:
            state.people.units = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_CHECK_ELIMINATED:
            state.people.eliminated = action.value;    
            return {
                ...state
            };
        case constants.PEOPLE_UPLOAD:
            state.errors = action.value;    
            return {
                ...state
            };
        default: return UnitReducer(state, action);
    }
};

export default PeopleReducer;