import React, {useReducer, useEffect, Fragment, useState} from 'react';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {InputText} from 'primereact/inputtext';
import classNames from 'classnames';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as constants from "../../Constants";
import UserReducer from "./reducers/UserReducer";
import * as action from "./actions/UserAction"
import { strengthIndicator, strengthColor } from './StrengthPassword';

const UserForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        dispatch({type: constants.USER_SECURITY_LOAD})
        }, []
    );

    const [loading, setLoading] = useState(false)
    const [oldColor, setOldColor] = useState('gray')
    const [newColor, setNewColor] = useState('gray')
    const [state, dispatch] = useReducer(UserReducer, {
        ...prevState,
        access: true,
        security: {}
    });
   
    function oldPwd(e){
        let strength = strengthIndicator(e.target.value);
        setOldColor(strengthColor(strength));
        dispatch({type: constants.USER_SECURITY_OLD, value: e.target.value});
    }

    function newPwd(e){
        let strength = strengthIndicator(e.target.value);
        setNewColor(strengthColor(strength));
        dispatch({type: constants.USER_SECURITY_NEW, value: e.target.value});
    }

    function disabled(){
        return newColor === 'red' || newColor === 'gray'
    }

    function change(){
        let message = undefined
        if (!state.security.old){
            message = 'Ingresar la contraseña actual';
        } else if (state.security.old === state.security.new){
            message = 'Deben ser diferentes';
        }

        if (message){
            state.messages.show({ severity: 'warn', summary: message, sticky: true });
        } else {
            setLoading(true);
            setOldColor('gray');
            setNewColor('gray');
            action.change(state, dispatch, setLoading);
        }
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div class="p-grid box-default-margin">
                        <div className="p-md-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon" style={{borderRadius:'25px 0 0 25px'}}><i className="pi pi-unlock" /></span>
                                <InputText className="input-login p-md-12" type="password" placeholder="Contraseña actual" 
                                    style={{paddingInlineEnd: '10%', borderColor: oldColor, 
                                    webkitBoxShadow: '0 0 0 0 ' + oldColor, boxShadow: '0 0 0 0 ' + oldColor}}
                                    onChange={(e) => oldPwd(e)}/>
                            </div>
                            
                            <div className="p-inputgroup" style={{ marginTop: '10px'}}>
                                <span className="p-inputgroup-addon" style={{borderRadius:'25px 0 0 25px'}}><i className="pi pi-unlock"/></span>
                                <InputText className="p-md-12" type="password" placeholder="Nueva contraseña" 
                                    style={{paddingInlineEnd: '10%',
                                    borderColor: newColor, webkitBoxShadow: '0 0 0 0 ' + newColor,
                                    boxShadow: '0 0 0 0 ' + newColor}}
                                    onChange={(e) => newPwd(e)}/>
                            </div>
                            <Button label="Cambiar" style={{marginTop:'20px'}}
                                className="p-button-warning" icon="pi pi-pencil" iconPos="right" 
                                disabled = {disabled()}
                                onClick={() => change()}/>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default UserForm;
