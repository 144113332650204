import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const search = (pagination, state, dispatch, fn) => {
    let url = `/api/commonareas/building/` + state.building.id 
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false)
        state.message = undefined;
        dispatch({ type: constants.COMMON_AREA_SEARCH, value: response.data });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMMON_AREA_SEARCH, dispatch, false);
    })  
}

export const commonAreasLoadByReservation = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/commonareas/building/` + state.building.id + `?all=true`, null)
    )
    .then(response => {
        fn(false)
        state.message = undefined;
        dispatch({ type: constants.COMMON_AREA_RESERVATION_SEARCH, value: response.data });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMMON_AREA_RESERVATION_SEARCH, dispatch, false);
    })  
}

export const saveArea = (state, dispatch, isNew, fn, setLoading) => { 
    state.area.buildingId = state.building.id;

    axios(
        access.build('post', '/api/commonareas' , state.area)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = isNew ? 'Area creado correctamente.' : 'Area modificado correctamente.';

            state.area = {};
            
            severity = 'info';
            search(null, state, dispatch, fn);
        }
        else {
            message = response.data.message;
            severity = 'error';
        }

        setLoading(false)
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedArea = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/commonareas/' + state.area.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Area eliminado correctamente.';

            state.area = {};
            severity = 'info';
            
            search(null, state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}
