import * as constants from '../../../Constants';
import CompanyReducer from '../../company/reducers/CompanyReducer';

const InformationReducer = (state, action) => {
    switch (action.type) {
        case constants.INFORMATION_LOAD:
            return {
                ...state,
                quotes: action.value,
                company: action.valueCompany
            };
        default: return CompanyReducer(state, action);
    }
};

export default InformationReducer;