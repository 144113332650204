import React, {Fragment, useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {Checkbox} from 'primereact/checkbox';

import * as constants from "../../../Constants";
import * as action from "./actions/MovementGroupAction";
import * as actionAccount from "../account/actions/MovementAccountAction"

const MovementGroupDialog = ({ state, isNew, dispatch, fn, fnLoading }) => {

    useEffect(() => {
        fnLoading(true)
        if (isNew){
            state.movementGroup = {};
        }
        actionAccount.search(state, dispatch, fnLoading) 
      }, []) 

    const onSave = () => {
        action.saveMovementGroup(state, isNew, dispatch, fn, fnLoading)
    }

    return (
        <div className="p-fluid" style={{margin: '0 auto'}}>
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-8">
                    <InputText value={state.movementGroup.name} style={{marginBottom:'2%'}}
                        onChange={(e) => dispatch({type: constants.MOVEMENT_GROUP_NAME, value: e.target.value})} placeholder="Nombre" required/>
                    <Dropdown  optionLabel="name" value={state.movementGroup.oType} options={state.types}
                        style={{borderLeft: !state.movementGroup.oType ? '3px solid salmon' : '', marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.MOVEMENT_GROUP_TYPE, value: e.value})} placeholder="Tipo" required/>
                    <Dropdown  optionLabel="name" value={state.movementGroup.account} options={state.accounts}
                        style={{borderLeft: !state.movementGroup.account ? '3px solid salmon' : '', marginBottom:'2%'}} 
                        onChange={(e) => dispatch({type: constants.MOVEMENT_GROUP_ACCOUNT, value: e.value})} placeholder="Caja" required/>
                    
                    <div style={{marginTop:'15px'}} className="p-md-12">
                        {
                            state.movementGroup.oType && 
                            state.movementGroup.oType.code === 'INCOME' &&
                            <Fragment>
                                <div className="p-md-12">
                                    <Checkbox onChange={(e) => dispatch({type: constants.MOVEMENT_GROUP_CHECK_PRINT, value: e.checked})} checked={state.movementGroup.toPrint}></Checkbox>
                                    <label htmlFor="cb1" className="p-checkbox-label">Informe liquidación</label>
                                </div>
                                <div className="p-md-12">
                                    <Checkbox onChange={(e) => dispatch({type: constants.MOVEMENT_GROUP_APPLY_COEF, value: e.checked})} checked={state.movementGroup.applyCoefficient}></Checkbox>
                                    <label htmlFor="cb1" className="p-checkbox-label">Aplica coeficiente</label>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="p-md-4 p-sm-12">
                    {
                        state.movementGroup.oType && 
                        state.movementGroup.oType.code === 'EXPENSE' &&
                        <h3 className="title-content-menu" style={{marginBottom:'10px'}}>Egreso para la administradora</h3>
                    }
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>
            </form>    
        </div>
    );
}

export default MovementGroupDialog;
