import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const search = (state, dispatch, fn) => {
    if (!state.filter){
        state.filter = {};
    }
    state.filter.userId = state.user.id;
    axios(
        access.build('post', `/api/upkeeps/user/${state.user.id}/filter`, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.UPKEEP_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false)
        access.responseData('error', e.message, state, constants.UPKEEP_LOAD, dispatch, false);
    }) 
}

export const searchByFilters = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/upkeeps/filter`, state.filter)
    )
    .then(response => {
        state.message = undefined;
        fn(false)
        dispatch({ type: constants.UPKEEP_LOAD, value: response.data });
    })
    .catch((e) => {
        fn(false)
        access.responseData('error', e.message, state, constants.UPKEEP_LOAD, dispatch, false);
    }) 
}

export const eliminatedUpkeep = (state, dispatch, fn, setLoading) => {
    setLoading(true)
    axios(
        access.build('delete','/api/upkeeps/' + state.upkeep.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Mantenimiento eliminado correctamente.';

            state.upkeep = {};
            severity = 'info';
            
            search(state, dispatch, setLoading);
            fn(false)
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);setLoading(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const saveUpkeep = (state, dispatch, isNew, fn, setLoading) => {
    state.upkeep.userId = state.user.id;

    axios(
        access.build('post', '/api/upkeeps', state.upkeep)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200){
            message = isNew ? 'Mantenimiento creada correctamente.' : 'Mantenimiento modificada correctamente.';
            severity = 'info';
            
            state.upkeep = {};
            search(state, dispatch, setLoading);
            fn(false);

            access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);setLoading(false);
            access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
        }
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 
