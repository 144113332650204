import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import * as constants from "../../Constants";
import * as action from "./actions/CompanyAction"

const AgentDialog = ({ state, isNew, dispatch, fn }) => {

    const onSave = e => {
        e.preventDefault();
        if (state.buttonSave){
            action.saveAgent(state, dispatch, isNew, fn);
        }
    }

    function eliminatedAgent() {
        state.buttonSave = false
        dispatch({type: constants.AGENT_BUILDING_ELIMINATED_SAVE})
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-5 p-sm-12 inline">
                    <InputText value={state.agent.firstName} style={{marginBottom:'2px'}} placeholder="Nombre"
                        onChange={(e) => dispatch({type: constants.AGENT_FIRST_NAME, value: e.target.value})} required/>
                    <InputText value={state.agent.lastName} style={{marginBottom:'2px'}} placeholder="Apellido"
                        onChange={(e) => dispatch({type: constants.AGENT_LAST_NAME, value: e.target.value})}/>
                    <InputText value={state.agent.email} style={{marginBottom:'2px'}} placeholder="Email"
                        onChange={(e) => dispatch({type: constants.AGENT_EMAIL, value: e.target.value})} required/>
                    <Dropdown  optionLabel="name" value={state.buildingSelected} options={state.buildings} placeholder="Edificio" style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.AGENT_BUILDING_SELECTED, value: e.value})} />
                </div>                    
                        
                { state.agent.buildings && state.agent.buildings.length > 0 &&
                    <div className="p-md-6 inline">
                        <DataTable value={state.agent.buildings} style={{marginTop:'15px'}}
                            selection={state.agent.buildingSelected} onSelectionChange={e => dispatch({type: constants.AGENT_BUILDING_ELIMINATED_SELECTED, value: e.value})}>
                            <Column selectionMode="single" header="Todos"/>
                            <Column field="name" header="Nombre" />
                        </DataTable>
                        <div className="p-md-6">
                            <Button label="Eliminar edificios" className="p-button-warning" icon="pi pi-trash" iconPos="right" 
                                onClick={() => eliminatedAgent()}/>
                        </div>
                        
                    </div>
                }

                <div className="p-md-4 p-sm-12">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" onClick={() => (state.buttonSave = true)} />
                </div>          
            </form>    
        </div>
    );
}

export default AgentDialog;
