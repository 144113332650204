import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as actionCompany from "../../company/actions/CompanyAction";
import * as actionSupplier from "../../supplier/actions/SupplierAction";

export const load = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/comunications/categories/${state.companyId}`, null)
     )
    .then(response => {
        if (state.admin){
            actionCompany.getCompany(state, state.companyId, dispatch);
            actionSupplier.suppliers(state, dispatch, constants.COMUNICATION_SUPPLIERS);
            searchByCompany(state, dispatch, fn);
            summary(state, dispatch);
        } else {
            searchByUser(state, dispatch, fn);
        }
        dispatch({ type: constants.COMUNICATION_CATEGORY_LOAD, value: response.data});   
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMUNICATION_CATEGORY_LOAD, dispatch, false);
    })
}

export const summary = (state, dispatch) => {
    axios(
        access.build('get', `/api/comunications/summary/${state.companyId}`, null)
     )
    .then(response => {
        dispatch({ type: constants.COMUNICATION_SUMMARY, value: response.data.value});   
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMUNICATION_SUMMARY, dispatch, false);
    })
}

export const search = (state, dispatch, fn) => {
    if (state.admin){
        searchByCompany(state, dispatch, fn);
    } else {
        searchByUser(state, dispatch, fn);
    }
}

export const searchByUser = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/comunications/user/${state.user.id}/filter`, getFilter(state))
     )
    .then(response => {
        fn(false)
        dispatch({ type: constants.COMUNICATION_LOAD, value: response.data});    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMUNICATION_LOAD, dispatch, false);
    })
}

export const searchByCompany = (state, dispatch, fn) => {
    axios(
        access.build('post', `/api/comunications/company/${state.companyId}/filter`, getFilter(state))
     )
    .then(response => {
        fn(false);
        dispatch({ type: constants.COMUNICATION_LOAD, value: response.data});    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COMUNICATION_LOAD, dispatch, false);
    })
}

export const getFilter = (state) => {
    let filter = {};
    if (state.filter.type){
        filter.type = state.filter.type.type;
    }    
    if (state.filter.status){
        filter.status = state.filter.status.type;
    }
    if (state.filter.category){
        filter.categoryId = state.filter.category.id;
    }
    if (state.filter.unit){
        filter.unitId = state.filter.unit.id;
        filter.unit = undefined;
    }
    if (state.filter.supplier){
        filter.supplierId = state.filter.supplier.id;
    }
    if (state.filter.agent){
        filter.assignId = state.filter.agent.id;
        filter.agent = undefined;
    }
    filter.date = state.filter.date;
    return filter;
}

export const saveComunication = (state, isNew, dispatch, fn) => {
    state.comunication.companyId = state.companyId;
    state.comunication.type = state.comunication.typeSelected.type;
    state.comunication.userId = state.user.id;
    state.comunication.unitId = state.comunication.unit ? state.comunication.unit.id : undefined;
    state.comunication.unit = undefined;
    state.comunication.created = undefined;
    if (state.comunication.statusSelected){
        state.comunication.status = state.comunication.statusSelected.type;
    }
    if(!state.admin && isNew){
        state.comunication.status = 'PENDING'
    }

    axios(
        access.build('post', `/api/comunications/`, state.comunication)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (isNew){
                message = 'Comunicación creada correctamente.';
            } else {
                message = 'Comunicación modificada correctamente.';
            }

            state.comunication = {
                building: state.building
            };
            severity = 'info';
            
            if (state.admin){
                summary(state, dispatch);
            }

            search(state, dispatch, fn);
        } else {
            message = 'Error al guardar.';
            severity = 'error';
        }
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);

    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 

export const notes = (state, dispatch) => {
    axios(
        access.build('get', `/api/comunications/notes/${state.comunication.id}`, null)
     )
    .then(response => {
        dispatch({ type: constants.COMUNICATION_NOTES, value: response.data.value});   
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMUNICATION_NOTES, dispatch, false);
    })
}

export const saveNote = (state, dispatch, fn) => {
    if (state.note.supplier){
        state.note.supplierId = state.note.supplier.id;
    }
    if (state.note.agent){
        state.note.assignId = state.note.agent.id;
    }
    state.note.userId = state.user.id;

    axios(
        access.build('post', `/api/comunications/note/` + state.comunication.id, state.note)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Nota creada correctamente.';

            state.note = {};
            severity = 'info';
            search(state, dispatch, fn)
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);
        }
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);

    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
} 

export const searchComunicationById = (id, type, state, dispatch, fnLoading, fn) => {
    axios(
        access.build('get', `/api/comunications/${id}`, null)
     )
    .then(response => {
        fnLoading(false);
        fn(true);
        console.log(response)
        dispatch({ type: type, value: response.data});    
    })
    .catch((e) => {
        fnLoading(false);
        access.responseData('error', e.message, state, type, dispatch, false);
    })
}