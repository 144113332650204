import * as constants from '../../../../Constants';
import BuildingReducer from '../../../building/reducers/BuildingReducer';

const UpkeepReducer = (state, action) => {
    switch (action.type) {
        case constants.UPKEEP_LOAD:
            state.upkeeps = action.value
            return {
                ...state
            };
        case constants.UPKEEP_NAME:
            state.upkeep.name = action.value
            return {
                ...state
            };
        case constants.UPKEEP_DESCRIPTION:
            state.upkeep.description = action.value;
            return {
                ...state
            };
        case constants.UPKEEP_INGRESS:
            state.upkeep.ingress = action.value
            return {
                ...state
            };
        case constants.UPKEEP_PRICE_ID:
            state.upkeep.priceId = action.value
            return {
                ...state
            };
        case constants.UPKEEP_SUPPLIER_SELECTED:
            state.upkeep.supplier = action.value
            state.upkeep.supplierId = action.value.id;
            return {
                ...state
            };
        case constants.UPKEEP_STATUS_SELECTED:
            state.upkeep.statusSelected = action.value
            state.upkeep.status = action.value.type;
            return {
                ...state
            };
        case constants.UPKEEP_ELIMINATED:
            state.upkeep.eliminated = action.value
            return {
                ...state
            };
        case constants.UPKEEP_SELECTED:
            state.upkeep = action.value.value;
            return {
                ...state
            };
        case constants.UPKEEP_FILTER_DATE:
            state.filter.ingress = action.value
            return {
                ...state
            };
        case constants.UPKEEP_FILTER_PRICE:
            state.filter.priceId = action.value
            return {
                ...state
            };
        case constants.UPKEEP_FILTER_SUPPLIER:
            if (action.value){
                state.filter.supplier = action.value;
                state.filter.supplierId = action.value.id;
            } else {
                state.filter.supplier = undefined;
                state.filter.supplierId = undefined;
            }
            return {
                ...state
            };
        default: return BuildingReducer(state, action);
    }
};

export default UpkeepReducer;