import React, {Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import * as constants from "../../Constants";
import * as action from "./actions/CompanyAction"

const CompanyDialog = ({ state, isNew, dispatch, fn }) => {

    const onSave = () => {
        if (isNew){
            action.saveCompany(state, dispatch);
        } else {
            action.updateCompany(state, dispatch, fn);
        }
    }

    const upload = (event) => {
        action.uploadIcon(event, state, dispatch, fn);
    };

    return (
            <div className="p-grid">
                <p>{isNew ? 'Debe ingresar los datos de su administración para acceder a nuestra plataforma.' : 'Editar Administración'}</p>
                <div className="p-md-offset-1 p-md-6 p-sm-12">
                    <InputText value={state.company.name} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                            onChange={(e) => dispatch({type: constants.COMPANY_NAME, value: e.target.value})} placeholder="Nombre"/>
                    <InputText value={state.company.address} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.COMPANY_ADDRESS, value: e.target.value})} placeholder="Dirección"/>
                    <InputText value={state.company.phone} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.COMPANY_PHONE, value: e.target.value})} placeholder="Telefonos"/>
                    <InputText value={state.company.mobile} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.COMPANY_MOBILE, value: e.target.value})} placeholder="Celulares"/>
                    <InputText value={state.company.rut} className="p-md-12 p-sm-12" style={{marginBottom:'2px'}}
                        onChange={(e) => dispatch({type: constants.COMPANY_RUT, value: e.target.value})} placeholder="Rut"/>
                    {
                        !isNew &&
                        <div className='p-md-12 p-sm-12'>
                            <FileUpload name="name" url="./upload" 
                                mode='basic'
                                maxFileSize={200000}
                                accept='image/*'
                                multiple={false}
                                customUpload={true} 
                                uploadHandler={(event) => upload(event)}
                                chooseLabel="Agregar Logo"/>
                        </div>
                    }
                    <div className='p-md-12 p-sm-12'>
                        <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning p-md-3" icon="pi pi-plus" iconPos="right" 
                            onClick={() => onSave()}/>
                    </div>
                    
                </div>
            </div>        
    );
}

export default CompanyDialog;
