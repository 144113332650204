import React, {useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import '../../css/Styles.css'

import * as constants from "../../Constants";
import * as action from "./actions/ComunicationAction"

const ComunicationDialog = ({ state, dispatch, isNew, fn }) => {
    
    useEffect(() => {
        if (!state.admin && (state.units.length == 1)){
            state.comunication.unit = state.units[0]; 
        }

        if (!isNew){
            state.types.map(item => {
                if (item.type === state.comunication.type){
                    state.comunication.typeSelected = item;
                }
            });
    
            state.statuses.map(item => {
                if (item.type === state.comunication.status){
                    state.comunication.statusSelected = item;
                }
            });
    
            if (state.comunication.unit){
                state.units.map(item => {
                    if (item.name    === state.comunication.unit.name){
                        state.comunication.unit = item;
                    }
                });
            }
           
            if (state.comunication.category){
                state.categories.map(item => {
                    if (item.id === state.comunication.category.id){
                        state.comunication.category = item;
                    }
                });
            }
        }

        state.typesByRole = state.admin ? [{'name': 'Consulta', 'type': 'HELP'},{'name': 'Reclamo', 'type': 'CLAIM'}, {'name': 'Comunicado', 'type': 'NOTICE'}] : 
        [{'name': 'Consulta', 'type': 'HELP'},{'name': 'Reclamo', 'type': 'CLAIM'}]
        
    }, []
    );
    

    const onSave = () => {
        action.saveComunication(state, isNew, dispatch, fn)
    }

    return (
        <form className="p-fluid" onSubmit={onSave}>
           <div className="p-grid">
            <div className="p-md-offset-2 p-md-8 p-sm-12">
                <InputText value={state.comunication.title} style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.COMUNICATION_TITLE, value: e.target.value})} placeholder="Titulo" required />
                <Dropdown optionLabel="name" value={state.comunication.typeSelected} options={state.typesByRole} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.comunication.typeSelected ? '3px solid salmon' : ''}}
                    onChange={(e) => dispatch({type: constants.COMUNICATION_TYPE_SELECTED, value: e.value})} placeholder="Tipo" required/>
                <Dropdown optionLabel="name" value={state.comunication.category} options={state.categories} className="p-md-3 p-sm-12 " style={{marginBottom:'5px',borderLeft: !state.comunication.category ? '3px solid salmon' : ''}}
                    onChange={(e) => dispatch({type: constants.COMUNICATION_CATEGORY_SELECTED, value: e.value})} placeholder="Categoria" required/>
                { state.admin && 
                    <Dropdown optionLabel="name" value={state.comunication.statusSelected} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.comunication.statusSelected ? '3px solid salmon' : ''}}
                    onChange={(e) => dispatch({type: constants.COMUNICATION_STATUS_SELECTED, value: e.value})} placeholder="Estado" required/>
                }
                <InputTextarea rows={5} cols={20} value={(state.comunication.description || state.comunication.description != null) ? state.comunication.description : ''} placeholder="Detalle" 
                        autoResize={true} onChange={(e) => dispatch({type: constants.COMUNICATION_DESCRIPTION, value: e.target.value})}/>
                { (state.admin || state.units.length > 1) &&
                    <Dropdown optionLabel="name" value={state.comunication.unit} options={state.units} className="p-md-3 p-sm-12" style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.COMUNICATION_UNIT_SELECTED, value: e.value})} placeholder="Unidad"/>
                }
            </div>
        </div>

        <div className="p-grid">
            <div className="p-md-4 p-sm-12">
                <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
            </div>
        </div>    
    </form>
    );
}

export default ComunicationDialog;
