import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const search = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/accounts/` + state.building.id, null)
    )
    .then(response => {
        state.message = undefined;
        fn(false);
        dispatch({ type: constants.MOVEMENT_ACCOUNT_SEARCH, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.MOVEMENT_ACCOUNT_SEARCH, dispatch, false);
    })  
}

export const saveMovementAccount = (state, isNew, dispatch, fn) => { 
    state.movementAccount.buildingId = state.building.id;
    axios(
        access.build('post', '/api/accounts/', state.movementAccount)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined){
                message = isNew ? 'Caja creada correctamente.' : 'Caja modificada correctamente.';
            } else {
                message = response.data.message;    
            }
            
            search(state, dispatch, fn);
            state.movementAccount = {};
            fn(false)
            severity = 'info';
        } else {
            message = 'Error al guardar.';
            severity = 'error';
            fn(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedMovementAccount = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/accounts/' + state.movementAccount.id,  null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Caja eliminado correctamente.';
            } else {
                message = response.data.message;    
            }

            search(state, dispatch, fn);
            state.movementAccount = {};
            severity = 'info';
            fn(false)
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }

        
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}