import * as constants from "../../../Constants";
import axios from "axios";
import * as utils from "../../../components/Utils"
import * as access from "../../AccessAction";

export const login = (state, dispatch, fn, fnAssingPending) => {   
    let token = window.btoa(state.user.email+':'+state.user.password); 
    let header = {
        'Authorization': token
    }  
    axios({
        method: 'post',
        url: '/api/login',
        headers: header
    })
    .then((response) => {
        if (response.status === 200) {
            if (utils.isSupplierByUser(response.data.user) || response.data.companyId){
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('companyId', response.data.companyId);
                localStorage.setItem("forbidden", false);
                localStorage.setItem('buildings', '[]');
                localStorage.setItem("building", null);
                console.log(response.data)
                if (utils.isAdminByUser(response.data.user)){
                    getBuildingsByAdmin(response, state, dispatch);
                } else if (utils.isUser(response.data.user)){
                        localStorage.setItem('buildings', JSON.stringify(response.data.completed.buildings));
                        if (response.data.completed.onlyBuilding){
                            localStorage.setItem('building', JSON.stringify(response.data.completed.buildings[0]));
                        }
                        
                        dispatch({ type: constants.LOGIN, value: response.data }); 
                } else {
                        dispatch({ type: constants.LOGIN, value: response.data });
                }
            } else if (utils.isUser(response.data.user)){
                fn(false)
                fnAssingPending(true)
                dispatch({ type: constants.LOGIN_ERROR_USER, value: response.data.user })
            } else {
                fn(false)
                dispatch({ type: constants.LOGIN_ERROR, value: 'Aun no se encuentra asociado a alguna administración. Por favor contacte a su administrador.' })
            }
        }
    })
    .catch((e) => {
        fn(false)
        dispatch({ type: constants.LOGIN_ERROR, value: 'Valide sus datos nuevamente.' });
    });
}

const getBuildingsByAdmin = (response, state, dispatch) => {
    if (response.data.companyId != undefined){
        axios(
            access.build('get', `/api/buildings/company/${response.data.companyId}`, null)
        )
        .then(response_building => {
            localStorage.setItem('buildings', JSON.stringify(response_building.data));
            dispatch({ type: constants.LOGIN, value: response.data }); 
        })
        .catch((e) => {
            dispatch({ type: constants.LOGIN, value: 'Errror al obtener los edificios asociado al usuario.' });
        })
    } else {
        dispatch({ type: constants.LOGIN, value: true });
    }
}

export const register = (state, dispatch, fn) => {
    if (state.register.password != state.register.reply){
        dispatch({ type: constants.REGISTER_ERROR, value: 'No coincide las contraseñas.' });
    } else {
        axios({
            method: 'post',
            url: '/api/register/',
            data: state.register
        })
        .then((response) => {
            fn(false);
            if (response.status === 200) {
                dispatch({ type: constants.REGISTER, value: true });    
            }
        })
        .catch((e) => {
            fn(false);
            let message = (e.response.status === 500) ? e.response.data.message : 'Valide sus datos nuevamente.'
            dispatch({ type: constants.REGISTER_ERROR, value: message });
        });
    }
}

export const verify = (state, dispatch, fn) => {
    let data = {
        'user': state.register,
        'verify_code': state.verifyCode
    }
    axios({
        method: 'post',
        url: '/api/register/verify',
        data: data
    })
    .then((response) => {
        fn(false)
        if (response.status === 200) {
            dispatch({ type: constants.REGISTER_FORWARD_LOGIN });
        }
    })
    .catch((e) => {
        fn(false)
        let message = (e.response.status === 500) ? 'Error al verificar codigo' : 'Valide sus datos nuevamente.'
        dispatch({ type: constants.REGISTER_VERIFY_ERROR, value: message });
    });
}

export const contact = (state, dispatch, fn) => {
    let data = {
        'user': state.user
    }
    axios({
        method: 'post',
        url: '/api/register/contact',
        data: data
    })
    .then(() => {
        fn(false)
        let message = 'Gracias por enviar su contacto.';
        dispatch({ type: constants.LOGIN_ERROR_USER, message: message, value: state.user });
    })
    .catch((e) => {
        fn(false)
        let message = 'Error al enviar información. Por favor enviar su contacto por mail a info@woloolo.com.';
        dispatch({ type: constants.LOGIN_ERROR_USER, messageError: message, value: state.user });
    });
}