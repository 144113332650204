import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const search = (search, state, dispatch) => {
    if ((search.first_name === undefined || search.first_name.length == 0) &&
        (search.last_name === undefined || search.last_name.length == 0) &&
        (search.email === undefined || search.email.length == 0)){
            searchPeoples(state, dispatch);
    } else {
        axios(
            access.build('post', `/api/peoples/filter/` + state.companyId, search)
         )
        .then(response => {
            dispatch({ type: constants.PEOPLE_SEARCH, value: response.data});    
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.PEOPLE_SEARCH, dispatch, false);
        })
    }
    
}

export const unitsByPeople = (people, state, dispatch) => {  
    axios(
        access.build('get', `/api/peoples/units/` + people.id, null)
    )
    .then(response => {
        dispatch({ type: constants.PEOPLE_UNIT, value: response.data });    
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.PEOPLE_UNIT, dispatch, false);
    })    
}

export const savePeople = (state, isNew, dispatch) => {
    state.people.companyId = state.companyId;
    let type = isNew ? constants.PEOPLE_DIALOG : constants.PEOPLE_DIALOG_SELECTED;

    if (state.people.oType){
        state.people.peopleType = state.people.oType.code;
    }
    
    axios(
        access.build('post', `/api/peoples/`, state.people)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (isNew){
                message = 'Persona creada correctamente.';
            } else {
                state.selectedPeople = false;
                message = 'Persona modificada correctamente.';
            }

            state.people = {};
            severity = 'info';
        } else {
            message = 'Error al guardar.';
            severity = 'error';
        }
        access.responseData(severity, message, state, type , dispatch, false);

    })
    .catch((e) => {
        access.responseData('error', e.message, state, type, dispatch, false);
    });
}

export const searchPeoples = (state, dispatch) => {  
    axios(
        access.build('get', `/api/peoples/company/` + state.companyId, null)
    )
    .then(response => {
        dispatch({ type: constants.PEOPLE_SEARCH, value: response.data });    
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.PEOPLE_SEARCH, dispatch, false);
    })    
}

export const upload = (event, state, dispatch, fn) => {
    const data = new FormData();
        data.append('file', event.files[0]);
        data.append('name', 'payment');    
        axios(
            access.build('post', `/api/peoples/upload/${state.building.id}`  , data)
        )
        .then((response) => {
            let message, severity;
            if (response.status === 200) {
                message = 'Archivo cargado correctamente.';
    
                state.load = {};
                severity = 'info';
            } else {
                message = 'Error al guardar.';
                severity = 'error';
            }

            let valid = response.data.length > 0; 
            fn(valid);
            
            if (valid){
                let errors = [];
                response.data.map((error) => {
                    errors.push(JSON.parse(error));
                });

                dispatch({ type: constants.PEOPLE_UPLOAD, value: errors }); 
            } else {
                access.responseData(severity, message, state, constants.PEOPLE_UPLOAD , dispatch, false);
            }
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.PEOPLE_UPLOAD, dispatch, false);
        });
}