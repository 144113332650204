/* Company */

export const COMPANY_LOAD = 'COMPANY_LOAD';
export const COMPANY_NAME = 'COMPANY_NAME';
export const COMPANY_ADDRESS = 'COMPANY_ADDRESS';
export const COMPANY_PHONE = 'COMPANY_PHONE';
export const COMPANY_MOBILE = 'COMPANY_MOBILE';
export const COMPANY_RUT = 'COMPANY_RUT';
export const COMPANY_IPC = 'COMPANY_IPC';
export const COMPANY_DIALOG = 'COMPANY_DIALOG';
export const COMPANY_DIALOG_SELECTED = 'COMPANY_DIALOG_SELECTED';
export const COMPANY_BUILDING_LOAD = 'COMPANY_BUILDING_LOAD';
export const COMPANY_ICON = 'COMPANY_ICON';
export const AGENT_SELECTED = 'AGENT_SELECTED';
export const AGENT_FIRST_NAME = 'AGENT_FIRST_NAME';
export const AGENT_LAST_NAME = 'AGENT_LAST_NAME';
export const AGENT_EMAIL = 'AGENT_EMAIL';
export const AGENT_BUILDING_SELECTED = 'AGENT_BUILDING_SELECTED';
export const AGENT_BUILDING_ELIMINATED_SELECTED = 'AGENT_BUILDING_ELIMINATED_SELECTED';
export const AGENT_BUILDING_ELIMINATED_SAVE = 'AGENT_BUILDING_ELIMINATED_SAVE';
export const COMPANY_EVENT = 'COMPANY_EVENT';

/* Building */
export const BUILDING_NAME = 'BUILDING_NAME';
export const BUILDING_ADDRESS = 'BUILDING_ADDRESS';
export const BUILDING_PHONE = 'BUILDING_PHONE';
export const BUILDING_MOBILE = 'BUILDING_MOBILE';
export const BUILDING_CODE = 'BUILDING_CODE';
export const BUILDING_DIALOG = 'BUILDING_DIALOG';
export const BUILDING_TYPE = 'BUILDING_TYPE';
export const BUILDING_EXECUTION_TYPE = 'BUILDING_EXECUTION_TYPE';
export const BUILDING_NOTE = 'BUILDING_NOTE';
export const BUILDING_CLOSE_DAY = 'BUILDING_CLOSE_DAY';
export const BUILDING_PAY_DAY = 'BUILDING_PAY_DAY';
export const BUILDING_CHECK_ELIMINATED = 'BUILDING_CHECK_ELIMINATED';
export const BUILDING_SELECTED = 'BUILDING_SELECTED';
export const BUILDING_DATA_SELECTED = 'BUILDING_DATA_SELECTED';
export const BUILDING_SERVICE_NAME = 'BUILDING_SERVICE_NAME';
export const BUILDING_SERVICE_ADD = 'BUILDING_SERVICE_ADD';
export const BUILDING_SERVICE_ELIMINATED_SAVE = 'BUILDING_SERVICE_ELIMINATED_SAVE';
export const BUILDING_DASHBOARD_ACCESS_DENIED = 'BUILDING_DASHBOARD_ACCESS_DENIED';
export const BUILDING_DASHBOARD_REDIRECT = 'BUILDING_DASHBOARD_REDIRECT';
export const BUILDING_DASHBOARD_PERIOD_SELECTED = 'BUILDING_DASHBOARD_PERIOD_SELECTED';
export const BUILDING_DASHBOARD_SEARCH = 'BUILDING_DASHBOARD_SEARCH';
export const BUILDING_DASHBOARD_SIZE = 'BUILDING_DASHBOARD_SIZE';
export const BUILDING_ALERTS = 'BUILDING_ALERTS';
export const BUILDING_ALERT_PENDING_DAY = 'BUILDING_ALERT_PENDING_DAY';
export const BUILDING_ALERT_DEBTOR = 'BUILDING_ALERT_DEBTOR';
export const BUILDING_EXTERNAL_LOAD = 'BUILDING_EXTERNAL_LOAD';
export const BUILDING_EXTERNAL_ABITAB_COMPANY_NAME = 'BUILDING_EXTERNAL_ABITAB_COMPANY_NAME';
export const BUILDING_EXTERNAL_ABITAB_COMPANY_NR = 'BUILDING_EXTERNAL_ABITAB_COMPANY_NR';
export const BUILDING_EXTERNAL_ABITAB_SUBCOMPANY_NR = 'BUILDING_EXTERNAL_ABITAB_SUBCOMPANY_NR';
export const BUILDING_EXTERNAL_REDPAGO_COMPANY_NAME = 'BUILDING_EXTERNAL_REDPAGO_COMPANY_NAME';
export const BUILDING_EXTERNAL_REDPAGO_COMPANY_NR = 'BUILDING_EXTERNAL_REDPAGO_COMPANY_NR';
export const BUILDING_EXTERNAL_REDPAGO_SUBCOMPANY_NR = 'BUILDING_EXTERNAL_REDPAGO_SUBCOMPANY_NR';
export const BUILDING_COMUNICATION_SEARCH = 'BUILDING_COMUNICATION_SEARCH';
export const BUILDING_CURRENCY = 'BUILDING_CURRENCY';
export const BUILDING_INTERNAL_NOTE = 'BUILDING_INTERNAL_NOTE';

/* Personas */
export const PEOPLE_EMAIL_SEARCH = 'PEOPLE_EMAIL_SEARCH';
export const PEOPLE_FIRST_NAME_SEARCH = 'PEOPLE_FIRST_NAME_SEARCH';
export const PEOPLE_LAST_NAME_SEARCH = 'PEOPLE_LAST_NAME_SEARCH';
export const PEOPLE_SEARCH = 'PEOPLE_SEARCH';
export const PEOPLE_DIALOG = 'PEOPLE_DIALOG';
export const PEOPLE_DIALOG_SELECTED = 'PEOPLE_DIALOG_SELECTED';
export const PEOPLE_UNIT = 'PEOPLE_UNIT';
export const PEOPLE_BUILDING_SEARCH = 'PEOPLE_BUILDING_SEARCH';
export const PEOPLE_SELECTED = 'PEOPLE_SELECTED';
export const PEOPLE_FIRST_NAME = 'PEOPLE_FIRST_NAME';
export const PEOPLE_LAST_NAME = 'PEOPLE_LAST_NAME';
export const PEOPLE_DOCUMENT = 'PEOPLE_DOCUMENT';
export const PEOPLE_EMAIL = 'PEOPLE_EMAIL';
export const PEOPLE_PHONE = 'PEOPLE_PHONE';
export const PEOPLE_MOBILE = 'PEOPLE_MOBILE';
export const PEOPLE_TYPE = 'PEOPLE_TYPE';
export const PEOPLE_CHECK_ELIMINATED = 'PEOPLE_CHECK_ELIMINATED';
export const PEOPLE_UPLOAD = 'PEOPLE_UPLOAD';

/* Unidades */
export const UNIT_DIALOG = 'UNIT_DIALOG';
export const UNIT_BUILDING_SELECTED = 'UNIT_BUILDING_SELECTED';
export const UNIT_SAVE_BUILDING_SELECTED = 'UNIT_SAVE_BUILDING_SELECTED';
export const UNIT_BUILDING_SEARCH = 'UNIT_BUILDING_SEARCH';
export const UNIT_NAME = 'UNIT_NAME';
export const UNIT_SEARCH = 'UNIT_SEARCH';
export const UNIT_SELECTED = 'UNIT_SELECTED';
export const UNIT_LIST_PEOPLE = 'UNIT_LIST_PEOPLE';
export const UNIT_PEOPLES = 'UNIT_PEOPLES';
export const UNIT_LOAD_PEOPLES_SELECTED = 'UNIT_LOAD_PEOPLES_SELECTED';
export const UNIT_NOT_RENTED = 'UNIT_NOT_RENTED';

export const ACCESS_DENIED = 'ACCESS_DENIED';
export const REDIRECT = "REDIRECT";
export const DEFAULT = "DEFAULT";

/* Income and Expense */
export const EXPENSE_SELECTED = 'EXPENSE_SELECTED';
export const INCOME_SELECTED = 'INCOME_SELECTED';

/* Filter */
export const FILTER_DATE = 'FILTER_DATE';
export const FILTER_DATE_PAY = 'FILTER_DATE_PAY';
export const FILTER_CREATED = 'FILTER_CREATED';
export const FILTER_UNIT_SELECTED = 'FILTER_UNIT_SELECTED';
export const FILTER_DOCUMENT = 'FILTER_DOCUMENT';
export const FILTER_NAME = 'FILTER_NAME';
export const FILTER_CODE = 'FILTER_CODE';
export const FILTER_MOVEMENT_GROUP_SELECTED = 'FILTER_MOVEMENT_GROUP_SELECTED';
export const FILTER_SUPPLIER_SELECTED = 'FILTER_SUPPLIER_SELECTED';
export const FILTER_PAYMENT_TYPE_SELECTED = 'FILTER_PAYMENT_TYPE_SELECTED';
export const FILTER_EXPENSE_ID = 'FILTER_EXPENSE_ID';

/* Cash */
export const CASH_LOAD = 'CASH_LOAD';
export const CASH_UNITS = 'CASH_UNITS';
export const CASH_PERIOD_SELECTED = 'CASH_PERIOD_SELECTED';
export const CASH_UNIT_SELECTED = 'CASH_UNIT_SELECTED';
export const CASH_INCOME_UNIT_SELECTED = 'CASH_INCOME_UNIT_SELECTED';
export const CASH_INCOME_EXPIRES_SELECTED = 'CASH_INCOME_EXPIRES_SELECTED';
export const CASH_INCOME_MOVEMENT_GROUP_SELECTED = 'CASH_INCOME_MOVEMENT_GROUP_SELECTED';
export const CASH_INCOME_DESCRIPTION = 'CASH_INCOME_DESCRIPTION';
export const CASH_INCOME_NUMBER = 'CASH_INCOME_NUMBER';
export const CASH_INCOME_AMOUNT = 'CASH_INCOME_AMOUNT';
export const CASH_INCOME_OBSERVATIONS = 'CASH_INCOME_OBSERVATIONS';
export const CASH_INCOME_PEOPLE_TYPE = 'CASH_INCOME_PEOPLE_TYPE';
export const CASH_INCOME_SAVE = 'CASH_INCOME_SAVE';
export const CASH_EXPENSE_EXPIRES_SELECTED = 'CASH_EXPENSE_EXPIRES_SELECTED';
export const CASH_EXPENSE_MOVEMENT_GROUP_SELECTED = 'CASH_EXPENSE_MOVEMENT_GROUP_SELECTED';
export const CASH_EXPENSE_SUPPLIERS = 'CASH_EXPENSE_SUPPLIERS';
export const CASH_EXPENSE_SUPPLIER_SELECTED = 'CASH_EXPENSE_SUPPLIER_SELECTED';
export const CASH_EXPENSE_UPLOAD = 'CASH_EXPENSE_UPLOAD';
export const CASH_EXPENSE_DESCRIPTION = 'CASH_EXPENSE_DESCRIPTION';
export const CASH_EXPENSE_AMOUNT = 'CASH_EXPENSE_AMOUNT';
export const CASH_EXPENSE_NAME = 'CASH_EXPENSE_NAME';
export const CASH_EXPENSE_PEOPLE_TYPE = 'CASH_EXPENSE_PEOPLE_TYPE';
export const CASH_EXPENSE_SAVE = 'CASH_EXPENSE_SAVE';
export const CASH_SEARCH_INCOME = 'CASH_SEARCH_INCOME';
export const CASH_SEARCH_EXPENSE = 'CASH_SEARCH_EXPENSE';
export const CASH_SEARCH_PAYMENT = 'CASH_SEARCH_PAYMENT';
export const CASH_SEARCH_FILTER_PAYMENT = 'CASH_SEARCH_FILTER_PAYMENT';
export const CASH_SEARCH_PAYMENT_EXPENSE = 'CASH_SEARCH_PAYMENT_EXPENSE';
export const CASH_SEARCH_FILTER_PAYMENT_EXPENSE = 'CASH_SEARCH_FILTER_PAYMENT_EXPENSE';
export const CASH_SEARCH_PAYMENT_ELIMINATED = 'CASH_SEARCH_PAYMENT_ELIMINATED';
export const CASH_SEARCH_FILTER_PAYMENT_ELIMINATED = 'CASH_SEARCH_FILTER_PAYMENT_ELIMINATED';
export const CASH_SEARCH_FILTER_INCOME = 'CASH_SEARCH_FILTER_INCOME';
export const CASH_SEARCH_FILTER_EXPENSE = 'CASH_SEARCH_FILTER_EXPENSE';
export const CASH_SEARCH_RANGE_PERIOD = 'CASH_SEARCH_RANGE_PERIOD';
export const CASH_PAYMENT_SUMMARY_TOTAL = 'CASH_PAYMENT_SUMMARY_TOTAL';

/* MovementGroup */
export const MOVEMENT_GROUP_DIALOG = 'MOVEMENT_GROUP_DIALOG';
export const MOVEMENT_GROUP_DIALOG_SELECTED = 'MOVEMENT_GROUP_DIALOG_SELECTED';
export const MOVEMENT_GROUP_BUILDING_SELECTED = 'MOVEMENT_GROUP_BUILDING_SELECTED';
export const MOVEMENT_GROUP_SELECTED = 'MOVEMENT_GROUP_SELECTED';
export const MOVEMENT_GROUP_NAME = 'MOVEMENT_GROUP_NAME';
export const MOVEMENT_GROUP_TYPE = 'MOVEMENT_GROUP_TYPE';
export const MOVEMENT_GROUP_ACCOUNT = 'MOVEMENT_GROUP_ACCOUNT';
export const MOVEMENT_GROUP_CHECK_PRINT = 'MOVEMENT_GROUP_CHECK_PRINT';
export const MOVEMENT_GROUP_APPLY_COEF = 'MOVEMENT_GROUP_APPLY_COEF';
export const MOVEMENT_GROUP_BUILDING = 'MOVEMENT_GROUP_BUILDING';

export const EXPENSE = 'EXPENSE';
export const INCOME = 'INCOME';
export const DEBT = 'DEBT';

/* Information */
export const INFORMATION_LOAD = "INFORMATION_LOAD";

/* Access */
export const LOGIN = 'LOGIN';
export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';
export const LOGIN_USER_CONTACT = 'LOGIN_USER_CONTACT';
export const LOGIN_FORWARD_REGISTER = 'LOGIN_FORWARD_REGISTER';
export const REGISTER = 'REGISTER';
export const REGISTER_FORWARD_LOGIN = 'REGISTER_FORWARD_LOGIN';
export const REGISTER_NAME = 'REGISTER_NAME';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const REGISTER_PASSWORD = 'REGISTER_PASSWORD';
export const REGISTER_REPLY_PASSWORD = 'REGISTER_REPLY_PASSWORD';
export const REGISTER_ROLE = 'REGISTER_ROLE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_VERIFY_CODE = 'REGISTER_VERIFY_CODE';
export const REGISTER_VERIFY_ERROR = 'REGISTER_VERIFY_ERROR';

/* Comunication */
export const COMUNICATION_CATEGORY_LOAD = 'COMUNICATION_CATEGORY_LOAD';
export const COMUNICATION_COMPANY_LOAD = 'COMUNICATION_COMPANY_LOAD';
export const COMUNICATION_LOAD = 'COMUNICATION_LOAD';
export const COMUNICATION_SUPPLIERS = 'COMUNICATION_SUPPLIERS';
export const COMUNICATION_SUMMARY = 'COMUNICATION_SUMMARY';
export const COMUNICATION_TITLE = 'COMUNICATION_TITLE';
export const COMUNICATION_DESCRIPTION = 'COMUNICATION_DESCRIPTION';
export const COMUNICATION_SELECTED = 'COMUNICATION_SELECTED';
export const COMUNICATION_FILTER_AGENT_SELECTED = 'COMUNICATION_FILTER_AGENT_SELECTED';
export const COMUNICATION_FILTER_CATEGORY_SELECTED = 'COMUNICATION_FILTER_CATEGORY_SELECTED';
export const COMUNICATION_FILTER_TYPE_SELECTED = 'COMUNICATION_FILTER_TYPE_SELECTED';
export const COMUNICATION_FILTER_STATUS_SELECTED = 'COMUNICATION_FILTER_STATUS_SELECTED';
export const COMUNICATION_FILTER_UNIT_SELECTED = 'COMUNICATION_FILTER_UNIT_SELECTED';
export const COMUNICATION_FILTER_SUPPLIER_SELECTED = 'COMUNICATION_FILTER_SUPPLIER_SELECTED';
export const COMUNICATION_FILTER_DATE = 'COMUNICATION_FILTER_DATE';
export const COMUNICATION_CATEGORY_SELECTED = 'COMUNICATION_CATEGORY_SELECTED';
export const COMUNICATION_TYPE_SELECTED = 'COMUNICATION_TYPE_SELECTED';
export const COMUNICATION_STATUS_SELECTED = 'COMUNICATION_STATUS_SELECTED';
export const COMUNICATION_UNIT_SELECTED = 'COMUNICATION_UNIT_SELECTED';

/* Note */
export const COMUNICATION_NOTES = 'COMUNICATION_NOTES';
export const COMUNICATION_NOTE_AGENT = 'COMUNICATION_NOTE_AGENT';
export const COMUNICATION_NOTE_SUPPLIER = 'COMUNICATION_NOTE_SUPPLIER';
export const COMUNICATION_NOTE_MESSAGE = 'COMUNICATION_NOTE_MESSAGE';

/* Payment */
export const CHANGE_PAYMENT_NAME = 'CHANGE_PAYMENT_NAME';
export const CHANGE_PAYMENT_DESCRIPTION = 'CHANGE_PAYMENT_DESCRIPTION';
export const CHANGE_PAYMENT_CODE = 'CHANGE_PAYMENT_CODE';
export const CHANGE_PAYMENT_DETAIL_CODE = 'CHANGE_PAYMENT_DETAIL_CODE';
export const CHANGE_PAYMENT_LASTNAME = 'CHANGE_PAYMENT_LASTNAME';
export const CHANGE_PAYMENT_DOCUMENT = 'CHANGE_PAYMENT_DOCUMENT';
export const CHANGE_PAYMENT_TOTAL = 'CHANGE_PAYMENT_TOTAL';
export const CHANGE_PAYMENT_PAY = 'CHANGE_PAYMENT_PAY';
export const CHANGE_PAYMENT_DETAIL_PAY = 'CHANGE_PAYMENT_DETAIL_PAY';
export const CHANGE_PAYMENT_DETAIL_DATE = 'CHANGE_PAYMENT_DETAIL_DATE';
export const CHANGE_PAYMENT_EXECUTION_ID = 'CHANGE_PAYMENT_EXECUTION_ID';
export const CHANGE_PAYMENT_DATE = 'CHANGE_PAYMENT_DATE';
export const CHANGE_PAYMENT_AMOUNT_ACCOUNT = 'CHANGE_PAYMENT_AMOUNT_ACCOUNT';
export const CHANGE_PAYMENT_UNIT_SELECTED = 'CHANGE_PAYMENT_UNIT_SELECTED';
export const CHANGE_PEOPLE_TYPE = 'CHANGE_PEOPLE_TYPE';
export const PAYMENT_TYPE_SELECTED = 'PAYMENT_TYPE_SELECTED';
export const PAYMENT_DETAIL_TYPE_SELECTED = 'PAYMENT_DETAIL_TYPE_SELECTED';
export const PAYMENT_SEARCH = 'PAYMENT_SEARCH';
export const PAYMENT_LOAD = 'PAYMENT_LOAD';
export const PAYMENT_DIALOG = 'PAYMENT_DIALOG';
export const PAYMENT_SELECTED = 'PAYMENT_SELECTED';
export const CHANGE_PAYMENT_UNIT_UNDEFINED = 'CHANGE_PAYMENT_UNIT_UNDEFINED';

/* Payment expense */
export const PAYMENT_EXPENSE_EXPIRES_SELECTED = 'PAYMENT_EXPENSE_EXPIRES_SELECTED';
export const PAYMENT_EXPENSE_MOVEMENT_GROUP_SELECTED = 'PAYMENT_EXPENSE_MOVEMENT_GROUP_SELECTED';
export const PAYMENT_EXPENSE_UPLOAD = 'PAYMENT_EXPENSE_UPLOAD';
export const PAYMENT_EXPENSE_DESCRIPTION = 'PAYMENT_EXPENSE_DESCRIPTION';
export const PAYMENT_EXPENSE_AMOUNT = 'PAYMENT_EXPENSE_AMOUNT';
export const PAYMENT_EXPENSE_NAME = 'PAYMENT_EXPENSE_NAME';
export const PAYMENT_EXPENSE_DOCUMENT = 'PAYMENT_EXPENSE_DOCUMENT';
export const PAYMENT_EXPENSE_ID = 'PAYMENT_EXPENSE_ID';
export const PAYMENT_EXPENSE_PEOPLE_TYPE = 'PAYMENT_EXPENSE_PEOPLE_TYPE';
export const PAYMENT_EXPENSE_SAVE = 'PAYMENT_EXPENSE_SAVE';
export const PAYMENT_EXPENSE_LOAD = 'PAYMENT_EXPENSE_LOAD';
export const PAYMENT_EXPENSE_SUPPLIER_SELECTED = 'PAYMENT_EXPENSE_SUPPLIER_SELECTED';
export const PAYMENT_EXPENSE_SELECTED = 'PAYMENT_EXPENSE_SELECTED';
export const SEARCH_PAYMENT_EXPENSES = 'SEARCH_PAYMENT_EXPENSES';

/* Payment load*/
export const PAYMENT_LOAD_EXT_SELECTED = 'PAYMENT_LOAD_EXT_SELECTED';
export const PAYMENT_LOAD_TYPE_SELECTED = 'PAYMENT_LOAD_TYPE_SELECTED';
export const PAYMENT_LOAD_SELECTED = 'PAYMENT_LOAD_SELECTED';

/* History */
export const HISTORY_PAYMENT_FILTER_TYPE_SELECTED = 'HISTORY_PAYMENT_FILTER_TYPE_SELECTED';
export const HISTORY_PAYMENT_FILTER_STATUS_SELECTED = 'HISTORY_PAYMENT_FILTER_STATUS_SELECTED';
export const HISTORY_PAYMENT_SEARCH = 'HISTORY_PAYMENT_SEARCH';

/* Liquidation */
export const LIQUIDATION_PERIOD_SELECTED = 'LIQUIDATION_PERIOD_SELECTED';
export const LIQUIDATION_SEARCH = 'LIQUIDATION_SEARCH';
export const LIQUIDATION_BUILDING_SELECTED = 'LIQUIDATION_BUILDING_SELECTED';
export const LIQUIDATION_DATE = 'LIQUIDATION_DATE';
export const LIQUIDATION_EXECUTION_REF_ID = 'LIQUIDATION_EXECUTION_REF_ID';

/* Supplier */
export const SUPPLIER_BUILDING = 'SUPPLIER_BUILDING';
export const SUPPLIER_SELECTED = 'SUPPLIER_SELECTED';
export const SUPPLIER_NAME = 'SUPPLIER_NAME';
export const SUPPLIER_EMAIL = 'SUPPLIER_EMAIL';
export const SUPPLIER_PHONE = 'SUPPLIER_PHONE';
export const SUPPLIER_MOBILE = 'SUPPLIER_MOBILE';
export const SUPPLIER_RUT = 'SUPPLIER_RUT';
export const SUPPLIER_ADDRESS = 'SUPPLIER_ADDRESS';
export const SUPPLIER_TYPE = 'SUPPLIER_TYPE';
export const SUPPLIER_BANK_NAME = 'SUPPLIER_BANK_NAME';
export const SUPPLIER_BANK_TYPE_ACCOUNT = 'SUPPLIER_BANK_TYPE_ACCOUNT';
export const SUPPLIER_BANK_STORE = 'SUPPLIER_BANK_STORE';
export const SUPPLIER_BANK_NUMBER_ACCOUNT = 'SUPPLIER_BANK_NUMBER_ACCOUNT';
export const SUPPLIER_PAYMENT_TYPE = 'SUPPLIER_PAYMENT_TYPE';
export const SUPPLIER_ELIMINATED = 'SUPPLIER_ELIMINATED';
export const SUPPLIER_PAYMENTS = 'SUPPLIER_PAYMENTS';
export const SUPPLIER_FILTER_DATE = 'SUPPLIER_FILTER_DATE';
export const SUPPLIER_FILTER_DOCUMENT = 'SUPPLIER_FILTER_DOCUMENT';

/* Work */
export const WORK_SUMMARY = 'WORK_SUMMARY';
export const WORK_FILTER_DATE = 'WORK_FILTER_DATE';
export const WORK_SELECTED = 'WORK_SELECTED';
export const WORK_STATUS_SELECTED = 'WORK_STATUS_SELECTED';
export const WORK_DESCRIPTION = 'WORK_DESCRIPTION';
export const WORK_CLOSED = 'WORK_CLOSED';

/* Price */
export const PRICE_NAME = 'PRICE_NAME';
export const PRICE_DESCRIPTION = 'PRICE_DESCRIPTION';
export const PRICE_STATUS_SELECTED = 'PRICE_STATUS_SELECTED';
export const PRICE_INGRESS = 'PRICE_INGRESS';
export const PRICE_WORK_ID = 'PRICE_WORK_ID';
export const PRICE_SUPPLIER_SELECTED = 'PRICE_SUPPLIER_SELECTED';
export const PRICE_ELIMINATED = 'PRICE_ELIMINATED';
export const PRICE_SELECTED = 'PRICE_SELECTED';
export const PRICE_FILTER_DATE = 'PRICE_FILTER_DATE';
export const PRICE_FILTER_WORK = 'PRICE_FILTER_WORK';
export const PRICE_FILTER_SUPPLIER = 'PRICE_FILTER_SUPPLIER';
export const PRICE_UPLOAD = 'PRICE_UPLOAD';
export const PRICE_LOAD = 'PRICE_LOAD';

/* Upkeep */
export const UPKEEP_NAME = 'UPKEEP_NAME';
export const UPKEEP_DESCRIPTION = 'UPKEEP_DESCRIPTION';
export const UPKEEP_STATUS_SELECTED = 'UPKEEP_STATUS_SELECTED';
export const UPKEEP_INGRESS = 'UPKEEP_INGRESS';
export const UPKEEP_PRICE_ID = 'UPKEEP_PRICE_ID';
export const UPKEEP_SUPPLIER_SELECTED = 'UPKEEP_SUPPLIER_SELECTED';
export const UPKEEP_ELIMINATED = 'UPKEEP_ELIMINATED';
export const UPKEEP_SELECTED = 'UPKEEP_SELECTED';
export const UPKEEP_FILTER_DATE = 'UPKEEP_FILTER_DATE';
export const UPKEEP_FILTER_PRICE = 'UPKEEP_FILTER_PRICE';
export const UPKEEP_FILTER_SUPPLIER = 'UPKEEP_FILTER_SUPPLIER';
export const UPKEEP_LOAD = 'UPKEEP_LOAD';

/* Event */
export const EVENT_LOAD = 'EVENT_LOAD'
export const EVENT_PLACE = 'EVENT_PLACE'
export const EVENT_START = 'EVENT_START'
export const EVENT_TITLE = 'EVENT_TITLE'
export const EVENT_PEOPLE = 'EVENT_PEOPLE'

/* Security */
export const USER_SECURITY_LOAD = 'USER_SECURITY_LOAD';
export const USER_SECURITY_OLD = 'USER_SECURITY_OLD';
export const USER_SECURITY_NEW = 'USER_SECURITY_NEW';

/* Discount and tax */
export const PAYMENT_DISCOUNT_LOAD = 'PAYMENT_DISCOUNT_LOAD';
export const PAYMENT_DISCOUNT_SELECTED = 'PAYMENT_DISCOUNT_SELECTED';
export const PAYMENT_DISCOUNT_DESCRIPTION = 'PAYMENT_DISCOUNT_DESCRIPTION';
export const PAYMENT_DISCOUNT_PEOPLE_TYPE = 'PAYMENT_DISCOUNT_PEOPLE_TYPE';
export const PAYMENT_DISCOUNT_AMOUNT = 'PAYMENT_DISCOUNT_AMOUNT'
export const PAYMENT_TAX_LOAD = 'PAYMENT_TAX_LOAD';
export const PAYMENT_TAX_SELECTED = 'PAYMENT_TAX_SELECTED';
export const PAYMENT_TAX_AMOUNT = 'PAYMENT_TAX_AMOUNT';
export const PAYMENT_TAX_DESCRIPTION = 'PAYMENT_TAX_DESCRIPTION';

/* Coeficientes */
export const COEFFICIENT_SEARCH = 'COEFFICIENT_SEARCH';
export const COEFFICIENT_SELECTED = 'COEFFICIENT_SELECTED';
export const COEFFICIENT_PERCENTAGE = 'COEFFICIENT_PERCENTAGE';
export const COEFFICIENT_UNIT_SELECTED = 'COEFFICIENT_UNIT_SELECTED';
export const COEFFICIENT_SUMMARY = 'COEFFICIENT_SUMMARY';
export const COEFFICIENT_LOAD_UNITS = 'COEFFICIENT_LOAD_UNITS';

/* Cajas */
export const MOVEMENT_ACCOUNT_SELECTED = 'MOVEMENT_ACCOUNT_SELECTED';
export const MOVEMENT_ACCOUNT_SEARCH = 'MOVEMENT_ACCOUNT_SEARCH';
export const MOVEMENT_ACCOUNT_NAME = 'MOVEMENT_ACCOUNT_NAME';
export const MOVEMENT_ACCOUNT_PRIORITY = 'MOVEMENT_ACCOUNT_PRIORITY';
export const MOVEMENT_ACCOUNT_GROUPTYPE = 'MOVEMENT_ACCOUNT_GROUPTYPE';
export const MOVEMENT_ACCOUNT_INCOME = 'MOVEMENT_ACCOUNT_INCOME';
export const MOVEMENT_ACCOUNT_EXPENSE = 'MOVEMENT_ACCOUNT_EXPENSE';
export const MOVEMENT_ACCOUNT_ALLOW_DELETE = 'MOVEMENT_ACCOUNT_ALLOW_DELETE';
export const CHANGE_AMOUNT_ACCOUNT = 'CHANGE_AMOUNT_ACCOUNT';

/* CommonAreas */
export const COMMON_AREA_SEARCH = 'COMMON_AREA_SEARCH';
export const COMMON_AREA_RESERVATION_SEARCH = 'COMMON_AREA_RESERVATION_SEARCH';
export const COMMON_AREA_SELECTED = 'COMMON_AREA_SELECTED';
export const COMMON_AREA_NAME = 'COMMON_AREA_NAME';
export const COMMON_AREA_DESCRIPTION = 'COMMON_AREA_DESCRIPTION';
export const COMMON_AREA_COST = 'COMMON_AREA_COST';
export const COMMON_AREA_MOVEMENT_GROUP = 'COMMON_AREA_MOVEMENT_GROUP';
export const COMMON_AREA_BOOKING_LIMIT = 'COMMON_AREA_BOOKING_LIMIT';
export const COMMON_AREA_BOOKING_MAX = 'COMMON_AREA_BOOKING_MAX';
export const COMMON_AREA_CHECK_ADMIN = 'COMMON_AREA_CHECK_ADMIN';
export const COMMON_AREA_CHECK_DEBTOR_UNIT = 'COMMON_AREA_CHECK_DEBTOR_UNIT';
export const COMMON_AREA_RESERVATION_PER_USER = 'COMMON_AREA_RESERVATION_PER_USER';
export const COMMON_AREA_ANTICIPATION_DAY = 'COMMON_AREA_ANTICIPATION_DAY';
export const COMMON_AREA_RESERVATION_MAX_DAY = 'COMMON_AREA_RESERVATION_MAX_DAY';
export const COMMON_AREA_RESERVATION_DAY = 'COMMON_AREA_RESERVATION_DAY';

/* Reservation */
export const RESERVATION_SEARCH = 'RESERVATION_SEARCH';
export const RESERVATION_SELECTED = 'RESERVATION_SELECTED';
export const RESERVATION_SUMMARY = 'RESERVATION_SUMMARY';
export const RESERVATION_START = 'RESERVATION_START';
export const RESERVATION_END = 'RESERVATION_END';
export const RESERVATION_COMMON_AREA_SELECTED = 'RESERVATION_COMMON_AREA_SELECTED';
export const RESERVATION_UNIT_SELECTED = 'RESERVATION_UNIT_SELECTED';
export const RESERVATION_STATUS_SELECTED = 'RESERVATION_STATUS_SELECTED';
export const RESERVATION_PEOPLE_TYPE = 'RESERVATION_PEOPLE_TYPE';
export const RESERVATION_FILTER_DATE = 'RESERVATION_FILTER_DATE';
export const RESERVATION_FILTER_COMMON_AREA_SELECTED = 'RESERVATION_FILTER_COMMON_AREA_SELECTED';
export const RESERVATION_FILTER_UNIT_SELECTED = 'RESERVATION_FILTER_UNIT_SELECTED';
export const RESERVATION_FILTER_STATUS_SELECTED = 'RESERVATION_FILTER_STATUS_SELECTED';