export const page = 'assets/pages/landing.html';

export const es = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sm'
};

export const appendZeroes = (n) => {
    if(n <= 9){
      return "0" + n;
    }
    return n
}

export const typePayments = [{'name': 'DEPOSITO BUZONERA', 'type': 'DEPOSIT'}, {'name': 'BROU', 'type': 'BROU'}, {'name': 'SCOTIABANK', 'type': 'SCOTIABANK'}, 
        {'name': 'ITAU', 'type': 'ITAU'}, {'name': 'SANTADER', 'type': 'SANTANDER'}, 
        {'name': 'ABITAB', 'type': 'ABITAB'}, {'name': 'REDPAGOS', 'type': 'REDPAGOS'}, {'name': 'EFECTIVO', 'type': 'CASH'}]

export const currencies = [{currency: 'UYU'},{currency: 'USD'}]        

export const peopleTypes = [{name: 'Propietario', code: 'P'}, {name: 'Inquilino', code: 'I'}]