import * as constants from "../../../../Constants";
import axios from "axios";
import * as access from "../../../AccessAction";

export const saveMovementGroup = (state, isNew, dispatch, fn, setLoading) => { 
    setLoading(true)

    let type = isNew ? constants.MOVEMENT_GROUP_DIALOG : constants.MOVEMENT_GROUP_DIALOG_SELECTED;
    axios(
        access.build('post', '/api/movement/groups/' + state.building.id, state.movementGroup)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (isNew){
                message = 'Grupo de movimiento creada correctamente.';
            } else {
                state.selectedMovementGroup = false;
                message = 'Grupo de movimiento modificada correctamente.';
            }

            state.movementGroup = {};
            severity = 'info';
        } else {
            message = 'Error al guardar.';
            severity = 'error';
        }

        fn(false);
        search(null, state, dispatch, setLoading);
        access.responseData(severity, message, state, type, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, type, dispatch, false);
    });
}

export const eliminatedMovementGroup = (state, dispatch, fn, setLoading) => { 
    setLoading(true)

    axios(
        access.build('delete', '/api/movement/groups/' + state.movementGroup.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Grupo de movimiento eliminado correctamente.';
            }

            state.movementGroup = {};
            severity = 'info';
        } else {
            message = response.data.message;
            severity = 'error';
        }

        fn(false)
        search(null, state, dispatch, setLoading);
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const search = (pagination, state, dispatch, fn) => {
    let url = `/api/movement/groups/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false);
        dispatch({ type: constants.MOVEMENT_GROUP_BUILDING_SELECTED, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.MOVEMENT_GROUP_BUILDING_SELECTED, dispatch, false);
    })  
}


export const movementGroupsByType = (state, dispatch, type, fn) => {
    axios(
        access.build('get', `/api/movement/groups/` + state.building.id + `/` + type, null)
    )
    .then(response => {
        fn(false);
        dispatch({ type: constants.MOVEMENT_GROUP_BUILDING, value: response.data, typeGroup: type });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.MOVEMENT_GROUP_BUILDING_SELECTED, dispatch, false);
    })  
}

export const movementGroupsByTypeOnlineEvent = (state, dispatch, type) => {
    axios(
        access.build('get', `/api/movement/groups/` + state.building.id + `/` + type, null)
    )
    .then(response => {
        dispatch({ type: constants.MOVEMENT_GROUP_BUILDING, value: response.data, typeGroup: type });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.MOVEMENT_GROUP_BUILDING_SELECTED, dispatch, false);
    })  
}

export const movementGroupsAll = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/movement/groups/` + state.building.id + `?all=true`, null)
    )
    .then(response => {
        fn(false);
        dispatch({ type: constants.MOVEMENT_GROUP_BUILDING, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.MOVEMENT_GROUP_BUILDING, dispatch, false);
    })  
}