import React, {useReducer, useEffect, Fragment, useState} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import * as constants from "../../Constants";
import BuildingReducer from "./reducers/BuildingReducer";
import BuildingDialog from "./BuildingDialog";
import * as action from "./actions/BuildingAction"

const BuildingForm = ({ prevState, menuRightActive }) => {

    const [newBuilding, setNewBuilding] = useState(false)
    const [editdBuilding, setEditBuilding] = useState(false)

    useEffect(() => {
        action.buildingsByCompany(state.companyId, state, dispatch);
        }, []
    );

    const selectedBuildingEvent = (buildingValue) => {
        dispatch({type: constants.BUILDING_DATA_SELECTED, value: buildingValue});
        setEditBuilding(true);
    }

    const createdBuilding = () => {
        state.buildingData = {};
        setNewBuilding(true);
    }

    const [state, dispatch] = useReducer(BuildingReducer, {
        ...prevState,
        buildings: {},
        service: {},
        visibleService: false
    });

    
    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            <Dialog header="Editar edificio" visible={editdBuilding} style={{maxWidth:'50%'}} onHide={() => setEditBuilding(false)}>
                <BuildingDialog
                    state = {state}
                    isNew = {false}
                    fn = {setEditBuilding}
                    dispatch = {dispatch} />
            </Dialog>
            <Dialog header="Crear edificio" visible={newBuilding} onHide={() => setNewBuilding(false)}>
                <BuildingDialog
                    state = {state}
                    isNew = {true}
                    fn = {setNewBuilding}
                    dispatch = {dispatch} 
                />
            </Dialog>

            <div className={classNames({'menu-right-active': menuRightActive})}>
                <h2 className="title-content">Edificios</h2>

                <div className="box-default" style={{padding:'10px'}}>
                    <div className="p-grid p-md-6">
                        <Button label="Crear edificio" style={{marginBottom:'10px'}}
                            className="p-button-warning" icon="pi pi-plus" iconPos="right"
                            onClick={() => createdBuilding()} />
                    </div>
                    <DataTable value={state.buildings} paginator={true} rows={10} selectionMode="single" selection={state.selectedBuilding} 
                        onSelectionChange={e =>  selectedBuildingEvent(e.value)}
                        style={{marginTop:'10px', padding:'10px' }} >
                        <Column field="id" header="Identificador" sortable={true}/>
                        <Column field="name" header="Nombre" sortable={true}/>
                        <Column field="address" header="Dirección" sortable={true}/>
                        <Column field="quantityUnits" header="Cant. unidades"/>
                        <Column field="closeDay" header="Día cierre" sortable={true}/>
                    </DataTable>
                </div>                    
            </div>
        </Fragment>
    );
}

export default BuildingForm;
