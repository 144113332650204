import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import * as constants from "../../../Constants";
import * as action from "./actions/UpkeepAction";

const UpkeepDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        if (!isNew){
            state.statuses.map(item => {
                if (item.type === state.upkeep.status){
                    state.upkeep.statusSelected = item;
                }       
            });

            state.user.suppliers.map(item => {
                if (item.id === state.upkeep.supplierId){
                    state.upkeep.supplier = item;
                }       
            });
        }
        }, []
    );

    const onSave = () => {
        setLoading(true)
        action.saveUpkeep(state, dispatch, isNew, fn, setLoading)
    }

    return (
        <form className="p-fluid" onSubmit={onSave}>
            <div className="p-grid">
                <div className="p-md-offset-2 p-md-8 p-sm-12">
                    <InputText value={state.upkeep.name} style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.UPKEEP_NAME, value: e.target.value})} placeholder="Nombre" required />
                    <InputText value={state.upkeep.priceId} style={{marginBottom:'5px'}}
                        onChange={(e) => dispatch({type: constants.UPKEEP_PRICE_ID, value: e.target.value})} placeholder="Cotización relacionado (ingrese nro de referencia)" />
                    <Dropdown optionLabel="name" value={state.upkeep.supplier} options={state.user.suppliers} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.upkeep.supplier ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.UPKEEP_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor" required/>
                    <Dropdown optionLabel="name" value={state.upkeep.statusSelected} options={state.statuses} className="p-md-3 p-sm-12" style={{marginBottom:'5px', borderLeft: !state.upkeep.statusSelected ? '3px solid salmon' : ''}}
                        onChange={(e) => dispatch({type: constants.UPKEEP_STATUS_SELECTED, value: e.value})} placeholder="Estado" required/>
                    <InputTextarea rows={5} cols={20} value={(state.upkeep.description || state.upkeep.description != null) ? state.upkeep.description : ''} placeholder="Detalle" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.UPKEEP_DESCRIPTION, value: e.target.value})}/>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-md-4 p-sm-12">
                    <Button label={isNew ? 'Crear' : 'Guardar'} className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>
            </div> 
        </form>
     
    );
}

export default UpkeepDialog;
