import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {Calendar} from 'primereact/calendar';
import {FileUpload} from 'primereact/fileupload';
import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import * as action from "./actions/PaymentExpenseAction"
import * as actionSupplier from "../supplier/actions/SupplierAction";

const PaymentExpenseDialog = ({ state, dispatch, fn, setLoading }) => {

    useEffect(() => {
        state.paymentExpense = {
            payDate: new Date()
        }
        actionSupplier.suppliers(state, dispatch, constants.CASH_EXPENSE_SUPPLIERS);
      }, [])

    const upload = (event) => {
        dispatch({type: constants.PAYMENT_EXPENSE_UPLOAD, value: event.files[0]})
    };

    const onSave = () => {
        action.savePaymentExpense(state, dispatch, fn, setLoading);
    }

    const clear = () => {
        dispatch({type: constants.PAYMENT_EXPENSE_SUPPLIER_SELECTED, value: undefined});
    };

    function setNumberDecimal(event) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: constants.PAYMENT_EXPENSE_AMOUNT, value: amount})
    }

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-12">
                    <div className="p-md-6">
                        <Calendar locale={configurations.es} placeholder="Fecha" showIcon={true} value={state.paymentExpense.payDate} dateFormat="dd/mm/yy"
                            onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_EXPIRES_SELECTED, value: e.value})} required/>
                    </div>
                    <div className="p-md-12">
                        <InputText value={state.paymentExpense.name} className="p-md-12" placeholder="Descripción" 
                            onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_NAME, value: e.target.value})}/>
                    </div>
                    <div className="p-md-6">
                        <InputText value={state.paymentExpense.document} className="p-md-12" placeholder="Documento ref." 
                            onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_DOCUMENT, value: e.target.value})}/>
                    </div>
                    <div className="p-md-6">
                        <InputText value={state.paymentExpense.expenseId} className="p-md-12" placeholder="Ref. a pagar" 
                            onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_ID, value: e.target.value})}/>
                    </div>
                    <div className="p-md-6">
                        <InputText placeholder="Monto" tooltip={'Monto en ' + `${state.building.currency}`}
                            onChange={(e) => setNumberDecimal(e.target.value)} type="number"
                            min="1.00" step='0.01' required/>
                    </div>
                    
                    { state.building &&
                        <Fragment>
                            <div className="p-md-6">
                                <Dropdown optionLabel="name" value={state.paymentExpense.movementGroup} options={state.movementsByExpense}
                                    style={{borderLeft: !state.paymentExpense.movementGroup ? '3px solid salmon' : ''}} 
                                    onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_MOVEMENT_GROUP_SELECTED, value: e.value})} placeholder="Tipo" required/>
                            </div>
                            <div className="p-md-6">
                                <div className="p-md-9 inline" style={{marginLeft:'-5px'}}>
                                    <Dropdown optionLabel="name" value={state.paymentExpense.supplier} options={state.suppliers}
                                        onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor"/>
                                </div>
                                {
                                    state.paymentExpense.supplier &&
                                    <div className="p-md-2 inline">
                                        <i className="pi pi-trash" style={{ marginLeft: '10px',fontSize: '2em', color:'#f88d12', verticalAlign:'middle' }} onClick={() => clear()} title="Limpiar proveedor"></i>  
                                    </div>
                                }
                            
                            </div>
                        </Fragment>
                    }

                    <div className="p-md-12">
                        <FileUpload mode="basic" 
                            name="name" 
                            accept=".jpg, .png, .pdf" 
                            url="./upload"
                            mode="advanced"
                            maxFileSize={1000000} //1MB
                            customUpload={true} 
                            uploadHandler={(event) => upload(event)} 
                            chooseLabel="Seleccionar"
                            uploadLabel="Cargar"
                            cancelLabel="Cancelar"/>

                        {state.upload &&
                            <h3 className="title-content">Archivo cargado correctamente</h3>
                        }
                    </div>
                    <div className="p-md-12">
                        <InputTextarea rows={3} cols={20} value={state.paymentExpense.description} placeholder="Observaciones" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.PAYMENT_EXPENSE_DESCRIPTION, value: e.target.value})}/>
                    </div>

                </div>

                <div className="p-md-3">
                    <Button label="Guardar" className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default PaymentExpenseDialog;
