import * as constants from '../../../Constants';
import BuildingReducer from '../../building/reducers/BuildingReducer'

const LiquidationReducer = (state, action) => {
    switch (action.type) {
        case constants.LIQUIDATION_BUILDING_SELECTED:
            return {
                ...state,
                building: action.value
            };
        case constants.LIQUIDATION_PERIOD_SELECTED:
            return {
                ...state,
                period: action.value
            };
        case constants.LIQUIDATION_SEARCH:
            return {
                ...state,
                liquidations: action.value.content,
                totals: action.value.totalElements
            };
        case constants.LIQUIDATION_DATE:
            return {
                ...state,
                liquidationDate: action.value
            };
        case constants.LIQUIDATION_EXECUTION_REF_ID:
            return {
                ...state,
                executionRefId: action.value
            };
        default: return BuildingReducer(state, action);
    }
};

export default LiquidationReducer;