import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const suppliers = (state, dispatch, t) => {
    axios(
        access.build('get', `/api/suppliers/building/` + state.building.id, null)
    )
    .then(response => {
        dispatch({ type: t, value: response.data });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, t, dispatch, false);
    })  
}

export const load = (pagination, state, dispatch, fn) => {
    let url = `/api/suppliers/page/building/` + state.building.id 
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false)
        dispatch({ type: constants.SUPPLIER_BUILDING, value: response.data });
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.SUPPLIER_BUILDING, dispatch, false);
    })  
}

export const saveSupplier = (state, isNew, dispatch, fn) => { 
    state.supplier.buildingId = state.building.id;
    axios(
        access.build('post', '/api/suppliers/', state.supplier)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = isNew ? 'Proveedor creado correctamente.' : 'Proveedor modificado correctamente.';
            }

            state.supplier = {};
            severity = 'info';

            load(null, state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedSupplier = (state, dispatch, fn) => {     
    axios(
        access.build('delete', '/api/suppliers/' + state.supplier.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Proveedor eliminado correctamente.';
            }

            state.supplier = {};
            severity = 'info';

            load(null, state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}
