import React, {useReducer, useEffect, useState, Fragment} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import classNames from 'classnames';
import * as constants from "../../Constants";
import CompanyDialog from "./CompanyDialog";
import CompanyReducer from "./reducers/CompanyReducer";
import AgentDialog from "./AgentDialog";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as action from "./actions/CompanyAction"
import * as utils from "../Utils";

const CompanyForm = ({ prevState, menuRightActive }) => {

    useEffect(() => {
        action.getCompany(state, state.companyId, dispatch);
        action.getIcon(state, dispatch);
        }, []
    );

    const [loading, setLoading] = useState(false)
    const [visibleCompany, setVisibleCompany] = useState(false)
    const [visibleAgent, setVisibleAgent] = useState(false)
    const [visibleAgentEdit, setVisibleAgentEdit] = useState(false)
    const [visibleEliminated, setVisibleEliminated] = useState(false)
    const [state, dispatch] = useReducer(CompanyReducer, {
        ...prevState,
        company: {},
        agent: {},
        visible: false,
        visibleAgentSelected: false
    });

    function createAgent(){
        state.agent = {}
        setVisibleAgent(true);
    }

    function selected(e){
        setVisibleAgentEdit(true)
        dispatch({type: constants.AGENT_SELECTED, value: e})
    }

    const confirmation = (
        <div>
            <Button label="Si" icon="pi pi-check" onClick={() => action.eliminatedAgent(state, dispatch, setVisibleEliminated)} />
            <Button label="No" icon="pi pi-times" onClick={() => setVisibleEliminated(false)} />
        </div>
    );

    function actionRow(value) {
        return <div>
            <i className="pi pi-pencil inline" style={{fontSize: '2em', color:'#f88d12' }} onClick = {() => selected(value)} title="Editar"></i>  
            <i className="pi pi-trash inline" style={{fontSize: '2em', color:'gray' }} onClick = {() => selectedElimindated(value)} title="Eliminar"></i>  
        </div>   
    }

    function selectedElimindated(e){
        setVisibleEliminated(true);
        dispatch({type: constants.AGENT_SELECTED, value: e})
    }

    function setNumberDecimal(event, state) {
        let amount = parseFloat(event).toFixed(2);
        dispatch({type: state, value: amount});
      }
      
    function actionUpdateCompany() {
        setLoading(true);
        action.updateCompany(state, dispatch, setLoading);
    }

    return (
        <Fragment>
            <Messages ref={(el) => state.messages = el}></Messages>
            <Dialog header="Operador" visible={visibleAgent} style={{maxWidth:'80%'}} onHide={() => setVisibleAgent(false)}>
                <AgentDialog
                    state = {state}
                    isNew = {true}
                    dispatch = {dispatch}
                    fn = {setVisibleAgent} />
            </Dialog>
            
            <Dialog header="Operador" visible={visibleAgentEdit} style={{maxWidth:'80%'}} onHide={() => setVisibleAgentEdit(false)}>
                <AgentDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch} 
                    fn = {setVisibleAgentEdit}/>
            </Dialog>

            <Dialog header="Eliminar operador" footer={confirmation} visible={visibleEliminated} style={{width: '50vw'}} modal={true} onHide={() => setVisibleEliminated(false)}>
                Confimación para eliminar operador
            </Dialog>

            <Dialog header="Administración" visible={visibleCompany} style={{maxWidth:'80%'}} onHide={() => setVisibleCompany(false)}>
                <CompanyDialog
                    state = {state}
                    isNew = {false}
                    dispatch = {dispatch}
                    fn = {setVisibleCompany} />
            </Dialog>

            {
                loading && 
                <div style={{width:'100%', minHeight: '100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader type="Rings" color="#00BFFF" height={50} width={100} />
                </div>
            }
            {
                !loading &&
                <div className={classNames({'menu-right-active': menuRightActive})}>
                    <div className="box-default">
                        <div className="p-md-1 p-sm-4 inline">
                            <p>Nombre</p>
                            <p>Dirección</p>
                            <p>Telefono</p>
                            <p>Cel.</p>
                            <p>Rut</p>
                        </div>
                        <div className="p-md-3 p-sm-5 inline">
                            <p>{state.company.name}</p>
                            <p>{state.company.address}</p>
                            <p>{state.company.phone ? state.company.phone : '-'}</p>
                            <p>{state.company.mobile ? state.company.mobile : '-'}</p>
                            <p>{state.company.rut ? state.company.rut : '-'}</p>
                        </div>
                        <div className="p-md-6 p-sm-5 inline">
                            {
                                state.icon &&
                                <img src={'data:image/png;base64,' + state.icon} alt="Icono de la administración"
                                    style={{width:'200px', height:'150px'}}></img>
                            }
                            {
                                !state.icon &&
                                <Fragment>
                                    <i className="pi pi-user" style={{fontSize: '3rem', color:'gray'}}></i>
                                    <h3 className="title-content">Sin logo asociado</h3>
                                </Fragment>
                                
                            }
                        </div>
                        <div className="p-md-12 p-sm-12">
                            <Button label="Editar" style={{margin:'10px'}} className="p-button-warning p-md-2" icon="pi pi-pencil"
                                        iconPos="right" onClick={() => setVisibleCompany(true)}/>
                        </div>
                    </div>

                    <div className="box-default-margin">
                        <div className="p-md-12">
                            <h3 className="title-content-menu">IPC</h3>
                        </div>
                        <div className="p-md-12">
                            <InputText value={state.company.ipc} className="p-md-2 p-sm-2" style={{marginBottom:'2px'}} type="number" min="1.00" step='0.01'
                                    onChange={(e) => setNumberDecimal(e.target.value, constants.COMPANY_IPC)} placeholder="IPC"/>   
                        </div>
                        <Button label="Guardar" style={{margin:'10px'}} className="p-button-warning p-md-1" icon="pi pi-pencil"
                                        iconPos="right" onClick={() => actionUpdateCompany()}/>
                    </div>

                    <div className="box-default-margin">
                        <div className="p-md-12">
                            <h3 className="title-content-menu">Operadores</h3>
                            <Button label="Crear" style={{marginBottom:'10px'}}
                                className="p-button-warning p-md-1" icon="pi pi-plus" iconPos="right" onClick={() => createAgent()}/>
                            {
                                state.company.agents && 
                                <Fragment>
                                    <DataTable value={state.company.agents} paginator={true} responsive={true} rows={10} selectionMode="single" selection={state.selectedAgent} 
                                        style={{marginTop:'10px'}} >
                                        <Column field="firstName" header="Nombre" sortable={true} />
                                        <Column field="lastName" header="Apellido" sortable={true}/>
                                        <Column field="email" header="Email" />
                                        <Column header="Acciones" body={(r) => actionRow(r)} style={{width: '15%'}}/>
                                    </DataTable> 
                                    <Button label="Guardar Operadores" style={{marginBottom:'10px'}} className="p-button-warning" icon="pi pi-pencil"
                                        iconPos="right" onClick={() => actionUpdateCompany()}/>
                                </Fragment>
                            }
                            

                            
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default CompanyForm;
