import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const load = (state, dispatch, fn) => {
    axios(
        access.build('get', `/api/coefficients/` + state.building.id , null)
    )
    .then(response => {
        state.message = undefined;
        summaryCoefficient(state, dispatch, response.data, fn);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COEFFICIENT_SUMMARY, dispatch, false);
    })  
}

export const search = (pagination, state, dispatch, fn) => {
    let url = `/api/coefficients/` + state.building.id 
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false)
        state.message = undefined;
        dispatch({ type: constants.COEFFICIENT_SEARCH, value: response.data });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COEFFICIENT_SEARCH, dispatch, false);
    })  
}

export const summaryCoefficient = (state, dispatch, coefficients, fn) => {
    axios(
        access.build('get', `/api/coefficients/summary/` + state.building.id, null)
    )
    .then(response => {
        state.message = undefined;
        fn(false);
        dispatch({ type: constants.COEFFICIENT_SUMMARY, value: response.data.value, data: coefficients });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COEFFICIENT_SUMMARY, dispatch, false);
    })  
}

export const unitsByCoefficient = (state, dispatch, fn) => {  
    axios(
        access.build('get', `/api/units/building/` + state.building.id, null)
    )
    .then(response => {
        fn(false);
        if (state.coefficient){
            state.coefficient.unit = response.data.filter(u => u.id === state.coefficient.unitId)[0];
        }
        dispatch({ type: constants.COEFFICIENT_LOAD_UNITS, value: response.data });  
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.COEFFICIENT_LOAD_UNITS, dispatch, false);
    })    
}


export const saveCoefficient = (state, dispatch, isNew, fn, setLoading) => { 
    state.coefficient.buildingId = state.building.id;
    state.coefficient.unitId = state.coefficient.unit.id;
    axios(
        access.build('post', '/api/coefficients' , state.coefficient)
    )
    .then((response) => {
        let message, severity;
        if (response.data.code === undefined) {
            message = isNew ? 'Coeficiente creado correctamente.' : 'Coeficiente modificado correctamente.';

            state.coefficient = {};
            
            severity = 'info';
            load(state, dispatch, fn);
        }
        else {
            message = response.data.message;
            severity = 'error';
        }

        setLoading(false)
        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedCoefficient = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/coefficients/' + state.coefficient.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Coeficiente eliminado correctamente.';

            state.coefficient = {};
            severity = 'info';
            
            load(state, dispatch, fn);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}
