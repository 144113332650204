import * as constants from '../../../Constants';
import CashReducer from '../../cash/reducers/CashReducer'

const IncomeExpenseReducer = (state, action) => {
    switch (action.type) {
        case constants.EXPENSE_SELECTED:
            state.expense = action.value;
            return {
                ...state
            };
        case constants.INCOME_SELECTED:
            state.income = action.value;
            return {
                ...state
            };
        case constants.FILTER_DATE:
            state.filter.date = action.value;
            return {
                ...state
            };
        case constants.FILTER_DOCUMENT:
            state.filter.document = action.value;
            return {
                ...state
            };   
        case constants.FILTER_EXPENSE_ID:
            state.filter.expenseId = action.value;
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_BUILDING:
            if (action.typeGroup === 'EXPENSE'){
                state.movementsByExpense = action.value;
            } else {
                state.movementsByIncome = action.value
            }
            return {
                ...state
            };
        case constants.SEARCH_PAYMENT_EXPENSES:
            state.paymentExpenses = action.value;
            return {
                ...state
            };
        default: return CashReducer(state, action);
    }
};

export default IncomeExpenseReducer;