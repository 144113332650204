import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";

export const search = (type, state, dispatch) => {  
    axios(
        access.build('get', `/api/units/building/` + state.building.id, null)
    )
    .then(response => {
        dispatch({ type: type, value: response.data });    
    })
    .catch((e) => {
        access.responseData('error', e.message, state, type, dispatch, false);
    })    
}

export const searchByBuilding = (pagination, state, dispatch, fn) => {  
    let url = `/api/units/page/building/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('get', url, null)
    )
    .then(response => {
        fn(false)
        dispatch({ type: constants.UNIT_BUILDING_SEARCH, value: response.data });    
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.UNIT_BUILDING_SEARCH, dispatch, false);
    })    
}

export const searchPeoplesByUnit = (state, dispatch) => {  
    axios(
        access.build('get', `/api/units/peoples/` + state.unit.id, null)
    )
    .then(response => {
        dispatch({ type: constants.UNIT_LOAD_PEOPLES_SELECTED, peoples: state.peoples, targets: response.data });    
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.UNIT_LOAD_PEOPLES_SELECTED, dispatch, false);
    })    
}

export const searchPeoplesByCompany = (state, dispatch) => {  
    axios(
        access.build('get', `/api/peoples/company/` + state.companyId, null)
    )
    .then(response => {
        dispatch({ type: constants.PEOPLE_SEARCH, value: response.data });    
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.PEOPLE_SEARCH, dispatch, false);
    })    
}

export const saveUnit = (state, isNew, dispatch, fn, setLoading) => {
    if (state.unit.building){
        state.unit.buildingId = state.unit.building.id;
    }

    state.unit.peoples = state.targets;
    axios(
        access.build('post', '/api/units/', state.unit)
     )
    .then((response) => {
        let message, severity;
        if (response.status === 200 && !response.data.code) {
            if (isNew){
                message = 'Unidad creada correctamente.';
            } else {
                state.selectedMovementGroup = false;
                message = 'Unidad modificada correctamente.';
            }
            severity = 'info';
        } else {
            message = response.data.message ? response.data.message : 'Error al guardar.';
            severity = 'error';
        }

        state.unit = {
            notRented: true
        }
        fn(false);setLoading(false);
        if(state.building){
            searchByBuilding(null, state, dispatch, setLoading);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const eliminatedUnit = (state, dispatch, fn, setLoading) => {     
    axios(
        access.build('delete', '/api/units/' + state.unit.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            if (response.data.code === undefined) {
                message = 'Unidad eliminado correctamente.';
            }

            state.unit = {
                notRented: true
            };
            severity = 'info';
            fn(false)
        } else {
            message = response.data.message;
            severity = 'error';
        }

        setLoading(false);
        if(state.building){
            searchByBuilding(null, state, dispatch, setLoading);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}
