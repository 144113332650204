import * as configurations from "../Configurations";


export function changeStyleSheetUrl(id, value, prefix) {
  let element = document.getElementById(id);
  let urlTokens = element.getAttribute('href').split('/');
  urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
  let newURL = urlTokens.join('/');

  replaceLink(element, newURL);
}

function replaceLink(linkElement, href) {
  if(isIE()){
      linkElement.setAttribute('href', href);
  }
  else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          cloneLinkElement.setAttribute('id', id);
      });
  }
}

function isIE() {
  return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
}

export const formatNumber = (value, decimal = 2, separator = ',') => {
  if (value == null)
    value = 0;  
  return Number(value).toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)
}

export const isAdmin = (state) => {
  let admin = false;
  if (!state.user){
    getDataStore(state);
  }
  
  return admin;
}

export const isAdminByUser = (user) =>{
  let admin = false;
  if (user && user.roles){
    user.roles.forEach(role => {
      if (role.name === 'ADMIN'){
        admin = true;
      }
    });  
  }

  return admin;
}

export const isUser = (user) =>{
  let rolUser = false;
  if (user && user.roles){
    user.roles.forEach(role => {
      if (role.name === 'USER'){
        rolUser = true;
      }
    });  
  }

  return rolUser;
}

export const isSupplierByUser = (user) =>{
  let isSupplier = false;
  if (user && user.roles){
    user.roles.forEach(role => {
      if (role.name === 'SUPPLIER'){
        isSupplier = true;
      }
    });  
  }

  return isSupplier;
}

export const getDataStore = (state) => {
  if (!state.user){
    let dataUser = localStorage.getItem('user')
    state.user = JSON.parse(dataUser).user;
    state.completed = JSON.parse(dataUser).completed;
  }
  
  let user = state.user;
  if (user && user.roles){
    user.roles.forEach(role => {
      if (role.name === 'ADMIN'){
        state.admin = true;
      }
    });
    
    if (!state.admin){
      user.roles.forEach(role => {
        if (role.name === 'SUPPLIER'){
          state.isSupplier = true;
        }
      });
    }
  }

  state.suppliers = user.suppliers;
  if (!state.companyId){
    let companyId = localStorage.getItem('companyId');
    if (companyId !== "undefined"){
      state.companyId = companyId;
    }
  }
  
  let building = localStorage.getItem('building');
  if (building !== "undefined") {
    state.building = JSON.parse(building);
  }

  let buildings = localStorage.getItem('buildings');
  if (buildings !== "undefined") {
    state.buildings = JSON.parse(buildings);
  }
  
  state.forbidden = localStorage.getItem('forbidden');
}

export const isPermission = (state, permission) => {
  let isPermission = false;
  if (state.user.permissions){
    state.user.permissions.forEach(p => {
      if (p.name === permission){
        isPermission = true;
      }
    });  
  }

  return isPermission;
}

export function formatCurrency(value, currencySymbol) {
  return value != null ? value.toLocaleString('en-US', { style: 'currency', currency: currencySymbol, currencyDisplay: 'code' }) : value;
}

export function priceRow(value, currencySymbol) {
  return formatCurrency(value, currencySymbol);
}

export function percentageRow(value) {
  return value + '%';
}

export function getConvertPeriod(period){
  return String(period.getFullYear()).concat(configurations.appendZeroes(period.getMonth() + 1));
}

export function dateRow(value){
  return new Date(value).toLocaleString();
}

export function dateToString(event){
  let options = { dateStyle: 'short' };
  return event.toLocaleString('en', options);
}

export function statusRow(status){
  if (status === 'PENDING'){
    return 'Pendiente'
  }
  if (status === 'PROCESS'){
    return 'En proceso'
  }
  if (status === 'FINALIZED'){
    return 'Finalizado'
  }
  if (status === 'FUTURE'){
    return 'Programado'
  }
  if (status === 'CANCELLED'){
    return 'Cancelado'
  }
  if (status === 'DECLINED'){
    return 'Rechazado'
  }
}

export function getDate(date){
  return String(date.getFullYear()).concat(configurations.appendZeroes(date.getMonth() + 1))
      .concat(configurations.appendZeroes(date.getDate()));
}

export const period = (n) => {
  let now = new Date();
  now.setMonth(now.getMonth() + n);
  return String(now.getFullYear()).concat(configurations.appendZeroes(now.getMonth() + 1));
}

export const getPeriod = (state) => {
  let data = state.period ? 
      String(state.period.getFullYear()).concat(configurations.appendZeroes(state.period.getMonth() + 1)) : 
      period(0);
  return data; 
}

export function removeItem(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function isEmpty(obj) {    
    return Object.keys(obj).length === 0;
}