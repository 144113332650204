import React, {useEffect, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {FileUpload} from 'primereact/fileupload';

import * as constants from "../../Constants";
import * as configurations from "../../Configurations";
import * as action from "./actions/IncomeExpenseAction"
import * as actionSupplier from "../supplier/actions/SupplierAction";
import * as actionMovementGroup from "../movement/group/actions/MovementGroupAction";

const ExpenseDialog = ({ state, dispatch, isNew, fn, setLoading }) => {

    useEffect(() => {
        setLoading(true)
        state.expense.expires = new Date()      
        actionSupplier.suppliers(state, dispatch, constants.CASH_EXPENSE_SUPPLIERS);
        actionMovementGroup.movementGroupsByType(state, dispatch, 'EXPENSE', setLoading);
      }, [])

    const upload = (event) => {
        dispatch({type: constants.CASH_EXPENSE_UPLOAD, value: event.files[0]})
    };

    const onSave = () => {
        action.saveExpense(state, isNew, dispatch, fn, setLoading);
    }

    const clear = () => {
        dispatch({type: constants.CASH_EXPENSE_SUPPLIER_SELECTED, value: undefined});
    };

    return (
        <div className="p-fluid">
            <form className="p-fluid" onSubmit={onSave}>
                <div className="p-md-12">
                    <div className="p-md-6">
                        {
                            isNew &&
                            <Calendar locale={configurations.es} placeholder="Fecha" showIcon={true} value={state.expense.expires} dateFormat="yy-mm-dd"
                            tooltip="Fecha que expira el egreso mensual"
                            onChange={(e) => dispatch({type: constants.CASH_EXPENSE_EXPIRES_SELECTED, value: e.value})} required/>
                        }
                        
                    </div>
                    <div className="p-md-10">
                        <InputText value={state.expense.name} className="p-md-12" placeholder="Descripción" 
                            onChange={(e) => dispatch({type: constants.CASH_EXPENSE_NAME, value: e.target.value})} required/>
                    </div>
                    <div className="p-md-6">
                        <InputText value={state.expense.amount} placeholder="Monto" tooltip={'Monto en ' + `${state.building.currency}`}
                            onChange={(e) => dispatch({type: constants.CASH_EXPENSE_AMOUNT, value: e.target.value})} required/>
                    </div>
                    
                    { state.building &&
                        <Fragment>
                            <div className="p-md-6">
                                <Dropdown optionLabel="name" value={state.expense.movementGroup} options={state.movementsByExpense}
                                    style={{borderLeft: !state.expense.movementGroup ? '3px solid salmon' : ''}} 
                                    onChange={(e) => dispatch({type: constants.CASH_EXPENSE_MOVEMENT_GROUP_SELECTED, value: e.value})} placeholder="Grupo" required/>
                            </div>

                            <div className="p-md-8">
                                <div className="p-md-9 inline" style={{marginLeft:'-5px'}}>
                                    <Dropdown optionLabel="name" value={state.expense.oSupplier} options={state.suppliers}
                                        onChange={(e) => dispatch({type: constants.CASH_EXPENSE_SUPPLIER_SELECTED, value: e.value})} placeholder="Proveedor"/>
                                </div>
                                {
                                    state.expense.oSupplier &&
                                    <div className="p-md-2 inline">
                                        <i className="pi pi-trash" style={{ marginLeft: '10px',fontSize: '2em', color:'#f88d12', verticalAlign:'middle' }} onClick={() => clear()} title="Limpiar filtros"></i>  
                                    </div>
                                }
                                
                            </div>
                        </Fragment>
                    }

                    <div className="p-md-12">
                        <FileUpload mode="basic" 
                            name="name" 
                            accept=".jpg, .png, .pdf" 
                            url="./upload"
                            mode="advanced"
                            maxFileSize={1000000} //1MB
                            customUpload={true} 
                            uploadHandler={(event) => upload(event)} 
                            chooseLabel="Seleccionar"
                            uploadLabel="Cargar"
                            cancelLabel="Cancelar"/>

                        {state.upload &&
                            <h3 className="title-content">Archivo cargado correctamente</h3>
                        }
                    </div>
                    <div className="p-md-12">
                        <InputTextarea rows={3} cols={20} value={state.expense.description} placeholder="Observaciones" 
                            autoResize={true} onChange={(e) => dispatch({type: constants.CASH_EXPENSE_DESCRIPTION, value: e.target.value})}/>
                    </div>

                </div>

                <div className="p-grid p-md-3">
                    <Button label="Crear" className="p-button-warning" icon="pi pi-plus" iconPos="right" />
                </div>          
            </form>    
        </div>
    );
}

export default ExpenseDialog;
