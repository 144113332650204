import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as actionPayment from "../../cash/actions/PaymentAction";
import * as actionAccount from "../../movement/account/actions/MovementAccountAction"

export const buildingsByRole = (companyId, state, dispatch) => {
    if (state.admin){
        buildingsByCompany(companyId, state, dispatch)
    } else {
        let buildings = localStorage.getItem('buildings');
        if (buildings !== "undefined") {
            buildings = JSON.parse(buildings);
        }
        dispatch({ type: constants.COMPANY_BUILDING_LOAD, value: buildings }); 
    }
    
}

export const buildingsByCompany = (companyId, state, dispatch) => {
    axios(
        access.build('get', `/api/buildings/company/${companyId}`, null)
    )
    .then(response => {
        dispatch({ type: constants.COMPANY_BUILDING_LOAD, value: response.data }); 
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.COMPANY_BUILDING_LOAD, dispatch, false);
    })
}

export const selectedBuilding = (building, state, dispatch) => {
    localStorage.setItem('building', JSON.stringify(building));
    dispatch({ type: constants.BUILDING_SELECTED, value: building }); 
}

export const getBuilding = (state, dispatch, building, typeReducer) => {
     axios(
        access.build('get', '/api/buildings/' + building.id, null)
    )
    .then(response => {
        dispatch({ type: typeReducer, value: response.data });   
    })
    .catch((e) => {
        access.responseData('error', e.message, state, typeReducer, dispatch, false);
    })    
}

export const getBuildingOnSelected = (state, dispatch, building, fnSelected, fnView) => {
    axios(
       access.build('get', '/api/buildings/' + building.id, null)
   )
   .then(response => {
        if (response.status === 200) {
            fnSelected(response.data);
        } else {
            fnSelected(building);
        }
        fnView(true);
   })
   .catch((e) => {
       access.responseData('error', e.message, state, constants.BUILDING_DIALOG, dispatch, false);
   })    
}

export const getBuildingOnEdit = (state, dispatch, building, fnEdit) => {
    axios(
       access.build('get', '/api/buildings/' + building.id, null)
   )
   .then(response => {
        if (response.status === 200) {
            state.buildingData = response.data;
        } else {
            state.buildingData = building;
        }

        state.buildingData.externalAbitab = {}
        state.buildingData.externalRedPago = {}
        fnEdit(true);
   })
   .catch((e) => {
       access.responseData('error', e.message, state, constants.BUILDING_DIALOG, dispatch, false);
   })    
}

export const saveBuilding = (state, isNew, dispatch, fn) => {
    state.buildingData.companyId = state.companyId;

    if (isNew) {
        state.buildingData.closeDay = 1;
    } else {
        if (state.buildingData.oType){
            state.buildingData.type = state.buildingData.oType.type;
        }
        if (state.buildingData.oExecution){
            state.buildingData.executionType = state.buildingData.oExecution.type;
        }
    }

    if (state.buildingData.oCurrency){
        state.buildingData.currency = state.buildingData.oCurrency.currency;
    }

    axios(
        access.build('post', '/api/buildings/', state.buildingData)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200 && response.data.code === undefined) {
            if (isNew){
                state.buildings.push(response.data);
                localStorage.setItem('buildings', JSON.stringify(state.buildings));
                message = 'Edificio creada correctamente.';
            } else {
                if (state.building != undefined && state.buildingData.id === state.building.id){
                    localStorage.setItem('building', JSON.stringify(response.data));
                }

                state.selectedBuilding = false;
                message = 'Edificio modificada correctamente.';
            }

            saveExternalCharge(state, state.buildingData.externalAbitab, 'ABITAB', dispatch);
            saveExternalCharge(state, state.buildingData.externalRedPago, 'REDPAGOS', dispatch);

            state.buildingData = {};
            severity = 'info';

            buildingsByCompany(state.companyId, state, dispatch);
            fn(false)
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false)
        }
        access.responseData(severity, message, state, constants.BUILDING_DIALOG, dispatch, false);

    })
    .catch((e) => {
        fn(false)
        access.responseData('error', e.message, state, constants.BUILDING_DIALOG, dispatch, false);
    });
}

export const eliminatedBuilding = (state, dispatch, fn) => { 
    axios(
        access.build('delete', '/api/buildings/' + state.buildingData.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200) {
            message = 'Edificio eliminado correctamente.';

            state.buildingData = {};
            severity = 'info';

            buildingsByCompany(state.companyId, state, dispatch);
            fn(false);
        } else {
            message = response.data.message;
            severity = 'error';
            fn(false);
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const alertsByBuilding = (state, dispatch) => {
    axios(
        access.build('get', `/api/alerts/${state.buildingData.id}`, null)
    )
    .then(response => {
        state.alertPendingPay = {}
        state.alertPendingPay.delay = state.buildingData.closeDay
        state.alertPendingPay.type = 'PENDING_PAY'
         state.alertPendingPay.buildingId = state.buildingData.id
        
        state.alertDebtor = {}
        state.alertDebtor.type = 'DEBTOR'
        state.alertDebtor.buildingId = state.buildingData.id

        if (response.data){
            response.data.forEach(alert => {
                if (alert.type === 'PENDING_PAY'){                    
                    state.alertPendingPay.delay = alert.delay;
                    state.alertPendingPay.lastExecution = alert.lastExecution;
                    state.alertPendingPay.id = alert.id;
                } else if (alert.type === 'DEBTOR'){                    
                    state.alertDebtor.frecuency = alert.frecuency;
                    state.alertDebtor.lastExecution = alert.lastExecution;
                    state.alertDebtor.id = alert.id; 
                }
            });
        }

        externalCharge(state, dispatch);
        dispatch({ type: constants.BUILDING_ALERTS, value: response.data }); 
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.BUILDING_ALERTS, dispatch, false);
    })
}

export const saveAlert = (alert, state, dispatch) => {
    axios(
        access.build('post', '/api/alerts/', alert)
    )
    .then((response) => {
       
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.BUILDING_DIALOG, dispatch, false);
    });
}

export const search = (period, state, dispatch, fn) => {
    let building = JSON.parse(localStorage.getItem('building')); 
    if (building != null){
        axios(
            access.build('get', `/api/movement/summary/` + building.id + `/` + period, null)
        )
        .then(response => {
            fn(false);
            summary(response.data, building, state, dispatch, fn);
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.BUILDING_DASHBOARD_SEARCH, dispatch, false);
        }) 
    }
    
}

const summary = (response, building, state, dispatch, fn) => {
    graphPayment(response);
    graphExpense(response);
    size(building, response);
    actionAccount.search(state, dispatch, fn);
    state.building = building;
    fn(false);
    
    let viewPhone = viewPhoneOnlyDialog();
    dispatch({ type: constants.BUILDING_DASHBOARD_SEARCH, data: response, buildingState: building, viewPhoneState: viewPhone });  
}

function viewPhoneOnlyDialog(){
    let viewPhone = localStorage.getItem('view_phone_admin');
    if (!viewPhone){
        localStorage.setItem("view_phone_admin", true);
        return true;
    } 
    return false;
}

const graphExpense = (response) => {
    let data = graph(response.value.expenses, 'egresos', true, false);
    response.value.graphExpense = data;
}

const graphPayment = (response) => {
    let data = graph(response.value.payments, null, false, true);
    response.value.graphPayment = data;
}

const graph = (value, label, defaultValue, mapperLabel) => {
    let labels = [];
    let totals = [];
    if (defaultValue){
        labels.push('');
        totals.push(0);
    }
    
    value.forEach(e => {
        let name = mapperLabel ? actionPayment.namePaymentByType(e.type) : e.type;
        labels.push(name);
        totals.push(e.pay);
    }); 
    let data = {
        labels: labels,
        datasets: [
            {
                data: totals,
                label: label,
                backgroundColor: [
                    "#BBFA73",
                    "#F59A93",
                    "#FBEF8C",
                    "#91CEFC",
                    "#C091FC",
                    "#91FCE0",
                    "#FFCC9E"
                ]
            }]
        };
    return data;
}

const size = (building, response) => {
    let data = [];
    let total = building.quotas.SUMMARY ? building.quotas.SUMMARY : 1;
   
    let quota = {};
    quota.name = 'Documentos'; 
    quota.size = building.quotas.DOCUMENTATION ? building.quotas.DOCUMENTATION.size : 0;
    quota.percentage = (quota.size*100) / total;
    data.push(quota);

    quota = {};
    quota.name = 'Facturas/Boletas'; 
    quota.size = building.quotas.EXPENSE ? building.quotas.EXPENSE.size : 0;
    quota.percentage = (quota.size*100) / total;
    data.push(quota);

    response.value.sizes = data;
}

export const refresh = (state, dispatch, type) => {
    state.message = undefined;
    dispatch({ type: type, value: JSON.parse(localStorage.getItem('building')) });  
}

export const externalCharge = (state, dispatch) => {
    axios(
        access.build('get', `/api/external/liquidations/` + state.buildingData.id, null)
    )
    .then(response => {
        dispatch({type: constants.BUILDING_EXTERNAL_LOAD, value: response.data});
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.BUILDING_EXTERNAL_LOAD, dispatch, false);
    })   
}

export const saveExternalCharge = (state, external, type, dispatch) => {
    if (external){
        external.paymentType = type;
        external.buildingId = state.buildingData.id;
        axios(
            access.build('post', '/api/external', external)
        )
        .then((response) => {
            if (response.status !== 200) {
                access.responseData('error', response.message, state, constants.BUILDING_DIALOG, dispatch, false);
            }
        })
        .catch((e) => {
            access.responseData('error', e.message, state, constants.BUILDING_DIALOG, dispatch, false);
        });
    }
}

export const uploadDocument = (event, state, dispatch, fn) => {
    const data = new FormData();
    data.append('file', event.files[0]);
    data.append('name', 'document');

    axios(
        access.build('post', `/api/buildings/${state.buildingData.id}/upload/document`  , data)
    )
    .then((response) => {
        let message, severity;
        severity = 'info';
        message = 'Archivo cargado correctamente.'
        state.load = {};
        fn(false);
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const deleteDocument = (value, state, dispatch, fn) => {
    axios(
        access.build('delete', `/api/buildings/${state.buildingData.id}/document/` + value  , null)
    )
    .then((response) => {
        let message, severity;
        severity = 'info';
        message = 'Archivo eliminado correctamente.'
        fn(false);
        access.responseData(severity, message, state, constants.DEFAULT , dispatch, false);
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}

export const downloadDocument = (value, state, dispatch, fn) => {
    fn(true)
    let ext = value.includes('pdf') ? `pdf/` : `img/`;
    axios( 
        access.buildType('get',  `/api/buildings/download/document/` + state.building.id + `/` + value, null, 'blob')
    )
    .then(response => {
        fn(false)
        const file = new Blob(
            [response.data], 
            {type: (ext === 'pdf') ? 'application/pdf' : ('image/'+ext) });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
        fn(false);
    });
   
}

export const cloneBuilding = (state, dispatch, building) => { 
    axios(
        access.build('post', '/api/buildings/clone/' + building.id, null)
    )
    .then((response) => {
        let message, severity;
        if (response.status === 200 && response.data.code === undefined) {
            message = 'El edificio fue copiado correctamente.';
            severity = 'info';

            buildingsByCompany(state.companyId, state, dispatch);
        } else {
            message = response.data.message;
            severity = 'error';
        }

        access.responseData(severity, message, state, constants.DEFAULT, dispatch, false);
    })
    .catch((e) => {
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    });
}