import * as constants from "../../../Constants";
import axios from "axios";
import * as access from "../../AccessAction";
import * as utils from "../../Utils";
import * as actionPaymentExpense from '../../payment_expense/actions/PaymentExpenseAction';
import * as actionPayment from './PaymentAction';

export const search = (state, dispatch, fn) => {
    let period = utils.getPeriod(state);
    let building = JSON.parse(localStorage.getItem('building')); 
    
    summary(period, building, state, dispatch, fn);
}

export const summary = (period, building, state, dispatch, fn) => {
    if (building){
        let param = state.unit != null ? `?unitId=` + state.unit.id : `?unitId=`;
 
        axios(
            access.build('get', `/api/movement/summary/flow-cash/` + building.id + `/` + period + param, null)
        )
        .then(response => {
            state.message = undefined;
            paginationByIdx(null, building, state, dispatch, fn); 
            dispatch({ type: constants.CASH_LOAD, value: response.data.value, buildingState: building }); 
        })
        .catch((e) => {
            fn(false);
            access.responseData('error', e.message, state, constants.CASH_LOAD, dispatch, false);
        })  
    }
}

export const pagination = (pagination, state, dispatch, fn) => {
    let building = JSON.parse(localStorage.getItem('building')); 
    paginationByIdx(pagination, building, state, dispatch, fn);
}

export const paginationByIdx = (pagination, building, state, dispatch, fn) => {
    if (state.activeIndex === 0)
        actionPayment.searchPay(pagination, building, state, dispatch, fn);
    else if (state.activeIndex === 1){
        let period = utils.getPeriod(state);
        actionPayment.searchPaymentExpense(pagination, period, building, state, dispatch, fn);
    } else if (state.activeIndex === 2)
        actionPayment.searchPayEliminated(pagination, building, state, dispatch, fn);
}

export const download = (executionId, state, dispatch, fn) => {
    fn(true)
    axios( 
        access.buildType('get', 
        `/api/report/` + state.companyId + `/building/` + state.building.id + `/` + executionId, null, 'blob')
    )
    .then(response => {
        fn(false)
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
        fn(false);
    });
   
}

export const searchByFilter = (pagination, state, dispatch, fn, setLoading) => {
    setLoading(true)
    if (state.activeIndex === 0)
        searchPayByFilter(pagination, state, dispatch, fn, setLoading);
    if (state.activeIndex === 1){
        searchPaymentExpenseByFilter(pagination, state, dispatch, fn, setLoading);
    }
    if (state.activeIndex === 2)
        searchPayByFilteEliminated(pagination, state, dispatch, fn, setLoading);
}

const searchPayByFilter = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.unit){
        state.filter.unitId = state.filter.unit.id;
    }
    if (state.filter.paymentType){
        state.filter.type = state.filter.paymentType.type;
    }
    
    let url = `/api/payments/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('post', url, state.filter)
    )
    .then(response => {
        fn(false)
        setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_PAYMENT, value: response.data });
    })
    .catch((e) => {
        fn(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    })
}

const searchPaymentExpenseByFilter = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.movementGroup){
        state.filter.groupId = state.filter.movementGroup.id;
    }
    if (state.filter.oSupplier){
        state.filter.supplierId = state.filter.oSupplier.id;
    }

    let url = `/api/payment_expense/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('post', url, state.filter)
    )
    .then(response => {
        fn(false);setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_PAYMENT_EXPENSE, value: response.data });
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    })  
}

const searchPayByFilteEliminated = (pagination, state, dispatch, fn, setLoading) => {
    if (state.filter.unit){
        state.filter.unitId = state.filter.unit.id;
    }
    if (state.filter.paymentType){
        state.filter.type = state.filter.paymentType.type;
    }

    state.filter.eliminated = true;
    
    let url = `/api/payments/filter/` + state.building.id;
    if (pagination === null){
        state.first = 0;
    } else {
        url += '?page=' + pagination.page + '&size=' + pagination.rows;
    }

    axios(
        access.build('post', url, state.filter)
    )
    .then(response => {
        fn(false);setLoading(false);
        dispatch({ type: constants.CASH_SEARCH_FILTER_PAYMENT_ELIMINATED, value: response.data });
    })
    .catch((e) => {
        fn(false);setLoading(false);
        access.responseData('error', e.message, state, constants.DEFAULT, dispatch, false);
    })
}