import * as constants from '../../../../Constants';
import MovementAccountReducer from '../../account/reducers/MovementAccountReducer';

const MovementGroupReducer = (state, action) => {
    switch (action.type) {
        case constants.MOVEMENT_GROUP_DIALOG:
            return {
                ...state,
                visibleMovementGroup: action.value
            };
        case constants.MOVEMENT_GROUP_DIALOG_SELECTED:
            return {
                ...state,
                visibledMovementGroupSelected: action.value
            };
        case constants.MOVEMENT_GROUP_NAME:
            state.movementGroup.name = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_TYPE:
            state.movementGroup.oType = action.value;
            state.movementGroup.type = state.movementGroup.oType.code;
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_ACCOUNT:
            state.movementGroup.account = action.value;
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_CHECK_PRINT:
            state.movementGroup.toPrint = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_APPLY_COEF:
            state.movementGroup.applyCoefficient = action.value
            return {
                ...state
            };
        case constants.MOVEMENT_GROUP_BUILDING_SELECTED:
            return {
                ...state,
                movements: action.value.content,
                totals: action.value.totalElements
            };
        case constants.MOVEMENT_GROUP_SELECTED:
            state.movementGroup = action.value;
            state.movementGroup.oType = state.types.filter(type => type.code === state.movementGroup.type)[0];
            state.movementGroup.oGroupType = state.groups.filter(group => group.code === state.movementGroup.groupType)[0];
            return {
                ...state,
                visibledMovementGroupSelected: true
            };     
        default: return MovementAccountReducer(state, action);
    }
};

export default MovementGroupReducer;